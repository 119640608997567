<div class="list-wrapper">
  <div class="list">
    <div class="item" *ngFor="let starter of textList(); let idx = index; let last = last; trackBy: trackByIndex">
      <u-input
        #inputItem
        class="item-input"
        [model]="starter"
        [inlineExtraIcon]="state !== 'readonly' && clearIcon"
        [inlineExtraIconPos]="'right'"
        [inlineExtraIconStyle]="{ fontSize: '9px', color: 'var(--color-text-tertiary)'}"
        [pTooltipTextInlineExtraIcon]="'Clear'"
        (onInlineExtraIconClick)="onRemoveItem(idx)"
        (onChange)="onChangeItem($event, idx)"
        inputSize="small"
        [label]="label"
        [placeholder]="placeholder"
        [readonly]="state === 'readonly'"
        [disabled]="state === 'disabled'"
        [inputFocus]="last && focusLastInput"
        (keydown.enter)="$event.stopPropagation()"
      ></u-input>
    </div>
  </div>
  @if (state !== 'readonly') {
    <u-button
      [versionNumber]="2"
      [label]="'Add'"
      [styleType]="'text'"
      [typeElement]="'primary'"
      [stateElement]="textList().length >= limit || disableAddBtn() || state === 'disabled' ? 'disabled' : 'regular'"
      [sizeElement]="'large'"
      fontIcon="mac-maximize-plus"
      (onClick)="onAddItem()"
    >
    </u-button>
  }
</div>
