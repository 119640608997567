<div class="home-side-panel-container">
  <div class="content">
    <div class="headline">
      <div class="user-name">Hi {{ userFirstName }} ,</div>
      <div class="sub-title">{{ subTitleMessage }}</div>
    </div>
    <div class="search-container" data-cy="home-search-container">
      <div class="input-search">
        <u-input
          id="inputHome"
          #searchElement
          (onChange)="onSearch($event)"
          [model]="search"
          [inputSize]="'large'"
          [placeholder]="placeHolder"
          [isSearchInput]="true"
          [isLoading]="false"
          [inlineExtraIcon]="searchIcon"
          [inlineExtraIconPos]="'left'"
          (click)="onInputClick()"
          data-cy="home-search-input"
          [ngClass]="{ 'default-popup-open': isSearchPopupOpen && !search }"
          [styles]="{ paddingRight: (!isSearchPopupOpen ? INPUT_PADDING : ENTER_INDICATION_INPUT_PADDING) + 'px' }"
          [ignoreEnter]="true"
        >
          <div (click)="onEnterPressed()" class="enter-indication" *ngIf="search && !selectedItem && isSearchPopupOpen">
            <u-icon [model]="{ type: 'font', value: 'icon-key-enter' }"></u-icon>
            <span>to search</span>
          </div>
        </u-input>
      </div>
      @if (!(isSearchPopupOpen && search) || emptySearchItems) {
        <default-view-side-panel-home></default-view-side-panel-home>
      }
    </div>
  </div>
</div>
