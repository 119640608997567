import { Constants } from '@local/common';
import { SideBarOptions } from './settings-sidebar.component';

export const sideBarOptionsData: SideBarOptions[] = [
  {
    sections: [
      {
        title: 'My Profile',
        icon: {
          type: 'font',
          value: 'icon-user1',
        },
        path: 'my-profile',
        telemetry: 'my-profile',
      },
      {
        title: 'Workspace',
        icon: {
          type: 'font',
          value: 'icon-settings-company',
        },
        path: 'workspace',
        telemetry: 'workspace.general',
      },
      {
        title: 'Search',
        icon: {
          type: 'font',
          value: 'icon-search-icon-fixed',
        },
        path: 'search',
        telemetry: 'search',
      },
      {
        title: 'Sources',
        icon: {
          type: 'font',
          value: 'icon-apps',
        },
        path: 'sources',
        telemetry: 'sources',
      },
      {
        title: 'Unleash AI',
        icon: {
          type: 'font',
          value: 'icon-answer',
        },
        path: 'unleash-ai',
        telemetry: 'unleash-ai',
      },
      {
        title: 'Assistants',
        icon: {
          type: 'font',
          value: 'icon-assistant',
        },
        path: 'assistants',
        telemetry: 'assistants',
      },
      {
        title: 'Analytics',
        icon: {
          type: 'font',
          value: 'icon-analytics',
        },
        path: 'analytics',
        telemetry: 'analytics',
      },
      {
        title: 'API Keys',
        icon: {
          type: 'font',
          value: 'icon-file-key',
        },
        path: 'api-keys',
        telemetry: 'api-keys',
        disableFlag: Constants.ENABLED_API_KEYS,
      },
    ],
  },
  {
    title: 'SECURITY',
    sections: [
      {
        title: 'Users',
        icon: {
          type: 'font',
          value: 'icon-members',
        },
        path: 'users',
        telemetry: 'security.users',
      },
      {
        title: 'Permissions',
        icon: {
          type: 'font',
          value: 'icon-user-permission',
        },
        path: 'permissions',
        telemetry: 'security.permissions',
      },
    ],
  },
  {
    title: 'ADD-ONS',
    sections: [
      {
        title: 'Experiences',
        icon: {
          type: 'font',
          value: 'icon-experiences',
        },
        path: 'experiences',
        telemetry: 'addons.experiences',
      },
      {
        title: 'Slack',
        icon: {
          type: 'img',
          value: { lightUrl: '/assets/bar/results/share/slack_icon.svg' },
        },
        path: 'addons-slack',
        telemetry: 'addons.addons-slack',
      },
    ],
  },
];
