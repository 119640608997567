import { Injectable } from '@angular/core';
import { Filters, RecentSearches } from '@local/client-contracts';
import { EventsService, LogService, ServicesRpcService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { ReplaySubject } from 'rxjs';
import { FiltersService } from '../../services/filters.service';
import { HubService } from '../../services/hub.service';
import { RecentSearchesRpcInvoker } from '../../services/invokers/recent-searches-rpc-invoker';
import { NavTreeService } from '../../services/nav-tree.service';
import { FilterSuggestionData } from './models/filter-suggestion-data';
import { RecentSearchItem } from './models/results-types';
import { PARAMS_STATE_KEYS } from '../../services/search-params.service';

@Injectable()
export class RecentSearchesService {
  private logger: Logger;
  private service: RecentSearches.Service;
  private _searches$ = new ReplaySubject<RecentSearches.Item[]>(1);

  get searches$() {
    return this._searches$;
  }

  constructor(
    private logService: LogService,
    private services: ServicesRpcService,
    private navTreeService: NavTreeService,
    private eventsService: EventsService,
    private routerService: RouterService,
    private hubService: HubService,
    private filtersService: FiltersService
  ) {
    this.logger = this.logService.scope('RecentSearchesService');
    this.service = this.services.invokeWith(RecentSearchesRpcInvoker, 'recentsearches');
    this.service.current$.subscribe((s) => this._searches$.next(s));
  }

  clearAll(): Promise<void> {
    return this.service.clearAll();
  }

  add(query: string, filters: Filters.Values, nodeId: string, sessionId: string) {
    if (!query?.length) return;
    const item = { query, filters, nodeId, sessionId, timestamp: Date.now() };
    this.service.add(item);
  }

  async reSearch(recentSearch: RecentSearchItem, index: number, assistantId?: string, prefixUrl?: string) {
    const node = await this.navTreeService.get(recentSearch.nodeId);
    this.eventsService.event('search_page.recent_searches', { label: index + '' });
    const nodeId = this.navTreeService.getNodeUrl(node) || 'search';
    let url = `${prefixUrl || ''}/${nodeId}`;
    if (assistantId) {
      url += `?${PARAMS_STATE_KEYS.assistantId}=${assistantId}`;
    }

    await this.routerService.navigateByUrl(url);
    this.hubService.query = recentSearch.query;
    for (const tag of recentSearch.tags || []) {
      const filter = <FilterSuggestionData>tag.data;
      this.filtersService.addFilter(filter.name, filter.value, true, 'pre');
    }
  }
}
