import { Injectable } from '@angular/core';
import { capitalCase } from '@local/ts-infra';
import * as markdownItAttrs from '@marked-it/markdown-it-attrs';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import * as markdownItBracketedSpan from 'markdown-it-bracketed-spans';
import { full as markdownItEmoji } from 'markdown-it-emoji';

@Injectable()
export class ResultMarkdownService {
  private markdownIt: MarkdownIt;

  constructor() {
    this.init();
  }

  render(markdown: string, markdownData?: any): string {
    return this.markdownIt.render(markdown, markdownData);
  }

  private init() {
    this.markdownIt = new MarkdownIt({
      breaks: true,
      html: true,
      linkify: true,
      typographer: true,
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            const highlighted = hljs.highlight(lang, str, true).value;
            const languageLabel = `<answer-code-header data-code-lang='${capitalCase(lang)}'></answer-code-header>`;
            return `${languageLabel}<div class="code-content"><pre class="hljs language-${lang}"><code class="hljs language-${lang} ${lang}">${highlighted}</code></pre></div>`;
          } catch (__) {}
        }
        const esc = this.markdownIt.utils.escapeHtml;
        return lang ? '<pre class="hljs"><code class="hljs">' + esc(str) + '</code></pre>' : esc(str);
      },
    }).enable(['link']);

    const defaultRender =
      this.markdownIt.renderer.rules.link_open ||
      function (tokens, idx, options, _env, self) {
        return self.renderToken(tokens, idx, options);
      };

    this.markdownIt.use(markdownItAttrs).use(markdownItBracketedSpan).use(markdownItEmoji);

    this.markdownIt.inline.ruler.before('emphasis', 'custom_reference_item', function (state, silent) {
      if (!['chat-answer', 'result-answer'].includes(state.env?.sourceType)) {
        return;
      }

      const start = state.pos;
      const marker = state.src.charCodeAt(start);

      if (marker !== 0x25 || state.src.charCodeAt(start + 1) !== 0x25) {
        return false;
      }

      const match = state.src.slice(start).match(/^%%(.*?)%%/);

      if (!match) return false;

      const content = match[1];

      if (silent) {
        return false;
      }

      const token = state.push('custom_reference_item', 'i', 0);
      token.content = content;
      token.meta = state.env;
      state.pos += match[0].length;

      return true;
    });

    this.markdownIt.renderer.rules.custom_reference_item = function (tokens, index) {
      const token = tokens[index];
      const idIndex = Number(token.content);
      if (!token.meta.items?.length) {
        return `<inline-reference-item data-attr='${JSON.stringify({ idIndex: idIndex, searchId: token.meta.searchId })}'></inline-reference-item>`;
      }
      if (idIndex < 1 || idIndex > token.meta.items.length) {
        return;
      }
      let id: string;
      if (token.meta.sourceType === 'chat-answer') {
        id = token.meta.items[idIndex - 1]?.externalId;
      } else if (token.meta.sourceType === 'result-answer') {
        id = token.meta.items[idIndex - 1]?.id;
      }
      if (!id) {
        return;
      }
      const dataAttr = JSON.stringify({
        id,
        sourceType: token.meta.sourceType,
        idIndex,
        answerId: token.meta.identity,
        searchId: token.meta.searchId,
      });

      return `<inline-reference-item data-attr='${dataAttr}' ></inline-reference-item>`;
    };

    this.markdownIt.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      const hrefIndex = tokens[idx].attrIndex('target');

      if (hrefIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
      } else {
        tokens[idx].attrs[hrefIndex][1] = '_blank';
      }
      return defaultRender(tokens, idx, options, env, self);
    };
  }
}
