import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { hasReachedMaxLength, hasReachedMaxWords } from '@local/ts-infra';
@Component({
  selector: 'u-textarea',
  templateUrl: './u-textarea.component.html',
  styleUrls: ['./u-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UTextareaComponent implements OnInit {
  readonly MAX_INPUT_WORDS_REACHED_MSG = 'Word limit reached';
  readonly MAX_NUMBER = 524288;
  private _autoResize = true;
  private _model: string;

  isTitleFocused: boolean;
  doResize = false;
  isValidLength = signal(true);

  @Input() rows = 1;
  @Input() cols = 1;
  @Input() placeholder = '';
  @Input() styles: any = {};
  @Input() autofocus = true;
  @Input() disabled = false;
  @Input() ignoreEnter: boolean;
  @Input() readonly = false;
  @Input() disableAutoResize = true;
  @Input() maxLength: number;
  @Input() maxWords: number;
  @Input() setMaxLength: boolean;
  @Input() truncateLines: boolean;
  @Input() maxRowLimit: number;
  @Input() minHeight = 24;
  @Input() fixedBackgroundColor: boolean;
  @Input() displayLimitWarning: boolean;

  @Input()
  set autoResize(value: boolean) {
    this._autoResize = value;
    if (this._autoResize) {
      this.doResize = true;
    }
  }

  @Input() set model(value: string) {
    this._model = value;
    if (value) {
      this.validateTextInput(value);
    }
  }

  get model(): string {
    return this._model;
  }

  get autoResize(): boolean {
    return this._autoResize;
  }

  @ViewChild('textarea') textArea: ElementRef;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleValidationResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('style.--linesNumber') get linesNumber(): number {
    return this.rows;
  }

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.maxLength && !this.maxWords) {
      this.maxLength = this.rows * this.cols;
    }

    if (this.setMaxLength) {
      this.maxLength = this.MAX_NUMBER;
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      //HACK to fix the textarea height issue caused by page refresh.
      this.handleAutoResize();
      const textareaEl = this.textArea?.nativeElement;
      if (textareaEl) {
        textareaEl.style.height = `${textareaEl.scrollHeight}px`;
      }
      if (this.autofocus) {
        this.setFocus();
      }
    }, 250);
  }
  setFocus(): void {
    this.renderer.selectRootElement(this.textArea.nativeElement).focus({ preventScroll: true });
    this.cdr.markForCheck();
  }

  private validateTextInput(text: string) {
    const isValidLength = !text.length || (!hasReachedMaxWords(text, this.maxWords) && !hasReachedMaxLength(text, this.maxLength));
    this.isValidLength.set(isValidLength);
    this.handleValidationResult.emit(isValidLength);
  }

  private hasReachedMaxRows(text?: string): boolean {
    if (!this.maxRowLimit) {
      return false;
    }
    const inputText = text || this.textArea.nativeElement.value;
    if (!inputText) {
      return false;
    }
    const rows = this.calculateRows();
    return rows > this.maxRowLimit;
  }

  onChangeEvent($event: string) {
    this.onChange.emit($event);
    if ($event === '') {
      this.resetTextarea();
    }
    this.validateTextInput($event);
    this.handleAutoResize();
  }

  private handleAutoResize(text?: string) {
    if (!this.autoResize) return;
    this.doResize = !this.hasReachedMaxRows(text);
    this.cdr.markForCheck();
  }

  onFocusEvent($event) {
    this.isTitleFocused = true;
    this.scrollToEnd();
    this.onFocus.emit($event);
  }
  onBlurEvent($event) {
    this.isTitleFocused = false;
    this.scrollToStart();
    this.onBlur.emit($event);
  }
  onEnterEvent($event) {
    if (this.ignoreEnter) {
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();
    this.onEnter.emit({ target: $event, value: this.model });
  }
  private calculateRows(): number {
    const textareaElement = this.textArea.nativeElement;
    const lineHeight = parseInt(getComputedStyle(textareaElement).lineHeight, 10);
    const scrollHeight = textareaElement.scrollHeight;
    return Math.floor(scrollHeight / lineHeight);
  }
  clearInput() {
    this.model = '';
    this.onChange.emit('');
    this.cdr.markForCheck();
    this.validateTextInput('');
    this.resetTextarea();
  }

  private resetTextarea() {
    this.textArea.nativeElement.value = '';
    this.doResize = true;
    this.textArea.nativeElement.style.height = `${this.minHeight}px`;
    this.cdr.markForCheck();
  }

  private scrollToStart() {
    const el: HTMLElement = this.textArea?.nativeElement;
    if (el) {
      el.scrollTop = 0;
    }
  }

  private scrollToEnd() {
    const el: HTMLElement = this.textArea?.nativeElement;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }
}
