import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  private lastVisitedUrls: { [key: string]: string } = {};

  setLastVisitedUrl(tabId: string, url: string): void {
    this.lastVisitedUrls[tabId] = url;
  }

  getLastVisitedUrl(tabId: string): string {
    return this.lastVisitedUrls[tabId];
  }
}
