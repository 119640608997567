import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import {
  UAutoCompleteModule,
  UButtonModule,
  UCheckboxModule,
  UChipsModule,
  UColorPickerModule,
  UDropdownModule,
  UEditTextModule,
  UEmojiPickerModule,
  UFileUploadModule,
  UIconModule,
  UiInfraModule,
  UImageCropperModule,
  UInputGroupModule,
  UInputModule,
  UInputNumberModule,
  UMultiSelectModule,
  UProgressBarModule,
  URadioButtonModule,
  USplitButtonModule,
  UTableModule,
  UTextareaInteractiveModule,
  UTextareaModule,
  UTieredMenuModule,
  UToggleSwitchModule,
  UToggleButtonModule,
  UTreeModule,
  UTypingTypographyModule,
  USidebarModule,
  UIconBlinkModule,
  UListBoxModule,
  UInputListModule,
  URowManagerModule,
} from '@local/ui-infra';
import { PreHtmlPipe } from '@shared/pipes/pre-html.pipe';
import { SpaceHtmlEncodePipe } from '@shared/pipes/replace-space.pipe';
import { AngularSplitModule } from 'angular-split';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SidebarModule } from 'primeng/sidebar';
import { BarBadgeComponent } from '../bar/components/bar-badge/bar-badge.component';
import { ControlButtonComponent } from '../bar/components/control-button/control-button.component';
import { ImageService } from '../bar/views/settings/service/image.service';
import { EnterWorkspaceNameComponent } from '../onboarding-web/containers/enter-workspace-name/enter-workspace-name.component';
import { WorkspaceInviteComponent } from '../onboarding-web/containers/workspace-invite/workspace-invite.component';
import { GradientOverflowComponent } from '../shared/components/gradient-overflow/gradient-overflow.component';
import {
  AuthLoaderOverlayComponent,
  BannerAlertComponent,
  BulletItemComponent,
  BulletListComponent,
  ContextMenuComponent,
  KeyBindingComponent,
  OverlayComponent,
  DisclaimerAlertComponent,
} from './components';
import { AccountIconComponent } from './components/account-icon/account-icon.component';
import { AppEmptyStateComponent } from './components/app-empty-state/app-empty-state.component';
import { AppLogoComponent } from './components/app-logo/app-logo.component';
import { AppStatusBadgesComponent } from './components/app-status-badges/app-status-badges.component';
import { BackgroundComponent } from './components/background/background.component';
import { BannerComponent } from './components/banner/banner.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonSolidComponent } from './components/button-solid/button-solid.component';
import { CardComponent } from './components/card/card.component';
import { DownloadItemComponent } from './components/downloads/download-item/download-item.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { DynamicEllipsisComponent } from './components/dynamic-ellipsis/dynamic-ellipsis.component';
import { EntityIconComponent } from './components/entity-icon/entity-icon.component';
import { ErrorOverlayComponent } from './components/error-overlay/error-overlay.component';
import { ExclamationCircleComponent } from './components/exclamation-circle/exclamation-circle.component';
import { AvatarMultiSelectFilterComponent } from './components/filters/avatar-multi-select-filter/avatar-multi-select-filter.component';
import { DropdownWrapperComponent } from './components/filters/dropdown-wrapper/dropdown-wrapper.component';
import { MultiSelectWrapperComponent } from './components/filters/multi-select-wrapper/multi-select-wrapper.component';
import { ResultsFiltersBoxComponent } from './components/filters/results-filters-box/results-filters-box.component';
import { ShortSelectedComponent } from './components/filters/short-selected/short-selected.component';
import { SpreadFilterComponent } from './components/filters/spread/spread-filter.component';
import { TimeMultiSelectComponent } from './components/filters/time-multi-select/time-multi-select.component';
import { ToggleFilterComponent } from './components/filters/toggle/toggle-filter.component';
import { ImageCropperPopupComponent } from './components/image-cropper-popup/image-cropper-popup.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { LinkSettingsFrameComponent } from './components/link-settings/link-settings-frame.component';
import { LinkComponent } from './components/link/link.component';
import { LoaderOverlayComponent } from './components/loader-overlay/loader-overlay.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PlanCanceledPopupComponent } from './components/plan-canceled-popup/plan-canceled-popup.component';
import { CreateQuickLinksPopupComponent } from './components/quick-links-popup.ts/create-quick-links-popup/create-quick-links-popup.component';
import { QuickLinksPopupComponent } from './components/quick-links-popup.ts/quick-links-popup.component';
import { SmoothHeightComponent } from './components/smooth-height.component';
import { SynonymsPreviewPopupComponent } from './components/synonyms-preview-popup/synonyms-preview-popup.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TimesButtonComponent } from './components/times-button/times-button.component';
import { TrialBannerComponent } from './components/trial-banner/trial-banner.component';
import { TrialExpiredComponent } from './components/trial-expired-popup/trial-expired-popup.component';
import { TrialUpgradePopupComponent } from './components/trial-upgrade-popup/trial-upgrade-popup.component';
import { StepComponent } from './components/walkthrough/tour/step/step.component';
import { TourComponent } from './components/walkthrough/tour/tour.component';
import { CustomVirtualScrollDirective } from './directives/custom-virtual-scroll-strategy.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { IntersectDirective } from './directives/intersect.directive';
import { ClickTrackDirective } from './directives/track.directive';
import { HighlightPipe, KeyIconPipe, TruncatePipe, RemoveNewLinePipe } from './pipes';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FillPipe } from './pipes/fill.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { LineDownAtPipe } from './pipes/line-down-at.pipe';
import { RichTextFormattedPipe } from './pipes/rich-text-formatted.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { MultiChoicePopupService } from '../bar/services/multi-choice-popup.service';
import { MultiChoicePopupComponent } from '../bar/views/collections-page/components/multi-choice-popup/multi-choice-popup.component';
import { AssistantErrorComponent } from './components/assistant-error/assistant-error.component';
import { AvatarListTooltipComponent } from './components/avatar-list-tooltip/avatar-list-tooltip.component';
import { AvatarListComponent } from './components/avatar-list/avatar-list.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ConstantFilterComponent } from './components/filters/constant-filter/constant-filter.component';
import { MultiSelectValueComponent } from './components/filters/multi-select-value/multi-select-value.component';
import { NestedFilterComponent } from './components/filters/nested-filter/nested-filter.component';
import { NewIndicationComponent } from './components/new-indication/new-indication.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { RefineSearchMessageComponent } from './components/refine-search-message/refine-search-message.component';
import { SquareButtonComponent } from './components/square-button/square-button.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ToggleTooltipText } from './pipes/toggle-tooltip-text.pipe';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { WindowControlsComponent } from '../bar/views/hub/components/window-controls/window-controls.component';
import { AssistantItemComponent } from '../bar/views/settings/components/assistant-page/components/assistant-item/assistant-item.component';

const components = [
  ShortSelectedComponent,
  OverlayComponent,
  ContextMenuComponent,
  KeyBindingComponent,
  AuthLoaderOverlayComponent,
  LoaderComponent,
  IndicatorComponent,
  TabsComponent,
  TabComponent,
  AvatarComponent,
  BulletListComponent,
  BulletItemComponent,
  CardComponent,
  ButtonSolidComponent,
  BackgroundComponent,
  LoaderOverlayComponent,
  ErrorOverlayComponent,
  LinkComponent,
  DynamicEllipsisComponent,
  TimesButtonComponent,
  DownloadsComponent,
  DownloadItemComponent,
  SmoothHeightComponent,
  GradientOverflowComponent,
  TourComponent,
  StepComponent,
  BreadcrumbsComponent,
  ControlButtonComponent,
  AppStatusBadgesComponent,
  QuickLinksPopupComponent,
  CreateQuickLinksPopupComponent,
  BarBadgeComponent,
  AccountIconComponent,
  EntityIconComponent,
  SpreadFilterComponent,
  ToggleFilterComponent,
  AppLogoComponent,
  LinkSettingsFrameComponent,
  PageNotFoundComponent,
  AppEmptyStateComponent,
  ExclamationCircleComponent,
  MultiSelectWrapperComponent,
  AvatarMultiSelectFilterComponent,
  BannerComponent,
  DropdownWrapperComponent,
  TimeMultiSelectComponent,
  WorkspaceInviteComponent,
  EnterWorkspaceNameComponent,
  TrialBannerComponent,
  TrialExpiredComponent,
  TrialUpgradePopupComponent,
  ResultsFiltersBoxComponent,
  PlanCanceledPopupComponent,
  ImageCropperPopupComponent,
  SynonymsPreviewPopupComponent,
  SquareButtonComponent,
  AvatarListComponent,
  MultiChoicePopupComponent,
  NewIndicationComponent,
  StepperComponent,
  RefineSearchMessageComponent,
  ConstantFilterComponent,
  PoweredByComponent,
  AvatarListTooltipComponent,
  NestedFilterComponent,
  MultiSelectValueComponent,
  AssistantErrorComponent,
  WindowControlsComponent,
  AssistantItemComponent,
];

const pipes = [
  HighlightPipe,
  TruncatePipe,
  KeyIconPipe,
  CapitalizePipe,
  LineDownAtPipe,
  FileSizePipe,
  FillPipe,
  PreHtmlPipe,
  RichTextFormattedPipe,
  SpaceHtmlEncodePipe,
  RemoveNewLinePipe,
  FilterPipe,
  BannerAlertComponent,
  DisclaimerAlertComponent,
  ToggleTooltipText,
];

const directives = [
  ClickTrackDirective,
  CustomVirtualScrollDirective,
  IntersectDirective,
  DragAndDropDirective,
  DebounceClickDirective,
  HasPermissionDirective,
];

const providers = [ImageService, MultiChoicePopupService];
const modules = [
  AngularSplitModule,
  CommonModule,
  FormsModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  NgScrollbarModule,
  InfiniteScrollModule,
  ReactiveFormsModule,
  RouterModule,
  SidebarModule,
  UAutoCompleteModule,
  UButtonModule,
  UCheckboxModule,
  UChipsModule,
  UColorPickerModule,
  UDropdownModule,
  UEmojiPickerModule,
  UFileUploadModule,
  UIconModule,
  UIconBlinkModule,
  UImageCropperModule,
  UiInfraModule,
  UInputModule,
  UMultiSelectModule,
  URadioButtonModule,
  USplitButtonModule,
  UToggleSwitchModule,
  UToggleButtonModule,
  UTreeModule,
  UTieredMenuModule,
  UInputGroupModule,
  UTextareaModule,
  UTextareaInteractiveModule,
  UInputNumberModule,
  ScrollingModule,
  UEditTextModule,
  UProgressBarModule,
  CdkStepperModule,
  UTableModule,
  UTypingTypographyModule,
  USidebarModule,
  UListBoxModule,
  UInputListModule,
  URowManagerModule,
];

@NgModule({
  imports: modules,
  declarations: [...components, ...pipes, ...directives],
  exports: [...components, ...pipes, ...directives, ...modules],
  providers: [...providers],
})
export class SharedModule {}
