import { Commands, Experiences, MemorySearch, Search } from '@local/client-contracts';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { GoToItem, HeaderItem, SearchResults } from 'src/app/bar/views';
import { GoToSourceSettings } from '.';
import { GoToService } from '../../../go-to.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { ChatAssistantsService } from '../../../chat-assistants.service';
import { CHAT_PAGE_PATH } from 'src/app/bar/utils/constants';
import { getAssistantTitle, getGeneralAssistantIcon } from '@local/common-web';
import { DEFAULT_FOOTER_TITLE } from '../../models/search-client.constants';

export class GoToSearchClient extends MemorySearchClient<GoToSourceSettings> {
  private readonly ALLOWED_CHILDREN_TYPES = ['wikis'];
  private readonly DEFAULT_ALLOWED_GOTO_ITEMS = ['help', 'wikis', 'goto', 'assistants', 'search', 'admin'];
  constructor(
    private goToService: GoToService,
    logService: LogService,
    memorySearchService: MemorySearchService,
    private chatAssistantsService: ChatAssistantsService
  ) {
    super(logService, memorySearchService, ['Alphabetical']);
    this.logger = logService.scope('goto-search-client');
  }

  async getInput(request: SearchRequest<GoToSourceSettings>): Promise<MemorySearch.Item[]> {
    let combineItems: Observable<GoToItem[]>;
    const observables: Observable<any>[] = [];
    const allowedGotoItemsArr = request.sourceSettings?.allowedGotoItems || this.DEFAULT_ALLOWED_GOTO_ITEMS;
    if (allowedGotoItemsArr.includes('help')) {
      observables.push(this.goToService.helpItems$);
    }
    if (allowedGotoItemsArr.includes('goto')) {
      observables.push(this.goToService.gotoItems$);
    }
    if (allowedGotoItemsArr.includes('assistants')) {
      observables.push(this.getAssistantItems());
    }
    combineItems = combineLatest(observables).pipe(
      map((responses) => {
        let combinedItems = responses.flat();
        if (!allowedGotoItemsArr.includes('search')) {
          combinedItems = combinedItems.filter((i) => i.id !== 'search');
        }
        return combinedItems;
      })
    );
    const sorting = request.sourceSettings.sorting;
    const items: GoToItem[] = await firstValueFrom(combineItems);

    const mitems = items.map((i) => ({
      data: i,
      searchText: this.buildTextForSearch(i),
      sortValue: sorting?.by === 'Alphabetical' ? i.title : null,
    }));
    return this.getDefaultItemsOrder(mitems, allowedGotoItemsArr, request?.query);
  }

  async getOutput(items: MemorySearch.Item[]): Promise<Search.ResultItem[]> {
    return items.map((i) => i.data);
  }

  addHeaders(request: SearchRequest<GoToSourceSettings>, items: SearchResults[], resultCount: number, totalResults: number): void {
    const settings = request.sourceSettings;
    const { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, totalResults);
    const header: HeaderItem = {
      type: 'header',
      clickable: settings.header.clickable,
      origin: 'goto',
      title: title || 'Best match',
      titleEnd,
      group: settings.showHeaderButton ? settings.header.group : undefined,
    };
    const footer: HeaderItem = {
      type: 'header',
      clickable: true,
      origin: `footer-${settings.type}`,
      title: settings.footer?.title || DEFAULT_FOOTER_TITLE,
      isFooter: true,
      selectable: true,
      group: settings.showHeaderButton ? settings.header.group : undefined,
    };

    items.unshift(header);
    if (totalResults > settings.maxCount) {
      items.push(footer);
    }
  }

  private getAssistantItems() {
    return this.chatAssistantsService.assistantForChat$.pipe(
      map((items) => items.map((a) => this.assistantToGotoItem(a)).filter((a) => !!a))
    );
  }

  private assistantToGotoItem(assistant: Experiences.ExperienceItem): GoToItem {
    if (!assistant) {
      return;
    }
    const id = assistant.id;
    return {
      id,
      command: { type: 'open-page', url: `${CHAT_PAGE_PATH}/${id}` } as Commands.OpenPageCommand,
      icon: getGeneralAssistantIcon(assistant),
      type: 'goto',
      title: getAssistantTitle(assistant),
    };
  }

  private buildTextForSearch({ title, subtitle }: GoToItem): string {
    const searchableSubtitle = subtitle ? (typeof subtitle === 'string' ? subtitle : subtitle.map((b) => b.title).join(' ')) : undefined;
    return [title ?? '', searchableSubtitle ?? ''].join(' ').toLowerCase();
  }

  private getDefaultItemsOrder(
    items: MemorySearch.Item[],
    allowedChildrenTypes: string[] = this.ALLOWED_CHILDREN_TYPES,
    query: string
  ): MemorySearch.Item[] {
    const orderItems: MemorySearch.Item[] = [];
    let filteredItems = items.filter((item) => !item.data.parentId || allowedChildrenTypes.includes(item.data.parentId));
    if (query) {
      filteredItems = filteredItems.sort((prev, current) => prev.searchText.localeCompare(current.searchText));
    }
    filteredItems.forEach((parent) => {
      orderItems.push(parent);
    });
    const helpItems: MemorySearch.Item[] = items
      .filter((item) => item.data.parentId === 'help_items')
      .sort((prev, current) => prev.searchText.localeCompare(current.searchText));
    return [...orderItems, ...helpItems];
  }

  private getChildrenItems(parent: MemorySearch.Item, items: MemorySearch.Item[]): MemorySearch.Item[] {
    if (!items.filter((n) => n?.data.parentId === parent.data.id).length) {
      return [parent];
    }
    const orderItems = [parent];
    items
      .filter((n) => n.data.parentId === parent.data.id)
      .sort((prev, current) => prev.searchText.localeCompare(current.searchText))
      .forEach((item) => orderItems.push(...this.getChildrenItems(item, items)));
    return orderItems;
  }
}
