import { Config } from '@environments/config';
import { resultHeaderCountKey } from '@shared/utils/header-builder.util';
import {
  AnswersSourceSettings,
  AssistantsSourceSettings,
  CalculatorSourceSettings,
  GoLinksSourceSettings,
  GoToSourceSettings,
  LinkResourcesSourceSettings,
  LocalActionSourceSettings,
  RecentSearchesSourceSettings,
  SourceSettings,
  StaticItemsSourceSettings,
  WebSourceSettings,
} from 'src/app/bar/services/search/client';
import { CollectionsSourceSettings } from 'src/app/bar/services/search/client/collections';
import { EmbedItemsSourceSettings } from 'src/app/bar/services/search/client/embed-items';
import { FavoritesSourceSettings } from 'src/app/bar/services/search/client/favorites';
import { groupResourceSettings } from 'src/app/bar/services/search/client/groups';
import { MailSourceSettings } from 'src/app/bar/services/search/client/mail';
import { PeopleSourceSettings } from 'src/app/bar/services/search/client/people/people-source-settings';
import { QuestionnaireSourceSettings } from 'src/app/bar/services/search/client/questionnaire';
import { RelevantPeopleSourceSettings } from 'src/app/bar/services/search/client/relevant-people/relevant-people-source-settings';
import { ResourcesFavoritesSourceSettings } from 'src/app/bar/services/search/client/resources-favorites/resources-favorites-source-settings';
import { StaticCollectionItemSourceSettings } from 'src/app/bar/services/search/client/static-collection-items';
import { SuggestionsSourceSettings } from 'src/app/bar/services/search/client/suggestions';
import { VisitsSourceSettings } from 'src/app/bar/services/search/client/visits';
import { WikiCollectionItemSourceSettings } from 'src/app/bar/services/search/client/wiki-collection-items';
import { WikiDraftsSourceSettings } from 'src/app/bar/services/search/client/wiki-drafts';

export type SettingsPlatform =
  | 'desktop-native-search-page'
  | 'desktop-web-search-page'
  | 'desktop-native-search-bar'
  | 'desktop-web-embed-search-bar'
  | 'desktop-web-embed-search-page'
  | 'desktop-assistant-web-search-page'
  | 'desktop-assistant-embed-search-bar'
  | 'desktop-assistant-embed-search-page'
  | 'desktop-browser-extension-side-panel';

export type SettingsFormat = 'default' | 'search' | 'group' | 'sorting';
export class ResultSettings {
  private static readonly HIGHLIGHTS_COUNT = 0;
  private static readonly RECENT_SEARCH_COUNT = 5;
  private static readonly RECENT_SEARCH_COUNT_LAUNCHER = 3;

  private static readonly CLOUD_SEARCH_COUNT = 20;
  private static readonly GOTO_COUNT = 2;
  private static readonly LOCAL_SEARCH_COUNT = 2;
  private static readonly LOCAL_ACTION_COUNT = 3;
  private static readonly WEB_SEARCH_COUNT = 1;
  private static readonly COLLECTIONS_COUNT = 2;
  private static readonly GO_LINK_COUNT = 2;
  private static readonly FAVORITES_COUNT = 3;
  private static readonly PEOPLE_COUNT = 3;
  private static readonly SUGGESTIONS_COUNT = 5;
  private static readonly RECENTLY_COUNT = 3;

  private static readonly GROUP_MAX_SEARCH_COUNT = 20;
  private static readonly GROUP_CLOUD_SEARCH_COUNT = 20;
  private static readonly GROUP_LOCAL_SEARCH_COUNT = 3;
  private static readonly LOCAL_GROUP_LOCAL_SEARCH_COUNT = 3;
  private static readonly GROUP_GOTO_COUNT = 50;
  private static readonly GROUP_LOCAL_ACTION_COUNT = 50;
  private static readonly GROUP_WEB_SEARCH_COUNT = 50;
  private static readonly GROUP_RECENTLY_COUNT = 50;

  private static readonly LAUNCHER_GROUP_MAX_SEARCH_COUNT = 10;
  private static readonly LAUNCHER_CLOUD_SEARCH_COUNT = 10;
  private static readonly LAUNCHER_LOCAL_SEARCH_COUNT = 3;
  private static readonly LAUNCHER_WEB_SEARCH_COUNT = 3;

  private static readonly EMBEDDED_FULL_CLOUD_SEARCH_COUNT = 3;
  private static readonly EMBEDDED_FULL_LOCAL_SEARCH_COUNT = 3;

  private constructor() {}

  private static readonly groupVisits: VisitsSourceSettings = {
    type: 'visits',
    cache: 'first',
    defaultSort: 'recent-first',
    includeInteractions: 'wait',
    includeTraits: 'wait',
    maxCount: ResultSettings.GROUP_RECENTLY_COUNT,
    header: { title: 'Recently viewed', titleEnd: resultHeaderCountKey },
  };

  private static readonly visits: VisitsSourceSettings = {
    type: 'visits',
    cache: 'only',
    defaultSort: 'recent-first',
    includeInteractions: 'cache',
    includeTraits: 'cache',
    maxCount: ResultSettings.RECENTLY_COUNT,
    header: {
      title: 'Recently viewed',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: { name: 'visits', title: 'Recently viewed' },
    },
  };

  private static readonly recentSearch: RecentSearchesSourceSettings = {
    type: 'recent-search',
    maxCount: ResultSettings.RECENT_SEARCH_COUNT,
    header: {
      title: 'Recent searches',
    },
  };

  private static readonly recentSearchEmbed: RecentSearchesSourceSettings = {
    type: 'recent-search',
    maxCount: ResultSettings.RECENT_SEARCH_COUNT_LAUNCHER,
    header: {
      title: 'Recent searches',
    },
  };

  private static readonly recentSearchLauncher: RecentSearchesSourceSettings = {
    type: 'recent-search',
    maxCount: ResultSettings.RECENT_SEARCH_COUNT_LAUNCHER,
    header: {
      title: 'Recent searches',
    },
    flag: 'enableLauncherRecentSearch',
  };

  private static readonly calculator: CalculatorSourceSettings = {
    type: 'calculator',
    minQueryLength: 3,
  };

  private static readonly answers: AnswersSourceSettings = {
    type: 'answers',
    minQueryLength: 3,
    minWordLength: 2,
    minWords: 2,
    maxCount: 1,
    ignoreDone: true,
  };

  private static readonly answersExtension: AnswersSourceSettings = {
    ...ResultSettings.answers,
    answerCacheTime: 120 * 1000,
  };

  private static readonly answersLauncher: AnswersSourceSettings = {
    ...ResultSettings.answers,
    noResults: true,
    displayOpenChatResult: true,
  };

  private static readonly defaultLinkResources: LinkResourcesSourceSettings = {
    id: 'link-resources',
    type: 'link-resources',
    debounce: Config.search.app.debounce,
    contentSearch: true,
    advancedSearch: true,
    localMaxCount: ResultSettings.LOCAL_SEARCH_COUNT,
    requestMaxCount: ResultSettings.CLOUD_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.CLOUD_SEARCH_COUNT,
    groupsFirst: true,
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    disableCloudGroup: true,
    showGroupHeader: true,
    forceFooter: false,
    filters: { excludeFilters: ['PeopleLink'] },
    preventRTF: Config.search.preventRTF,
  };

  private static readonly assistantLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.defaultLinkResources,
    filters: { excludeFilters: ['PeopleLink', 'GroupSearch'] },
  };

  private static readonly linkResourcesNoExclude: LinkResourcesSourceSettings = {
    ...ResultSettings.defaultLinkResources,
    filters: { excludeFilters: [] },
  };

  private static readonly launcherLinkResources: LinkResourcesSourceSettings = {
    id: 'link-resources',
    type: 'link-resources',
    debounce: Config.search.launcher.debounce,
    requestMaxCount: ResultSettings.LAUNCHER_CLOUD_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.LAUNCHER_CLOUD_SEARCH_COUNT,
    localMaxCount: ResultSettings.LAUNCHER_LOCAL_SEARCH_COUNT,
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    disableCloudGroup: true,
    groupsFirst: true,
    contentSearch: false,
    advancedSearch: false,
    filters: { excludeFilters: ['Wiki', 'PeopleLink'] },
    preventRTF: Config.search.preventRTF,
  };

  private static readonly embedLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    filters: { excludeFilters: ['PeopleLink'] },
  };

  private static readonly embeddedLinkResources: LinkResourcesSourceSettings = {
    id: 'link-resources',
    type: 'link-resources',
    debounce: Config.search.app.debounce,
    requestMaxCount: ResultSettings.EMBEDDED_FULL_CLOUD_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.EMBEDDED_FULL_CLOUD_SEARCH_COUNT,
    localMaxCount: ResultSettings.EMBEDDED_FULL_LOCAL_SEARCH_COUNT,
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    disableCloudGroup: true,
    groupsFirst: true,
    contentSearch: true,
    filters: { excludeFilters: ['PeopleLink'] },
    preventRTF: Config.search.preventRTF,
  };

  private static readonly groupResources: groupResourceSettings = {
    id: 'group-resources',
    type: 'group-resources',
    contentSearch: true,
    advancedSearch: true,
    requestMaxCount: 0,
    localMaxCount: 3,
    groupsOptions: {
      groupingType: 'type',
      groupSize: 3,
      maxGroups: 10,
    },
    noHeader: false,
    forceFooter: true,
    groupsFirst: true,
    sorting: {
      by: 'Timestamp',
      order: 'Descending',
      type: 'Time',
    },
  };

  private static readonly mailResources: MailSourceSettings = {
    id: 'mail-resources',
    type: 'mail-resources',
    debounce: Config.search.app.debounce,
    requestMaxCount: ResultSettings.GROUP_MAX_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.GROUP_CLOUD_SEARCH_COUNT,
    groupsOptions: undefined,
    noHeader: true,
    header: {
      title: 'Inbox',
      titleEnd: resultHeaderCountKey,
      multipleAccountsTitle: 'All inboxes',
    },
    sorting: {
      by: 'Timestamp',
      order: 'Descending',
      type: 'Time',
    },
    useSourceFilters: true,
    isDefault: true,
    disableAggregations: true,
    preventRTF: Config.search.preventRTF,
  };

  private static readonly mailSearchResources: MailSourceSettings = {
    ...ResultSettings.mailResources,
    isDefault: false,
    header: {
      title: `Showing ${resultHeaderCountKey} emails`,
    },
  };

  private static readonly launcherMailResources: MailSourceSettings = {
    ...ResultSettings.mailResources,
    debounce: Config.search.launcher.debounce,
  };

  private static readonly launcherMailSearchResources: MailSourceSettings = {
    ...ResultSettings.launcherMailResources,
    isDefault: false,
    header: {
      title: `Showing ${resultHeaderCountKey} emails`,
    },
  };

  private static readonly goto: GoToSourceSettings = {
    type: 'goto',
    maxCount: ResultSettings.GOTO_COUNT,
    header: {
      title: 'Go to',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly goLinks: GoLinksSourceSettings = {
    type: 'go-links',
    withVisits: true,
    maxCount: ResultSettings.GO_LINK_COUNT,
    changeAfterSearch: true,
  };

  public static readonly goLinksOnly: GoLinksSourceSettings = {
    type: 'go-links',
    withVisits: true,
    maxCount: 100,
    noHeader: true,
  };

  private static readonly searchGoLinks: GoLinksSourceSettings = {
    ...ResultSettings.goLinks,
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
    },
    showHeaderButton: true,
    withVisits: true,
    changeAfterSearch: false,
    maxCount: ResultSettings.GO_LINK_COUNT,
  };

  private static readonly launcherSearchGoLinks: GoLinksSourceSettings = {
    ...ResultSettings.goLinks,
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
    },
    showHeaderButton: true,
    withVisits: false,
  };

  private static readonly defaultLocalActions: LocalActionSourceSettings = {
    type: 'local-action',
    maxCount: ResultSettings.LOCAL_ACTION_COUNT,
    withAppActions: false,
    withGoLinksAction: true,
    header: { title: 'Create New' },
    iconAdjustments: 'Skip',
  };

  private static readonly browserExtensionLocalActions: LocalActionSourceSettings = {
    type: 'local-action',
    maxCount: ResultSettings.LOCAL_ACTION_COUNT,
    withAppActions: false,
    withGoLinksAction: true,
    withAddToCollectionAction: true,
    header: { title: 'Create New' },
    iconAdjustments: 'Skip',
  };

  private static readonly localActions: LocalActionSourceSettings = {
    ...ResultSettings.defaultLocalActions,
    header: { title: 'Create New', titleEnd: resultHeaderCountKey, group: { name: 'actions', title: 'Create New' }, clickable: true },
  };

  private static readonly launcherLocalActions: LocalActionSourceSettings = {
    ...ResultSettings.localActions,
    withAppActions: false,
  };

  private static readonly groupLinkResources: LinkResourcesSourceSettings = {
    id: 'link-resources',
    type: 'link-resources',
    debounce: Config.search.app.debounce,
    requestMaxCount: ResultSettings.GROUP_MAX_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.GROUP_CLOUD_SEARCH_COUNT,
    localMaxCount: ResultSettings.GROUP_LOCAL_SEARCH_COUNT,
    contentSearch: true,
    advancedSearch: true,
    groupsOptions: undefined,
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    disableCloudGroup: true,
    groupsFirst: true,
    preventRTF: Config.search.preventRTF,
  };

  private static readonly slackLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.groupLinkResources,
    cloudMaxCount: undefined,
  };
  private static readonly groupLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    requestMaxCount: ResultSettings.LAUNCHER_GROUP_MAX_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.GROUP_CLOUD_SEARCH_COUNT,
    groupsOptions: undefined,
    noHeader: false,
  };

  private static readonly groupLauncherLinkResourcesNoExclude: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    groupsOptions: undefined,
    noHeader: false,
    filters: { excludeFilters: [] },
  };

  private static readonly groupEmbedLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    filters: { excludeFilters: ['PeopleLink'] },
  };

  private static readonly assistantLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    filters: { excludeFilters: ['PeopleLink', 'GroupSearch'] },
  };

  private static readonly launcherLinkResourcesNoExclude: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    filters: { excludeFilters: [] },
  };

  private static readonly groupLauncherLinkResourcesLiveCollection: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    requestMaxCount: ResultSettings.LAUNCHER_GROUP_MAX_SEARCH_COUNT,
    cloudMaxCount: ResultSettings.GROUP_CLOUD_SEARCH_COUNT,
    groupsOptions: undefined,
    noHeader: false,
    contentSearch: false,
    advancedSearch: false,
    disableAggregations: true,
    filters: { excludeFilters: ['Wiki'] },
  };

  private static readonly slackLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.groupLauncherLinkResources,
    cloudMaxCount: undefined,
  };

  private static groupPeople: PeopleSourceSettings = {
    type: 'people',
    minQueryLength: 0,
    maxCount: 50,
    header: {
      title: 'All people',
      titleEnd: resultHeaderCountKey,
    },
    sorting: {
      by: 'Trait',
      order: 'Ascending',
      key: 'displayName',
      type: 'Alphabetical',
    },
    viewMode: 'line',
    noFooter: true,
  };

  private static groupRelevantPeople: RelevantPeopleSourceSettings = {
    type: 'relevant-people',
    minQueryLength: 1,
    header: {
      title: 'All relevant people',
      titleEnd: resultHeaderCountKey,
    },
    kind: 'relevant-people',
    viewMode: 'line',
  };

  private static readonly groupRelevantPeopleSearch: RelevantPeopleSourceSettings = {
    ...ResultSettings.groupRelevantPeople,
    header: {
      title: `Showing ${resultHeaderCountKey} relevant people`,
    },
  };

  private static readonly groupPeopleSearch: PeopleSourceSettings = {
    ...ResultSettings.groupPeople,
    header: {
      title: `Showing ${resultHeaderCountKey} people`,
    },
  };

  private static readonly fileLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.groupLinkResources,
    header: {
      title: 'All files',
      titleEnd: resultHeaderCountKey,
    },
    iconAdjustments: 'Remove-Icon',
  };

  private static readonly fileSearchLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.fileLinkResources,
    header: {
      title: `Showing ${resultHeaderCountKey} files`,
    },
  };

  private static readonly fileLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.groupLauncherLinkResources,
    header: {
      title: 'All files',
      titleEnd: resultHeaderCountKey,
    },
    iconAdjustments: 'Remove-Icon',
  };

  private static readonly fileSearchLauncherLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.fileLauncherLinkResources,
    header: {
      title: `Showing ${resultHeaderCountKey} files`,
    },
  };

  private static readonly groupLocalLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.groupLinkResources,
    requestMaxCount: ResultSettings.GROUP_MAX_SEARCH_COUNT,
    localOnly: true,
    localMaxCount: ResultSettings.LOCAL_GROUP_LOCAL_SEARCH_COUNT,
    groupsOptions: undefined,
    noHeader: true,
  };

  private static readonly groupLauncherLocalLinkResources: LinkResourcesSourceSettings = {
    ...ResultSettings.launcherLinkResources,
    requestMaxCount: ResultSettings.GROUP_MAX_SEARCH_COUNT,
    localOnly: true,
    localMaxCount: ResultSettings.LOCAL_GROUP_LOCAL_SEARCH_COUNT,
    groupsOptions: undefined,
    noHeader: true,
  };

  private static readonly groupGoto: GoToSourceSettings = {
    ...ResultSettings.goto,
    allowedGotoItems: ['help', 'wikis', 'goto', 'search', 'settings'],
    maxCount: ResultSettings.GROUP_GOTO_COUNT,
    noHeader: true,
  };
  private static readonly groupChat: GoToSourceSettings = {
    ...ResultSettings.goto,
    allowedGotoItems: ['wikis', 'assistants', 'search', 'settings'],
    maxCount: ResultSettings.GROUP_GOTO_COUNT,
    header: {
      title: 'Chat',
      titleEnd: resultHeaderCountKey,
    },
  };
  private static readonly embedItems: EmbedItemsSourceSettings = {
    type: 'embed-items',
    maxCount: 3,
  };

  private static readonly searchViewGoLinks: GoLinksSourceSettings = {
    type: 'go-links',
    maxCount: undefined,
    header: {
      title: `Showing ${resultHeaderCountKey} go links`,
    },
    withVisits: true,
  };

  private static readonly searchViewLauncherGoLinks: GoLinksSourceSettings = {
    type: 'go-links',
    maxCount: undefined,
    header: {
      title: `Showing ${resultHeaderCountKey} go links`,
    },
    withVisits: false,
  };

  private static readonly groupGoLinks: GoLinksSourceSettings = {
    type: 'go-links',
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
    },
    withVisits: false,
  };

  private static readonly groupGoLinksLauncher: GoLinksSourceSettings = {
    ...ResultSettings.groupGoLinks,
  };

  private static readonly defaultGoLinks: GoLinksSourceSettings = {
    ...ResultSettings.goLinks,
    maxCount: undefined,
    groupsOptions: {
      maxGroups: 2,
      groupSize: 5,
      groupingType: 'type',
    },
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
    },
    changeAfterSearch: false,
  };

  private static readonly defaultLauncherGoLinks: GoLinksSourceSettings = {
    ...ResultSettings.goLinks,
    maxCount: undefined,
    groupsOptions: {
      maxGroups: 2,
      groupSize: 5,
      groupingType: 'type',
    },
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
    },
    withVisits: false,
    changeAfterSearch: false,
  };

  private static readonly groupLocalActions: LocalActionSourceSettings = {
    ...ResultSettings.localActions,
    maxCount: ResultSettings.GROUP_LOCAL_ACTION_COUNT,
    withAppActions: false,
    noHeader: true,
    noFooter: true,
  };

  private static readonly webSearch: WebSourceSettings = {
    type: 'web-search',
    maxCount: ResultSettings.WEB_SEARCH_COUNT,
    noHeader: true,
  };

  private static readonly launcherWebSearch: WebSourceSettings = {
    type: 'web-search',
    maxCount: ResultSettings.LAUNCHER_WEB_SEARCH_COUNT,
  };

  private static readonly goLinksFavorites: GoLinksSourceSettings = {
    type: 'go-links',
    useMemSearch: true,
    withVisits: false,
    filters: { postFilters: { favorite: ['true'] } },
  };

  private static readonly collectionsFavorites: CollectionsSourceSettings = {
    type: 'collection',
    filters: { postFilters: { favorite: ['true'] } },
    debounceTime: 40,
  };

  private static readonly staticCollectionsFavorites: StaticCollectionItemSourceSettings = {
    type: 'static-collection-items',
    filters: { postFilters: { favorite: ['true'] } },
    convertItem: true,
    isFavoriteItem: true,
  };

  private static readonly resourcesFavorites: ResourcesFavoritesSourceSettings = {
    type: 'resources-favorites',
    cache: 'first',
  };

  private static readonly defaultCards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    cache: 'first',
    searchView: true,
    withIcon: false,
    header: {
      title: 'Cards',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly cards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    cache: 'first',
    searchView: true,
    header: {
      title: 'Cards',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly searchCards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    cache: 'first',
    searchView: true,
    withIcon: false,
    header: {
      title: 'Cards',
    },
  };

  private static readonly launcherCards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    withIcon: true,
    cache: 'first',
    header: {
      title: 'Cards',
    },
    searchView: true,
  };

  private static readonly drafts: WikiDraftsSourceSettings = {
    type: 'wiki-drafts',
    header: {
      title: 'Drafts',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly searchDrafts: WikiDraftsSourceSettings = {
    type: 'wiki-drafts',
    header: {
      title: 'Drafts',
    },
  };

  private static readonly favorites: FavoritesSourceSettings = {
    type: 'favorites',
    maxCount: this.FAVORITES_COUNT,
    header: {
      title: 'Favorites',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: { name: 'favorites', type: 'active-page', value: 'favorites' },
    },
    sources: {
      goLinks: ResultSettings.goLinksFavorites,
      collections: ResultSettings.collectionsFavorites,
      resources: ResultSettings.resourcesFavorites,
      staticCollection: ResultSettings.staticCollectionsFavorites,
    },
  };

  private static readonly groupFavorites: FavoritesSourceSettings = {
    ...ResultSettings.favorites,
    maxCount: undefined,
    header: {
      title: 'All favorites',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly groupSearchFavorites: FavoritesSourceSettings = {
    ...ResultSettings.groupFavorites,
    header: {
      title: `Showing ${resultHeaderCountKey} favorites`,
    },
  };

  private static readonly groupWebSearch: WebSourceSettings = {
    type: 'web-search',
    maxCount: ResultSettings.GROUP_WEB_SEARCH_COUNT,
    noHeader: true,
  };

  private static readonly suggestions: SuggestionsSourceSettings = {
    type: 'suggestions',
    noHeader: true,
    header: { title: 'Suggestions' },
  };

  private static readonly relevantPeople: RelevantPeopleSourceSettings = {
    type: 'relevant-people',
    maxCount: this.PEOPLE_COUNT,
    minQueryLength: 1,
    header: {
      title: 'Relevant People',
      titleEnd: resultHeaderCountKey,
      group: {
        name: 'relevant-people',
        title: 'Relevant people',
        value: 'relevant-people',
        placeholder: 'Type a topic to see the people relevant to it',
      },
      clickable: true,
      leftIcon: 'icon-answer',
      leftIconStyles: {
        color: 'var(--color-primary)',
        fontSize: '16px',
      },
    },
    kind: 'relevant-people',
    viewMode: 'gallery',
  };

  private static readonly people: PeopleSourceSettings = {
    id: 'people',
    type: 'people',
    maxCount: this.PEOPLE_COUNT,
    minQueryLength: 1,

    header: {
      title: 'People',
      titleEnd: resultHeaderCountKey,
      group: { name: 'people', title: 'People', type: 'active-page', value: 'people' },
      clickable: true,
    },
    sorting: {
      by: 'Trait',
      order: 'Ascending',
      key: 'displayName',
      type: 'Alphabetical',
    },
    viewMode: 'gallery',
  };

  private static readonly launcherPeople: PeopleSourceSettings = {
    id: 'people',
    type: 'people',
    maxCount: this.PEOPLE_COUNT,
    minQueryLength: 1,

    header: {
      title: 'People',
      titleEnd: resultHeaderCountKey,
      group: { name: 'people', title: 'People', type: 'active-page', value: 'people' },
      clickable: true,
    },
    sorting: {
      by: 'Trait',
      order: 'Ascending',
      key: 'displayName',
      type: 'Alphabetical',
    },
    viewMode: 'line',
  };

  static readonly defaultAssistants: AssistantsSourceSettings = {
    type: 'assistants',
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    noFooter: false,
  };

  static readonly searchViewAssistants: AssistantsSourceSettings = {
    type: 'assistants',
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    noFooter: true,
  };

  static readonly defaultCollections: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    noFooter: true,
  };

  static readonly defaultWikis: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    noFooter: true,
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };

  static readonly searchViewCollections: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: `Results ${resultHeaderCountKey}`,
    },
    noFooter: true,
  };

  static readonly searchViewWikis: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: `Results ${resultHeaderCountKey}`,
    },
    noFooter: true,
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };

  private static readonly searchCollections: CollectionsSourceSettings = {
    id: 'collections',
    type: 'collection',
    maxCount: this.COLLECTIONS_COUNT,
    showGroupHeader: true,
    header: {
      title: 'Collections',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'collections',
        title: 'Collections',
        type: 'active-page',
        value: 'collections',
      },
    },
    filters: {
      preFilters: {
        'collection-type': ['Live', 'Static'],
      },
    },
  };

  private static readonly searchWikis: CollectionsSourceSettings = {
    id: 'wikis',
    type: 'collection',
    maxCount: this.COLLECTIONS_COUNT,
    showGroupHeader: true,
    header: {
      title: 'Wikis',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'wikis',
        title: 'Wikis',
        type: 'active-page',
        value: 'wikis',
      },
    },
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };

  private static readonly groupCollections: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: 'Collections',
      titleEnd: resultHeaderCountKey,
    },
  };

  private static readonly groupWikis: CollectionsSourceSettings = {
    type: 'collection',
    header: {
      title: 'Collections',
      titleEnd: resultHeaderCountKey,
    },
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };

  private static readonly staticCollections: StaticCollectionItemSourceSettings = {
    type: 'static-collection-items',
    header: {
      title: 'Showing results',
      titleEnd: resultHeaderCountKey,
    },
    convertItem: true,
  };

  private static readonly questionnaire: QuestionnaireSourceSettings = {
    type: 'fill-questionnaire',
  };

  private static readonly launcherStaticItems: StaticItemsSourceSettings = {
    type: 'static-items',
    itemTypes: ['open-external'],
  };

  private static readonly embedLauncherStaticItems: StaticItemsSourceSettings = {
    ...ResultSettings.launcherStaticItems,
    isExternalEmbed: true,
  };

  private static settings: {
    [platform: string]: {
      [view: string]: {
        default: SourceSettings[];
        search: SourceSettings[];
        group?: SourceSettings[];
        sorting?: SourceSettings[];
      };
    };
  } = {
    'desktop-native-search-page': {
      search: {
        default: [ResultSettings.recentSearch],
        search: [
          ResultSettings.answers,
          ResultSettings.people,
          ResultSettings.relevantPeople,
          ResultSettings.searchGoLinks,
          ResultSettings.searchCollections,
          ResultSettings.searchWikis,
          ResultSettings.defaultLinkResources,
        ],
        sorting: [ResultSettings.answers, ResultSettings.defaultLinkResources],
      },
      cards: {
        default: [ResultSettings.defaultCards],
        search: [ResultSettings.searchCards],
      },
      drafts: {
        default: [ResultSettings.drafts],
        search: [ResultSettings.searchDrafts],
      },
      view: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      appView: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      confluence: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
      'relevant-people': {
        default: [ResultSettings.groupRelevantPeople],
        search: [ResultSettings.groupRelevantPeopleSearch],
      },
      files: {
        default: [ResultSettings.fileLinkResources],
        search: [ResultSettings.fileSearchLinkResources],
      },
      mail: {
        default: [ResultSettings.mailResources],
        search: [ResultSettings.mailSearchResources],
      },
      linkResources: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      goto: {
        default: [ResultSettings.groupGoto],
        search: [ResultSettings.groupGoto],
      },
      chat: {
        default: [ResultSettings.groupChat],
        search: [ResultSettings.groupChat],
      },
      actions: {
        default: [ResultSettings.groupLocalActions],
        search: [ResultSettings.groupLocalActions],
      },
      webSearch: {
        default: [ResultSettings.groupWebSearch],
        search: [ResultSettings.groupWebSearch],
      },
      local: {
        default: [ResultSettings.groupLocalLinkResources],
        search: [ResultSettings.groupLocalLinkResources],
      },
      golinks: {
        default: [ResultSettings.defaultGoLinks],
        search: [ResultSettings.searchViewGoLinks],
        group: [ResultSettings.groupGoLinks],
      },
      visits: {
        default: [ResultSettings.groupVisits],
        search: [ResultSettings.groupVisits],
      },
      collections: {
        default: [ResultSettings.defaultCollections],
        search: [ResultSettings.searchViewCollections],
        group: [ResultSettings.groupCollections],
      },
      wikis: {
        default: [ResultSettings.defaultWikis],
        search: [ResultSettings.searchViewWikis],
        group: [ResultSettings.groupWikis],
      },
      favorites: {
        default: [ResultSettings.groupFavorites],
        search: [ResultSettings.groupSearchFavorites],
      },
      'live-collection': {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      'static-collection': {
        default: [ResultSettings.staticCollections],
        search: [ResultSettings.staticCollections],
      },
      'wiki-collection': {
        default: [ResultSettings.cards],
        search: [ResultSettings.answers, ResultSettings.cards],
      },
      assistants: {
        default: [ResultSettings.defaultAssistants],
        search: [ResultSettings.searchViewAssistants],
      },
    },
    'desktop-web-search-page': {
      search: {
        default: [ResultSettings.recentSearch],
        search: [
          ResultSettings.answers,
          ResultSettings.people,
          ResultSettings.relevantPeople,
          ResultSettings.searchGoLinks,
          ResultSettings.searchCollections,
          ResultSettings.searchWikis,
          ResultSettings.defaultLinkResources,
        ],
        sorting: [ResultSettings.answers, ResultSettings.defaultLinkResources],
      },
      view: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      appView: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      confluence: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      cards: {
        default: [ResultSettings.defaultCards],
        search: [ResultSettings.searchCards],
      },
      drafts: {
        default: [ResultSettings.drafts],
        search: [ResultSettings.searchDrafts],
      },
      slack: {
        default: [ResultSettings.slackLinkResources],
        search: [ResultSettings.answers, ResultSettings.slackLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.slackLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
      'relevant-people': {
        default: [ResultSettings.groupRelevantPeople],
        search: [ResultSettings.groupRelevantPeopleSearch],
      },
      files: {
        default: [ResultSettings.fileLinkResources],
        search: [ResultSettings.fileSearchLinkResources],
      },
      mail: {
        default: [ResultSettings.mailResources],
        search: [ResultSettings.mailSearchResources],
      },
      linkResources: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      goto: {
        default: [ResultSettings.groupGoto],
        search: [ResultSettings.groupGoto],
      },
      chat: {
        default: [ResultSettings.groupChat],
        search: [ResultSettings.groupChat],
      },
      actions: {
        default: [ResultSettings.groupLocalActions],
        search: [ResultSettings.groupLocalActions],
      },
      local: {
        default: [ResultSettings.groupLocalLinkResources],
        search: [ResultSettings.groupLocalLinkResources],
      },
      golinks: {
        default: [ResultSettings.defaultGoLinks],
        search: [ResultSettings.searchViewGoLinks],
        group: [ResultSettings.groupGoLinks],
      },
      visits: {
        default: [ResultSettings.groupVisits],
        search: [ResultSettings.groupVisits],
      },
      collections: {
        default: [ResultSettings.defaultCollections],
        search: [ResultSettings.searchViewCollections],
        group: [ResultSettings.groupCollections],
      },
      wikis: {
        default: [ResultSettings.defaultWikis],
        search: [ResultSettings.searchViewWikis],
        group: [ResultSettings.groupWikis],
      },
      favorites: {
        default: [ResultSettings.groupFavorites],
        search: [ResultSettings.groupSearchFavorites],
      },
      'live-collection': {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      'static-collection': {
        default: [ResultSettings.staticCollections],
        search: [ResultSettings.staticCollections],
      },
      'wiki-collection': {
        default: [ResultSettings.cards],
        search: [ResultSettings.answers, ResultSettings.cards],
      },
      assistants: {
        default: [ResultSettings.defaultAssistants],
        search: [ResultSettings.searchViewAssistants],
      },
    },
    'desktop-native-search-bar': {
      search: {
        default: [ResultSettings.defaultLocalActions, ResultSettings.recentSearchLauncher, ResultSettings.suggestions],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.questionnaire,
          ResultSettings.launcherStaticItems,
          ResultSettings.favorites,
          ResultSettings.launcherPeople,
          ResultSettings.launcherLocalActions,
          ResultSettings.visits,
          ResultSettings.launcherSearchGoLinks,
          ResultSettings.searchCollections,
          ResultSettings.searchWikis,
          ResultSettings.launcherCards,
          ResultSettings.launcherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.questionnaire, ResultSettings.launcherLinkResources],
      },
      view: {
        default: [ResultSettings.favorites, ResultSettings.groupLauncherLinkResourcesNoExclude],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.launcherStaticItems,
          ResultSettings.favorites,
          ResultSettings.groupLauncherLinkResourcesNoExclude,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.launcherStaticItems, ResultSettings.groupLauncherLinkResourcesNoExclude],
      },
      appView: {
        default: [ResultSettings.favorites, ResultSettings.visits, ResultSettings.groupLauncherLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.launcherStaticItems,
          ResultSettings.favorites,
          ResultSettings.visits,
          ResultSettings.groupLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.launcherStaticItems, ResultSettings.groupLauncherLinkResources],
      },
      confluence: {
        default: [ResultSettings.favorites, ResultSettings.visits, ResultSettings.groupLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.launcherStaticItems,
          ResultSettings.favorites,
          ResultSettings.visits,
          ResultSettings.groupLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.launcherStaticItems, ResultSettings.groupLinkResources],
      },
      slack: {
        default: [ResultSettings.favorites, ResultSettings.slackLauncherLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.launcherStaticItems,
          ResultSettings.favorites,
          ResultSettings.slackLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.launcherStaticItems, ResultSettings.slackLauncherLinkResources],
      },
      cards: {
        default: [ResultSettings.launcherCards],
        search: [ResultSettings.launcherCards],
      },
      drafts: {
        default: [ResultSettings.drafts],
        search: [ResultSettings.drafts],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
      files: {
        default: [ResultSettings.fileLauncherLinkResources],
        search: [ResultSettings.fileSearchLauncherLinkResources],
      },
      mail: {
        default: [ResultSettings.launcherMailResources],
        search: [ResultSettings.launcherMailSearchResources],
      },
      linkResources: {
        default: [ResultSettings.groupLauncherLinkResources],
        search: [ResultSettings.groupLauncherLinkResources],
      },
      goto: {
        default: [ResultSettings.groupGoto],
        search: [ResultSettings.groupGoto],
      },
      chat: {
        default: [ResultSettings.groupChat],
        search: [ResultSettings.groupChat],
      },
      actions: {
        default: [ResultSettings.groupLocalActions],
        search: [ResultSettings.groupLocalActions],
      },
      local: {
        default: [ResultSettings.groupLauncherLocalLinkResources],
        search: [ResultSettings.groupLauncherLocalLinkResources],
      },
      golinks: {
        default: [ResultSettings.defaultLauncherGoLinks],
        search: [ResultSettings.searchViewLauncherGoLinks],
        group: [ResultSettings.groupGoLinksLauncher],
      },
      visits: {
        default: [ResultSettings.groupVisits],
        search: [ResultSettings.groupVisits],
      },
      favorites: {
        default: [ResultSettings.groupFavorites],
        search: [ResultSettings.groupSearchFavorites],
      },
      collections: {
        default: [ResultSettings.defaultCollections],
        search: [ResultSettings.searchViewCollections],
        group: [ResultSettings.groupCollections],
      },
      wikis: {
        default: [ResultSettings.defaultWikis],
        search: [ResultSettings.searchViewWikis],
        group: [ResultSettings.groupWikis],
      },
      'live-collection': {
        default: [ResultSettings.groupLauncherLinkResourcesLiveCollection],
        search: [ResultSettings.groupLauncherLinkResourcesLiveCollection],
      },
      'static-collection': {
        default: [ResultSettings.staticCollections],
        search: [ResultSettings.staticCollections],
      },
      'wiki-collection': {
        default: [ResultSettings.cards],
        search: [ResultSettings.answersLauncher, ResultSettings.cards],
      },
      assistants: {
        default: [ResultSettings.defaultAssistants],
        search: [ResultSettings.searchViewAssistants],
      },
    },
    'desktop-web-embed-search-bar': {
      search: {
        default: [ResultSettings.recentSearchEmbed],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.questionnaire,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.embedItems,
          ResultSettings.visits,
          ResultSettings.embedLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherLinkResources],
      },
      view: {
        default: [ResultSettings.favorites, ResultSettings.launcherLinkResourcesNoExclude],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.favorites,
          ResultSettings.launcherLinkResourcesNoExclude,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
      },
      appView: {
        default: [ResultSettings.favorites, ResultSettings.visits, ResultSettings.groupEmbedLauncherLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.favorites,
          ResultSettings.visits,
          ResultSettings.groupEmbedLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.groupEmbedLauncherLinkResources],
      },
      confluence: {
        default: [ResultSettings.favorites, ResultSettings.visits, ResultSettings.groupLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.favorites,
          ResultSettings.visits,
          ResultSettings.groupLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.groupLinkResources],
      },
      slack: {
        default: [ResultSettings.favorites, ResultSettings.slackLauncherLinkResources],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.favorites,
          ResultSettings.slackLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.slackLauncherLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
      files: {
        default: [ResultSettings.fileLauncherLinkResources],
        search: [ResultSettings.fileSearchLauncherLinkResources],
      },
      mail: {
        default: [ResultSettings.launcherMailResources],
        search: [ResultSettings.launcherMailSearchResources],
      },
      linkResources: {
        default: [ResultSettings.groupEmbedLauncherLinkResources],
        search: [ResultSettings.groupEmbedLauncherLinkResources],
      },
      goto: {
        default: [ResultSettings.groupGoto],
        search: [ResultSettings.groupGoto],
      },
      chat: {
        default: [ResultSettings.groupChat],
        search: [ResultSettings.groupChat],
      },
      actions: {
        default: [ResultSettings.groupLocalActions],
        search: [ResultSettings.groupLocalActions],
      },
      local: {
        default: [ResultSettings.groupLauncherLocalLinkResources],
        search: [ResultSettings.groupLauncherLocalLinkResources],
      },
      golinks: {
        default: [ResultSettings.defaultLauncherGoLinks],
        search: [ResultSettings.searchViewLauncherGoLinks],
        group: [ResultSettings.groupGoLinksLauncher],
      },
      visits: {
        default: [ResultSettings.groupVisits],
        search: [ResultSettings.groupVisits],
      },
      favorites: {
        default: [ResultSettings.groupFavorites],
        search: [ResultSettings.groupSearchFavorites],
      },
      collections: {
        default: [ResultSettings.defaultCollections],
        search: [ResultSettings.searchViewCollections],
        group: [ResultSettings.groupCollections],
      },
      wikis: {
        default: [ResultSettings.defaultWikis],
        search: [ResultSettings.searchViewWikis],
        group: [ResultSettings.groupWikis],
      },
      'live-collection': {
        default: [ResultSettings.groupLauncherLinkResourcesLiveCollection],
        search: [ResultSettings.groupLauncherLinkResourcesLiveCollection],
      },
      'static-collection': {
        default: [ResultSettings.staticCollections],
        search: [ResultSettings.staticCollections],
      },
      'wiki-collection': {
        default: [ResultSettings.cards],
        search: [ResultSettings.answersLauncher, ResultSettings.cards],
      },
      cards: {
        default: [ResultSettings.cards],
        search: [ResultSettings.cards],
      },
      drafts: {
        default: [ResultSettings.drafts],
        search: [ResultSettings.drafts],
      },
      assistants: {
        default: [ResultSettings.defaultAssistants],
        search: [ResultSettings.searchViewAssistants],
      },
    },
    'desktop-web-embed-search-page': {
      search: {
        default: [ResultSettings.suggestions],
        search: [
          ResultSettings.questionnaire,
          ResultSettings.answers,
          ResultSettings.people,
          ResultSettings.relevantPeople,
          ResultSettings.searchGoLinks,
          ResultSettings.searchCollections,
          ResultSettings.searchWikis,
          ResultSettings.defaultLinkResources,
        ],
        sorting: [ResultSettings.answers, ResultSettings.defaultLinkResources],
      },
      view: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      appView: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      confluence: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.answers, ResultSettings.groupLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.groupLinkResources],
      },
      cards: {
        default: [ResultSettings.cards],
        search: [ResultSettings.cards],
      },
      drafts: {
        default: [ResultSettings.drafts],
        search: [ResultSettings.drafts],
      },
      slack: {
        default: [ResultSettings.slackLinkResources],
        search: [ResultSettings.answers, ResultSettings.slackLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.slackLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
      'relevant-people': {
        default: [ResultSettings.groupRelevantPeople],
        search: [ResultSettings.groupRelevantPeopleSearch],
      },
      files: {
        default: [ResultSettings.fileLinkResources],
        search: [ResultSettings.fileSearchLinkResources],
      },
      mail: {
        default: [ResultSettings.mailResources],
        search: [ResultSettings.mailSearchResources],
      },
      linkResources: {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      goto: {
        default: [ResultSettings.groupGoto],
        search: [ResultSettings.groupGoto],
      },
      chat: {
        default: [ResultSettings.groupChat],
        search: [ResultSettings.groupChat],
      },
      actions: {
        default: [ResultSettings.groupLocalActions],
        search: [ResultSettings.groupLocalActions],
      },
      local: {
        default: [ResultSettings.groupLocalLinkResources],
        search: [ResultSettings.groupLocalLinkResources],
      },
      golinks: {
        default: [ResultSettings.defaultGoLinks],
        search: [ResultSettings.searchViewGoLinks],
        group: [ResultSettings.groupGoLinks],
      },
      visits: {
        default: [ResultSettings.groupVisits],
        search: [ResultSettings.groupVisits],
      },
      collections: {
        default: [ResultSettings.defaultCollections],
        search: [ResultSettings.searchViewCollections],
        group: [ResultSettings.groupCollections],
      },
      wikis: {
        default: [ResultSettings.defaultWikis],
        search: [ResultSettings.searchViewWikis],
        group: [ResultSettings.groupWikis],
      },
      favorites: {
        default: [ResultSettings.groupFavorites],
        search: [ResultSettings.groupSearchFavorites],
      },
      'live-collection': {
        default: [ResultSettings.groupLinkResources],
        search: [ResultSettings.groupLinkResources],
      },
      'static-collection': {
        default: [ResultSettings.staticCollections],
        search: [ResultSettings.staticCollections],
      },
      'wiki-collection': {
        default: [ResultSettings.cards],
        search: [ResultSettings.answers, ResultSettings.cards],
      },
      assistants: {
        default: [ResultSettings.defaultAssistants],
        search: [ResultSettings.searchViewAssistants],
      },
    },
    'desktop-assistant-web-search-page': {
      search: {
        default: [ResultSettings.recentSearchEmbed],
        search: [ResultSettings.answers, ResultSettings.people, ResultSettings.groupResources, ResultSettings.assistantLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      view: {
        default: [ResultSettings.linkResourcesNoExclude],
        search: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      appView: {
        default: [ResultSettings.linkResourcesNoExclude],
        search: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      slack: {
        default: [ResultSettings.slackLauncherLinkResources],
        search: [ResultSettings.answers, ResultSettings.slackLauncherLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.slackLauncherLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
    },
    'desktop-assistant-embed-search-bar': {
      search: {
        default: [ResultSettings.recentSearchEmbed],
        search: [
          ResultSettings.answersLauncher,
          ResultSettings.embedLauncherStaticItems,
          ResultSettings.people,
          ResultSettings.groupResources,
          ResultSettings.assistantLauncherLinkResources,
        ],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
      },
      view: {
        default: [ResultSettings.launcherLinkResourcesNoExclude],
        search: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
      },
      appView: {
        default: [ResultSettings.launcherLinkResourcesNoExclude],
        search: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.launcherLinkResourcesNoExclude],
      },
      slack: {
        default: [ResultSettings.slackLauncherLinkResources],
        search: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.slackLauncherLinkResources],
        sorting: [ResultSettings.answersLauncher, ResultSettings.embedLauncherStaticItems, ResultSettings.slackLauncherLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
    },
    'desktop-assistant-embed-search-page': {
      search: {
        default: [ResultSettings.recentSearchEmbed],
        search: [ResultSettings.answers, ResultSettings.people, ResultSettings.groupResources, ResultSettings.assistantLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      view: {
        default: [ResultSettings.linkResourcesNoExclude],
        search: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      appView: {
        default: [ResultSettings.linkResourcesNoExclude],
        search: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
        sorting: [ResultSettings.answers, ResultSettings.linkResourcesNoExclude],
      },
      slack: {
        default: [ResultSettings.slackLauncherLinkResources],
        search: [ResultSettings.answers, ResultSettings.slackLauncherLinkResources],
        sorting: [ResultSettings.answers, ResultSettings.slackLauncherLinkResources],
      },
      people: {
        default: [ResultSettings.groupPeople],
        search: [ResultSettings.groupPeopleSearch],
      },
    },
    'desktop-browser-extension-side-panel': {
      search: {
        default: [],
        search: [
          ResultSettings.answersExtension,
          ResultSettings.people,
          ResultSettings.relevantPeople,
          ResultSettings.searchGoLinks,
          ResultSettings.searchCollections,
          ResultSettings.searchWikis,
          ResultSettings.defaultLinkResources,
        ],
        sorting: [ResultSettings.answers, ResultSettings.defaultLinkResources],
      },
    },
  };

  static GetSettings(platform: SettingsPlatform, format: SettingsFormat, view?: string): SourceSettings[] {
    if (!view) {
      view = 'search';
    }
    let settings = ResultSettings.settings[platform]?.[view]?.[format];
    if (!settings && format === 'sorting') {
      format = 'search';
      settings = ResultSettings.settings[platform]?.[view]?.[format];
    }
    if (!settings) {
      settings = ResultSettings.settings[platform]['view'][format];
    }
    return settings;
  }
}
