import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Style } from '@local/client-contracts';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { isTruncated } from '@shared/utils/elements-util';
import { SearchPopupItem, SearchPopupItemType } from '../model';

@Component({
  selector: 'search-popup-item',
  templateUrl: './search-popup-item.component.html',
  styleUrls: ['./search-popup-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopupItemComponent implements AfterViewInit, OnDestroy {
  subtitle: string;
  private _model: SearchPopupItem<SearchPopupItemType>;
  private subtitleSizeObserver: ResizeObserver;
  private appliedSubtitleShorteningLogic: boolean;
  @ViewChild('subtitleRef') subtitleRef: ElementRef;
  @Input() displaySubTitle = false;
  @Input() set model(value: SearchPopupItem<SearchPopupItemType>) {
    this._model = value;
    this.subtitle = this.buildSubtitle(value?.subtitle);
  }

  constructor(
    private ref: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  get model(): SearchPopupItem<SearchPopupItemType> {
    return this._model;
  }
  get icon(): Style.EntityIcon<Style.EntityIconType> {
    if (!this.model.icon) {
      return { type: 'font-icon', value: 'icon-question' };
    }
    return this.model.icon;
  }

  ngAfterViewInit() {
    if (!this.subtitleRef) return;
    const { nativeElement: el }: { nativeElement: HTMLElement } = this.subtitleRef;

    if (el && !this.subtitleSizeObserver) {
      this.subtitleSizeObserver = new (<any>window).ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (this.appliedSubtitleShorteningLogic || !entries.length) return;

        const newSubtitle = this.buildSubtitle(this.model.subtitle);

        if (newSubtitle && this.subtitle !== newSubtitle) {
          this.subtitle = newSubtitle;
          this.cdr.detectChanges();
        }
      });
      this.subtitleSizeObserver.observe(el);
    }
  }

  ngOnDestroy() {
    if (this.subtitleSizeObserver) {
      this.subtitleSizeObserver.disconnect();
    }
  }

  private buildSubtitle(subtitle: SearchPopupItem<SearchPopupItemType>['subtitle']): string {
    if (!subtitle) return;

    if (typeof subtitle === 'string') {
      return subtitle;
    } else if (isTruncated(this.ref.nativeElement)) {
      const sub: Breadcrumb[] = this.model.subtitle as Breadcrumb[];
      sub[0].title + '/.../' + sub[this.model.subtitle.length - 1].title;
      this.appliedSubtitleShorteningLogic = true;
    }
    return subtitle.map((b) => b.title).join('/');
  }
}
