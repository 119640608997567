import { Injectable } from '@angular/core';
import {
  Accounts,
  Collections,
  Commands,
  Favorites,
  Filters,
  HomePins,
  HomeTabs,
  NavTree,
  Omnibox,
  Permissions,
  Results,
  Search,
  SessionInfo,
  Style,
  Verifications,
  Wiki,
  Workspace,
} from '@local/client-contracts';
import { observable } from '@local/common';
import {
  generateId,
  generateUniqTitle,
  isEmbed,
  isFileItem,
  isLinkResourceItem,
  isLiveCollection,
  isStaticCollection,
  isUrlItem,
  isWikiCollection,
} from '@local/common-web';
import { capitalCase, cutText, generateTitleUrl } from '@local/ts-infra';
import { PopupRef, PopupService, INIT_POSITION_POPUP } from '@local/ui-infra';
import { DisplaySearchFilterValue, Filter } from '@shared/components/filters/models';
import { EmbedService } from '@shared/embed.service';
import { EventsService, LogService, ServicesRpcService, WindowService } from '@shared/services';
import { AccountsService } from '@shared/services/accounts.service';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { DatePickerService } from '@shared/services/date-picker.service';
import { GroupsService } from '@shared/services/groups.service';
import { RouterService } from '@shared/services/router.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { generateFullPrefixedURL } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { chain, cloneDeep, groupBy, isEmpty, isEqual, keyBy, map as lMap, mergeWith, uniqBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  startWith,
  take,
  takeUntil,
} from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { AppPopupComponent, AppPopupData } from '../components/app-popup/app-popup.component';
import { AddToCollectionSelect } from '../components/selected-item-popup/models/add-to-collection-select';
import { MoveCollectionWidgetToTabSelect } from '../components/selected-item-popup/models/move-collection-widget-to-tab-select';
import { SelectedItem } from '../components/selected-item-popup/selected-item-popup.component';
import { ShareOptionsPermissionsModel } from '../components/share-options-permissions/share-options-permissions.component';
import { VerificationOptionsDetailsModel } from '../components/verifications/verification-options-details/verification-options-details.component';
import { AvatarItemModel } from '../models/avatar-item.model';
import { CollectionResultData, CollectionSearchResultData } from '../models/collection-result-data';
import { MultiChoicePopupType } from '../views/collections-page/components/multi-choice-popup/multi-choice-popup.component';
import {
  COLLECTIONS_ROUTE_TYPES,
  COLLECTION_KIND_TO_FILTER,
  COLLECTION_ROUTE_TO_KIND_MAP,
  CollectionRouteType,
  CollectionViewMode,
} from '../views/collections-page/models';
import { CollectionTag } from '../views/collections-page/models/collection-tag.model';
import { CollectionViewType } from '../views/collections-page/models/collection-view-type.model';
import { CollectionPopupService } from '../views/collections-page/services/collection-popup.service';
import { WidgetsService } from '../views/home-page/services/widgets.service';
import { ResultContextMenuItem, TelemetryTrigger } from '../views/results/models/results-types';
import { AvatarListService } from './avatar-list.service';
import { BlobsService } from './blobs/blob.service';
import { CollectionsUtilService } from './collections-util.service';
import { CommandsService } from './commands/commands.service';
import { FavoritesService } from './favorites.service';
import { FiltersService } from './filters.service';
import { HomePageService } from './home-page.service';
import { HomePinsService } from './home-pins.service';
import { HomeTabsService } from './home-tabs.service';
import { HubService } from './hub.service';
import { CollectionsRpcInvoker } from './invokers/collections.rpc-invoker';
import { NavTreeService } from './nav-tree.service';
import { PreviewService } from './preview.service';
import { SearchParamsService } from './search-params.service';
import { OpenSelectPopupModel } from './select-items/select-items-popup-base.service';
import { ShareOptionsService } from './share-options.service';
import { ShowToasterService } from './show-toaster.service';
import { SuggestionProvider } from './suggestions/suggestion-provider';
import { TagsService } from './tags.service';
import { VerificationsFyiHelperService } from './verifications-fyi-helper-service';
import { WikiCardsService } from './wikis/wiki-cards.service';
import { COLLECTION_TYPE_TO_ICON_MAP } from '../views/collections-page/helpers/collection-type-icon-map';

export interface SingleCollectionLayoutRoaming {
  id: string;
  layout: Results.LayoutType;
}

export type ItemType = 'pin' | 'collection';

export type CreateNewType = Collections.Kind | 'Card' | 'Folder';

@Injectable()
export class CollectionsService implements CollectionResultData {
  private readonly TOASTER_ID = 'collection-error';
  private readonly SKIP_GUARD_KEY_STATE = 'skip-collections-guard';
  private readonly CUSTOM_DATE_ROAMING_ENTRY = 'collectionCustomDate';
  private readonly COLLECTIONS_LAYOUT_MODE_ENTRY = 'collectionsLayoutMode';
  private readonly WIKIS_LAYOUT_MODE_ENTRY = 'wikisLayoutMode';
  private readonly COLLECTIONS_KINDS_ALL: Collections.Kind[] = ['Live', 'Static', 'Wiki'];
  private readonly TAGS_FILTER_NAME = 'collection-tags';
  private filtersByCollectionKind: { [kind in Collections.Kind]?: Filter[] } = {};
  private service: Collections.Service;
  private _currentCollection$ = new BehaviorSubject<Collections.Collection>(null);
  private _collectionTags$ = new BehaviorSubject<CollectionTag[]>(null);
  private logger: Logger;
  private _collectionCustomDateOverrides$ = new BehaviorSubject<Collections.CollectionCustomDateRoamingData>(null);
  private _collectionsLayoutMode$ = new BehaviorSubject<Results.LayoutType>(null);
  private _wikisLayoutMode$ = new BehaviorSubject<Results.LayoutType>(null);
  private _collectionResultData$ = new BehaviorSubject<CollectionSearchResultData>(null);
  private _all$ = new ReplaySubject<Collections.Collection[]>(1);
  private _deletedWiki$ = new Subject<Wiki.DeleteWikiRequest>();
  private _onFocusChange$ = new Subject<string>();
  private _isOwnerOrAdmin = false;
  private _selectedTabs: SelectedItem[] = [];
  private allTabs: HomeTabs.HomeTab[];
  private allCollection: Collections.Collection[];
  private isEmbed = isEmbed();
  private areYouSurePopupRef: PopupRef<AppPopupComponent, AppPopupData> = null;
  private _isDeleteMode: boolean;
  sessionInfo: SessionInfo;
  isSearchParamsChanged$ = new ReplaySubject<{ changed: boolean; collection: Collections.Collection }>(1);
  timeFilters: string[];
  openSelectPopup$ = new Subject<OpenSelectPopupModel>();

  get isDeleteMode() {
    return this._isDeleteMode;
  }

  get isOwnerOrAdmin() {
    return this._isOwnerOrAdmin;
  }

  private set isOwnerOrAdmin(value: boolean) {
    this._isOwnerOrAdmin = value;
  }

  get collections() {
    return this.allCollection;
  }

  get currentUser() {
    return this.sessionInfo.user;
  }

  @observable
  get currentCollection$(): BehaviorSubject<Collections.Collection> {
    return this._currentCollection$;
  }

  get currentCollection(): Collections.Collection {
    return this._currentCollection$.value;
  }

  @observable
  get collectionResultData$(): BehaviorSubject<CollectionSearchResultData> {
    return this._collectionResultData$;
  }

  private set currentCollection(collection: Collections.Collection) {
    this._currentCollection$.next(collection);
  }

  set onFocusChange(focus: string) {
    this._onFocusChange$.next(focus);
  }

  @observable
  get onFocusChange$(): Subject<string> {
    return this._onFocusChange$;
  }

  @observable
  get collectionTags$(): Observable<CollectionTag[]> {
    return this._collectionTags$.asObservable();
  }

  @observable
  get deletedWiki$(): Observable<Wiki.DeleteWikiRequest> {
    return this._deletedWiki$;
  }

  //public fields
  collectionActions: Collections.UpdateAction[];
  currentCollectionView: Collections.Collection;
  deleteCollectionId: string;

  get collectionCustomDateOverrides$(): Observable<Collections.CollectionCustomDateRoamingData> {
    return this._collectionCustomDateOverrides$.asObservable();
  }

  get collectionsLayoutMode$(): Observable<Results.LayoutType> {
    return this._collectionsLayoutMode$.asObservable();
  }

  get wikisLayoutMode$(): Observable<Results.LayoutType> {
    return this._wikisLayoutMode$.asObservable();
  }

  get workspace(): Workspace.Workspace {
    return this.sessionInfo?.workspace;
  }

  get selectedTabs(): SelectedItem[] {
    return this._selectedTabs || [];
  }

  constructor(
    services: ServicesRpcService,
    private hubService: HubService,
    protected previewService: PreviewService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private routerService: RouterService,
    private commandsService: CommandsService,
    private navTreeService: NavTreeService,
    private breadcrumbsService: BreadcrumbsService,
    private titleBarService: TitleBarService,
    private searchParamsService: SearchParamsService,
    private collectionPopupService: CollectionPopupService,
    private sessionService: SessionService,
    private sessionStorageService: SessionStorageService,
    private datePickerService: DatePickerService,
    private tagsService: TagsService,
    private accountsService: AccountsService,
    private groupsService: GroupsService,
    logger: LogService,
    private window: WindowService,
    private embedService: EmbedService,
    public popupService: PopupService,
    private wikiCardsService: WikiCardsService,
    private homePinsService: HomePinsService,
    private homeTabService: HomeTabsService,
    private workspacesService: WorkspacesService,
    private favoritesService: FavoritesService,
    private showToasterService: ShowToasterService,
    private collectionsUtilService: CollectionsUtilService,
    private verificationsFyiHelperService: VerificationsFyiHelperService,
    private avatarListService: AvatarListService,
    private widgetService: WidgetsService,
    protected homePageService: HomePageService,
    private shareOptionsService: ShareOptionsService,
    private blobsService: BlobsService
  ) {
    this.logger = logger.scope('CollectionsService');
    this.service = services.invokeWith(CollectionsRpcInvoker, 'collections');

    this.initSession();

    combineLatest([
      this.service.all$,
      this.favoritesService.getByType$('collection').pipe(startWith(null)),
      this.homePinsService.all$.pipe(
        map((hps) => hps?.filter((hp) => hp.type === 'collection')),
        startWith(null)
      ),
    ]).subscribe(([all, favorites, homePins]) => {
      this.createCollectionTags(all);
      if (favorites?.length || homePins?.length) {
        all = this.updateCollectionsData(all, homePins, favorites);
      }
      this.allCollection = all;
      this._all$.next(all);
      const fyiDataItems = (all || []).map((c) => ({ id: c.id, title: c.title }));
      this.verificationsFyiHelperService.insertItems(fyiDataItems, 'collection');
    });

    combineLatest([this.all$, this.routerService.activeRoute$]).subscribe(async ([collections, currentRoute]) => {
      const collectionRoute = currentRoute?.snapshot?.data?.collection as Collections.Collection;

      if (!collectionRoute || !collections?.length) {
        this.currentCollection = null;
        return;
      }

      if (!currentRoute) {
        return;
      }

      const oldCollection = this.currentCollection;
      const updatedCollection = collections.find((b) => b.id === collectionRoute.id);

      let currentCollection: Collections.Collection;

      if (updatedCollection) {
        currentCollection = { ...updatedCollection };
        if (isStaticCollection(currentCollection) && currentCollection.items) {
          currentCollection.items = this.mapCollectionItems(currentCollection, collections);
        }
        if (!location.pathname.startsWith('/a/') && !currentRoute.snapshot?.queryParams?.purl) {
          this.titleBarService.active = currentCollection.title;
        }
        const id = generateTitleUrl(this.getCollectionPrefix(currentCollection), currentCollection.title, currentCollection.id);
        if (collectionRoute.title !== currentCollection.title || id !== this.routerService.active) {
          this.hubService.setState(this.SKIP_GUARD_KEY_STATE, 'true');
          setTimeout(() => {
            if (location.pathname.startsWith('/a/')) return;
            this.hubService.routerNavigate(id);
          }, 0);
        } else {
          this.hubService.setState(this.SKIP_GUARD_KEY_STATE, null);
        }
        this.buildBreadcrumbs(updatedCollection, isWikiCollection(updatedCollection) ? 'wikis' : 'collections');
      }

      const isNewCollection =
        oldCollection?.id === currentCollection?.id ? !this.isEqualCollection(oldCollection, currentCollection) : true;

      if (isNewCollection) {
        this.currentCollection = currentCollection;
        setTimeout(async () => {
          const isEqualFilters = this.isEqualSearchParams(oldCollection, currentCollection);
          this.updateCollectionSearchParams(currentCollection, !isEqualFilters).then(() => {
            this.searchParamsChanged();
          });
        }, 100);
      }

      this.createCollectionTags(collections);
    });
    this.homeTabService.all$.subscribe(async (all) => {
      const list: SelectedItem[] = [];
      const homeTab: SelectedItem = {
        name: 'Home',
        id: 'home',
        icon: 'icon-arrow-right-square',
        avatarList: [this.avatarListService.createWorkspaceAvatar()],
      };
      for (const tab of all) {
        if (tab.id !== 'home') {
          list.push({
            name: tab.name,
            icon: 'icon-arrow-right-square',
            id: tab.id,
            avatarList: await this.avatarListService.createAvatarList(tab.accountId, tab.shareOptions, true),
          });
        }
      }
      list.unshift(homeTab);
      this.allTabs = all;
      this._selectedTabs = list;
    });
    this.sessionService.accountChanged$.subscribe((s) => {
      if (!s) {
        this._collectionsLayoutMode$.next('list');
        this._wikisLayoutMode$.next('list');
      } else {
        this.loadCollectionsLayoutModeRoaming();
        this.loadWikisLayoutModeRoaming();
      }
    });
    this.loadCustomDateData();
    this.initCollectionPageFilters();

    this.navTreeService.registerPrefixResolver('c/', (nodeId: string, nodes: NavTree.Node[]) => {
      const id = nodeId.substring(nodeId.lastIndexOf('-') + 1);
      const newNode = nodes.find((n) => n.id?.includes(id));
      return newNode ? of(newNode) : of();
    });
    this.navTreeService.registerPrefixResolver('b/', (nodeId: string, nodes: NavTree.Node[]) => {
      const blobId = nodeId.split('-').slice(-1)[0];
      const node = nodes.find(
        (node) =>
          node.id.startsWith('c/') &&
          isStaticCollection(node.data?.collection) &&
          node.data.collection.items?.find((item) => item.id === blobId)
      );
      return of(node);
    });
  }

  buildBreadcrumbs(collection: Collections.Collection, type: CollectionRouteType) {
    let header: Breadcrumb;
    if (type === 'collections') {
      header = {
        title: 'Collections',
        icon: { type: 'font-icon', value: 'icon-collection' },
        path: 'collections',
      };
    } else {
      header = {
        title: 'Wikis',
        icon: { type: 'font-icon', value: 'font-icon icon-wiki' },
        path: 'wikis',
      };
    }
    this.breadcrumbsService.items = [
      header,
      {
        emoji: collection.emoji,
        title: collection.title,
        path: generateTitleUrl(this.getCollectionPrefix(collection), collection?.title, collection?.id),
        icon: { type: 'font-icon', value: COLLECTION_TYPE_TO_ICON_MAP[collection.kind] },
      },
    ];
  }

  isEqualSearchParams(collection1: Collections.Collection, collection2: Collections.Collection) {
    if (!collection1 || !collection2) return;
    if (!isLiveCollection(collection1) || !isLiveCollection(collection2)) return;
    const searchParams1: Collections.LiveSearchParams = collection1?.searchParams;
    const searchParams2: Collections.LiveSearchParams = collection2?.searchParams;
    return isEqual(searchParams1?.filters, searchParams2?.filters) && searchParams1?.query === searchParams2?.query;
  }

  mapCollectionItems(collection, allCollections) {
    const allCollectionsMap = {};
    allCollections.forEach((c) => (allCollectionsMap[c.id] = c));
    return collection.items
      .map((item) => {
        if (item?.kind === 'collection' && !allCollectionsMap[item.id]) return;
        return item;
      })
      .filter((item) => !!item);
  }

  updateCollectionsData(all: Collections.Collection[], homePins: HomePins.HomePin[], favoritesData: Favorites.Item[]) {
    const homePinsCollection = {};
    const tabsMap = {};
    this.allTabs?.map((t) => (tabsMap[t.id] = t));

    for (const homePin of homePins || []) {
      if (this.allTabs?.length && !tabsMap[homePin.tabId]) {
        this.homePinsService.delete(homePin.id);
        continue;
      }
      if (homePinsCollection[homePin.pinedId]) {
        homePinsCollection[homePin.pinedId].push(homePin);
      } else {
        homePinsCollection[homePin.pinedId] = [homePin];
      }
    }

    const favorites = keyBy(favoritesData, (f) => f.id);

    all?.forEach((c) => {
      c.pinBy = homePinsCollection[c.id];
      const favoriteItem = favorites[c.id];
      c.favoriteMarkedTime = favoriteItem ? favoriteItem.markedTime : null;
      if (isStaticCollection(c)) {
        c?.items
          ?.filter((i) => !!i)
          ?.forEach((item) => {
            const favoriteItem = favorites[item.id];
            item.favoriteMarkedTime = favoriteItem ? favoriteItem.markedTime : null;
          });
      }
    });

    return all;
  }

  get all$(): Observable<Collections.Collection[]> {
    return this._all$;
  }

  getCollectionById(id: string): Collections.Collection {
    return this.allCollection?.find((c) => c.id === id);
  }

  private initSession(): void {
    combineLatest([this.sessionService.current$, this.accountsService.all$, this.groupsService.all$]).subscribe({
      next: ([sessionInfo, accounts, groups]) => {
        if (!sessionInfo) {
          return;
        }
        this.sessionInfo = cloneDeep(sessionInfo);
        if (this.sessionInfo.workspace) {
          this.sessionInfo.workspace.accounts = accounts;
          this.sessionInfo.workspace.groups = groups;
        }
      },
    });
    this.workspacesService.ownerOrAdmin$.pipe(take(1)).subscribe((s) => {
      this.isOwnerOrAdmin = s;
    });
  }

  async loadCustomDateData() {
    const filterValues = await this.filtersService.getSuggestions('', null, 'box', { limit: false, returnAllValues: true }, {});
    this.timeFilters = filterValues
      .map((f) => {
        if (f.picker === 'time-dropdown') {
          return f.id.replace('filter:', '');
        }
      })
      .filter((f) => !!f);
  }

  isEqualCollection(oldCollection: Collections.Collection, currentCollection: Collections.Collection) {
    if (!oldCollection || !currentCollection) {
      return;
    }
    return isEqual(oldCollection, currentCollection);
  }

  async updateCollectionSearchParams(collection: Collections.Collection, updateFilters = true) {
    if (!collection) {
      return;
    }
    const searchParams: Collections.SearchParams = collection.searchParams;
    if (isStaticCollection(collection)) {
      if (searchParams?.layoutMode) {
        this.hubService.addState(this.searchParamsService.searchParamsKeys.layoutMode, [searchParams?.layoutMode]);
      }
      return;
    }
    if (updateFilters) {
      this.setCollectionFilter(searchParams);
    }
    this.setSortAndLayoutState(searchParams);
  }

  async getCurrentCardAvatar(collection: Collections.Collection, wikiCard: Wiki.Card): Promise<AvatarItemModel> {
    if (!collection) {
      return;
    }
    let avatar;
    const accountId = wikiCard?.id ? wikiCard.publishedBy || this.workspace.accountId : this.workspace.accountId;
    if (accountId !== collection.accountId) {
      avatar = this.avatarListService.getAvatarById(accountId, 'id');
      avatar.description = 'Editor';
    } else {
      avatar = await this.avatarListService.getOwnerAvatar(collection.accountId);
    }
    return avatar;
  }

  private setCollectionFilter(searchParams: Collections.LiveSearchParams | Collections.WikiSearchParams) {
    const liveParams = searchParams as Collections.LiveSearchParams;
    for (const [name, value] of Object.entries(liveParams?.filters || {})) {
      this.filtersService.setFilters(name, value, 'pre');
    }
    if (liveParams?.query) {
      this.hubService.query = liveParams.query;
    }
  }

  private setSortAndLayoutState(searchParams: Collections.LiveSearchParams | Collections.WikiSearchParams) {
    const liveParams = searchParams as Collections.LiveSearchParams;
    if (liveParams?.sort) {
      this.searchParamsService.addSort(searchParams.sort);
    }
    if (searchParams?.layoutMode) {
      this.hubService.addState(this.searchParamsService.searchParamsKeys.layoutMode, [searchParams?.layoutMode]);
    }
  }

  getSuggestionProvider(type: CollectionRouteType = 'collections'): SuggestionProvider {
    return async (term: string, group: string) => {
      if (term || group) {
        const collections = (await firstValueFrom(this.service.all$.pipe(filter((s) => !!s))))
          .filter((collection) => {
            const searchText = `${collection.title || ''} ${collection.description || ''}`.toLowerCase().trim();
            return COLLECTION_ROUTE_TO_KIND_MAP[type].includes(collection.kind) && searchText.includes((term || '').toLowerCase().trim());
          })
          .sort((a, b) => {
            const searchTextA = `${a.title || ''} ${a.description || ''}`.trim();
            const searchTextB = `${b.title || ''} ${b.description || ''}`.trim();
            return searchTextB.indexOf(term) - searchTextA.indexOf(term);
          });
        const suggestions: Omnibox.Suggestion[] = collections.map((collection) => this.getCollectionTag(collection) as Omnibox.Suggestion);
        return suggestions;
      }
      if (!group) {
        return [
          {
            id: 'collections',
            type: 'group',
            title: 'Collections',
            icon: { type: 'font-icon', value: 'icon-collection' },
          },
        ];
      }
    };
  }

  async getById(id: string): Promise<Collections.Collection> {
    const collections = await firstValueFrom(this.all$);
    const collection = collections?.find((c) => c.id === id);
    if (collection) {
      return collection;
    }
    return this.service.get(id);
  }

  async createCollection(
    collection: Collections.Collection,
    showToaster = true,
    showToasterButton = false,
    duplicated?: boolean
  ): Promise<Collections.Collection> {
    this.breadcrumbsService.showGhost = true;
    const title = collection.title === '' || !collection.title ? 'Untitled' : collection.title;
    return this.service
      .create({ ...collection, title })
      .then(async () => {
        await firstValueFrom(this.all$.pipe(filter((all) => !!all.find((c) => c.id === collection.id))));
        if (!showToaster) return collection;
        const toaster = this.showToasterService.showToaster({
          id: 'collection-create',
          content: duplicated ? `Created "${collection.title}"` : `A new collection "${collection.title}" created successfully `,
          intent: 'primary',
          buttonText: showToasterButton ? 'Open' : null,
          icon: { type: 'font', value: 'icon-check-circle' },
          iconIntent: 'success',
        });
        await this.toasterOpenAction(toaster, collection);
        return collection;
      })
      .catch(async () => {
        this.showToasterService.showErrorToaster(this.TOASTER_ID);
        await this.deleteCollection(collection);
        this.routerService.navigateByUrl('collections');
        return null;
      })
      .finally(() => {
        this.breadcrumbsService.showGhost = false;
      });
  }

  private async toasterOpenAction(toaster: any, collection: Collections.Collection) {
    const isLauncher = await this.hubService.getIsLauncher();
    const viewType: CollectionViewType = isLauncher ? 'search-view' : 'collections-view';
    toaster.compInstance.invoke.subscribe(() => {
      this.eventsService.event('collections.open_collection', {
        location: { title: this.hubService.currentLocation },
      });
      this.openCollection(collection, viewType);
      toaster.destroy();
    });
  }

  updateCollection(
    collectionId: string,
    actions: Collections.UpdateAction[],
    showToaster = true,
    toasterTitle?: string,
    shareOptions?: Permissions.ShareOptions,
    showToasterButton = false
  ): Promise<Collections.Collection | void> {
    return this.service
      .update(collectionId, actions, shareOptions)
      .then(async () => {
        if (!showToaster) {
          return;
        }
        const collection = await this.getById(collectionId);
        if (collection.id === this.currentCollection?.id) {
          this.isSearchParamsChanged$.next({ changed: false, collection: this.currentCollection });
        }
        const toaster = this.showToasterService.showToaster({
          id: 'collection-edit',
          content: toasterTitle ?? `The changes to '${collection.title}' have been successfully saved`,
          intent: 'primary',
          buttonText: showToasterButton ? 'Open' : null,
        });
        await this.toasterOpenAction(toaster, collection);
      })
      .catch(() => this.showToasterService.showErrorToaster(this.TOASTER_ID));
  }

  async deleteCollection(collection: Collections.Collection): Promise<void> {
    if (collection.pinBy) {
      this.deleteManyPins(collection.pinBy);
    }

    if (!this.allCollection) {
      return;
    }

    this._isDeleteMode = true;
    this.allCollection = this.allCollection.filter((c) => c.id !== collection.id);
    this._all$.next(this.allCollection);
    return this.service
      .delete(collection.id)
      .catch(() => this.showToasterService.showErrorToaster(this.TOASTER_ID))
      .finally(() => {
        this._isDeleteMode = false;
      });
  }

  duplicateCollection(collection: Collections.Collection) {
    const duplicated = cloneDeep(collection);
    delete duplicated.id;
    delete duplicated.shareOptions;
    delete duplicated.accountId;
    delete duplicated.modifiedBy;
    delete duplicated.modifiedTime;
    delete duplicated.isShared;
    duplicated.id = generateId();
    duplicated.title = `Copy of ${collection.title}`;
    this.createCollection(duplicated, true, false, true);
  }

  copyUrl(collection: Collections.Collection) {
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: generateFullPrefixedURL(`/${this.createCollectionUrl(collection)}`, 'path'),
    } as Commands.CopyClipboard);
  }

  async toggleFavorite(collection: Collections.Collection) {
    if (collection.favoriteMarkedTime) {
      this.favoritesService.delete(collection.id);
    } else {
      this.favoritesService.create({ id: collection.id, type: 'collection' });
    }
  }

  async openCollection(collection: Collections.Collection, viewType: CollectionViewType) {
    switch (viewType) {
      case 'collections-view':
        this.hubService.openUrl(this.createCollectionUrl(collection));
        break;
      case 'search-view':
        this.routerService.navigateByUrl(`search?col=${collection.id}`, { replaceUrl: this.isEmbed });
        await this.addCollectionTag(collection.id);
        await this.updateCollectionSearchParams(collection);
        break;
    }
  }

  private createCollectionUrl(collection: Collections.Collection) {
    const currentNode = this.navTreeService.currentNode?.children?.find((c) => c?.data?.collection?.id === collection.id);
    if (currentNode) {
      return currentNode.id;
    }
    return generateTitleUrl(this.getCollectionPrefix(collection), collection.title, collection.id);
  }

  getCollectionPrefix(collection: Collections.Collection): string {
    return isWikiCollection(collection) ? 'wikis' : 'c';
  }

  convertCardUrl(title: string, id: string) {
    return generateTitleUrl('a', title, id);
  }

  async buildNewCollection(collection: Collections.Collection): Promise<Collections.Collection> {
    const id = !collection.id ? generateId() : collection.id;
    const title = this.buildTitle(await firstValueFrom(this.service.all$), id, collection.title ?? 'Untitled');
    return { ...collection, id, title, accountId: this.workspace?.accountId };
  }

  async openCollectionView(
    collection: Collections.Collection,
    mode: 'new' | 'edit' | 'new-from' = 'new',
    navigate = true,
    showToaster = false,
    showToasterButton = false, 
    openInNewTab = false
  ): Promise<string> {
    let newCollection: Collections.Collection;
    if (mode === 'new' || mode === 'new-from') {
      const collections = await firstValueFrom(this._all$);
      this.eventsService.event('collections.create', {
        target: !collections?.length ? 'create_first_collection' : 'create_new_collection ',
        label: collection.kind.toLocaleLowerCase(),
        location: { title: this.hubService.currentLocation },
      });
      newCollection = await this.buildNewCollection(collection);
    }
    const url = this.createCollectionUrl(newCollection);
    if (navigate) {
      this.routerService.navigateByUrl(url, { state: { mode } } ,true ,openInNewTab);
    }
    if (newCollection) {
      await this.createCollection(newCollection, showToaster, showToasterButton);
    }
    return url;
  }

  async openNewCollection(kind: Collections.Kind, state: 'new' | 'new-from') {
    const url = await this.openCollectionView({ kind }, state, false);
    this.hubService.openUrl(url, { state: { mode: state } });
  }

  private buildTitle(collections: Collections.Collection[], id: string, title: string): string {
    if (!collections) {
      return;
    }
    const current = collections.find((b) => b.id === id);
    if (current && current?.title === title) {
      // in case of updating and the title not changed - return the current title
      return title;
    }
    const titles: string[] = collections.map((b) => b.title);
    return generateUniqTitle(title, titles);
  }

  async buildResourceStaticItem(item: Collections.LinkResourceItem, collectionId: string) {
    const resource = {
      ...item.searchResource,
      collectionId,
      isFavorite: !!item?.favoriteMarkedTime,
    };

    const blobId = item.searchResource?.fileId;

    if (!blobId) {
      return resource;
    }

    const blobUrl = await this.blobsService.getBlobFullUrl(blobId);

    return {
      ...resource,
      blobUrl,
      srcUrl: blobUrl,
    };
  }

  private getCollectionTag(collection: Collections.Collection): Omnibox.Tag | Omnibox.Suggestion {
    return {
      id: `collection-${collection.id}`,
      type: 'primary',
      title: collection.title,
      data: { showLabelOnTag: true },
      emojiUnicode: collection.emoji,
      icon: this.getCollectionTypeIcon(collection),
      tooltip: isLiveCollection(collection) ? this.createSearchParamsSummary(collection) : null,
    };
  }

  private getCollectionTypeIcon(collection: Collections.Collection): Style.EntityIcon<Style.EntityIconType> {
    return collection.emoji
      ? null
      : ({ type: 'font-icon', value: `icon-${isWikiCollection(collection) ? 'wiki' : 'collection'}` } as Style.EntityIcon<'font-icon'>);
  }

  async addCollectionTag(collectionId: string) {
    const collection = await this.getById(collectionId);
    const tag = this.getCollectionTag(collection) as Omnibox.Tag;
    if (!this.tagsService.all.find((t) => t.id === tag.id)) {
      this.tagsService.remove('collections');
      this.tagsService.add(tag);
    }
  }

  async onRemoveCollectionTag() {
    const collectionId = this.searchParamsService.searchParamsKeys.collection;
    this.hubService.removeMultiState([collectionId]);
    this.filtersService.removeAllFilters();
  }

  saveCollection(collection: Collections.Collection): Promise<Collections.Collection | void> {
    return this.updateCollection(collection.id, this.collectionActions);
  }

  openNewCollectionPopup(elm: Element, isPopupPositionBelow = false, tags: string[] = [], type: MultiChoicePopupType) {
    const popupRefNewCollection = this.collectionPopupService.openNewCollectionPopup(elm, isPopupPositionBelow, type);
    popupRefNewCollection.compInstance.onClickEvent.pipe(take(1)).subscribe(async (kind: CreateNewType) => {
      switch (kind) {
        case 'Card':
          this.collectionsUtilService.openWikiCard();
          break;
        case 'Live':
        case 'Wiki':
        case 'Static':
          this.openCollectionView({ kind, tags });
          break;
      }
    });
    return popupRefNewCollection;
  }

  openNewStaticItemPopup(position: { x; y }, trigger: 'button' | 'empty-state' | 'right-click') {
    return this.collectionPopupService.openNewStaticItemPopup(position, trigger);
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  createCollectionTags(res: Collections.Collection[]) {
    const isWikiKind = this.currentCollection?.kind === 'Wiki';
    const tagsMap = new Map<string, number>();
    res?.forEach((col) => {
      if ((isWikiKind && col.kind !== 'Wiki') || (!isWikiKind && col.kind === 'Wiki')) {
        return;
      }
      col?.tags?.forEach((tag) => {
        if (!tagsMap.has(tag)) {
          tagsMap.set(tag, 1);
        } else {
          tagsMap.set(tag, tagsMap.get(tag) + 1);
        }
      });
    });
    const collectionTag: CollectionTag[] = [];
    tagsMap.forEach((value, key) => {
      collectionTag.push({ value: key, amount: value });
    });
    this._collectionTags$.next(collectionTag);
  }

  openAreYouSurePopup(deactivateSubject: Subject<any>) {
    if (this.areYouSurePopupRef) {
      this.areYouSurePopupRef.destroy();
    }
    this.areYouSurePopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'You are about to leave this page,<br> do you want to save your changes before you go?',
        showButtons: true,
        rightButtonStyle: { type: 'primary', size: 152 },
        leftButtonStyle: { size: 152 },
        content: {
          secondaryButton: 'Discard',
          primaryButton: 'Save',
        },
        messageStyle: { fontSize: '14px' },
        popupStyle: { paddingTop: '32px' },
        showCloseIcon: true,
      },
      { position: INIT_POSITION_POPUP }
    );
    this.areYouSurePopupRef.compInstance.primaryButton.pipe(takeUntil(this.areYouSurePopupRef.destroy$)).subscribe(() => {
      const searchParams: Collections.LiveSearchParams = { filters: this.filtersService.inlineFilters };
      const collection = { ...this.currentCollectionView, searchParams };
      const updateAction: Collections.UpdateAction = { field: 'searchParams', type: 'Update', value: searchParams };
      this.updateCollection(collection.id, [updateAction], true);
      deactivateSubject.next(true);
    });
    this.areYouSurePopupRef.compInstance.secondaryButton.pipe(takeUntil(this.areYouSurePopupRef.destroy$)).subscribe(() => {
      deactivateSubject.next(true);
    });
    this.areYouSurePopupRef.compInstance.closeButton.pipe(takeUntil(this.areYouSurePopupRef.destroy$)).subscribe(() => {
      deactivateSubject.next(false);
    });
    this.areYouSurePopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.areYouSurePopupRef = null;
    });
    return this.areYouSurePopupRef;
  }

  async updateFavoriteStaticItem(collectionId: string, resultItem: Search.StaticCollectionResultItem) {
    let collection: Collections.Collection;
    if (this.currentCollectionView) {
      collection = this.currentCollectionView;
    } else {
      collection = await this.getById(collectionId);
    }
    if (isStaticCollection(collection)) {
      const itemIndex = collection.items.findIndex((collectionItem) => collectionItem.id === resultItem.id);
      const collectionStaticItem = collection.items[itemIndex];
      if (isUrlItem(collectionStaticItem) || isLinkResourceItem(collectionStaticItem) || isFileItem(collectionStaticItem)) {
        if (collectionStaticItem.favoriteMarkedTime) {
          this.favoritesService.delete(collectionStaticItem.id);
        } else {
          this.favoritesService.create({ id: collectionStaticItem.id, type: 'collection-item', parentId: collectionId });
        }
      }
    }
  }

  updateCollectionView(field: string, value: any) {
    this.currentCollectionView = { ...this.currentCollectionView, [field]: value };
  }

  private loadCollectionsLayoutModeRoaming() {
    return this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.COLLECTIONS_LAYOUT_MODE_ENTRY)
      .get()
      .then((layout: Results.LayoutType) => {
        this._collectionsLayoutMode$.next(layout);
      });
  }

  private loadWikisLayoutModeRoaming() {
    return this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.WIKIS_LAYOUT_MODE_ENTRY)
      .get()
      .then((layout: Results.LayoutType) => {
        this._wikisLayoutMode$.next(layout);
      });
  }

  saveLayoutModeRoaming(layoutMode: Results.LayoutType, contentType: 'collections' | 'wikis') {
    switch (contentType) {
      case 'collections':
        this.saveCollectionsLayoutModeRoaming(layoutMode);
        break;
      case 'wikis':
        this.saveWikisLayoutModeRoaming(layoutMode);
        break;
    }
  }

  private saveCollectionsLayoutModeRoaming(layoutMode: Results.LayoutType) {
    this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.COLLECTIONS_LAYOUT_MODE_ENTRY)
      .set(layoutMode)
      .then(() => {
        this._collectionsLayoutMode$.next(layoutMode);
      });
  }

  private saveWikisLayoutModeRoaming(layoutMode: Results.LayoutType) {
    this.sessionStorageService
      .getStore('roaming', 'account')
      .entry<Results.LayoutType>(this.WIKIS_LAYOUT_MODE_ENTRY)
      .set(layoutMode)
      .then(() => {
        this._wikisLayoutMode$.next(layoutMode);
      });
  }

  removeDateInCollectionCustomDateRoaming(collection: Collections.Collection, filterName: string) {
    const collectionRoaming = this._collectionCustomDateOverrides$.value as Collections.CollectionCustomDateRoamingData;
    if (collectionRoaming && collectionRoaming[collection.id]) {
      delete collectionRoaming[collection.id][filterName];
      this._collectionCustomDateOverrides$.next(collectionRoaming);
      this.sessionStorageService
        .getStore('roaming', 'account')
        .entry<Collections.CollectionCustomDateRoamingData>(this.CUSTOM_DATE_ROAMING_ENTRY)
        .set(collectionRoaming);
    }
  }

  openPinCollectionPopup(collection: Collections.Collection, trigger: TelemetryTrigger) {
    this.onFocusChange = 'pin-collection';
    const homePins = this.homePinsService.getAllByPinedId(collection.id);
    const currentTabsId = homePins?.map((h) => h.tabId);
    const itemsList = this.selectedTabs.map((s) => {
      return { ...s, icon: null };
    });
    const selectedTabs = itemsList.filter((s) => currentTabsId.includes(s.id));
    this.eventsService.event('home_tabs.pin', {
      label: trigger,
      target: 'move_to_tab',
      location: { title: this.hubService.currentLocation },
    });
    this.openSelectPopup$.next({
      data: {
        itemsList,
        selected: selectedTabs,
        allTabs: this.allTabs,
        currentPins: homePins,
        collection: collection,
      } as MoveCollectionWidgetToTabSelect,
      type: 'pin-collection-to-tab',
    });
  }

  async deleteManyPins(pins: HomePins.HomePin[]): Promise<void> {
    const tasks = [];
    pins.forEach((pin) => {
      tasks.push(this.deletePinById(pin));
    });
    await Promise.all(tasks);
  }

  async deletePinById(pin: HomePins.HomePin): Promise<void> {
    await Promise.all([this.homePinsService.delete(pin.id), this.homeTabService.deleteWidget(pin.tabId, pin.widgetId)]);
  }

  private getShareTitle(collection: Collections.Collection) {
    const type = isWikiCollection(collection) ? 'Wiki' : 'Collection';
    return `Share ${collection.title ? '"' + collection.title + '"' : ''} ${type}`;
  }

  private getShareDescription(collection: Collections.Collection) {
    const type = isWikiCollection(collection) ? 'wiki' : 'collection';
    return `Select which group or member to share this ${type} with`;
  }

  shareCollection(collection: Collections.Collection, showWarning = false) {
    this.onFocusChange = 'share-collection';
    const shareOptionsModel: ShareOptionsPermissionsModel = {
      accountId: collection.accountId,
      isShared: collection.isShared,
      shareOptions: collection.shareOptions,
      title: this.getShareTitle(collection),
      updateGroupAndMembers: 'Groups and Members',
      displayPermissionOption: true,
      telemetryName: 'collection_share',
      descriptionShareGroups: this.getShareDescription(collection),
      isOwner: this.workspacesService.isMe(collection.accountId),
      warningMessage: showWarning ? 'Ensure that users who have access to the collection also have access to the tab.' : null,
    };

    this.shareOptionsService.openShareOptionPopup(shareOptionsModel, (target, shareOptions) => {
      this.sendShareOptionTelemetry(target, shareOptions?.level.toLowerCase());
      this.updateCollection(collection.id, [], false, null, shareOptions);
    });

    this.eventsService.event('collections.collection_share_open', {
      location: { title: this.hubService.currentLocation },
    });
  }

  openVerificationDetails(collection: Collections.Collection, policy: Verifications.Policy) {
    this.onFocusChange = 'verification';
    const isEditor = this.collectionsUtilService.canEdit(collection);
    const verificationModel: VerificationOptionsDetailsModel = {
      item: {
        accountId: collection.accountId,
        collection: collection,
        shareOptions: collection.shareOptions,
        isEditor,
        policy,
      },
      type: 'wiki',
      title: 'Verification details',
      displayPermissionOption: false,
      displayWorkspacePermissions: false,
      telemetryName: 'verification',
      descriptionShareGroups: 'Select who will keep this information up-to date',
      displayPermissionAndRemove: true,
    };

    const verificationDetailsPopupRef = this.collectionPopupService.openVerificationDetailsPopup(verificationModel);
    verificationDetailsPopupRef.compInstance.savePopup.pipe(takeUntil(verificationDetailsPopupRef.destroy$)).subscribe((value) => {
      const { disabled, interval, verifiers, verifiersWithoutPermissions } = value.policy;
      if (!policy && disabled === false) {
        this.createPolicy({ interval, verifiers, verifiersWithoutPermissions }, collection.id);
      } else if (verifiers || interval || disabled !== undefined) {
        this.updatePolicy({ ...value.policy, policyId: policy.id }, collection.id);
      }
    });
  }

  sendShareOptionTelemetry(target: string, label: string) {
    this.eventsService.event('collections.collection_share', {
      target,
      label: label === 'protected' ? 'members' : label === 'public' ? 'workspace' : 'private',
      location: { title: this.hubService.currentLocation },
    });
  }

  async openExternal(collectionId: string) {
    const collection = await this.getById(collectionId);
    const url = this.createCollectionUrl(collection);
    if (this.isEmbed) {
      this.embedService.openUrl(url);
    } else {
      this.window.switchToStandard(url);
    }
  }

  saveChanges(collection: Collections.Collection, showToaster: boolean, manualSave?: boolean) {
    this.updateCollection(collection.id, this.collectionActions, showToaster);
    this.collectionActions = [];
    this.eventsService.event('collections.collection_save', {
      location: { title: this.hubService.currentLocation },
      target: manualSave ? 'save' : 'background',
    });
  }

  private searchParamsChanged() {
    if (!this.currentCollection) {
      this.isSearchParamsChanged$.next({ changed: false, collection: this.currentCollection });
      return;
    }

    const collection = this.currentCollection;
    const currentCollectionSearchParams = collection?.searchParams;
    const inlineFilters = this.filtersService.inlineFilters;

    let newSearchParams: Collections.SearchParams = {
      layoutMode: this.searchParamsService.layoutMode,
    };
    switch (collection.kind) {
      case 'Live':
        newSearchParams = {
          filters: isEmpty(inlineFilters) ? null : inlineFilters,
          query: this.hubService.query,
          sort: this.searchParamsService.getSort(),
          ...newSearchParams,
        } as Collections.LiveSearchParams;
        break;
      case 'Wiki':
        newSearchParams = {
          sort: this.searchParamsService.getSort(),
          ...newSearchParams,
        } as Collections.WikiSearchParams;
        break;
    }

    Object.keys(newSearchParams).forEach((key) => {
      if (!newSearchParams[key]) delete newSearchParams[key];
    });

    if (isEmpty(newSearchParams) && isEmpty(currentCollectionSearchParams)) {
      this.isSearchParamsChanged$.next({ changed: false, collection: this.currentCollection });
      return;
    }

    const changed = !isEqual(currentCollectionSearchParams, newSearchParams);
    this.isSearchParamsChanged$.next({ changed, collection: this.currentCollection });
  }

  createSearchParamsSummary(collection: Collections.LiveCollection): string {
    let searchParamsSummary = '';
    if (!collection?.searchParams) {
      searchParamsSummary = null;
    } else if (collection?.searchParams?.filters) {
      searchParamsSummary = '';
      const filterOrderArr = this.createLoopFiltersArray(collection);
      for (const [key, values] of filterOrderArr) {
        const fkey = values.length > 1 ? `${key}s` : key;
        searchParamsSummary = `${searchParamsSummary === '' ? searchParamsSummary : searchParamsSummary + ', '} ${capitalCase(
          fkey
        )}: ${values.toString()}`;
      }
    } else if (collection?.searchParams?.query) {
      searchParamsSummary = `${searchParamsSummary} , Free text: ${collection.searchParams.query}`;
    }
    return searchParamsSummary;
  }

  private createLoopFiltersArray(collection: Collections.LiveCollection) {
    const res = [];
    const filters = Object.entries(collection.searchParams.filters);
    const app = filters.find((elm) => elm[0] === 'app');
    if (app) res.push(app);
    const type = filters.find((elm) => elm[0] === 'type');
    if (type) res.push(type);
    const rest = filters.filter((elm) => !['app', 'type'].includes(elm[0]));
    res.push(...rest);
    return res;
  }

  private async onContextMenuDelete(collection: Collections.Collection) {
    const deletePopupRef = this.collectionPopupService.openDeleteCollectionPopup(collection);
    deletePopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(async () => {
      this.deleteCollectionId = collection.id;
      if (isWikiCollection(collection) && collection?.cardIds?.length > 0) {
        this._deletedWiki$.next({ id: collection.id });
      }
      this.deleteCollection(collection).then(() => (this.deleteCollectionId = null));
      if (this.currentCollectionView) {
        await this.routerService.navigateByUrl(isWikiCollection(collection) ? 'wikis' : 'collections', { replaceUrl: true });
      }
    });
  }

  private async deletePin(collection: Collections.Collection) {
    const currentTabId = this.homePageService.currentTabId;
    const tab = this.selectedTabs.find((tab) => tab.id == currentTabId);
    const pin = collection.pinBy.find((p) => p.tabId === tab.id);
    await this.deletePinById(pin);
    this.showToasterService.showToaster({
      id: 'collection-pin',
      content: `Your updates to collection "${cutText(collection.title, 40)}" have been saved`,
      timeout: 5000,
      intent: 'primary',
    });
  }

  async onContextMenuCommand(
    item: ResultContextMenuItem,
    trigger: TelemetryTrigger,
    collection: Collections.Collection,
    viewMode?: CollectionViewMode
  ) {
    let target = item.id as string;
    if (target === 'pin' && item.text === 'Manage Pins') {
      target = 'manage_pins';
    }
    this.eventsService.event('collections.collection_actions', {
      target,
      label: trigger === 'context_menu_click' ? 'mouse_click' : 'keyboard',
      location: { title: this.hubService.currentLocation },
    });

    let label = item.id as string;
    if (label === 'favorite') {
      label = `${!collection?.favoriteMarkedTime ? 'add' : 'remove'}_favorite`; // inverse since we already toggled
    } else if (label === 'pin') {
      label = target;
    }

    this.eventsService.event('collections.menu_action', {
      label,
      location: { title: this.hubService.currentLocation },
    });

    switch (item.command.type) {
      case 'open-collection': {
        this.openCollection(collection, 'collections-view');
        break;
      }
      case 'search-in-collection': {
        this.openCollection(collection, 'search-view');
        break;
      }
      case 'edit-collection':
        if (await this.hubService.getIsLauncher()) {
          this.openExternal(collection.id);
        }
        break;
      case 'copy-url':
        this.copyUrl(collection);
        break;
      case 'change-favorite-status':
        this.toggleFavorite(collection);
        break;
      case 'delete':
        await this.onContextMenuDelete(collection);
        break;
      case 'make_a_copy':
        this.duplicateCollection(collection);
        break;
      case 'add-to-collection':
        this.openSelectPopup$.next({
          data: {
            item: collection,
            currentUser: this.sessionInfo.user,
          } as AddToCollectionSelect,
          type: 'add-to-collection-popup',
        });
        break;
      case 'paste':
        break;
      case 'unpin-collection':
        this.eventsService.event('home_tabs.pin', {
          label: trigger === 'context_menu_click' ? 'mouse_click' : 'keyboard',
          location: { title: this.hubService.currentLocation },
        });

        if (collection.isShared) {
          const currentTabId = this.homePageService.currentTabId;
          const tab = this.selectedTabs.find((tab) => tab.id == currentTabId);
          const pinPopupRef = this.collectionPopupService.openPinSharedCollectionMessagePopup(tab.name);
          pinPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => this.deletePin(collection));
        } else {
          this.deletePin(collection);
        }

        break;
      case 'share-collection':
        this.shareCollection(collection, viewMode === 'widget');
        break;
      case 'pin':
        this.openPinCollectionPopup(collection, trigger);
        break;
      case 'verification-details':
        if (isWikiCollection(collection)) {
          this.openVerificationDetails(collection, collection.policy);
        }
        break;
    }
  }

  isCollectionsRoute(route: string): boolean {
    return COLLECTIONS_ROUTE_TYPES.some((c) => route.startsWith(c));
  }

  private mergeCountFilters(list1: DisplaySearchFilterValue[], list2: DisplaySearchFilterValue[]) {
    const combinedList = cloneDeep(list1.concat(list2));
    const groupedByValue = groupBy(combinedList, (a) => a.value);
    const mergedList = lMap(groupedByValue, (group) => {
      const mergedObject = mergeWith({}, ...group, (objValue: string, srcValue: string, key: string) => {
        if (key === 'label') {
          const sum = (+objValue || 0) + (+srcValue || 0);
          return `${sum}`;
        }
      });
      return mergedObject;
    });

    return mergedList;
  }

  getPageFiltersByType(kinds: Collections.Kind[]) {
    const result: { [filterName: string]: Filter } = {};
    for (const key of Object.keys(this.filtersByCollectionKind).filter((k) => kinds.includes(k as Collections.Kind))) {
      const f1: Filter[] = this.filtersByCollectionKind[key];
      for (const filter of f1) {
        if (!result[filter.name]) {
          result[filter.name] = cloneDeep(filter);
          continue;
        }
        const oldValues = result[filter.name].values;
        const newValues = filter.values;
        let merged: DisplaySearchFilterValue[];
        if (filter.name !== this.TAGS_FILTER_NAME) {
          merged = uniqBy(newValues.concat(oldValues), (a) => a.value);
        } else {
          merged = this.mergeCountFilters(oldValues, newValues);
        }
        result[filter.name].values = cloneDeep(merged);
      }
    }
    return Object.values(result);
  }

  //@@@@@@@FILTERS

  initCollectionPageFilters() {
    combineLatest([
      this.all$.pipe(
        distinctUntilChanged((prev, next) => {
          return isEqual(prev, next);
        })
      ),
      this.accountsService.all$.pipe(distinctUntilChanged()),
      this.sessionService.current$.pipe(filter((v) => !!v)),
      this.filtersService.definitions$.pipe(distinctUntilChanged()),
    ]).subscribe(async ([collections, accounts, sessionInfo, activeFilters]) => {
      if (!collections || !accounts) {
        return;
      }

      for (const kind of this.COLLECTIONS_KINDS_ALL) {
        const collectionsByKind = collections.filter((c) => c.kind === kind);

        //createdBy
        const createdByMap = new Set<string>();
        collectionsByKind.forEach((c) => {
          createdByMap.add(c.accountId);
        });

        const accountsMap = chain(accounts)
          .keyBy((a) => a.id)
          .value();
        const createdBy = [];
        [...createdByMap.keys()].forEach((c) => {
          const elm = accountsMap[c];
          if (elm) {
            if (elm?.userId === sessionInfo?.user.id) {
              elm.name = 'Me';
              createdBy.unshift(elm, {
                id: 'shared-with-me',
                name: 'Not me',
              });
            } else {
              createdBy.push(elm);
            }
          }
        });
        const lastEdited = activeFilters?.modifiedAt;

        //tags
        const tags = new Set<string>();
        const collectionTagsCount: { count?: number } = {};
        collectionsByKind.forEach((c) => {
          c?.tags?.forEach((t) => {
            tags.add(t);
            collectionTagsCount[t] = collectionTagsCount[t] ?? { count: 0 };
            collectionTagsCount[t].count++;
          });
        });
        const filters: Filter[] = await this.createCollectionPageFilters(createdBy, lastEdited, tags, collectionTagsCount, kind);
        this.filtersByCollectionKind[kind] = filters;
      }
    });
  }

  async createCollectionPageFilters(
    createdBy: Accounts.WorkspaceAccount[],
    lastEdited: Filters.FilterDefinition,
    tags: Set<string>,
    collectionTagsCount: { count?: number },
    kind: Collections.Kind
  ): Promise<Filter[]> {
    const filters: Filter[] = [];

    const createdByName = 'collection-createdBy';
    filters.push({
      type: 'post',
      name: createdByName,
      title: 'Created by',
      icon: { type: 'font-icon', value: 'icon-user-circle' },
      picker: 'multi-select',
      viewDetails: {
        isTwoLine: true,
        showClearAll: true,
      },
      sort: 'none',
      values: createdBy?.map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value?.id,
            value: value?.id,
            title: value?.name,
            subtitle: value?.email || '',
            filterName: createdByName,
          }
      ),
      disabled: false,
    });

    //last edited
    if (lastEdited) {
      const values = await this.filtersService.getSuggestions('', lastEdited.name, 'box', { returnAllValues: true }, {});
      const lastEditedName = `collection-${lastEdited.name}`;
      filters.push({
        type: 'post',
        name: lastEditedName,
        title: lastEdited.title,
        icon: lastEdited.icon,
        picker: lastEdited.floating.picker,
        viewDetails: {
          isTwoLine: false,
          isFullDetailLine: true,
          showClearAll: true,
          placeholder: 'Last Edited...',
          oneValue: true,
          noCheckbox: true,
          hideOnSelect: true,
        },
        values: values.map(
          (value) =>
            <DisplaySearchFilterValue>{
              id: value.id,
              value: value.title,
              title: value.title,
              subtitle: value.subtitle,
              filterName: lastEditedName,
            }
        ),
        disabled: false,
      });
    }

    filters.push({
      type: 'post',
      name: this.TAGS_FILTER_NAME,
      title: 'Tags',
      icon: { type: 'font-icon', value: 'icon-tag-label' },
      picker: 'multi-select',
      values: Array.from(tags).map(
        (value) =>
          <DisplaySearchFilterValue>{
            id: value,
            value: value,
            title: value,
            subtitle: null,
            filterName: this.TAGS_FILTER_NAME,
            label: collectionTagsCount[value].count + '',
          }
      ),
      disabled: false,
      viewDetails: {
        showClearAll: true,
        showItemIcon: true,
        isFullDetailLine: true,
        showSelectedItemIcons: true,
        showItemLabel: true,
      },
    });

    if (kind !== 'Wiki') {
      const collectionTypeFilter = COLLECTION_KIND_TO_FILTER[kind];

      const typeName = 'collection-type';
      filters.push({
        type: 'post',
        name: typeName,
        title: 'Type',
        icon: { type: 'font-icon', value: 'icon-collection' },
        picker: 'multi-select',
        viewDetails: {
          showItemIcon: true,
          showClearAll: true,
          isFullDetailLine: true,
          showSelectedItemIcons: true,
          oneValue: true,
          noCheckbox: true,
        },
        values: [
          <DisplaySearchFilterValue>{
            id: collectionTypeFilter.value,
            value: collectionTypeFilter.value,
            title: collectionTypeFilter.title,
            icon: collectionTypeFilter.icon,
            subtitle: null,
            filterName: typeName,
          },
        ],
        disabled: false,
      });
    }
    return filters;
  }

  shouldUnpin(collection: Collections.Collection): boolean {
    return !collection?.pinBy || this.isOwnerOrAdmin;
  }

  async pinToTabs(tabIds: string[], collection: Collections.Collection) {
    const items: HomePins.HomePin[] = tabIds.map((tabId) => ({
      pinedId: collection.id,
      type: 'collection',
      widgetId: generateId(),
      tabId,
    }));
    const tasks = [];
    tasks.push(this.homePinsService.createMany(items, false));
    items.forEach((item) => {
      tasks.push(
        this.homeTabService.addWidget(item.tabId, {
          id: item.widgetId,
          tabId: item.tabId,
          type: 'collection',
          collectionId: collection.id,
          layout: this.getLayoutMode(collection),
          sort: (collection.searchParams as Collections.WikiSearchParams)?.sort,
        } as HomeTabs.CollectionWidget)
      );
    });
    return Promise.all(tasks);
  }

  private getLayoutMode(collection: Collections.Collection): Results.LayoutType {
    const layoutMode = collection.searchParams?.layoutMode || collection.layout; // keeping collection.searchParams?.layoutMode for backward compatibility
    if (layoutMode) {
      return layoutMode;
    }
    return 'list';
  }

  buildCollectionsFilters(
    filters: Filter[],
    selectedFilters: Filters.Values,
    skipFilters: string[] = [],
    skipSelectedValues: Set<string> = new Set()
  ): Filter[] {
    const newFilters = cloneDeep(this.refineFilters(filters, skipFilters, skipSelectedValues));
    for (const filter of newFilters) {
      const selected = new Set<string>(selectedFilters[filter.name] || []);
      if (filter.picker === 'time-dropdown' && selected.size) {
        const selectedCustomValues: DisplaySearchFilterValue[] = this.filtersService.getCustomSelectedTimeFilter(
          filter.values,
          selected,
          filter.name,
          filter.icon
        );
        filter.values.push(...selectedCustomValues);
      }
      filter.values = filter.values?.map((v) => ({ ...v, selected: !filter.disableMarkSelected && selected.has(v.value) }));
    }
    return newFilters;
  }

  private refineFilters(filters: Filter[], skipFilters: string[], skipSelectedValues: Set<string>) {
    return (
      filters
        ?.filter((f) => !skipFilters.includes(f.name))
        .map((f) => {
          return { ...f, disableMarkSelected: skipSelectedValues.has(f.name) };
        }) || []
    );
  }

  createPolicy(policy: Verifications.CreatePolicy, collectionId: string) {
    return this.service.createPolicy(policy, collectionId);
  }

  async updatePolicy(policy: Verifications.UpdatePolicy, collectionId: string) {
    await this.service.updatePolicy(policy, collectionId);
  }

  async getScopes(id: string, accountId: string): Promise<Collections.scopesResponse> {
    return this.service.getScopes(id, accountId);
  }
}
