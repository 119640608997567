import { CdkDragEnter, CdkDropList, DragRef, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Collections, Commands, Results, Search } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { isExtension, isMac } from '@local/common-web';
import { KeyName, isEnterKey, removeModifiers } from '@local/ts-infra';
import { PopupService, TypingViewMode } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteKeyboardHelperService } from '@shared/helper/infinite-keyboard-helper.service';
import { EventsService, WindowService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { isDynamicCommand, windowSizeObserver } from '@shared/utils';
import { isEqual } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { ContextMenu } from 'src/app/bar/services/commands/results-item-context-menu-helper';
import { HubService } from 'src/app/bar/services/hub.service';
import { PreviewKeyboardService } from 'src/app/bar/services/preview-keyboard.service';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { ResultsService } from 'src/app/bar/services/results.service';
import { SearchResultContext } from 'src/app/bar/services/search';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { SummaryService } from 'src/app/bar/services/summary.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import { WikisService } from 'src/app/bar/services/wikis/wikis.service';
import { FilePreviewService } from '../../../preview/file-preview/services/file-preview.service';
import { DirtyChangeStatus } from '../../../preview/model/preview-component';
import { PeopleService } from '../../../preview/people-preview/services/people.service';
import {
  AnswerFeedbackSearchItem,
  AnswerSearchItem,
  HeaderItem,
  RecentSearchItem,
  ScrollTrigger,
  SearchResults,
  isHeader,
  isPreviewClickAction,
  isWikiCard,
} from '../../../results';
import { PreviewResultsComponent } from '../../../results/components/results-preview/preview-results.component';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import { Action, PageType } from '../../../results/models/view-filters';
import { InfiniteScrollService } from '../../../results/services/infinite-scroll.service';
import { COLLECTION_COLUMNS_COUNTER } from '../../models';
import { CollectionViewType } from '../../models/collection-view-type.model';
import { ResultListOptions } from '../../models/result-list-options.mode';
import { BlobPreviewService } from '../../services/blobs-preview.service';
import { SearchExpandableButtonComponent } from '../search-expandable-button/search-expandable-button.component';
import { LoadingData } from '../static-collection/static-collection.component';
import { PreviewHandlerService } from './preview-handler.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';

@UntilDestroy()
@Component({
  selector: 'search-results-list',
  templateUrl: 'search-results-list.component.html',
  styleUrls: ['search-results-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsListComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly INITIAL_ITEMS_LIMIT = 12;
  private readonly COLLECTION_COLUMNS_COUNTER = COLLECTION_COLUMNS_COUNTER;
  private afterViewInitPromise: ManualPromise<void> = new ManualPromise();
  private resultsWidth: number;
  private scrollService: InfiniteScrollService;
  protected keyHandlerId: string;
  private keyboardHelperService: InfiniteKeyboardHelperService;
  private _loadingItem: LoadingData;
  private _layoutMode: Results.LayoutType;
  private _selectedIndex: number;
  private _items: SearchResults[];
  private _displayedContext: SearchResultContext;
  private _componentFocused: boolean;
  private _customScrollHeight: string;
  private nextPagePromise = new ManualPromise<void>();
  private nextPageRunning = false;
  private firstPage: boolean;
  private gridColumns: number;
  private _loading: boolean;
  // drag&drop
  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  private sourceIndex: number;
  private dragRef: DragRef = null;

  isExtension = isExtension();
  scrollHeight: string;
  searchModel: string;
  formInputChanged = new Subject<string>();
  dragInprogress = false;
  titleChangedSub = new Subject<{ $event: string; item: Search.ResultResourceItem }>();
  hoveredIndex: number;
  keyboardOn = false;
  onOpenContextMenu: boolean;
  onOpenSummaryPopup: boolean;
  ghostItems: any[];
  resultsLimit: number;
  isLauncher: boolean;
  isAnswerFeedbackOpen: boolean;
  private answerFeedbackIndex: number;

  itemIdTrack = (index, item: any) => {
    return item?.type + '##' + item?.id + '##';
  };

  private defaultResultListOptions: ResultListOptions = {
    checkboxAlwaysVisible: false,
    allowEnterClickMarkCheckbox: false,
  };

  @Input() showSearchResults = true;
  @Input() isLoadingAnswer: boolean;
  @Input() disableAnswerTyping: boolean;
  @Input() displayBg: boolean;
  @Input() listName: string;
  @Input() pageType: PageType;
  @Input() smallView = false;
  @Input() removeFocus = false;
  @Input() maxColumnsInScreen = false;
  @Input() emptyResults: boolean;
  @Input() emptyResultsText = 'No results';
  @Input() defaultResultSections: Results.ShowResultSections;
  @Input() showAlwaysDefaultResultSections: boolean;
  @Input() changedData: EventEmitter<any>;
  @Input() isOwner: boolean;
  @Input() searchId: string;
  @Input() virtualScroll = true;
  @Input() actionsReadOnly: boolean;
  @Input() inlineButtons: boolean;
  @Input() answerTypingViewMode: TypingViewMode = 'interactive';
  @Input() listView: { isEditable?: boolean; isDraggable?: boolean; limitResults?: boolean; hideFollowUp?: boolean } = {
    isEditable: false,
    isDraggable: false,
    limitResults: false,
  };
  @Input() checkboxIsNoColor = true;
  @Input() resultListOptions: ResultListOptions;

  @Input() set loading(val: boolean) {
    this.isAnswerFeedbackOpen = false;
    this._loading = val;
  }

  @Input() set layoutMode(val: Results.LayoutType) {
    this._layoutMode = val;
    this.initScroll();
  }
  @Input() set items(val: SearchResults[]) {
    const prevItems = this._items;
    this._items = val;
    if (this.listView.limitResults) {
      if (!this.resultsLimit || this.firstPage) {
        this.scrollService?.scrollToStart(this.scrollbarRef);
        this.resultsLimit = this.INITIAL_ITEMS_LIMIT;
        setTimeout(() => {
          this.resultsLimit = this.items?.length;
        }, 2);
      }
    } else {
      this.resultsLimit = this.items?.length;
    }

    if (!this.selectedIndex) {
      const selected = this.getFirstSelectable();
      this._selectedIndex = selected >= 0 ? selected : undefined;
    }
    this.updateResultsWidthAndGridColumns();
    if (this.items && this.scrollService && !isEqual(prevItems, this.items)) {
      this.nextPageRunning = false;
      if (this.componentFocused) {
        this.registerKeyboardHandler();
      }
      if (!this.firstPage && !this.nextPagePromise.status) {
        this.nextPagePromise.resolve();
      }
      this.initScroll();
      this.setUpKeyboardHelperService();
      this.keyboardHelperService.onInit(this.selectedIndex, this.items);
    }
    if (!prevItems?.length && this.items?.length > 0) {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.skipHeader(this.selectedIndex);
    }
    this.cdr.markForCheck();
  }
  @Input() set componentFocused(focus: boolean) {
    this._componentFocused = focus;
    if (focus && this.items) {
      this.registerKeyboardHandler();
      if (this.items?.length > 0) this.skipHeader(0);
    } else {
      this.unregisterKeyHandler();
    }
    if (!focus && this.removeFocus) {
      this.skipHeader(null, true);
    }
    this.cdr.markForCheck();
  }
  @Input() set displayedContext(val: SearchResultContext) {
    this.firstPage = val?.id !== this.displayedContext?.id;
    this._displayedContext = val;
    this.displayedContext?.items
      .filter((i) => !!i)
      .forEach((item, i) => {
        item.resultIndex = this.getRealPosition(i);
      });

    this.addAnswerFeedbackIfNeeded(this.items);
    this.items = this.displayedContext?.items;
    if (!this.displayedContext) {
      this.previewService?.setPreviewState('none');
    }
    this.previewHandlerService.displayedContext = val;
    this.cdr.markForCheck();
  }
  @Input() set loadingItem(data: LoadingData) {
    this._loadingItem = data;
    this.ghostItems = data ? Array(data.amount).fill(0) : [];
    this.cdr.markForCheck();
  }
  @Input() set customScrollHeight(val: string) {
    this._customScrollHeight = val;
    this.initScroll();
  }
  @Input() set resizeChange(val: any) {
    this.updateResultsWidthAndGridColumns();
    this.initScroll();
  }

  @Input() specialTemplate: any;

  @Output() titleChanged = new EventEmitter<{ value: string; item: Search.ResultResourceItem }>();
  @Output() onDynamicCommand = new EventEmitter<{ value: string; item: SearchResults }>();
  @Output() onDragChange = new EventEmitter<{ currentIndex: number; previousIndex: number }>();
  @Output() viewModeChanged = new EventEmitter();
  @Output() selectedIndexChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() nextPage = new EventEmitter<ScrollTrigger>();
  @Output() componentFocusedFinish = new EventEmitter<'next' | 'prev' | null>();
  @Output() resultHeaderClick = new EventEmitter<HeaderItem>();
  @Output() onRenameFlow = new EventEmitter<boolean>();
  @Output() openChatClicked = new EventEmitter<AnswerSearchItem>();
  @Output() recentSearchItemClicked = new EventEmitter<RecentSearchItem>();

  get supportedInGalleryView(): boolean {
    return this.layoutMode === 'gallery';
  }

  get supportedInListView(): boolean {
    return this.layoutMode === 'list';
  }

  get layoutMode() {
    return this._layoutMode;
  }

  get hiddenPreview() {
    return (
      this.displayedContext?.items?.length === 0 || !this.previewHandlerService.isActivePreview || this.previewHandlerService.isSmallScreen
    );
  }

  get loading(): boolean {
    return this._loading;
  }

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    if (this.keyboardHelperService) this.keyboardHelperService.selectedIndex = value;
    if (this.previewHandlerService) {
      this.previewHandlerService.selectedIndex = value;
      this.previewHandlerService.checkSelected();
    }
    this.selectedIndexChanged.emit(value);
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  get items(): SearchResults[] {
    return this._items;
  }

  get displayedContext(): SearchResultContext {
    return this._displayedContext;
  }

  get componentFocused(): boolean {
    return this._componentFocused;
  }

  get loadingItem() {
    return this._loadingItem;
  }

  get customScrollHeight() {
    return this._customScrollHeight;
  }

  get isSearchInProgress() {
    return this.hubService.query !== '' && this.hubService.query !== undefined;
  }

  get isPreviewMode() {
    return this.previewHandlerService.previewViewModel.previewState === 'side';
  }

  get query() {
    if (this.hubService.query && this.searchModel?.length > 0) return this.searchModel;
    return this.hubService.query ? this.hubService.query : this.searchModel?.length > 0 ? this.searchModel : null;
  }

  @HostBinding('attr.layoutMode') get resultsViewMode() {
    return this.layoutMode;
  }

  @HostBinding('attr.inlineButtons') get hasInlineButtons() {
    return this.inlineButtons;
  }

  @HostBinding('style.--grid-columns') get gridColumnNumber() {
    return this.gridColumns;
  }

  @HostBinding('attr.isExtensionMode')
  get isExtensionMode() {
    return this.isExtension;
  }

  //checkbox
  @Input() checkedItems = new Set<string>();
  @Input() clearCheckedItems: EventEmitter<any> = new EventEmitter();
  @Output() checkedItemsUpdate = new EventEmitter<Set<string>>();

  @ViewChild('resultsSplitArea') resultsRef: ElementRef;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('placeholder', { read: CdkDropList }) placeholder: CdkDropList;
  @ViewChild(SearchExpandableButtonComponent) searchButton: SearchExpandableButtonComponent;
  @ViewChildren(CdkDropList) cdkDropLists: QueryList<CdkDropList>;
  @ViewChildren('resultWrapper', { read: ElementRef }) resultsWrappers: QueryList<ElementRef>;
  @ViewChildren('resultItem') resultsElementsRef: QueryList<any>;
  @ViewChildren('resultItem', { read: ElementRef }) resultsElements: QueryList<ElementRef>;
  @ViewChild('answerFeedback') answerFeedbackRef: ElementRef;

  //container height
  private windowSize$ = windowSizeObserver();
  private contextObserver: ResizeObserver;
  itemHeights: number[];
  @ViewChild('contextContainer') contextContainer: ElementRef;
  @Input() updateHeight: EventEmitter<void> = new EventEmitter();

  //preview
  previewHandlerService: PreviewHandlerService;
  previewHeight: number;
  contextMenu: ContextMenu;
  openedDragContextMenuId: string;
  @Output() changePreviewMode = new EventEmitter<{ isPreviewMode: boolean; showBtn: boolean }>();
  @ViewChild(PreviewResultsComponent) previewContainer: PreviewResultsComponent;

  constructor(
    private ngZone: NgZone,
    private resultCommandService: ResultCommandService,
    protected keyboardService: KeyboardService,
    protected cdr: ChangeDetectorRef,
    private searchParamsService: SearchParamsService,
    public hubService: HubService,
    protected previewService: PreviewService,
    private resultsService: ResultsService,
    private previewKeyboardService: PreviewKeyboardService,
    protected eventsService: EventsService,
    private peopleService: PeopleService, // so the code in the ctor will run => for people preview
    private windowService: WindowService,
    protected collectionsService: CollectionsService,
    private summaryService: SummaryService,
    private blobPreviewService: BlobPreviewService,
    private filePreviewService: FilePreviewService,
    protected wikisService: WikisService,
    protected collectionsUtilService: CollectionsUtilService,
    protected wikiCardsService: WikiCardsService,
    protected popupService: PopupService,
    protected routerService: RouterService,
    protected commandsService: CommandsService
  ) {
    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe((l) => (this.isLauncher = l));
    this.previewHandlerService = new PreviewHandlerService(
      previewService,
      resultsService,
      previewKeyboardService,
      eventsService,
      hubService,
      keyboardService,
      resultCommandService,
      blobPreviewService
    );
  }

  ngOnInit(): void {
    this.changedData?.pipe(untilDestroyed(this)).subscribe((res) => this.onChangedData(res));
    this.createScrollService();
    if (!this.checkedItems) {
      this.checkedItems = new Set<string>();
    }
    if (this.listView.isEditable) {
      this.clearCheckedItems.pipe(untilDestroyed(this)).subscribe((v: any) => {
        if (v === true) {
          this.checkedItems.clear();
          this.checkedItemsUpdate.emit(this.checkedItems);
        } else {
          this.toggleChecked({ checked: false }, v);
        }
        this.cdr.markForCheck();
      });
    }

    this.updateHeight?.pipe(untilDestroyed(this)).subscribe((_v: any) => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    });

    this.titleChangedSub.pipe(debounceTime(0), distinctUntilChanged()).subscribe((val: any) => {
      this.titleChangedEvent(val.$event, val.item);
    });

    this.summaryService.popupOpen$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.onOpenSummaryPopup = res;
      this.cdr.markForCheck();
    });

    this.resultListOptions = { ...this.defaultResultListOptions, ...(this.resultListOptions || {}) };

    this.initPreviewHandler();
  }

  ngAfterViewInit(): void {
    this.setUpKeyboardHelperService();
    this.formInputChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe((search) => {
      this.searchModel = search;
      this.searchParamsService.innerQuery = !this.searchModel || this.searchModel.length === 0 ? null : this.searchModel;
      this.cdr.markForCheck();
    });
    this.initNgScrollerHeight();

    //solution for drag
    if (this.placeholder) {
      const placeholderElement = this.placeholder.element.nativeElement;
      placeholderElement.style.display = 'none';
      placeholderElement.parentNode.removeChild(placeholderElement);
    }

    this.afterViewInitPromise.resolve();
  }

  ngOnDestroy(): void {
    this.unregisterKeyHandler();
    this.contextObserver?.disconnect();
  }

  private createScrollService() {
    this.scrollService = new InfiniteScrollService();
  }

  private async initScroll() {
    if (!this.items?.length) {
      return;
    }
    await this.afterViewInitPromise;
    this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
  }

  private initNgScrollerHeight() {
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateResultsWidthAndGridColumns();
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    });
    if (this.contextContainer) {
      const { nativeElement: el } = this.contextContainer;
      this.contextObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.calculateNgScrollerHeight(entries[0]?.target);
      });
      this.contextObserver.observe(el);
    }
  }

  private addAnswerFeedbackIfNeeded(items: SearchResults[]) {
    const answerFeedback = items?.find((item) => item.type === 'answer-feedback');
    if (answerFeedback && this.isAnswerFeedbackOpen) {
      this.displayedContext?.items.splice(1, 0, answerFeedback);
      this.resultsLimit += 1;
      this.cdr.markForCheck();
    }
  }

  private calculateNgScrollerHeight(el) {
    if (!el) return;
    if (this.customScrollHeight) {
      el['style'].height = this.customScrollHeight;
      return;
    }
    const boundingClientRect = el.getBoundingClientRect();
    const elmHeight = window.innerHeight - boundingClientRect.top;
    this.previewHeight = elmHeight;
    this.scrollHeight = elmHeight + 'px';
    el['style'].height = this.scrollHeight;
  }

  private onChangedData(val: boolean) {
    if (val) {
      this.previewService.setPreviewState('none');
      this.searchModel = '';
      if (this.searchButton) {
        this.searchButton.resetData();
      }
    }
  }

  private isSelectable(item: SearchResults) {
    return (!isHeader(item) || item.selectable) && item.type !== 'collection-header';
  }

  private getFirstSelectable() {
    return this.items?.findIndex((item) => this.isSelectable(item));
  }

  private getNextSelectable(fromIndex = 0) {
    const slicedItems = this.items.slice(fromIndex);
    const index = slicedItems.findIndex((item) => this.isSelectable(item));
    return index + 1;
  }

  private getLastSelectable(fromIndex = 0) {
    const slicedItems = fromIndex ? this.items.slice(0, fromIndex) : this.items;
    let index = 1;
    for (let i = slicedItems.length - 1; i > 0; i--) {
      const item = slicedItems[i];
      if (this.isSelectable(item)) {
        index = i;
        break;
      }
    }
    return fromIndex - index;
  }

  private initPreviewHandler() {
    this.previewHandlerService.init(this);
    this.previewHandlerService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.previewHandlerService.updatePreviewViewModel.pipe(untilDestroyed(this)).subscribe((_res) => {
      this.changePreviewMode.emit({ isPreviewMode: this.isPreviewMode, showBtn: this.previewHandlerService.displayTogglePreview });
    });
    this.changePreviewMode.emit({ isPreviewMode: this.isPreviewMode, showBtn: this.previewHandlerService.displayTogglePreview });

    this.previewHandlerService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      this.selectedIndex = index;
      this.cdr.markForCheck();
    });
    this.previewHandlerService.selectedIndex = this.selectedIndex;
    this.previewHandlerService.checkSelected();
  }

  protected getShowResultSections(item): Results.ShowResultSections {
    return {
      ...(item?.showResultSections || this.defaultResultSections),
      ...(this.showAlwaysDefaultResultSections ? this.defaultResultSections : []),
      isDraggable: this.listView.isDraggable,
      isEditable: this.listView.isEditable && !this.isSearchInProgress,
    };
  }

  getRealPosition(index: number) {
    return this.resultCommandService.getRealPosition(this.displayedContext.items, index, this.displayedContext.lastHeaderIndex);
  }

  setUpKeyboardHelperService() {
    if (!this.keyboardHelperService) this.keyboardHelperService = new InfiniteKeyboardHelperService();
    this.keyboardHelperService.selectedIndex = this.selectedIndex;
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      this.selectedIndex = index;
      if (this.selectedIndex === 0) {
        this.componentFocusedFinish.emit('prev');
      }
      this.cdr.markForCheck();
    });
  }

  private getItemX(index: number) {
    const el = this.resultsElements.find((e) => e.nativeElement?.dataset?.index == index);
    return el?.nativeElement?.getBoundingClientRect()?.x;
  }

  protected registerKeyboardHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      if (this.isPreviewDirty()) return;
      this.keyboardOn = true;
      const key = keys[0];
      const selectedElementsArray = this.resultsWrappers.toArray();
      switch (key) {
        case 'ArrowDown':
          if (this.supportedInGalleryView) {
            let index = this.selectedIndex;
            const currentX = this.getItemX(index);
            let newX: number;
            while (newX !== currentX) {
              index++;
              if (index > this.items.length) {
                this.keyboardHelperService.onSelectedIndex(
                  this.items.length - 1 - this.selectedIndex,
                  event,
                  selectedElementsArray[this.items.length - 1 - this.selectedIndex]
                );
                break;
              }
              if (this.items[index]?.type === 'header' || this.items[index]?.type === 'collection-header') {
                continue;
              }
              newX = this.getItemX(index);
              if (newX === currentX) {
                this.keyboardHelperService.onSelectedIndex(index - this.selectedIndex, event, selectedElementsArray[index]);
                break;
              }
            }
          } else {
            const nextIndex = this.getNextSelectable(this.selectedIndex + 1);
            this.keyboardHelperService.onSelectedIndex(nextIndex, event, selectedElementsArray[this.selectedIndex + nextIndex]);
          }
          break;
        case 'ArrowUp':
          if (this.supportedInGalleryView) {
            let index = this.selectedIndex;
            const currentX = this.getItemX(index);
            let newX: number;
            while (newX !== currentX) {
              index--;
              if (index < 0) {
                this.keyboardHelperService.onSelectedIndex(-this.selectedIndex, event, selectedElementsArray[this.selectedIndex]);
                break;
              }
              if (this.items[index]?.type === 'header' || this.items[index]?.type === 'collection-header') {
                continue;
              }
              newX = this.getItemX(index);
              if (newX === currentX) {
                this.keyboardHelperService.onSelectedIndex(-(this.selectedIndex - index), event, selectedElementsArray[index]);
                break;
              }
            }
          } else {
            let lastIndex = 0;
            if (this.selectedIndex !== 0) {
              lastIndex = this.getLastSelectable(this.selectedIndex);
            }
            this.keyboardHelperService.onSelectedIndex(-lastIndex, event, selectedElementsArray[this.selectedIndex - lastIndex * 2]);
          }
          break;
        case 'ArrowRight':
          if (this.supportedInGalleryView) {
            const nextIndex = this.getNextSelectable(this.selectedIndex + 1);
            this.keyboardHelperService.onSelectedIndex(nextIndex, event, selectedElementsArray[this.selectedIndex + nextIndex]);
          }
          break;
        case 'ArrowLeft':
          if (this.supportedInGalleryView) {
            const lastIndex = this.getLastSelectable(this.selectedIndex);
            this.keyboardHelperService.onSelectedIndex(-lastIndex, event, selectedElementsArray[this.selectedIndex - lastIndex]);
          }
          break;
        default:
          break;
      }
      this.handleKeys(keys, event);
    }, 7);
    this.cdr.markForCheck();
  }

  toggleCheckboxOnEnter() {
    const item = this.items[this.selectedIndex] as Search.ResultResourceItem;
    if (this.resultListOptions.allowEnterClickMarkCheckbox) {
      this.toggleChecked({ checked: !this.checkedItems.has(item.id) }, item.id);
    }
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const item = this.items[this.selectedIndex] as Search.ResultResourceItem;
    if (!item) {
      return;
    }

    const nonModifiers = removeModifiers([...keys]);
    const key = nonModifiers[0];
    if (key && isEnterKey(key) && isHeader(item) && this.isSelectable(item)) {
      this.resultHeaderClick.emit(item);
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (this.resultListOptions.allowEnterClickMarkCheckbox && isEnterKey(key) && this.isSelectable(item)) {
      this.toggleCheckboxOnEnter();
      return;
    }
    if (key === 'escape' && this.isAnswerFeedbackOpen) {
      this.hideAnswerFeedback(this.answerFeedbackIndex);
      return;
    }

    const selectedItem = this.hoveredIndex ? this.getResultItem(this.hoveredIndex) : this.getResultItem(this.selectedIndex);
    if (this.getShowResultSections(selectedItem).showContextMenu === false && selectedItem?.showResultSections?.enableAction === false) {
      return;
    }
    if (selectedItem && 'shortcutsHandlers' in selectedItem) {
      if (isMac()) {
        keys = keys.map((k) => (k = k === 'control' ? 'command' : k));
      }
      const matchingItem = selectedItem.shortcutsHandlers.find(
        (item) => item?.keyBinding && item?.keyBinding?.length === keys.length && item.keyBinding.every((key) => keys.includes(key))
      );

      if (matchingItem) {
        matchingItem.fn(selectedItem, 'keyboard');
        event.stopPropagation();
        event.preventDefault();
        return;
      }
    }
  }

  private getResultItem(index: number) {
    return this.resultsElementsRef.toArray().find((i) => i.index === index);
  }

  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  async updateResultsLimit() {
    await this.nextPagePromise;
    this.resultsLimit = Math.min(this.resultsLimit + this.INITIAL_ITEMS_LIMIT, this.scrollService.MAX_RESULTS_LIMIT);
    this.cdr.markForCheck();
  }

  onScrollStart($event) {
    const readyForNextPage = this.scrollService.handleScroll($event);
    if (!readyForNextPage || !this.items?.length) {
      return;
    }
    if (this.items?.length - this.resultsLimit >= this.scrollService.NEXT_PAGE_MIN_COUNT) {
      return;
    }
    if (this.items?.length < this.scrollService.MAX_RESULTS_LIMIT && this.nextPageRunning === false) {
      this.nextPage.emit();
      this.nextPagePromise = new ManualPromise();
      this.nextPageRunning = true;
    }
  }

  openContextMenu(index: number, item: SearchResults) {
    this.updateSelectedIndex(index);
    this.hoveredIndex = index;
    this.previewHandlerService.hoveredItem = item;
    this.onOpenContextMenu = true;
    this.previewHandlerService.openContextMenu();
  }

  closeContextMenu() {
    this.openedDragContextMenuId = null;
    this.hoveredIndex = null;
    this.previewHandlerService.hoveredItem = null;
    this.onOpenContextMenu = false;
  }

  public async onInvoke(data: InvokeCommand, type: Search.ResultType): Promise<void> {
    if (isDynamicCommand(data.command)) {
      const command: Commands.DynamicCommand = data.command as Commands.DynamicCommand;
      const item = data.invokerItem || this.items[this.selectedIndex];
      this.onDynamicCommand.emit({ value: command.value, item: item as any });
      return;
    }

    this.previewHandlerService.onInvoke(data, type);

    const item = this.items[this.selectedIndex];
    this.resultCommandService.onInvoke(
      this.items,
      data,
      type,
      this.displayedContext.sessionId,
      this.displayedContext.clientSearchId,
      (<any>item)?.searchId,
      this.selectedIndex,
      0,
      null,
      '/search'
    );
  }

  protected skipHeader(index: number, removeFocus?: boolean) {
    if (removeFocus || (this.removeFocus && !this._componentFocused)) {
      this.selectedIndex = -1;
      return;
    }
    const subList = this.items.slice(index);
    const newIndex = subList.findIndex((i) => !isHeader(i));
    this.selectedIndex = newIndex === -1 ? index : index + newIndex;
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.keyboardOn = false;
  }

  toggleChecked($event, id: string) {
    $event?.originalEvent?.stopPropagation();
    if ($event.checked) {
      this.checkedItems.add(id);
    } else {
      this.checkedItems.delete(id);
    }
    this.checkedItemsUpdate.emit(this.checkedItems);
    this.cdr.markForCheck();
  }

  titleChangedEvent($event, item) {
    this.titleChanged.emit({ value: $event, item: item });
  }

  isCard(item) {
    return isWikiCard(item);
  }

  isPreviewClickAction(action: Action) {
    return isPreviewClickAction(action);
  }

  isPreviewDirty() {
    return (
      this.previewHandlerService.isActivePreview &&
      this.previewHandlerService.previewViewModel.previewState === 'side' &&
      this.isPreviewClickAction(this.previewHandlerService.selected?.action) &&
      this.previewContainer?.isDirty()
    );
  }

  updateSelectedIndex(val: number, data?: InvokeCommand) {
    if (data?.command?.type === 'summary') {
      return;
    }
    this.selectedIndex = val;
    this.cdr.markForCheck();
  }

  onInvokeDirty($event: DirtyChangeStatus) {
    if ($event !== 'open') {
      this.previewHandlerService.selected$.next([]);
      this.cdr.markForCheck();
    }
  }

  isSelected(index: number): boolean {
    return !this.dragInprogress && this.selectedIndex === index && this.componentFocused;
  }

  updateResultsWidthAndGridColumns() {
    setTimeout(() => {
      this.resultsWidth = this.resultsRef?.nativeElement?.offsetWidth;
      const column = this.COLLECTION_COLUMNS_COUNTER.find((item) => this.resultsWidth < item.maxWidth);
      this.gridColumns = this.maxColumnsInScreen ? column?.maxColumns : column?.minColumns;
      this.cdr.markForCheck();
    }, 0);
  }

  changeLayout() {
    this.viewModeChanged.emit();
    this.scrollService.scrollToStart(this.scrollbarRef);
  }

  openContextMenuFromDrag(event) {
    this.openedDragContextMenuId = event.itemId;
    this.cdr.markForCheck();
    this.contextMenu = event;
  }

  // this solution from this git issue: https://github.com/angular/components/issues/13372
  onDropListDropped() {
    if (!this.target) {
      return;
    }

    const placeholderElement: HTMLElement = this.placeholder?.element.nativeElement;
    const placeholderParentElement: HTMLElement = placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(this.source?.element.nativeElement, placeholderParentElement.children[this.sourceIndex]);

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef);
    }

    this.target = null;
    this.source = null;
    this.dragRef = null;

    if (this.sourceIndex !== this.targetIndex) {
      const items = [...this.items];
      moveItemInArray(items, this.sourceIndex, this.targetIndex);
      this._items = items;
      this.onDragChange.emit({ previousIndex: this.sourceIndex, currentIndex: this.targetIndex });
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    if (container == this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement = this.placeholder?.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer?.element.nativeElement;
    const dropElement: HTMLElement = container?.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = item.dropContainer;

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(placeholderElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement);

    this.placeholder._dropListRef.enter(item._dragRef, item.element.nativeElement.offsetLeft, item.element.nativeElement.offsetTop);
  }

  showAnswerFeedback($event, index: number) {
    const { resources, intent, type, searchId } = $event;
    if (this.isAnswerFeedbackOpen) {
      this.hideAnswerFeedback(index + 1);
    }
    const answerFeedback: AnswerFeedbackSearchItem = {
      type: 'answer-feedback',
      intent,
      feedbackType: type,
      resources: resources,
      linkIds: resources?.map((r) => r.link.id).join(','),
      resourceIds: resources?.map((r) => r.id).join(','),
      searchId,
    };
    this.items.splice(index + 1, 0, answerFeedback);
    this.isAnswerFeedbackOpen = true;
    this.answerFeedbackIndex = index + 1;
    this.resultsLimit += 1;
    this.cdr.markForCheck();
    setTimeout(() => {
      if (this.answerFeedbackRef?.nativeElement) {
        this.scrollbarRef.scrollToElement(this.answerFeedbackRef?.nativeElement, { duration: 500 });
      }
    }, 0);
  }

  hideAnswerFeedback(index: number) {
    this.items.splice(index, 1);
    this.isAnswerFeedbackOpen = false;
    this.resultsLimit -= 1;
    this.cdr.markForCheck();
  }

  followUpChat(answerSearchItem: AnswerSearchItem) {
    this.openChatClicked.emit(answerSearchItem);
  }

  recentSearchClicked(recentSearch: RecentSearchItem) {
    this.recentSearchItemClicked.emit(recentSearch);
  }
}
