import { Commands, Style } from '@local/client-contracts';
import { KeyName } from '@local/ts-infra';

export interface ContextMenuItem {
  id?: string;
  type?: 'text' | 'separator';
  text?: string;
  command?: Commands.Command;
  icon?: Style.EntityIcon<'font-icon' | 'img'>;
  shortcut?: Array<KeyName>;
  data?: { [key: string]: any };
  items?: ContextMenuData;
  disabled?: boolean;
}

export type Trigger = 'context_menu_click' | 'context_menu_keyboard';

export interface ContextMenuData {
  items: Array<ContextMenuItem>;
  onInvoke: (item: ContextMenuItem, trigger?: Trigger) => void;
  menuWidth?: number;
  minWidthItem?: number;
}

export const EXTENSION_WHITE_LIST_COMMANDS = [
  'open',
  'copy_clipboard',
  'favorite',
  'copy_email',
  'copy_phone',
  'edit_goLink',
  'copy_goLink',
  'open_collection',
  'download',
  'summary',
];
