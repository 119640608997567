<ng-container *ngIf="model">
  @if (model.type !== 'parent') {
    @if (model?.resultType === 'assistant') {
      <assistant-icon
        class="content-icon"
        [model]="model"
        [spriteEmojiSize]="14"
        [iconStyles]="{ 'font-size': '14px', height: '16px', width: '16px' }"
      ></assistant-icon>
    } @else {
      @if (model.icon.type !== 'emoji') {
        <u-icon class="content-icon" [model]="icon" [styles]="{ fontSize: '14px', height: '16px', width: '16px' }"></u-icon>
      } @else {
        <u-emoji class="content-icon" [spriteEmojiSize]="14" [unicode]="model.icon.value"></u-emoji>
      }
    }
  }
  <div class="title">
    <div
      data-cy="search-popup-header"
      *ngIf="model.title"
      class="content-title"
      [innerHTML]="model.title | highlight: model?.highlights ?? []"
      [class.has-subtitle]="!!subtitle || model.inlineIcon"
    ></div>
  </div>
  <u-icon *ngIf="model?.inlineIcon" [model]="model.inlineIcon" [styles]="{ width: '12px', height: '12px' }"></u-icon>
  @if (displaySubTitle) {
    <h3 [class.hidden]="!subtitle" #subtitleRef class="content-subtitle" [innerHTML]="subtitle | highlight: model?.highlights ?? []"></h3>
  }
  <h3
    [class.hidden]="!model?.subtitle"
    #subtitleRef
    class="content-subtitle"
    [innerHTML]="model?.subTitleByGroup | highlight: model?.subTitleByGroup ?? []"
  ></h3>

  <span *ngIf="model.shortcut" class="content-shortcut" [innerHTML]="model.shortcut | keyIcon"></span>
</ng-container>
