import { KeyName } from '@local/ts-infra';
import { ContextMenuItem } from '@shared/components';
import { KeyboardService } from '@shared/services/keyboard.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { AssistantItem } from 'src/app/bar/views/results';
import { Experiences } from '@local/client-contracts';

export class AssistantContextMenuBuilder {
  private contextMenu: ContextMenuItem[];

  constructor(private keyboardService: KeyboardService, private hubService: HubService) {}

  async getItems(model: AssistantItem): Promise<ContextMenuItem[]> {
    const shortcuts: any = await this.keyboardService.loadShortcuts<any>(['_assistant']);
    this.contextMenu = [
      {
        id: 'open_collection',
        text: 'Open',
        command: { type: 'open-assistant' },
        shortcut: <KeyName[]>shortcuts?.open.map((k) => k.toLowerCase()),
        icon: { type: 'font-icon', value: 'icon-launch' },
      },
      {
        id: 'copy_clipboard',
        text: 'Copy URL',
        command: { type: 'copy-url' },
        icon: { type: 'font-icon', value: 'icon-link2' },
        shortcut: <KeyName[]>shortcuts?.copyUrl.map((k) => k.toLowerCase()),
      },
    ];
    if (model && (model.experienceType === 'salesforce' || model.experienceType === 'general')) {
      this.contextMenu.push({
        id: 'copy_id',
        text: 'Copy Assistant ID',
        command: { type: 'copy-id' },
        icon: { type: 'font-icon', value: 'icon-copy-3' },
      });
    }
    const isLauncher = await this.hubService.getIsLauncher();
    if (!model.readOnly && !isLauncher) {
      this.contextMenu.push({
        id: 'share',
        text: 'Share',
        command: { type: 'share-assistant' },
        icon: { type: 'font-icon', value: 'icon-members' },
      });

      if (model && Experiences.ExperiencesWithEditMode.includes(model.experienceType) && !isLauncher) {
        this.contextMenu.push({
          id: 'delete',
          text: 'Delete',
          command: { type: 'delete' },
          icon: { type: 'font-icon', value: 'icon-delete' },
        });
      }
    }

    return this.contextMenu;
  }
}
