import { Assistants } from '@local/client-contracts';
import { SourceSettings } from '../source-settings';

export interface AnswersSourceSettings extends SourceSettings {
  type: 'answers';
  maxCount: number;
  minQueryLength: number;
  minWords: number;
  minWordLength: number;
  noResults?: boolean;
  collectionId?: string;
  displayOpenChatResult?: boolean;
  allowAllQuestionQueries?: boolean;
  preventFormattedAnswer?: boolean;
  chat?: Assistants.QuestionChatSettings;
  redirectToChat?: boolean;
  source?: Assistants.AnswerPlatformType;
  blobIds?: string[];
  answerCacheTime?: number;
}
