import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavTree } from '@local/client-contracts';
import { RouterService } from '@shared/services/router.service';
import { filter, firstValueFrom } from 'rxjs';
import { NavTreeService } from '../services/nav-tree.service';
import { getNodeIdFromRoute } from './nav-tree-node-id.resolver';

//Provided by BarModule

const SPECIAL_VIEWS: Record<string, string> = { calendar: 'calendar', favorites: 'favorites', analytics: 'analytics' }; // Those are resolved from the tree but redirect to other component.
@Injectable()
export class NavTreeNodeResolver {
  constructor(
    private navTreeService: NavTreeService,
    private routingService: RouterService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<NavTree.Node> {
    let nodeId: string = getNodeIdFromRoute(route);
    if (nodeId === '') {
      nodeId = 'home';
    } else if (!nodeId) {
      return;
    }

    if (nodeId === 'admin') {
      return;
    }

    await firstValueFrom(this.navTreeService.all$.pipe(filter((a) => a?.length > 0)));
    const node: NavTree.Node = await this.navTreeService.get(decodeURIComponent(nodeId));

    if (!node) {
      return;
    }

    const { id, group } = node;

    if (
      group === 'views' &&
      SPECIAL_VIEWS[id] &&
      state.url.split(this.routingService.prefix)[1] !== route.url.map((u) => u.path).join('/')
    ) {
      this.routingService.navigateByUrl(SPECIAL_VIEWS[id]);
    }

    return node;
  }
}
