import { ElementRef } from '@angular/core';
import { PopupRef } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuService } from '@shared/components';
import { KeyboardService } from '@shared/services/keyboard.service';
import { Subject } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { AssistantItem, ResultContextMenuItem, TelemetryTrigger } from 'src/app/bar/views/results';
import { AssistantContextMenuBuilder } from './assistant-context-menu-builder';
import { ContextMenuInvokeSource } from 'src/app/bar/services/commands/results-item-context-menu-helper';

export class AssistantContextMenuHelper {
  ref: PopupRef<ContextMenuComponent, ContextMenuData> | null;
  items: Array<ResultContextMenuItem> = [];
  invoke$ = new Subject<{ item: ResultContextMenuItem; trigger: TelemetryTrigger }>();
  contextMenuBuilder: AssistantContextMenuBuilder;

  constructor(
    private contextMenuService: ContextMenuService,
    protected keyboardService: KeyboardService,
    private actionsRef: ElementRef,
    protected assistantService: ExperiencesService,
    hubService: HubService
  ) {
    this.contextMenuBuilder = new AssistantContextMenuBuilder(this.keyboardService, hubService);
  }

  async open(
    event: MouseEvent,
    model?: AssistantItem,
    source?: ContextMenuInvokeSource
  ): Promise<PopupRef<ContextMenuComponent, ContextMenuData>> {
    const items = await this.contextMenuBuilder.getItems(model);

    let openAt: { x: number; y: number } = event;
    if (source === 'actions' && this.actionsRef) {
      const { width, left, top, height } = this.actionsRef.nativeElement.getBoundingClientRect();
      if (width && height) {
        openAt = { x: left + width / 2, y: top + height / 2 };
      }
    }

    this.ref = this.contextMenuService.open(
      openAt,
      {
        items,
        onInvoke: (item: ResultContextMenuItem, trigger) => {
          this.invoke$.next({ item, trigger });
        },
      },
      { position: source === 'actions' ? 'below' : 'right' }
    );

    return this.ref;
  }
}
