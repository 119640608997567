<div class="avatar-popup" data-cy="avatar-popup-container">
  <div class="avatar-popup-email" data-cy="avatar-popup-email">
    {{ avatarPopupData.email }}
  </div>
  <div class="avatar-popup-content" (click)="onClickWorkspace()">
    <u-icon class="avatar-popup-content-img" [model]="avatarPopupData.workspaceIcon"></u-icon>
    <div class="avatar-popup-info" #container>
      <div
        data-cy="avatar-popup-workspace-name"
        class="avatar-popup-info-header"
        [pTooltip]="avatarPopupData.workspace.name"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        [appendTo]="container"
      >
        {{ avatarPopupData.workspace.name }}
      </div>
      <div class="avatar-popup-info-subline" data-cy="avatar-popup-info">
        {{ avatarPopupData.workspace.type }} plan - {{ avatarPopupData.workspace.members }}
        {{ avatarPopupData.workspace.members > 1 ? 'members' : 'member' }}
      </div>
    </div>
  </div>
  <div class="avatar-popup-divider"></div>
  <div class="avatar-popup-logout-wrapper" *ngIf="!avatarPopupData.hideLogout">
    <div class="avatar-popup-logout-container" (click)="onLogout()" data-cy="avatar-popup-logout-container">
      <div class="avatar-popup-logout">Log Out</div>
    </div>
  </div>
</div>
