import { Directive, signal, TemplateRef, ViewContainerRef, effect, inject, input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspacesService } from 'src/app/bar/services';

@UntilDestroy()
@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  hasPermission = input<string>(null);
  hasPermissionElse = input<TemplateRef<any>>(null);

  private isAdmin = signal<boolean>(false);
  private isOwner = signal<boolean>(false);

  private workspacesService = inject(WorkspacesService);

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.workspacesService.current$.pipe(untilDestroyed(this)).subscribe((current) => {
      this.isAdmin.set(current.isAdmin || current.isOwner);
      this.isOwner.set(current.isOwner);
    });

    effect(() => {
      if (this.isAllowed(this.hasPermission())) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
        if (this.hasPermissionElse()) {
          this.viewContainer.createEmbeddedView(this.hasPermissionElse());
        }
      }
    });
  }

  isAllowed(requiredPermission) {
    switch (requiredPermission) {
      case 'owner':
        return this.isOwner();
      case 'admin':
        return this.isAdmin();
      case 'user':
        return !this.isAdmin() && !this.isOwner();
    }
  }
}
