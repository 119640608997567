export const findStateDifferences = (currentState, previousState): string[] => {
  const differences = [];
  const current = currentState || {};
  const previous = previousState || {};

  const allKeys = new Set([...Object.keys(current), ...Object.keys(previous)]);

  for (const key of allKeys) {
    if (
      !previous[key] ||
      !current[key] ||
      previous[key].length !== current[key].length ||
      previous[key].some((item) => !current[key].includes(item)) ||
      current[key].some((item) => !previous[key].includes(item))
    ) {
      differences.push(key);
    }
  }

  return differences;
};
