<div class="full-detail-line">
  <div class="details" [ngClass]="{ 'details-removable': showRemoveIcon && item?.selected }">
    @if (showItemIcon) {
      @if (item?.emoji) {
        <u-emoji class="detail-icon" [spriteEmojiSize]="16" [unicode]="item?.emoji"> </u-emoji>
      } @else {
        <u-icon class="detail-icon" [model]="item.icon" [styles]="{ fontSize: '16px' }"></u-icon>
      }
    }
    <indicator
      class="indicator"
      *ngIf="showIndicatorInList"
      [color]="item.icon.value.type === 'initials' ? item.icon.value.value.color : null"
    ></indicator>
    <div class="option-text">
      <div
        *ngIf="item.title"
        class="title"
        [class.title-full-details]="item.subtitle"
        [class.limit-length]="item.data?.showLabelOnTag && item.subtitle"
      >
        {{ item.title }}
      </div>
      <div
        *ngIf="item.subtitle"
        class="subtitle"
        [class.limit-length]="item.data?.showLabelOnTag"
        [pTooltip]="item.subtitle?.length > MAX_CHARACTERS ? item.subtitle : ''"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
      >
        {{ item.subtitle }}
      </div>
    </div>
  </div>
  <div
    *ngIf="showItemLabel && !(showRemoveIcon && item?.selected)"
    class="side-label"
    [pTooltip]="item.label?.length > MAX_CHARACTERS ? item.label : ''"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
  >
    {{ item.label | capitalize }}
  </div>
  @if (showItemIconLabel && item.iconLabels?.length) {
    <div class="item-icon-labels-container">
      @for (iconLabel of item.iconLabels; track $index) {
        <u-icon
          class="item-icon"
          [model]="{ type: 'font', value: iconLabel.icon }"
          [pTooltip]="iconLabel?.tooltip"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
          [styles]="{ color: iconLabel.color }"
        >
        </u-icon>
      }
    </div>
  } @else {
    <u-icon *ngIf="iconLabelOnHover" class="item-icon icon-label-on-hover" [model]="iconLabelOnHover"> </u-icon>
  }
  <u-icon
    *ngIf="showRemoveIcon && item?.selected"
    [model]="{ type: 'font', value: 'icon-Windows-close' }"
    [styles]="{ fontSize: '10px' }"
    class="remove-icon"
    [pTooltip]="'Remove Filter'"
    tooltipPosition="top"
    tooltipStyleClass="u-tooltip"
  ></u-icon>
</div>
