import { Config } from '@environments/config';
import { LocalActions, Search } from '@local/client-contracts';
import { isSearchCancelledError, ManualPromise, observable } from '@local/common';
import { isEmbed } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { EventInfo, EventInfoResource, EventsService, InternetService, LogService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { isMatrix } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, flatten } from 'lodash';
import { filter, firstValueFrom, forkJoin, isObservable, Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import * as uuid from 'uuid';
import { SearchOptions, SearchResultContext, SearchService, WorkContext } from '.';
import { HubService } from '../../services/hub.service';
import { CollectionItem, SearchResults } from '../../views';
import { isCollection, isGoLink, isHeader, listNameByType } from '../../views/results/utils/results.util';
import { FiltersService } from '../filters.service';
import { LinkResourcesResultExtra, SearchClient, SearchRequest } from './client';
import { SearchResponse } from './client/search-response';
import { SourceSettings } from './client/source-settings';
import { RetrySourceModel } from './models/retry-source-modal';
import { SearchClientFactoryService } from './search-client-factory-service';
import { SourceResult } from './source-result';
import { SourceWorkContext } from './source-work-context';

export class SearchSessionContext {
  id: number;
  alive: boolean;
  workContext?: WorkContext;
  result$: Subject<SearchResultContext>;
  destroyed$: Subject<void>;
}

export class SearchSession {
  currentSearch: SearchSessionContext;
  private logger: Logger;
  private currentSearchId: number;
  private sessionId: string;
  private sessionStartTime: number;
  private impressionTimeout: NodeJS.Timeout;

  constructor(
    private sessionName: string,
    private hubService: HubService,
    logService: LogService,
    private internetService: InternetService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private searchClientFactory: SearchClientFactoryService,
    private embedService: EmbedService,
    private routerService: RouterService,
    private searchService: SearchService
  ) {
    this.logger = logService.scope('SearchService');
  }

  @observable
  search$(searchOptions: SearchOptions): Observable<SearchResultContext> {
    this.stopCurrentSearch();
    let result$: Observable<SearchResultContext> = new ReplaySubject<SearchResultContext>(1);
    const id = (this.currentSearchId = (this.currentSearchId || 0) + 1);
    this.currentSearch = {
      id,
      alive: true,
      result$: result$ as ReplaySubject<SearchResultContext>,
      destroyed$: new ReplaySubject(1),
    };
    this.innerSearch(id, searchOptions);

    if (!searchOptions.includeNullResultContext) {
      result$ = result$.pipe(filter((p) => !!p));
    }
    return result$;
  }

  @observable
  nextPage$(trigger: string, sources?: { id?: string; type: string }[]): Observable<SearchResultContext[]> {
    if (this.impressionTimeout) {
      clearTimeout(this.impressionTimeout);
      this.impressionTimeout = null;
    }
    const searchId = this.currentSearch.id;
    const sourceWorkContexts = this.currentSearch.workContext?.sourceWorkContexts;
    const defaultSubject$ = new Subject<SearchResultContext[]>();
    defaultSubject$.complete();
    const subjects: Observable<SearchResultContext>[] = [];
    if (sources) {
      for (const { id, type } of sources) {
        const source = sourceWorkContexts.find((r) => (id && r.sourceSettings.id === id) || (!id && r.sourceSettings.type === type));
        if (source) {
          const client = this.searchClientFactory.getOrCreate(source.sourceSettings.type, this.searchService);
          const subject$ = new ReplaySubject<SearchResultContext>(1);
          subjects.push(subject$);
          const task = client.nextPage(source.request, source.response, trigger);
          if (task) {
            task.then(async () => {
              const resultContext = await firstValueFrom(this.currentSearch.result$);
              if (resultContext && this.searchAlive(searchId)) {
                subject$.next(resultContext);
              }
              subject$.complete();
            });
          }
        }
      }
    }
    return subjects?.length ? forkJoin(subjects) : defaultSubject$;
  }

  private stopCurrentSearch() {
    if (this.currentSearch) {
      this.currentSearch.alive = false;
      this.currentSearch.result$.next(null);
      this.currentSearch.result$.complete();
      for (const context of this.currentSearch.workContext?.sourceWorkContexts || []) {
        context.searchClient.destroy(this.currentSearch.id, this.sessionName);
      }
      this.currentSearch.destroyed$.next(null);
    }
    if (this.impressionTimeout) {
      clearTimeout(this.impressionTimeout);
      this.impressionTimeout = null;
    }
  }

  private next(context: SearchResultContext, complete = false) {
    if (!this.searchAlive(context.id)) {
      return;
    }
    context.searchCompleted = complete;
    const subject$ = this.currentSearch.result$;
    if (subject$) {
      subject$.next(context);
    }
  }

  retrySources(sources: RetrySourceModel[], reset?: boolean) {
    if (reset) {
      const updatedSources = [];
      const updatedOrder = [];
      let index = 0;
      for (const s of sources) {
        const currentSource = this.currentSearch.workContext.sourceWorkContexts[s.sourceIndex];
        if (!currentSource) {
          this.logger.error(
            `Failed to retry search for source type "${s.sourceSettings.type}". The source index ${s.sourceIndex} is invalid`,
            {
              searchSource: s,
            }
          );
          continue;
        }
        updatedSources.push(currentSource);
        updatedOrder.push({ innerIndex: index, resultIndex: index });
        index++;
      }
      this.currentSearch.workContext.sourceWorkContexts = updatedSources;
      this.currentSearch.workContext.orders = updatedOrder;
    }
    const contexts = this.currentSearch.workContext.sourceWorkContexts;
    for (const [index, s] of sources.entries()) {
      const sourceIndex = reset ? index : s.sourceIndex;
      if (!contexts[sourceIndex]) {
        continue;
      }
      const context = contexts[sourceIndex];
      const request = cloneDeep(context.request);
      request.sourceSettings = s.sourceSettings;
      this.startClientSearch(context.request.id, context.searchClient, request, context.response);
    }
  }

  searchAlive(id: number) {
    return this.currentSearch && this.currentSearchId === id && this.currentSearch.alive;
  }

  private innerSearch(id: number, searchOptions: SearchOptions) {
    const query = searchOptions.query;
    const clientSearchId = uuid.v4();
    const sessionId = searchOptions.resetSession ? undefined : this.sessionId;
    const resultCtx: SearchResultContext = {
      id,
      items: [],
      clientSearchId,
      sessionId,
      options: searchOptions,
    };

    const workContext: WorkContext = {
      id,
      sessionName: this.sessionName,
      sessionId,
      query,
      trigger: searchOptions.trigger,
      orders: [],
      sourceWorkContexts: [],
      telemetrySearchMethod: searchOptions.telemetrySearchMethod,
    };

    if (
      !resultCtx.sessionId ||
      (this.sessionStartTime && Date.now() - this.sessionStartTime >= 60000 * 5) ||
      (workContext.newSearchSession && Date.now() - this.sessionStartTime >= 30000)
    ) {
      resultCtx.sessionId = workContext.sessionId = this.sessionId = uuid.v4();
      this.sessionStartTime = Date.now();
    }
    this.currentSearch.workContext = workContext;
    this.next(resultCtx);

    const searchTelemetry = this.initSearchTelemetry(workContext, searchOptions.sources, searchOptions.trigger, clientSearchId);
    this.eventsService.event('search.start', searchTelemetry);
    workContext.searchTelemetry = searchTelemetry;
    this.processSources(searchOptions.sources, clientSearchId);
  }

  private processSources(sources: SourceSettings[], clientSearchId: string): void {
    const workContext = this.currentSearch.workContext;
    const sourceWorkContexts: { [id: string]: { context: SourceWorkContext; nextId: number } } = {};
    const duplicates = this.getDuplicates(sources);
    const processed = new ManualPromise<void>();
    let currentSourceIndex = 0;
    for (let index = 0; index < sources.length; index++) {
      let sourceIndex = currentSourceIndex;
      const source = sources[index];

      if (!source.id || !duplicates.has(source.id) || !sourceWorkContexts[source.id]) {
        currentSourceIndex++;
      } else {
        const sourceWorkContext = sourceWorkContexts[source.id];
        sourceWorkContext.nextId++;
        sourceIndex = sources.findIndex((s) => s.id === source.id);
      }

      const innerIndex = sourceWorkContexts[source.id]?.nextId;
      workContext.orders.push({ resultIndex: sourceIndex, innerIndex });
      if (innerIndex > 0) {
        if (innerIndex === 1) {
          const order = workContext.orders.find((o) => o.resultIndex === sourceIndex);
          order.innerIndex = 0;
        }
        continue;
      }
      const request: SearchRequest<typeof source> = {
        id: workContext.id,
        query: workContext.query,
        sessionId: workContext.sessionId,
        sessionName: workContext.sessionName,
        trigger: workContext.trigger,
        newSearchSession: workContext.newSearchSession,
        searchTelemetry: workContext.searchTelemetry,
        sourceSettings: source,
        clientSearchId,
      };
      const response = new SearchResponse(this.searchService, workContext.id, this.sessionName, source.type, this.currentSearch.destroyed$);
      const client = this.searchClientFactory.getOrCreate(source.type, this.searchService);
      const sourceWorkContext = { request, response, sourceSettings: source, searchClient: client };
      if (source.id) {
        sourceWorkContexts[source.id] = { context: sourceWorkContext, nextId: 0 };
      }
      workContext.sourceWorkContexts.push(sourceWorkContext);
      const hasFilters = Object.keys(source.filters?.preFilters || {}).length || Object.keys(source.filters?.postFilters || {}).length;
      if (hasFilters && !client.supportsFilters(source.filters)) {
        this.completeWithoutResults(response, workContext);
        continue;
      }
      if (source.sorting && !client.supportsSort(source.sorting)) {
        this.logger.info(`client ${source.type} not support sort`, source.sorting);
        this.completeWithoutResults(response, workContext);
        continue;
      }
      response.update$.pipe(takeUntil(this.currentSearch.destroyed$)).subscribe(async () => {
        await processed;
        if (this.searchAlive(workContext.id)) {
          this.onWorkContextUpdate(workContext);
        }
      });
      if (Config.search.emptyStates[request.sourceSettings.type]) {
        this.completeWithoutResults(response, workContext);
        continue;
      }
      this.startClientSearch(workContext.id, client, request, response);
    }
    processed.resolve();
  }

  private completeWithoutResults(response: SearchResponse, workContext: WorkContext) {
    response.items = [];
    response.complete();
    this.onWorkContextUpdate(workContext);
  }

  private handleSearchObservable(obs$: Observable<void>, request: SearchRequest<SourceSettings>, response: SearchResponse) {
    obs$.pipe(takeUntil(this.currentSearch.destroyed$)).subscribe({
      next: () => {},
      error: (error) => {
        this.handleSearchErrors(request, response, error);
      },
    });
  }

  private startClientSearch(
    id: number,
    client: SearchClient<SourceSettings>,
    request: SearchRequest<SourceSettings>,
    response: SearchResponse
  ) {
    try {
      const searchTask = client.search(request, response);
      if (isObservable(searchTask)) {
        this.handleSearchObservable(searchTask, request, response);
      } else {
        searchTask
          .then((res) => {
            if (!this.searchAlive(id)) {
              return;
            }
            if (isObservable(res)) {
              this.handleSearchObservable(res, request, response);
            }
          })
          .catch((error) => this.handleSearchErrors(request, response, error));
      }
    } catch (error) {
      this.handleSearchErrors(request, response, error);
    }
  }

  private handleSearchErrors(request: SearchRequest<SourceSettings>, response: SearchResponse, error: any) {
    if (isSearchCancelledError(error)) {
      this.logger.info('search cancelled - dropping update state', { requestId: request.id });
    } else {
      this.logger.error(`got error while searching ${request.sourceSettings.type} source`, error);
      if (response.cancelled) {
        return;
      }
      response.error = error;
      response.complete();
    }
  }

  private getDuplicates(sources: SourceSettings[]): Set<string> {
    const ids = new Set<string>();
    const multiples = new Set<string>();
    for (const s of sources) {
      if (!s.id) {
        continue;
      }
      if (ids.has(s.id)) {
        multiples.add(s.id);
        continue;
      }
      ids.add(s.id);
    }
    return multiples;
  }

  private async onWorkContextUpdate(ctx: WorkContext) {
    this.hubService.context.sessionId = ctx.sessionId;
    const result = cloneDeep(await firstValueFrom(this.currentSearch.result$));
    if (!result || !this.searchAlive(ctx.id)) {
      return;
    }
    let items = [];
    let finished = true;
    for (const order of ctx.orders) {
      const sourceResult = ctx.sourceWorkContexts[order.resultIndex];
      const response = sourceResult.response;
      const source = sourceResult.sourceSettings;
      if (!response.done && !source.ignoreDone) {
        finished = false;
      }
      if (response.error) {
        if (!sourceResult.errorLogged) {
          this.logger.error('failed in search', { error: response.error, searchSource: source });
          sourceResult.errorLogged = true;
        }
        continue;
      }
      let newItems = response.items;

      if (isMatrix(newItems)) {
        newItems = order.innerIndex != undefined ? (<SearchResults[][]>response.items)[order.innerIndex] : flatten(response.items);
      }

      for (const i of newItems || []) {
        (<SearchResults>i).source = source.type;
        (<SearchResults>i).clientId = source.id;
      }

      items = [...items, ...(newItems || [])];
      if (!finished) {
        break;
      }
      if (!sourceResult.ended) {
        this.logger.info('search source ended', { id: ctx.id, source: sourceResult.sourceSettings.type, resultsCount: items.length });
        sourceResult.ended = true;
        const searchClient = sourceResult.searchClient;
        this.sendEndEvent(response, searchClient, source);
      }
    }
    result.sources = ctx.sourceWorkContexts.map((c) => {
      const { done, error, items, extra, duration } = c.response;
      return { source: c.sourceSettings, done, error, items, extra, duration } as SourceResult;
    });
    result.items = items;
    result.lastHeaderIndex =
      result.items
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => isHeader(item))
        .splice(-1)[0]?.index || 0;
    if (result.items.length) {
      this.setImpressionEvent(ctx.id, ctx.searchTelemetry);
    }

    this.next(result, finished);
    return;
  }

  private initSearchTelemetry(
    workContext: WorkContext,
    sources: SourceSettings[],
    trigger: string,
    clientSearchId: string
  ): Partial<EventInfo> {
    const filtersSettings = sources?.find((s) => !!s.filters?.preFilters);
    const preFilters = filtersSettings?.filters?.preFilters;
    const postFilters = this.filtersService.postFilters;
    const sourcesData = sources.map((r) => r.type);
    const currentState = this.hubService.getState('ap');
    const location = currentState.length ? `${this.hubService.currentLocation}_${currentState}` : this.hubService.currentLocation;
    return {
      location: { title: location },
      search: {
        sources: sourcesData,
        offline: this.internetService.status === 'offline',
        clientSearchId,
        query: workContext.query,
        scope: Object.entries(preFilters || {})
          .filter((e) => e[1] && e[1].length)
          .map((e) => ({ type: e[0], values: e[1] })),
        filter: Object.entries(postFilters || {})
          .filter((e) => e[1] && e[1].length)
          .map((e) => ({ type: e[0], values: e[1] })),
        sessionId: workContext.sessionId,
        trigger,
      },
    };
  }

  private sendEndEvent(response: SearchResponse, searchClient: SearchClient<SourceSettings>, sourceSettings: SourceSettings) {
    const searchTelemetry = this.currentSearch.workContext.searchTelemetry;
    let end = searchClient.getTelemetryEndEvent(response);
    if (!end?.length) {
      end = [{}];
    }
    for (const endEvent of end) {
      const searchEnd: Partial<EventInfo> = {
        ...searchTelemetry,
        search: {
          ...searchTelemetry.search,
          stepDuration: response.duration,
          origin: sourceSettings.type,
          responseIgnored: response.cancelled,
          resultsCount: response.items?.length || 0,
          ...(endEvent?.search || {}),
        },
      };
      if (endEvent.exception) {
        searchEnd.exception = endEvent.exception;
      }
      if (endEvent.jsonData) {
        searchEnd.jsonData = endEvent.jsonData;
      }
      if (this.currentSearch?.workContext?.telemetrySearchMethod === 'Quick-Search') {
        searchEnd.label = 'quick_search';
      }
      this.eventsService.event('search.end', searchEnd);
    }
  }

  private setImpressionEventGoLink(item: Search.GoLinkResultItem, index: number): EventInfoResource {
    return {
      position: index,
      category: '',
      appId: 'go_link',
      linkId: item.id,
      id: item.data.nameId,
      list: this.hubService.currentLocation === 'search' ? 'go_links' : item.data.group,
      type: 'go_link',
      clientLink: item.data.url,
      name: item.data.name,
    };
  }

  private setImpressionEventCollection(item: CollectionItem | Search.ResourceItem, index: number): EventInfoResource {
    return {
      position: index,
      category: '',
      appId: (item as Search.ResourceItem)?.resource?.appId || 'collections',
      linkId: item.id,
      id: item.id,
      list: listNameByType(item, this.hubService.currentLocation),
      type: item.type,
    };
  }

  setImpressionEvent(id: number, searchTelemetry: Partial<EventInfo>) {
    if (this.impressionTimeout || (isEmbed() && !this.embedService.shown)) {
      return;
    }
    this.impressionTimeout = setTimeout(async () => {
      if (!this.searchAlive(id)) return;
      const currentResult = await firstValueFrom(this.currentSearch.result$);
      if (!currentResult || !this.searchAlive(id)) {
        return;
      }
      const resources: EventInfoResource[] = currentResult?.items
        .filter((r) => ['result', 'local-action', 'go-link', 'create-go-link', 'collection'].includes(r?.type))
        .map((r) => <Search.ResultResourceItem | LocalActions.LocalActionItem | Search.GoLinkResultItem | CollectionItem>r)
        .map((r, i) => {
          if (isGoLink(r)) {
            return this.setImpressionEventGoLink(<Search.GoLinkResultItem>r, i);
          } else if (isCollection(r) || this.hubService.currentLocation.includes('c/')) {
            return this.setImpressionEventCollection(<CollectionItem>r, i);
          } else {
            const t = (<any>r).resource || r;

            return {
              position: i,
              category: '',
              appId: t.appId,
              linkId: t.linkId,
              id: t.id,
              list: listNameByType(r, this.hubService.currentLocation),
              type: t.type,
            };
          }
        });

      const extra: LinkResourcesResultExtra = currentResult.sources?.find((s) => s.source.type === 'link-resources')?.extra;
      let duration: number;
      if (extra) {
        const cloud = Math.floor(extra.search?.origins?.Cloud?.duration || 0);
        const local = Math.floor(extra.search?.origins?.Local?.duration || 0);
        duration = Math.max(cloud, local);
      }
      const currentState = this.hubService.getState('ap');
      const location = currentState.length ? `${this.hubService.currentLocation}_${currentState}` : this.hubService.currentLocation;
      const event: Partial<EventInfo> = {
        location: { title: location },
        resources,
        search: {
          sessionId: searchTelemetry.search.sessionId,
          clientSearchId: searchTelemetry.search.clientSearchId,
        },
      };
      if (duration) {
        event.search.stepDuration = duration;
      }
      const url = await firstValueFrom(this.routerService.activeRoute$);
      const searchTrigger = (await firstValueFrom(url.queryParams))['search-trigger'];
      if (searchTrigger) {
        event.jsonData = { 'action.trigger': searchTrigger[0] };
      }
      this.eventsService.event('resources.impression', event);
      this.impressionTimeout = null;
    }, 1500);
  }

  destroy() {
    this.stopCurrentSearch();
  }
}
