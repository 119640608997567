import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { Collections, Commands, Fyis, Links, NavTree, Results, Search } from '@local/client-contracts';
import { SessionKeyValueStorage } from '@local/common';
import { isEmbed, isExtension, lookup } from '@local/common-web';
import { UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterChangeData } from '@shared/components/filters/models';
import { getFileType, isGoogleAppsType } from '@shared/consts/file-types';
import { EmbedService } from '@shared/embed.service';
import { EventsService, TelemetryService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { FlagsService } from '@shared/services/flags.service';
import { LinksService } from '@shared/services/links.service';
import { NativeAppService } from '@shared/services/native-app.service';
import { RouterService } from '@shared/services/router.service';
import { ServicesRpcService } from '@shared/services/rpc.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { isEmpty } from 'lodash';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ToasterData } from '../components/toaster/toaster-data';
import { isResourceResult, isResult, isWikiCardRemote, ResultItem, SearchResults } from '../views';
import { ConnectAppsModel } from '../views/hub/connect-apps/connect-apps.component';
import { Action, ActionType, PageType, RESULT_ACTION_SETTING } from '../views/results/models/view-filters';
import { ResultsRpcInvoker } from '../views/results/services/results.rpc.invokers';
import { EMPTY_STATE_VIEWS } from '../views/results/utils/results-empty-state';
import { CollectionsService } from './collections.service';
import { FiltersService } from './filters.service';
import { HubService } from './hub.service';
import { NavTreeService } from './nav-tree.service';
import { SearchParamsService } from './search-params.service';
import { generateFullPrefixedURL } from '@shared/utils';
import { generateTitleUrl } from '@local/ts-infra';

type LinkStateLabel = 'stale_multi' | 'stale' | 'sync_not_complete' | 'sync_not_complete_multi';
type StaleAppTimestamps = { [appId: string]: number };

interface LinksState {
  links: string[];
  apps: string[];
  owned?: string[];
}
@UntilDestroy()
@Injectable()
export class ResultsService {
  barItems$: BehaviorSubject<Results.BrowseBarItem[]> = new BehaviorSubject<Results.BrowseBarItem[]>(null);

  private service: Results.Service;
  private allLinks: Links.DisplayItem[];
  private _displayInstallExtensionMessage: boolean;
  private _staleAppToastersCleared: StaleAppTimestamps = {};
  private storage: SessionKeyValueStorage;
  private embedInline: boolean;
  private isExtension = isExtension();

  private readonly STALE_APPS_STORAGE_KEY = 'staleAppsToasters';
  private readonly isEmbed = isEmbed();
  private readonly STALE_TOASTER_TIMESTAMP = 1000 * 60 * 60 * 24;
  private readonly OFFICE_APP_ID = 'office365';

  get updates$(): Observable<Search.Item[]> {
    return this.service.updates$;
  }

  get displayInstallExtensionMessage(): boolean {
    return this._displayInstallExtensionMessage;
  }

  set displayInstallExtensionMessage(value: boolean) {
    this._displayInstallExtensionMessage = value;
    this.service.setExtensionMessageDisplayed(value);
  }

  get staleAppToastersCleared(): StaleAppTimestamps {
    return this._staleAppToastersCleared;
  }

  set staleAppToastersCleared(value: StaleAppTimestamps) {
    this._staleAppToastersCleared = value;
    this.storage.entry<StaleAppTimestamps>(this.STALE_APPS_STORAGE_KEY).set(value);
  }

  get enableCollectionIcon(): boolean {
    if (this.searchParamsService.collection || this.hubService.currentLocation === 'collections') return false;
    const apState = this.hubService.getState('ap');
    const searchGroup = this.hubService.getState('sg');
    const goLinks = 'golinks';
    return (
      this.hubService.currentLocation !== goLinks &&
      apState?.[0] !== goLinks &&
      !searchGroup?.length &&
      !this.isDefaultSearch() &&
      !isEmpty(this.hubService.state)
    );
  }

  constructor(
    private services: ServicesRpcService,
    private nativeAppService: NativeAppService,
    private filtersService: FiltersService,
    private linksService: LinksService,
    private applicationsService: ApplicationsService,
    private hubService: HubService,
    private analyticsService: TelemetryService,
    private sessionService: SessionService,
    private sessionStorageService: SessionStorageService,
    public routingService: RouterService,
    private eventsService: EventsService,
    private collectionsService: CollectionsService,
    private navTreeService: NavTreeService,
    private searchParamsService: SearchParamsService,
    private embedService: EmbedService,
    private flagsService: FlagsService
  ) {
    this.service = services.invokeWith(ResultsRpcInvoker, 'results');
    this.linksService.visible$.subscribe((links) => (this.allLinks = links));
    this.initStorage();
    this.initExtensionMessage();
    this.setEmbedInline();
  }

  nextPage(origin: Search.Origin, token: string): Promise<Search.Response> {
    return this.service.nextPage(origin, token);
  }

  search$(request: Search.Request): Promise<Observable<Search.Context>> {
    return this.service.search$(request);
  }

  isSiteLinksEnabled(): Promise<boolean> {
    return this.service.isSiteLinksEnabled();
  }

  setSiteLinksEnabled(enabled: boolean): Promise<void> {
    return this.service.setSiteLinksEnabled(enabled);
  }

  getItems$(r: Results.GetItemsRequest): Observable<Search.ResultResourceItem[]> {
    return this.service.getItems$(r);
  }

  isContentSearchEnabled(): Promise<boolean> {
    return this.service.isContentSearchEnabled();
  }

  setContentSearchEnabled(enabled: boolean): Promise<void> {
    return this.service.setContentSearchEnabled(enabled);
  }

  getFilteredBarApps(barItems: Results.BrowseBarItem[]) {
    const barApps = barItems.filter(
      (b) =>
        (b.type == 'app' && ![this.OFFICE_APP_ID].includes(b.appId) && (!this.embedInline || b.appId !== 'pc')) ||
        (b.appId == this.OFFICE_APP_ID && b.type == 'service')
    );
    return barApps;
  }

  isActivePreview(view: PageType): boolean {
    return Config.preview?.supportedViews?.includes(view);
  }

  hasPostFilters(): boolean {
    return Object.keys(this.filtersService.postFilters).length > 0;
  }

  hasPreFilters(): boolean {
    return Object.keys(this.filtersService.getPreFilters()).length > 0;
  }

  hasInlineFilters(): boolean {
    return Object.keys(this.filtersService.inlineFilters).length > 0;
  }

  isDefaultSearch() {
    return !this.hasInlineFilters() && !this.hasPostFilters() && !this.hubService.query;
  }

  isPageDefaultSearch() {
    return isEmpty(this.filtersService.inlineFilters) && !this.hasPostFilters() && !this.hubService.query;
  }

  private async setEmbedInline() {
    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
    }
  }

  private async isAvailableSummary(item: ResultItem): Promise<boolean> {
    if (!item.resource) return false;
    const resource = this.applicationsService.apps[item.resource.appId]?.resources.find((resource) => resource.type === item.resource.type);
    return !!resource?.flags?.summary && !!item.hasContent && (await this.flagsService.isEnabled('resourcesSummary'));
  }

  async getResultAction(item: SearchResults): Promise<Action> {
    if (!isResourceResult(item)) {
      return;
    }

    const availableSummary = await this.isAvailableSummary(item);
    if (this.isExtension) {
      if (isWikiCardRemote(item)) {
        const url = generateFullPrefixedURL('/' + generateTitleUrl('a', item.view.title.text, item.resource.externalId), 'path');
        item.view.title.onClick = {
          type: 'open-url',
          url: url,
        } as Commands.OpenUrl;
      }
      if (availableSummary) {
        return { type: item.type, click: { actions: [{ type: 'summary' }] } };
      }
      return;
    }

    for (const [key, value] of Object.entries(RESULT_ACTION_SETTING)) {
      if (!value) {
        continue;
      }
      if (value.condition.types && !value.condition.types.includes(item.filterType)) {
        continue;
      }
      if (value.condition.apps && item.resource && !value.condition.apps.includes(item.resource.appId)) {
        continue;
      }
      if (value.condition.links && item.resource && !value.condition?.links.includes(item.resource?.linkId)) {
        continue;
      }

      if (item.resource && key === 'files') {
        if (!item.resource.traits?.mimeType) {
          item.resource.traits = item.resource.traits || {};
          const resourceName = item.resource.name;
          const name = item.resource.traits.name || (resourceName.includes('.') ? resourceName : '');
          item.resource.traits = {
            ...item.resource.traits,
            name,
            mimeType: lookup(name),
          };
        }
        const fileType = getFileType(item.resource.traits?.mimeType, item.resource.name);
        if (!fileType || !item.view?.title?.onDrag || (fileType === 'online' && item.resource.appId === 'pc')) {
          continue;
        }
      }

      let action = value.action;
      if (availableSummary) {
        if (action.click.primary) {
          action.click = { ...action.click, actions: [{ type: 'summary' }] };
        } else {
          action = { type: item.type, click: { actions: [{ type: 'summary' }] } };
        }
      }

      if (action?.type === 'files' && item.resource && isGoogleAppsType(item.resource.traits?.mimeType)) {
        return { ...action, click: { primary: { type: 'open' }, secondary: { type: 'preview' }, actions: [{ type: 'summary' }] } };
      }

      return action;
    }

    if (availableSummary) {
      return { type: item.type, click: { actions: [{ type: 'summary' }] } };
    }
  }

  private removedAction(action: Action, actionType: ActionType): Action {
    if (action.click?.primary?.type === actionType) {
      delete action.click.primary;
    }
    if (action.click?.secondary?.type === actionType) {
      delete action.click.secondary;
    }
    if (action.hover?.primary?.type === actionType) {
      delete action.hover.primary;
    }
    if (action.hover?.secondary?.type === actionType) {
      delete action.hover.secondary;
    }
    return action;
  }

  async getPageType(query: string, currentNode?: NavTree.Node): Promise<PageType> {
    let page: PageType;
    if (query.trim().startsWith('go/')) {
      page = 'golinks';
    }
    if (!currentNode) {
      page = 'search';
    }

    if (currentNode) {
      const nodeViewName = currentNode?.data?.view?.name;

      if (nodeViewName) {
        page = nodeViewName as PageType;
      }
      const ancestors = await this.navTreeService.getAncestors(currentNode.id);
      if (ancestors.length === 0) {
        page = !['root', 'search'].includes(currentNode.id) ? (currentNode.id as PageType) : 'search';
      }
      if (!page) {
        page = ancestors[0].id as PageType;
      }
    }

    if (page === 'search' && this.hasPreFilters()) {
      const preFilters = this.filtersService.getPreFilters();
      const appFilters = preFilters?.app || [];
      const filtersCount = Object.keys(preFilters || {}).length;
      if (appFilters.length === 1 && filtersCount === 1) {
        const app = appFilters[0].toLowerCase();
        return RESULT_ACTION_SETTING[app] ? (app as PageType) : 'search';
      }
    }
    return page;
  }

  async getSuggestedApps(node: NavTree.Node): Promise<ConnectAppsModel> {
    if (!node) return;
    const { id, data } = node;

    if (!Object.keys(EMPTY_STATE_VIEWS).includes(id)) return;

    const allApps = await firstValueFrom(this.applicationsService.all$);
    if (!this.allLinks) {
      await firstValueFrom(this.linksService.visible$);
    }

    let apps = allApps.filter((app) => data?.apps?.includes(app.id));
    const allLinks = new Set(this.allLinks?.map((l) => l.appId));
    let appLinks = [...apps.filter((a) => allLinks.has(a.id))];

    if (id === 'files') {
      const EXPLICIT_FILES_APPS = ['box', 'office365', 'dropbox', 'gdrive'];
      apps = apps.filter((a) => EXPLICIT_FILES_APPS.includes(a.id));
      if (appLinks.length === 1 && appLinks[0].id === 'pc' && !this.nativeAppService.canSearchPc()) {
        appLinks = [];
      }
    }

    if (id === 'people' && (!data.filters.account.length || Config.search.emptyStates.people)) {
      EMPTY_STATE_VIEWS[id].apps = apps.map(({ id }) => id);
      EMPTY_STATE_VIEWS[id].appLinks = appLinks.map((app) => app.name);
      return EMPTY_STATE_VIEWS[id];
    }

    if (!appLinks.length) {
      EMPTY_STATE_VIEWS[id].apps = apps.map(({ id }) => id);
      return EMPTY_STATE_VIEWS[id];
    }
  }

  isQueryEmpty() {
    return !this.hubService.query || this.hubService.query.length === 0;
  }

  getLinksState(
    resultsItems: SearchResults[],
    linksSyncStatuses: { [linkId: string]: Fyis.SyncStatus }
  ): {
    stale: LinksState;
    syncing: LinksState;
  } {
    const stale: { links: Set<string>; apps: Set<string>; owned: Set<string> } = {
      links: new Set(),
      apps: new Set(),
      owned: new Set(),
    };
    const syncing: { links: Set<string>; apps: Set<string> } = { links: new Set(), apps: new Set() };

    resultsItems
      .filter((i) => isResult(i))
      .forEach((item: Search.ResultResourceItem) => {
        const link: Links.Link = item.link;
        if (!link) return;

        const syncStatus: Fyis.SyncStatus = linksSyncStatuses?.[link.id];
        if (syncStatus === 'Started') {
          syncing.links.add(link.id);
          syncing.apps.add(link.appId);
        }
        if (link.stale) {
          stale.links.add(link.id);
          stale.apps.add(link.appId);
          const owned = link.ownedByMe;

          if (owned) stale.owned.add(link.appId);
        }
      });
    return {
      stale: { apps: [...stale.apps], links: [...stale.links], owned: [...stale.owned] },
      syncing: { apps: [...syncing.apps], links: [...syncing.links] },
    };
  }

  buildSyncingLinksToasterData(syncing: LinksState): ToasterData {
    let content = 'Not all data is available as we are still syncing ';
    let target: string;
    if (syncing.apps.length >= 2) {
      const app1: string = this.applicationsService.apps[syncing.apps[0]]?.name;
      const app2: string = this.applicationsService.apps[syncing.apps[1]]?.name;
      if (!app1 || !app2) return;
      content += syncing.apps.length > 2 ? 'some of your apps' : `${app1} & ${app2}`;
      target = JSON.stringify(syncing.apps);
    } else {
      const app: string = this.applicationsService.apps[syncing.apps[0]]?.name;
      if (!app) return;
      content += `${app}`;
      target = syncing.apps[0];
    }
    const label: LinkStateLabel = syncing.links.length > 1 ? 'sync_not_complete_multi' : 'sync_not_complete';
    this.sendLinksStateToasterEvent(label, target);
    const icon: UiIconModel =
      syncing.apps.length > 1
        ? { type: 'font', value: 'icon-duo-exclamation-circle' }
        : { type: 'img', value: this.applicationsService.apps[syncing.apps[0]].icon };

    return {
      id: label,
      spinner: true,
      content,
      iconIntent: 'primary',
      label,
      icon: icon,
      clickable: true,
      isAnimated: !!syncing.apps.length,
    };
  }

  buildStaleLinksToasterData(stale: LinksState): { toaster: ToasterData; onDestroy: () => void } {
    const displayClickableToaster = stale.owned?.length >= 1;
    let id = 'stale-';
    let content = "Results might not be up to date because there's an issue with ";
    let target: string;
    const buttonText = displayClickableToaster ? 'Reconnect' : 'Contact Administrator';

    const now = Date.now();
    const apps = [];
    for (const app of stale.apps || []) {
      const timestamp = this.staleAppToastersCleared[app];
      if (!timestamp || timestamp < now - this.STALE_TOASTER_TIMESTAMP) {
        apps.push(app);
      }
    }
    if (!apps.length) {
      return;
    }
    stale.apps = apps;

    if (stale.apps.length >= 2) {
      const app1: string = this.applicationsService.apps[stale.apps[0]]?.name;
      const app2: string = this.applicationsService.apps[stale.apps[1]]?.name;
      if (!app1 || !app2) return;
      content += stale.apps.length === 2 ? `${app1} & ${app2} links` : "some of your apps' links";
      id += 'apps';
      target = JSON.stringify(stale.apps);
    } else {
      const app: string = this.applicationsService.apps[stale.apps[0]]?.name;
      if (!app) return;
      content += `your ${app} link`;
      target = stale.apps[0];
      if (stale.links.length >= 2) {
        content += 's';
        id += `links?${stale.apps[0]}`;
      } else {
        id += `link?${stale.links[0]}`;
      }
    }
    const label: LinkStateLabel = stale.links.length > stale.apps.length ? 'stale_multi' : 'stale';
    this.sendLinksStateToasterEvent(label, target);
    const icon: UiIconModel =
      stale.apps.length > 1
        ? { type: 'font', value: 'icon-duo-exclamation-circle' }
        : { type: 'img', value: this.applicationsService.apps[stale.apps[0]].icon };

    const toaster: ToasterData = {
      id,
      icon,
      content,
      buttonText,
      intent: 'success',
      iconIntent: 'danger',
      label,
      clickable: displayClickableToaster,
      showIndicator: !!stale.apps.length,
    };
    if (!displayClickableToaster) toaster.clickable = false;
    const onDestroy = () => {
      const now = Date.now();
      const apps: StaleAppTimestamps = {};
      for (const app of stale.apps) {
        apps[app] = now;
      }
      this.staleAppToastersCleared = apps;
    };
    return { toaster, onDestroy };
  }

  private sendLinksStateToasterEvent(label: string, target: string): void {
    this.analyticsService.event({
      category: 'alert',
      name: 'show',
      label,
      target,
    });
  }

  handleResultsLinksState(resultsItems: SearchResults[], linksSyncStatuses: { [linkId: string]: Fyis.SyncStatus }) {
    let linksStateToasterData;
    const { stale, syncing } = this.getLinksState(resultsItems, linksSyncStatuses);
    if (!syncing.apps.length && !stale.apps.length) return { linksStateToasterData: null, onDestroy: null };

    let onDestroy = null;
    if (syncing.apps.length) {
      linksStateToasterData = this.buildSyncingLinksToasterData(syncing);
    } else {
      const res = this.buildStaleLinksToasterData(stale);
      onDestroy = res?.onDestroy;
      linksStateToasterData = res?.toaster;
    }

    return { linksStateToasterData, onDestroy };
  }

  handleDataChanges(data: FilterChangeData) {
    const name = data.name;
    if (data.action === 'ClearAll') {
      this.filtersService.setFilters(name, [], 'pre');
      return;
    }
    if (name === 'assistant-type') {
      this.filtersService.removeAllFilters('all', false, ['assistant-createdBy']);
    }
    let addTag = false;
    let values: string[] = data.current?.values.map((v) => v.value) || [];
    if (data.supportTag) {
      if (['Add', 'Set'].includes(data.action)) {
        if (this.isDefaultSearch() || this.filtersService.hasSingleFilterTag(name)) {
          addTag = true;
        } else {
          const tagValues = this.filtersService.tagFilters[name];
          if (tagValues?.length) {
            const changesValuesExist: boolean = data.changes?.values?.some((v) => tagValues.includes(v.value));
            // Combine the values from tags and the new changes
            if (changesValuesExist) {
              values = values.filter((v) => !tagValues.includes(v));
            } else {
              values.push(...tagValues);
            }
            this.filtersService.convertTagFiltersToRegular();
          }
        }
      } else if (data.action === 'Remove' && this.filtersService.tagFilters[name]) {
        addTag = true;
      }
    }
    this.filtersService.setFilters(data.name, [...new Set(values)], 'pre', addTag);
  }

  private async initExtensionMessage() {
    if (this.isEmbed && (await this.embedService.isExternalWebSite())) {
      this._displayInstallExtensionMessage = false;
      return;
    }
    this._displayInstallExtensionMessage = await this.service.shouldDisplayExtensionMessage();
  }

  private async initStorage() {
    this.storage = this.sessionStorageService.getStore('local', 'account');
    this.storage
      .entry<StaleAppTimestamps>(this.STALE_APPS_STORAGE_KEY)
      .get()
      .then((res) => {
        this._staleAppToastersCleared = res || {};
      });
  }

  initBarItems() {
    this.services
      .observable('results.appbaritems$')
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.barItems$.next(items);
      });
  }

  async openCollection() {
    this.eventsService.event('collections.search_bar', {
      location: { title: this.hubService.currentLocation },
    });
    const preFilters = this.filtersService.getPreFilters(true);
    if (preFilters) {
      switch (this.navTreeService.activeNode) {
        case 'mail':
          delete preFilters['anyLabel'];
          this.fixAppFilter(preFilters);
          break;
        case 'people':
          delete preFilters['app'];
          delete preFilters['class'];
          break;
        case 'files':
          this.fixAppFilter(preFilters);
          break;
        default:
          break;
      }
    }
    const postFilters = this.filtersService.postFilters;
    if (this.navTreeService.activeNode === 'mail') {
      if (postFilters['isRead']) {
        delete postFilters['isRead'];
        postFilters['isRead'] = ['yes'];
      }
      if (postFilters['hasAttachments']) {
        postFilters['hasAttachments'] = ['yes'];
      }
      if (postFilters['hasLinks']) {
        postFilters['hasLinks'] = ['yes'];
      }
      if (postFilters['isStarred']) {
        delete postFilters['isStarred'];
        postFilters['label'] = ['STARRED'];
      }
    }

    this.collectionsService.openCollectionView(<Collections.LiveCollection>{
      kind: 'Live',
      searchParams: {
        filters: { ...preFilters, ...postFilters },
        query: this.hubService.query,
      },
    });
  }

  fixAppFilter(preFilters) {
    const realApps = [];
    const myConnectedApps = this.getFilteredBarApps(this.barItems$.value);

    preFilters['app'].forEach((app) => {
      const found = myConnectedApps.find((myApp) => myApp.name === app && app !== 'This PC');
      if (found) realApps.push(app);
    });
    preFilters['app'] = realApps;
  }
}
