@switch (model?.type) {
  @case ('img') {
    <u-icon
      [class]="model.type + ' ' + (class ?? '')"
      class="icon"
      [model]="{ type: 'img', value: model.value }"
      [styles]="{ width: size, height: size }"
    ></u-icon>
  }
  @case ('font-icon') {
    <ng-container *ngTemplateOutlet="fontIcon"></ng-container>
  }
  @case ('font') {
    <ng-container *ngTemplateOutlet="fontIcon"></ng-container>
  }
  @case ('account') {
    <account-icon
      class="account-icon"
      [class]="model.type + ' ' + (class ?? '')"
      [model]="model.value"
      [size]="size"
      [state]="state"
    ></account-icon>
  }
  @case ('emoji') {
    <u-emoji [spriteEmojiSize]="spriteEmojiSize" [unicode]="model.value"></u-emoji>
  }
}

<ng-template #fontIcon>
  <u-icon
    [class]="(mirrorClass ? (class ?? '') : '') + ' ' + model.type"
    class="icon"
    [style.font-size]="size"
    [model]="{ type: 'font', value: model.value || 'font-icon icon-question-mark-circle' }"
    [styles]="completedStyle"
  ></u-icon>
</ng-template>
