import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  RendererFactory2,
  ViewChild,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Config } from '@environments/config';
import { NavTree, Omnibox, Search, SessionInfo, User as UserInt, Window, Workspace } from '@local/client-contracts';
import { Constants, ManualPromise, performanceCheckpoint } from '@local/common';
import { isEmbed, isFirefox, isMac, isNativeWindow, isExtension } from '@local/common-web';
import {
  KeyName,
  clamp,
  getModifiers,
  isCopyEvent,
  isEnterKey,
  isKey,
  isModifierKey,
  isPrintableKey,
  isSelectAllBeforeEvent,
  isSelectAllEvent,
  isSelectNextEvent,
  isSelectPreviousEvent,
  isSemicolonKey,
  keyCodes,
} from '@local/ts-infra';
import { DynamicComponentBase, PopupRef, PopupService, STYLE_SERVICE, UiIconModel } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmbedOptions, EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { KeyIconPipe } from '@shared/pipes';
import { EventInfo, EventsService, InternetService, LogService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { BannerService } from '@shared/services/banner.service';
import { ClientStorageService } from '@shared/services/client-storage.service';
import { FlagsService } from '@shared/services/flags.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { LinksService } from '@shared/services/links.service';
import { LocalActionsRegistrator } from '@shared/services/local-actions-registrator';
import { RouterService } from '@shared/services/router.service';
import { NativeMainRpcService, ServicesRpcService, componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { TimerService } from '@shared/services/timer.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { WalkthroughService } from '@shared/services/walkthrough.service';
import { WindowService } from '@shared/services/window.service';
import { isClickInElement, SCREEN_SIZE_MAX_WIDTH, windowSizeObserver } from '@shared/utils';
import { getWidthBreakpointScreen } from '@shared/utils/break-point.utils';
import { isTruncated } from '@shared/utils/elements-util';
import { SplitComponent } from 'angular-split';
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom, fromEvent, take, throttleTime } from 'rxjs';
import { debounceTime, filter, map, pairwise } from 'rxjs/operators';
import { AttributesResolver } from '../../../routes/attributes-resolver.service';
import { SuggestResyncOverlayComponent } from '../../components/suggest-resync-overlay/suggest-resync-overlay.component';
import { SuggestionsAutocompleteComponent } from '../../components/suggestions-autocomplete/suggestions-autocomplete.component';
import { SuggestionsDropdownComponent } from '../../components/suggestions-dropdown/suggestions-dropdown.component';
import { TagListComponent } from '../../components/suggestions-tags/tag-list/tag-list.component';
import { FyiService, WorkspacesService } from '../../services';
import { CommandBarService } from '../../services/command-bar.service';
import { FiltersService } from '../../services/filters.service';
import { GoLinksService } from '../../services/go-links.service';
import { HelpPopupService } from '../../services/help-popup.service';
import { FocusState, HubService } from '../../services/hub.service';
import { NavTreeService } from '../../services/nav-tree.service';
import { PricingService } from '../../services/pricing.service';
import { AddCollectionToTabPopupService } from '../../services/select-items/add-collection-to-tab-popup.service';
import { AddToCollectionPopupService } from '../../services/select-items/add-to-collection-popup.service';
import { MoveCollectionWidgetToTabPopupService } from '../../services/select-items/move-collection-widget-to-tab-popup.service';
import { MoveHeaderWidgetToTabPopupService } from '../../services/select-items/move-header-widget-to-tab-popup.service';
import { MovePostWidgetToTabPopupService } from '../../services/select-items/move-post-widget-to-tab-popup.service';
import { SidebarService } from '../../services/sidebar.service';
import { SuggestionsService } from '../../services/suggestions/suggestions.service';
import { TagsService } from '../../services/tags.service';
import { UrlPreviewService } from '../../services/url-preview.service';
import { MailService } from '../preview/mail-preview/services/mail.service';
import { TelemetryTrigger } from '../results';
import { OptionsPopupComponent, OptionsPopupData } from './components/launcher-avatar-popup/options-popup.component';
import { MenuItem, MenuItemOrder, WEB_SEARCH_ITEMS } from './shared/sidebar/menu-items';
import { SEARCH_WITH_FILTERS_PLACEHOLDER } from '../../utils/constants';
import { WikiItemSelectionPopupService } from '../collections-page/services/wiki-item-selection-popup.service';
import { SearchParamName } from '../../services/search-params.service';

export const SUGGESTION_CHAR = ':';
export const MAX_CHARACTERS = 500;
export interface PageSuggestionData {
  url: string;
}
export type SidebarState = 'closed' | 'opened';

const ACTIONS_SHORTCUT_ENABLED = false;
@UntilDestroy()
@Component({
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubComponent implements OnInit, OnDestroy, AfterViewInit, DynamicComponentBase<any> {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  @ViewChild('main') mainRef: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;
  @ViewChild('inputContainer') inputContainerRef: ElementRef;
  @ViewChild('autoComplete') autoCompleteRef: ElementRef;
  @ViewChild('overlayObserver') overlayObserver: ElementRef;
  @ViewChild('tagList') tagList: TagListComponent;
  @ViewChild('tagList', { read: ElementRef }) tagListRef: ElementRef;
  @ViewChild('contentPanel') panel: ElementRef;
  @ViewChild(SuggestionsAutocompleteComponent) autoCompleteComp: SuggestionsAutocompleteComponent;
  @ViewChild('asSplit') asSplit: SplitComponent;
  @ViewChild('launcherAvatarElement') avatarElement: ElementRef;
  @ViewChild('embeddedAvatarElement') embeddedAvatarElement: ElementRef;

  data: any;
  componentFather: any;

  bannerText: string;
  showTrialBanner: boolean;

  controls: Window.Controls[];
  searchResponse: Search.Response;
  heightObserver: any;
  preventSearch$: Observable<boolean>;
  omniboxCursorPosition = 0;
  errorCount: number;
  destroyed: boolean;
  preserveInput: string;
  loading: boolean;
  hideSearch = true;
  animatePanel: 'open' | 'close';
  isLauncher: boolean;
  isBarMode: boolean;
  searchBarBorderRadius: number;
  private logger: any;
  private optionsPopupRef: PopupRef<OptionsPopupComponent, OptionsPopupData>;
  private resyncShown$ = new BehaviorSubject<boolean>(undefined);

  hideCursor: boolean;
  panelHeight = 0;
  autoComplete$ = new BehaviorSubject<Omnibox.Suggestion>(null);
  private intercomLoaded: boolean;
  sidebarState: SidebarState = 'closed';
  showToggleButton = false;
  isHoveringOnToggleButton = false;
  isHoveringOnSidebar = false;
  menuWidth: number;
  maxMenuWidth: number;
  toggleButtonPosition: string;
  toggleButtonSide: 'right' | 'left';
  workspace: Workspace.Workspace;
  ownerOrAdmin: boolean;
  showSideBar: boolean;
  workspaceIcon: UiIconModel;
  lastDragSize;
  sidebarOrder: number;
  mainOrder: number;
  isMac: boolean;
  isLoaded: boolean;
  onTrialPlan: boolean;
  SIDEBAR_MIN_WIDTH: number;
  SIDEBAR_MAX_WIDTH: number;
  tooltipClearTemp = 'Clear search<div class="clear-tooltip-shortcut">Esc</div>';
  readonly MINIMIZED_SIDEBAR_WIDTH = 48;
  readonly isEmbed: boolean = isEmbed();
  readonly isExtension: boolean = isExtension();
  planDaysLeft: number;
  private user: UserInt.Info;
  private resizeObserver;
  isNative: boolean;
  private afterViewInit = new ManualPromise<void>();
  private pages: MenuItem[] = [];
  externalEmbed = true;
  private mockErrorId: string;

  screenMaximized: boolean;
  keyHandlerId: string;
  isSidebarInit: boolean;
  private _inputQuery: string;
  private readonly SEARCH_PAGE_ROUTE: string = 'search';
  private readonly ACTIVE_PAGE_BLACKLIST = ['home', 'search', 'calendar', 'chat', 'drafts'];
  private readonly NON_REMOVABLE_PARAMS: SearchParamName[] = ['assistantId'];
  readonly isNativeWindow = isNativeWindow();
  displayEnterIndication = false;
  showBranding: boolean;
  embedInline: boolean;
  inlineEmbedCleanBackground: boolean;
  isSearchPage: boolean;
  hideSearch$: Subscription;
  isOnline$: Observable<boolean>;
  private activeComponent: any;
  private scrolledSubmitted: boolean;
  disableCollection: boolean;

  renderer: any;

  private get allPages() {
    return [...this.pages, ...WEB_SEARCH_ITEMS];
  }

  get redirectOnEnter() {
    return this.isLauncher;
  }

  get inputQuery() {
    return this._inputQuery;
  }

  set inputQuery(value: string) {
    this.hubService.inputQuery = value;
    this._inputQuery = value;
    if (!value?.trim()) this.autoComplete$.next(null);
  }

  constructor(
    private ngzone: NgZone,
    private internet: InternetService,
    rendererFactory: RendererFactory2,
    private cdr: ChangeDetectorRef,
    logger: LogService,
    private services: ServicesRpcService,
    private linksService: LinksService,
    private window: WindowService,
    private eventsService: EventsService,
    public hubService: HubService,
    private suggestionService: SuggestionsService,
    private tagsService: TagsService,
    private keyboardService: KeyboardService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    private sessionService: SessionService,
    private popupService: PopupService,
    private walkthroughService: WalkthroughService,
    public routingService: RouterService,
    localActionRegistrator: LocalActionsRegistrator,
    private commandBarService: CommandBarService,
    private clientStorage: ClientStorageService,
    private loaderService: LoaderService,
    private appService: AppService,
    private sidebarService: SidebarService,
    private documentService: AttributesResolver,
    private embedService: EmbedService,
    private navTreeService: NavTreeService,
    private filtersService: FiltersService,
    private mailService: MailService,
    private timerService: TimerService,
    private mainRpc: NativeMainRpcService,
    private bannerService: BannerService,
    private goLinksService: GoLinksService,
    public titleBarService: TitleBarService,
    private fyiService: FyiService,
    private helpPopupService: HelpPopupService,
    private workspaceService: WorkspacesService,
    private pricingService: PricingService,
    private flagsService: FlagsService,
    private urlPreviewService: UrlPreviewService, //for init service purposes
    private moveCollectionWidgetToTabPopupService: MoveCollectionWidgetToTabPopupService, //for init service purposes
    private addToCollectionPopupService: AddToCollectionPopupService, //for init service purposes
    private addCollectionToTabPopupService: AddCollectionToTabPopupService, //for init service purposes
    private moveHeaderWidgetToTabPopupService: MoveHeaderWidgetToTabPopupService, //for init service purposes
    private movePostWidgetToTabPopupService: MovePostWidgetToTabPopupService, //for init service purposes
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService
  ) {
    this.isLoaded = false;
    this.preventSearch$ = this.hubService.preventSearch$;
    this.animatePanel = this.hubService.panelVisible ? 'open' : 'close';
    this.hideSearch$ = this.hubService.readOnly$
      .pipe(
        pairwise(),
        map(([_, n]) => n)
      )
      .subscribe((v) => {
        this.hideSearch = v;
        this.updateKeyboardRegistration(!v);
        setTimeout(() => {
          this.cdr.markForCheck();
        }, 0);
      });

    performanceCheckpoint('time-to-hub-constructor');

    if (this.window) {
      this.window.maximized$.pipe(untilDestroyed(this)).subscribe((maximized) => (this.screenMaximized = maximized));
    }

    this.timerService.register(
      () => {
        this.mailService.refreshInbox();
      },
      { focusInterval: 60000 }
    );

    this.isMac = isMac();
    this.tags$ = this.tagsService.all$;
    this.logger = logger.scope('BarComponent');
    this.renderer = rendererFactory.createRenderer(null, null);
    performanceCheckpoint('hub_constructor');

    if (this.embedService) {
      this.embedService.show$.pipe(untilDestroyed(this)).subscribe(() => {
        setTimeout(() => this.focusSearchInput(), 100);
      });
    }
    localActionRegistrator.init();
    this.updateKeyboardRegistration(true);
    this.syncSuggestionsWithCommandBar();

    if (this.isNative) {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          this.goLinksService.refresh();
          setTimeout(() => {
            this.selectAll();
          }, 100);
        }
      });
    }
  }

  getWindowSize() {
    windowSizeObserver()
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.handleScreenSizeChange(value));
  }

  updateKeyboardRegistration(enable: boolean) {
    if (enable && !this.keyHandlerId) {
      this.keyHandlerId = this.keyboardService.registerKeyHandler(
        (keys: Array<KeyName>, event: CustomKeyboardEvent) => this.handleKeys(keys, event),
        4
      );
    }

    if (!enable) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
      this.keyHandlerId = null;
    }
  }

  get menuVisible(): boolean {
    return true;
  }

  get readOnly$(): Observable<boolean> {
    return this.hubService.readOnly$;
  }

  get toggleTooltip(): string {
    if (this.hubService.windowStyle != 'standard') return 'Full app';
    return this.sidebarState === 'opened' ? 'Close sidebar' : 'Open sidebar';
  }

  private setSidebar(): void {
    if (this.sidebarState !== 'closed') {
      this.updateSidebarWidth();
    } else {
      this.updateSidebarWidth(this.MINIMIZED_SIDEBAR_WIDTH, true);
    }
    this.sidebarService.toggleSidebarState(this.sidebarState === 'opened' ? 'open' : 'close');
    if (!this.hubService.disableLoaderRemoval) {
      this.loaderService.ready$.next(true);
    }
    this.isSidebarInit = true;
  }

  private handleScreenSizeChange(value: { width: number }) {
    const isSmallScreen = value.width <= SCREEN_SIZE_MAX_WIDTH.medium;
    if (isSmallScreen) {
      this.sidebarState = 'closed';
      this.updateSidebarWidth(this.MINIMIZED_SIDEBAR_WIDTH, true);
      this.sidebarService.toggleSidebarState('close');
      this.cdr.markForCheck();
    }
  }

  toggleSidebar(): void {
    if (this.hubService.windowStyle != 'standard') {
      const location = this.hubService.currentLocation;
      this.eventsService.event('full_app', { location: { title: location } });
      this.appService.switchToStandard();
    } else if (this.sidebarState === 'opened') {
      this.sidebarState = 'closed';
      this.updateSidebarWidth(this.MINIMIZED_SIDEBAR_WIDTH, true);
    } else if (this.sidebarState === 'closed') {
      this.sidebarState = 'opened';
      this.updateSidebarWidth();
    }
    this.lastDragSize = this.menuWidth;

    const isSidebarOpen = this.sidebarState === 'opened';
    const state = isSidebarOpen ? 'open' : 'close';
    const target = isSidebarOpen ? 'expand_panel' : 'collapse_panel';
    this.sidebarService.toggleSidebarState(state);
    const location: string = this.hubService.currentLocation;
    this.eventsService.event('side_panel', { target, location: { title: location } });
    this.clientStorage.set('sidebarState', this.sidebarState);
  }

  onMouseleaveSidebar(): void {
    this.isHoveringOnSidebar = false;
    setTimeout(() => {
      this.showToggleButton = this.isHoveringOnToggleButton;
      this.cdr.markForCheck();
    }, 200);
  }

  onMouseEnterSidebar(): void {
    this.isHoveringOnSidebar = true;
    this.showToggleButton = true;
  }

  onMouseLeaveButton(): void {
    this.isHoveringOnToggleButton = false;
    setTimeout(() => {
      this.showToggleButton = this.isHoveringOnSidebar;
      this.cdr.markForCheck();
    }, 200);
  }

  onMousenterToggleButton(): void {
    this.isHoveringOnToggleButton = true;
    this.showToggleButton = true;
  }

  private async initSidebarView() {
    await this.afterViewInit;
    if (this.isEmbed) {
      const options = await firstValueFrom(this.embedService.options$);
      const sidebar = options?.popup?.sidebar || options?.sidebar;
      if (sidebar?.style === 'hidden' || options?.type != 'app') {
        return;
      }
    }
    setTimeout(() => {
      document
        .getElementById('split')
        .getElementsByClassName('as-split-gutter')[0]
        .addEventListener('dblclick', () => {
          this.sidebarState = 'opened';
          this.updateSidebarWidth();
        });

      this.asSplit.dragProgress$.pipe(untilDestroyed(this)).subscribe((drag) => {
        const currentSidebarSize = Number(drag.sizes[0]);
        if (currentSidebarSize < this.SIDEBAR_MIN_WIDTH && currentSidebarSize <= this.lastDragSize) {
          if (this.menuWidth === this.MINIMIZED_SIDEBAR_WIDTH) {
            // we need to update the menuWith because it triggers the dragEnd event in angular-split
            this.menuWidth = currentSidebarSize;
            this.cdr.detectChanges();
          }
          this.updateSidebarWidth(this.MINIMIZED_SIDEBAR_WIDTH, true);
          this.sidebarState = 'closed';
          this.cdr.detectChanges();
        } else if (currentSidebarSize > this.lastDragSize && currentSidebarSize > this.MINIMIZED_SIDEBAR_WIDTH) {
          if (this.sidebarState === 'closed') {
            this.sidebarState = 'opened';
            this.cdr.detectChanges();
          } else if (this.sidebarState === 'opened') {
            this.updateSidebarWidth(currentSidebarSize, true);
          }
          this.lastDragSize = currentSidebarSize;
        } else if (this.sidebarState === 'opened') {
          // toggleButton's position is 13px from the sidebar's size
          this.toggleButtonPosition = currentSidebarSize - 13 + 'px';
          this.lastDragSize = currentSidebarSize;
        }
        this.sidebarService.toggleSidebarState(this.sidebarState === 'opened' ? 'open' : 'close');
        this.clientStorage.set('sidebarState', this.sidebarState);
      });

      // ensure that we display the container panel only if there is content inside
      this.hubService.panelVisible$.pipe(untilDestroyed(this)).subscribe((v) => {
        this.animatePanel = v ? 'open' : 'close';
      });

      if (this.panel?.nativeElement) {
        this.resizeObserver = new (<any>window).ResizeObserver(() => {
          this.panelHeight = this.panel.nativeElement.firstElementChild.offsetHeight;
        });
        this.resizeObserver.observe(this.panel.nativeElement);
      }
    }, 0);
  }

  /** The sidebar should be 18% of the initial app width (which is defined by the screen)
   * This fn define this as global variable.
   * We must use the global variable since other layout stuff are relying on it
   */
  private initSidebarWidthLogic() {
    fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        throttleTime(20),
        untilDestroyed(this)
      )
      .subscribe((width: number) => {
        if (this.sidebarState === 'opened') this.updateSidebarWidth(width);
      });

    this.updateSidebarWidth(window.innerWidth);
  }

  updateSidebarWidth(width?: number, useWidth?: boolean) {
    const screenWidth = window.innerWidth;
    if (!width) width = screenWidth;
    const newWidth = useWidth ? width : clamp(this.SIDEBAR_MIN_WIDTH, width * 0.18, this.SIDEBAR_MAX_WIDTH);

    document.documentElement.style.setProperty('--menu-width', newWidth + 'px');
    this.menuWidth = newWidth;
    this.maxMenuWidth = clamp(this.SIDEBAR_MIN_WIDTH, screenWidth * 0.18, this.SIDEBAR_MAX_WIDTH);
    this.toggleButtonPosition = newWidth - 13 + 'px';
    this.lastDragSize = this.menuWidth;
    this.cdr.markForCheck();
  }

  private setBarPlaceholder(): void {
    this.hubService.placeholder = SEARCH_WITH_FILTERS_PLACEHOLDER;
  }

  private reportsNotifications() {
    // report only once to telemetry
    this.isOnline$
      .pipe(
        filter((v) => v === false),
        take(1)
      )
      .subscribe(() => this.eventsService.event('hub_error', { category: 'notification_popup', name: 'internet_error' }));
  }

  onNavigation($event: Component) {
    this.activeComponent = $event;
    this.setBarPlaceholder();
  }

  get scrollVisible() {
    return this.activeComponent;
  }

  private syncSuggestionsWithCommandBar() {
    combineLatest([this.commandBarService.select$, this.routingService.params$.pipe(map((p) => p['engine']))])
      .pipe(
        untilDestroyed(this),
        filter(([{ item }, e]) => item?.id?.includes('web-search') && !!e)
      )
      .subscribe(([_, engine]) => {
        const i = WEB_SEARCH_ITEMS.filter((p) => p.id.split('web-search_')[1] === engine)[0];
        if (i) {
          this.tagsService.all = [this.hubService.suggestionsToTag(i)];
        }
      });
  }

  private menuItemToSuggestion(page: MenuItem): Omnibox.Suggestion {
    return {
      id: 'page:' + page.id,
      icon: page.icon,
      title: page.title,
      // keywords: [],
      type: 'page',
      data: { ...page.data, url: page.page, clickTrack: page.clickTrack, showLabelOnTag: true },
    };
  }

  getPageSuggestions(term: string, suggestionContext: 'autocomplete' | 'box', filterBy: 'title' | 'label' = 'title'): Omnibox.Suggestion[] {
    const currentPage = this.hubService.activePage;
    let suggestions = this.allPages
      .filter((p) => {
        const split = p.id.split('web-search_');
        if (split.length === 1) {
          return split[0] !== currentPage;
        }
        return split[1] !== this.routingService.params['engine'];
      })
      .sort((a, b) => {
        if (a.displayOrder === b.displayOrder) return a.title.localeCompare(b.title);
        return a.displayOrder - b.displayOrder;
      })
      .map((p) => this.menuItemToSuggestion(p));
    if (term) {
      suggestions = suggestions.filter((model) => {
        const content = model.title;
        const itemWords = (content + ' ' + content.replace(/([a-z])([A-Z])/g, '$1 $2'))
          .split(/[\s,\-_.@/`]+/)
          .map((a) => a.toLowerCase())
          .filter((a) => a);

        const queryWords = term
          .toLowerCase()
          .split(' ')
          .filter((word) => word.length > 0);

        const res = queryWords.every((q) => itemWords.some((a) => a.toLocaleLowerCase().startsWith(q)));
        if (filterBy === 'label') {
          const labelText = suggestionContext == 'box' ? model.label || '' : '';
          const itemWords = (labelText + ' ' + labelText.replace(/([a-z])([A-Z])/g, '$1 $2'))
            .split(/[\s,\-_.@/`]+/)
            .map((a) => a.toLowerCase())
            .filter((a) => a);
          return res ? false : queryWords.every((q) => itemWords.some((a) => a.toLocaleLowerCase().startsWith(q)));
        }
        return res;
      });

      suggestions = suggestions
        .map((model) => {
          let index = model.title.toLocaleLowerCase().indexOf(term);
          let rank = 0;
          if (index == -1) {
            rank++;
            // if (model.keywords?.length)
            //   index = Math.min(...model.keywords.map((k) => k.toLocaleLowerCase().indexOf(term)).filter((s) => s != -1));
            if (index == -1 && model.label) {
              rank++;
              index = model.label.toLocaleLowerCase().indexOf(term);
            }
          }
          return { rank, index, model };
        })
        .sort((m1, m2) => {
          if (m1.rank != m2.rank) return m1.rank - m2.rank;
          if (m1.index != m2.index) return m1.index - m2.index;
          if (m1.model.title.length != m2.model.title.length) return m1.model.title.length - m2.model.title.length;
          return suggestions.indexOf(m1.model) - suggestions.indexOf(m2.model);
        })
        .map((m) => m.model);
    }
    return suggestions;
  }

  getBoxSuggestions = (term: string, group: string) => {
    if (!this.hubService.suggestionsEnabled) {
      return [];
    }
    return this.getSuggestions(term, group, 'box');
  };

  private async getSuggestions(term: string, group: string, context: 'box' | 'autocomplete'): Promise<Omnibox.Suggestion[]> {
    let suggestions: Omnibox.Suggestion[] = [];

    if (this.routingService.active === 'web-search' || group == 'group:page') return this.getPageSuggestions(term, context);

    if (this.suggestionService.provider) {
      suggestions = await this.suggestionService.provider(term, group, context);

      if (this.routingService.active !== this.hubService.defaultPage) {
        return suggestions;
      }
    }

    if (!group && this.hubService.enablePagesSuggestion) {
      const suggestPage = term?.slice(Math.max(0, term.lastIndexOf(' ')))?.trim() && !this.tagsService.all?.length;
      if (context == 'box' || suggestPage) {
        const pages = this.getPageSuggestions(term, context);
        if (!term) {
          suggestions = suggestions ? suggestions.concat(pages) : pages;
        } else {
          const pagesByType = this.getPageSuggestions(term, context, 'label');
          suggestions = [...pages, ...suggestions, ...pagesByType];
        }
      }
    }

    return suggestions;
  }

  dragEnd(event): void {
    const size = Number(event.sizes[0]);
    if (size < this.SIDEBAR_MIN_WIDTH && this.sidebarState === 'opened') {
      this.updateSidebarWidth(this.SIDEBAR_MIN_WIDTH, true);
    } else if (size >= this.SIDEBAR_MIN_WIDTH) {
      this.updateSidebarWidth(size, true);
    }
  }

  async ngAfterViewInit() {
    this.afterViewInit.resolve();
    this.asSplit.startKeyboardDrag = (event: KeyboardEvent, gutterOrder: number, gutterNum: number) => {}; // disable keyboard navigation

    performanceCheckpoint('ng_after_view_ready');
    this.ngzone.runOutsideAngular(() => {
      combineLatest([
        this.hubService.intercom$,
        this.sessionService.current$,
        this.walkthroughService.activeTour$,
        this.hubService.overlayShown$,
      ]).subscribe(async ([enabled, s, activeTour, overlayShown]) => {
        if (enabled && !activeTour && !overlayShown && s && !this.isLauncher) {
          this.initIntercom(s?.user);
          this.intercomLoaded = true;
        } else {
          this.hideIntercom();
          this.intercomLoaded = false;
        }
      });
    });

    this.focusSearchInput();
  }

  tags$: Observable<Omnibox.Tag[]>;

  get suggestionQuery() {
    return this.hubService.suggestionQuery;
  }

  set suggestionQuery(value: string) {
    this.hubService.suggestionQuery = value;
  }

  get searchInputEl(): HTMLInputElement {
    return this.searchInputRef?.nativeElement;
  }

  get showAutoComplete() {
    if (!this.searchInputEl) return;
    return setTimeout(() => {
      if (!this.searchInputEl) {
        return;
      }
      return !isTruncated(this.searchInputEl);
    }, 50);
  }

  private focusSearchInput(value = true, force = false) {
    if (value) {
      if (this.hubService.canFocus(force)) {
        this.searchInputEl?.focus();
      }
    } else {
      this.searchInputEl?.blur();
    }
    this.cdr.markForCheck();
  }

  initActivation() {
    window.addEventListener('blur', () => {
      this.setSuggestionsDropdownVisibility(false, { preserveChar: null }, 'external_click');
      this.cdr.markForCheck();
    });
  }

  /** keyResolvers will work only if the key is in the placeholder example: `<b>/</b>` */
  onPlaceholderClick(event: MouseEvent): void {
    const location: string = this.hubService.currentLocation;
    const target: HTMLElement = event.target as HTMLElement;
    if (target.nodeName !== 'B') return;

    const shortcut = target.innerText;

    const keyResolvers: Record<string, { name: string; callback: CallableFunction }> = {
      ';': {
        callback: () => {
          const keyboardEvent = new KeyboardEvent('keydown', { key: ';', code: 'semicolon' });
          this.keyboardService.simulateClick(<any>keyboardEvent);
        },

        name: 'filters',
      },
    };

    if (ACTIONS_SHORTCUT_ENABLED) {
      keyResolvers['/'] = {
        name: 'actions',
        callback: () => {
          this.openPage('?actionsOnly=true', 'placeholder', 'mouse_click');
        },
      };
    }

    let label: string;
    if (keyResolvers[shortcut]) {
      keyResolvers[shortcut].callback();
      label = keyResolvers[shortcut].name;
    } else {
      const keys = new KeyIconPipe().reverse(shortcut).map((k) => k.toLocaleLowerCase());
      const page = this.allPages.find(
        (item) =>
          item.shortcuts &&
          item.shortcuts.some((s) => s.length === keys.length && s.every((key) => keys.indexOf(key.toLocaleLowerCase()) !== -1))
      );
      if (page) {
        this.openPage(page.page, page.clickTrack, 'mouse_click');
        label = page.title;
      }
    }
    this.eventsService.event('search_bar.placeholder', { target: shortcut, label: label?.toLowerCase(), location: { title: location } });
  }

  ngOnInit() {
    this.loaderService.ready$.pipe(untilDestroyed(this)).subscribe((res: boolean) => {
      this.isLoaded = res;
      this.cdr.markForCheck();
    });
    performanceCheckpoint('hub-init');
    this.getWindowSize();
    this.isOnline$ = this.internet.isOnline$;
    this.services.observable('mock.error$').subscribe(async (id) => {
      const field = 'mock.error';
      const oldId = this.mockErrorId || (await this.clientStorage.get(field));
      if (id === oldId) {
        return;
      }
      this.mockErrorId = id;
      this.clientStorage.set(field, id);
      setTimeout(() => {
        throw new Error('Mock Error: Happy Testing!');
      }, 200);
    });

    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((session) => !!session)
      )
      .subscribe((s: SessionInfo) => {
        this.user = s.user;
        this.workspace = s.workspace;
        const value = this.workspaceService.getLogo();
        this.workspaceIcon = { type: 'img', value };
        this.cdr.markForCheck();
      });

    combineLatest([this.workspaceService.onTrial$, this.workspaceService.ownerOrAdmin$, this.workspaceService.trialDaysLeft$])
      .pipe(untilDestroyed(this))
      .subscribe(([onTrial, ownerOrAdmin, daysLeft]) => {
        this.ownerOrAdmin = ownerOrAdmin;
        this.planDaysLeft = daysLeft;
        this.onTrialPlan = onTrial;
        this.bannerText = `${daysLeft} DAYS LEFT`;
        this.cdr.markForCheck();
      });

    this.hubService.isLauncher$.pipe(untilDestroyed(this)).subscribe(async (isLauncher) => {
      this.isLauncher = isLauncher;
      this.isBarMode = this.hubService.isBarMode;
      this.showSideBar = !this.isLauncher;

      if (this.isEmbed) {
        const opts = await firstValueFrom(this.embedService.options$);
        this.isSearchPage = opts?.type === 'search-page';
        const sidebar = opts?.popup?.sidebar || opts?.sidebar;
        this.showSideBar = this.showSideBar && sidebar?.style != 'hidden' && opts?.type === 'app';
        const isExternalWebSite = await this.embedService.isExternalWebSite();
        const showBranding = await this.embedService.isShowBranding();
        this.showBranding = isExternalWebSite && showBranding && !this.isExtension;
        this.embedInline = await this.embedService?.isInline();
        this.inlineEmbedCleanBackground = this.embedInline && sidebar?.style === 'hidden';
      }

      this.documentService.addAttribute('windowType', this.isLauncher ? 'launcher' : 'app');
      this.documentService.addAttribute('is-embed', this.isEmbed?.toString());

      this.sidebarOrder = this.hubService.windowStyle === 'right-side-bar' ? 1 : 0;
      this.mainOrder = this.hubService.windowStyle === 'right-side-bar' ? 0 : 1;
      this.toggleButtonSide = this.hubService.windowStyle === 'right-side-bar' ? 'right' : 'left';
      this.SIDEBAR_MIN_WIDTH = this.isLauncher ? 48 : 162;
      this.SIDEBAR_MAX_WIDTH = this.isLauncher ? 48 : 230;

      if (this.isLauncher) {
        this.lastDragSize = this.MINIMIZED_SIDEBAR_WIDTH;
        this.setSidebar();
      } else {
        this.clientStorage.get('sidebarState').then((v: 'opened' | 'closed') => {
          this.sidebarState = v ?? 'closed';
          this.lastDragSize = this.sidebarState === 'closed' ? this.MINIMIZED_SIDEBAR_WIDTH : this.SIDEBAR_MIN_WIDTH;
          this.setSidebar();
        });
      }

      if (!this.isLauncher) {
        this.reportsNotifications();
        this.initSidebarWidthLogic();
        this.initSidebarView();
      }

      this.initOverlays();
      this.cdr.markForCheck();
    });

    this.embedService?.isExternalWebSite().then((v) => {
      this.externalEmbed = v;
      this.cdr.markForCheck();
    });
    this.embedService?.options$.pipe(untilDestroyed(this)).subscribe((o: EmbedOptions) => {
      this.searchBarBorderRadius = o?.popup?.borderRadius;
      this.cdr.markForCheck();
    });

    this.navTreeService.all$.pipe(untilDestroyed(this)).subscribe((all: NavTree.Node[]) => {
      this.pages = all
        .filter((a) => !this.ACTIVE_PAGE_BLACKLIST.includes(a.id.toLowerCase()) && (!a.parentId || !!a.data?.autoComplete))
        .map((p) => ({
          id: p.id,
          page: p.id,
          clickTrack: `hub_side_menu_${p.id}`,
          displayOrder: MenuItemOrder.Page,
          type: 'primary',
          icon: p.icon,
          title: typeof p.data?.autoComplete === 'string' ? p.data?.autoComplete : p.title,
          subtitle: 'Page',
          data: { label: 'active-page' },
        }));
    });

    this.hubService.inputQuery$.pipe(untilDestroyed(this)).subscribe((query: string) => {
      this.checkDisplayEnterIndication();
      if (this._inputQuery !== query) {
        if (!this.hubService.autoFocus) {
          this.hubService.autoCompleteEnabled = this.hubService.focusInputState;
        }
        this._inputQuery = query;
        this.cdr.markForCheck();
      }
    });
    this.initActivation();
    this.initStyle();
    this.initAutoComplete();
    this.initPageState();

    this.initFocus();
    this.initLoading();
  }

  private initOverlays() {
    combineLatest([
      this.hubService.instructionsShown$.pipe(filter((a) => a != undefined)),
      this.resyncShown$.pipe(filter((a) => a != undefined)),
    ]).subscribe(([shown1, shown2]) => {
      this.hubService.overlayShown = shown1 || shown2;
    });
    this.hubService.initInstructions();
    this.initResyncSuggest();
  }

  private initLoading() {
    this.hubService.loading$.pipe(debounceTime(200), untilDestroyed(this)).subscribe((l: boolean) => {
      this.loading = l;
      this.cdr.markForCheck();
    });
  }

  private initFocus() {
    if (this.isEmbed) {
      this.embedService.activate$.pipe(untilDestroyed(this)).subscribe((isActive) => {
        if (isActive) {
          this.searchInputEl?.focus();
        }
      });
    }
    this.hubService.changeFocusInput$.pipe(untilDestroyed(this)).subscribe((res: FocusState) => {
      if (res.multi) {
        let maxFocusRetry = 10;
        const interval = setInterval(() => {
          if (!this.hubService.canFocus()) return;
          this.searchInputEl?.focus();
          if (maxFocusRetry === 0 || document.activeElement === this.searchInputEl) {
            clearInterval(interval);
          } else {
            maxFocusRetry--;
          }
        }, 50);
      } else {
        this.focusSearchInput(res.value, res.force);
      }
    });
  }

  initResyncSuggest() {
    combineLatest([this.sessionService.current$, this.linksService.all$]).subscribe(([session, links]) => {
      if (!links) return;
      const remoteLinks = links.filter((link) => link.appId !== 'pc');
      if (!session?.user?.suspended || remoteLinks.length === 0) {
        this.resyncShown$.next(false);
        return;
      }
      this.hubService.showDialog(SuggestResyncOverlayComponent, 'SuggestResync', this.resyncShown$, { backdropStyle: 'blur-5' });
    });
  }

  initStyle() {
    this.styleService.style$.pipe(untilDestroyed(this)).subscribe((style) => {
      Object.keys(style).forEach((property) => {
        document.body.style.setProperty(property, style[property]);
      });
    });
  }

  private initAutoComplete() {
    let cid = 0;
    const update$ = combineLatest([this.hubService.inputQuery$, this.hubService.autoCompleteEnabled$]).pipe(untilDestroyed(this));

    update$.pipe(untilDestroyed(this)).subscribe(async () => {
      //TODO: move to autocomplete disabled
      const lcid = ++cid;
      if (['web-search', 'apps', 'collections'].includes(this.routingService.active)) {
        if (this.autoComplete$.value) {
          this.autoComplete$.next(null);
        }
        return;
      }
      const query = this.inputQuery;

      if (!this.hubService.autoCompleteEnabled || !query || query.length < 2) {
        if (this.autoComplete$.value) this.autoComplete$.next(null);
        return;
      }
      const atLeastOneChar = /(?=[^A-Za-z0-9]*[A-Za-z0-9])/.test(query);
      if (!atLeastOneChar) {
        this.autoComplete$.next(null);
        return;
      }
      const suggestions = await this.getSuggestions(query, null, 'autocomplete');
      if (lcid != cid) return;
      if (query !== this.inputQuery) return;

      const suggestion = suggestions?.length ? suggestions[0] : null;
      if (suggestion) {
        const telemetryEvent: EventInfo = {
          name: 'impression',
          category: 'auto_complete',
          jsonData: this.getTagEventJsonData(this.tagList.model, { query }),
          location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
        };
        this.sendAutocompleteSuggestionEvent(telemetryEvent, suggestion);
      }
      this.autoComplete$.next(suggestion);
    });
  }

  private async initPageState() {
    combineLatest([this.hubService.state$, this.navTreeService.all$, this.flagsService.all$])
      .pipe(untilDestroyed(this))
      .subscribe(([_, nodes, flags]) => {
        if (flags) {
          this.disableCollection = flags.find((f) => f.flag === Constants.DISABLED_COLLECTIONS_FLAG)?.value;
        }
        const value: string = this.hubService.activePage;
        if (value) {
          const node = nodes.find((n) => n.id === value);
          if (!node) {
            return;
          }
          this.filtersService.routeFilters = node?.data?.filters || {};
          if (this.tagsService.all.some((t) => t.type === 'active-page')) {
            return;
          }
          this.tagsService.all = [
            ...(this.tagsService.all || []),
            {
              id: node.id,
              icon: node.icon,
              label: '',
              title: node.title,
              type: 'active-page',
            },
          ];
        } else if (this.routingService.active === this.SEARCH_PAGE_ROUTE) {
          this.filtersService.routeFilters = {};
          this.tagsService.all = (this.tagsService.all || []).filter((t) => t.type !== 'active-page');
        }
        this.checkDisplayEnterIndication();
      });
  }

  get placeholder$(): Observable<string> {
    return this.hubService.placeholder$;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.services?.destroy();
    this.resizeObserver?.unobserve(this.panel.nativeElement);
  }

  async onPin() {
    const location: string = this.hubService.currentLocation;
    const next = !this.window.pinned;
    this.window.pinned = next;
    this.cdr.markForCheck();
    this.focusSearchInput();
    const event: EventInfo = {
      name: 'search_bar',
      category: 'interaction.click',
      target: 'pin_icon',
      label: `top_most_${next ? 'on' : 'off'}`,
      location: { title: location },
      jsonData: JSON.stringify({ preferences: { general: { autoHide: await firstValueFrom(this.window.autoHide$) } } }),
    };
    this.eventsService.event('search_bar.pin', event);
  }

  toggleDatePickerVisible(v: boolean) {
    this.hubService.suggestionsDropdownVisible = !v;
    this.cdr.markForCheck();
  }

  onSuggestionSelected(suggestion: Omnibox.Suggestion, via?: 'autocomplete' | 'box') {
    this.hubService.removeState('appliedBy', 'system');
    if (!suggestion) return;

    if (suggestion.type == 'page') {
      const id = suggestion.id.substring('page:'.length, suggestion.id.length);
      if (id.startsWith('web-search')) {
        const url = `/${id.split('_').join('/')}`;
        this.routingService.navigateByUrl(url);
      } else if (id === 'analytics' && !this.isEmbed) {
        this.routingService.navigateByUrl(id);
      } else if (suggestion?.data?.label == 'active-page') {
        this.hubService.addActivePage(id);
      }
    } else {
      this.suggestionService.accept(suggestion);
    }

    this.hubService.suggestionsDropdownVisible = false;
    this.searchInputEl.selectionStart = this.searchInputEl?.value?.length;
    setTimeout(() => {
      this.focusSearchInput();
    });

    const telemetryEvent: EventInfo = {
      name: 'select',
      category: via == 'autocomplete' ? 'auto_complete' : 'suggestion_box',
      jsonData: this.getTagEventJsonData([...this.tagList.model, <any>suggestion], { query: this.inputQuery }),
      location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
    };
    this.sendAutocompleteSuggestionEvent(telemetryEvent, suggestion);
  }

  groupSelected(g: string) {
    const currentTags: Omnibox.Tag[] = this.tagList.model;

    const telemetryEvent: EventInfo = {
      name: 'expand_group',
      category: 'suggestion_box',
      label: g.toLowerCase(),
      jsonData: this.getTagEventJsonData(currentTags),
      location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
    };

    this.eventsService.event('suggestions', telemetryEvent);
  }

  onRemoveTag(tag: Omnibox.Tag, currentTags: Omnibox.Tag[] = this.tagList.model) {
    const appliedBy = this.hubService.state['appliedBy'];

    this.hubService.removeState('appliedBy', 'system'); // Remove anyway on this state change

    const telemetryEvent: EventInfo = {
      name: 'remove',
      category: 'suggestion_tags',
      label: tag.type === 'active-page' ? tag.id : tag.id.toLowerCase(),
      jsonData: this.getTagEventJsonData(currentTags.filter((m) => m.id !== tag.id)),
      location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
    };

    this.eventsService.event('suggestion_tags', telemetryEvent);

    const filterSuggestion = currentTags.filter((t) => tag.title !== t.title).map((tag) => ({ type: tag.label, value: tag.title }));
    this.eventsService.event('search.remove_filter', {
      target: `${tag.label}: ${tag.title}`,
      location: {
        title: this.hubService.currentLocation,
      },
      jsonData: JSON.stringify({ filter_suggestion: filterSuggestion }),
    });

    this.tagsService.remove(tag.id);

    if (tag.type === 'launcher-active-page') {
      this.hubService.openPage('search', this.inputQuery, this.hubService.state);
    }

    if (tag.type === 'active-page') {
      this.onPageRemovedTag(tag.id);
    }

    if (appliedBy && appliedBy[0] === 'system') {
      this.hubService.openPage('', this.inputQuery, this.hubService.state);
      return;
    }
    this.focusSearchInput();
  }

  private onPageRemovedTag(tagId: string) {
    if (this.preserveInput) {
      this.hubService.query = this.preserveInput;
      this.preserveInput = undefined;
    }
    if (tagId.includes('web-search')) {
      this.hubService.openPage(this.SEARCH_PAGE_ROUTE);
      return;
    }
    this.hubService.removeActivePage();
    this.filtersService.routeFilters = {};
    return;
  }

  getTagEventJsonData(tags: Omnibox.Tag[], base?: any) {
    const res = base || {};
    Object.assign(res, base);
    Object.assign(res, {
      context: this.hubService.context,
      suggestion_tags: tags.map((tag) => {
        return {
          title: tag.id.split(':').pop().toLowerCase(),
          type: tag.label,
        };
      }),
    });
    return JSON.stringify(res).toLowerCase();
  }

  @HostListener('document:mouseup', ['$event'])
  onDocumentMouseUp() {
    const selectedLength: number = this.searchInputEl ? this.searchInputEl.selectionEnd - this.searchInputEl.selectionStart : 0;
    if (this.hubService.canFocus() && this.searchInputEl && !selectedLength) {
      this.searchInputEl?.focus();
      const cursorPos = this.searchInputEl.selectionEnd;
      this.searchInputEl.setSelectionRange(0, 0); //resets cursor position
      this.searchInputEl.selectionStart = cursorPos;
    }
  }

  @HostListener('document:dblclick', ['$event'])
  dbClick(event: MouseEvent) {
    if (event.target['attributes']['query']) {
      this.searchInputEl?.select();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onDocumentMouseDown(event: MouseEvent) {
    //To handle clicking on transparent space of the app
    if (this.hubService.suggestionsDropdownVisible && !isClickInElement(this.suggestionsDropdownRef.nativeElement, event)) {
      this.setSuggestionsDropdownVisibility(false, { preserveChar: null }, 'external_click');
    }
    this.focusSearchInput();
  }

  openPage(url: string, clickTrack: string, trigger: TelemetryTrigger) {
    const prefixIndex = url.indexOf('bar');

    this.routingService.navigateByUrl(prefixIndex == -1 ? url : url.split('bar')[1]);
    this.eventsService.event(`${clickTrack}.open`, { label: trigger });
  }

  unselectAll() {
    const cursorPosition = this.searchInputEl.selectionStart;
    this.searchInputEl.setSelectionRange(0, 0); //resets cursor position
    this.searchInputEl.selectionStart = cursorPosition;
    this.tagList.unmarkAll();
    this.cdr.markForCheck();
  }

  onAutoCompleteSelected() {
    const value = this.autoComplete$.getValue();
    this.onSuggestionSelected(value, 'autocomplete');
    if (!['query-filter'].includes(value?.type)) {
      const currentInputQuery = this.inputQuery?.trim();
      let query = currentInputQuery?.slice(0, Math.max(0, currentInputQuery.lastIndexOf(' ') + 1));
      const titleWords = value.title.toLowerCase().split(' ').slice(0, -1);
      for (const word of titleWords) {
        if (!query?.endsWith(word + ' ')) break;
        query = query.slice(0, Math.max(0, query.trimEnd().lastIndexOf(' ') + 1));
      }
      this.hubService.query = query;
    }
  }

  /** Handles 'keydown' event that was triggered on the search input only (not globally)!  */
  async onKeyDown(event: KeyboardEvent): Promise<void> {
    const modifiers = getModifiers(event);
    if (this.walkthroughService.activeTour) return;
    if (this.autoCompleteComp && this.autoComplete$.value) {
      if (
        isKey(event, keyCodes.tab) ||
        (isKey(event, keyCodes.ArrowRight) &&
          modifiers?.length === 0 &&
          this.searchInputEl &&
          this.searchInputEl.selectionStart == this.searchInputEl.selectionEnd &&
          this.searchInputEl.selectionStart == this.searchInputEl.value?.length)
      ) {
        this.autoCompleteComp.onSelect();
        event.stopPropagation();
        return;
      }
    }
    if (isKey(event, keyCodes.tab) || isKey(event, keyCodes.escape) || isModifierKey(event.key.toLowerCase())) return;

    if (isKey(event, keyCodes.ArrowDown) || isKey(event, keyCodes.ArrowUp)) {
      this.unselectAll();
      event.preventDefault();
      return;
    }
    if (isKey(event, keyCodes.enter)) return;

    const selectedLength: number = this.searchInputEl ? this.searchInputEl.selectionEnd - this.searchInputEl.selectionStart : 0;
    if (isCopyEvent(event) && selectedLength > 0) event.stopImmediatePropagation();
    const allTextSelected = this.inputQuery && selectedLength === this.inputQuery.length;
    const allSelected = allTextSelected && this.tagList.isAllMarked;
    let removedPageTag = false;

    let tagsCleared = false;
    if (modifiers?.length === 0 && (isKey(event, keyCodes.backspace) || isKey(event, keyCodes.delete))) {
      if (this.tagList.marked?.length > 0) {
        removedPageTag = this.clearTags(this.tagList.marked);
        tagsCleared = true;
      }
    }

    this.hubService.notifyTextCleared(allTextSelected);
    if ((!this.inputQuery || allTextSelected) && isPrintableKey(event) && modifiers?.length === 0) {
      this.preserveInput = undefined;
      if (!tagsCleared) {
        if (this.tagList.isAllMarked) {
          removedPageTag = this.clearTags();
          tagsCleared = true;
        } else if (this.tagList.isSomeMarked) {
          removedPageTag = this.clearTags(this.tagList.marked);
          tagsCleared = true;
        }
      }
    }

    if (!allSelected) {
      if (this.hubService.canFocus()) {
        this.searchInputEl.focus();
      }

      if ((!this.inputQuery || allTextSelected) && (isSelectAllEvent(event) || isSelectAllBeforeEvent(event))) {
        this.tagList.markAll();

        return;
      }
      if (this.searchInputEl.selectionStart === 0 && isSelectPreviousEvent(event)) {
        this.tagList.markNext('left');
        return;
      }
    }

    if (this.tagList.isSomeMarked && isSelectNextEvent(event)) {
      event.preventDefault();
      this.tagList.unmarkNext('right');
      return;
    }

    if (
      this.hubService.suggestionsEnabled &&
      !this.hubService.preventSearch &&
      ((isKey(event, keyCodes.colon) && modifiers?.length === 1 && modifiers[0] === 'shift') || isSemicolonKey(event))
    ) {
      if (await this.handleColon(event.key)) {
        event.stopPropagation();
      }

      return;
    }

    if (
      isKey(event, keyCodes.backspace) &&
      modifiers.length === 0 &&
      this.searchInputEl &&
      this.searchInputEl.selectionEnd === 0 &&
      this.searchInputEl.selectionStart === 0
    ) {
      const tags = this.tagList.model;

      if (tags?.length > 0 && !tagsCleared) {
        this.onRemoveTag(tags[tags?.length - 1]);
        event.stopPropagation();
        return;
      }
    }

    if (modifiers.length === 0 && (isKey(event, keyCodes.home) || isKey(event, keyCodes.end))) this.tagList.unmarkAll();

    this.selectTags(event);

    if ((this.hubService.readOnly || this.hubService.preventSearch) && !removedPageTag) {
      event.preventDefault();
    }
  }

  onInput(event: Event) {
    if (!(event instanceof KeyboardEvent) || !(event.target instanceof HTMLInputElement)) {
      event.stopPropagation();
      return;
    }
    const { value } = event.target;
    const count = value.trim()?.length;

    if (count > MAX_CHARACTERS) {
      setTimeout(() => {
        (this.hubService.query = value.slice(0, MAX_CHARACTERS)), false;
      });
      event.stopPropagation();
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let value = event.clipboardData?.getData('text/plain');
    const count = value?.trim()?.length;

    if (count > MAX_CHARACTERS) {
      value = ((this.inputQuery ?? '') + value).slice(0, MAX_CHARACTERS);
    }

    if (isFirefox()) {
      document.execCommand('insertText', false, value);
    } else {
      document.execCommand('insertHTML', false, value);
    }
  }

  /** @return if any of the cleared tag was page */
  private clearTags(tags: Omnibox.Tag[] = [...this.tagList.model]): boolean {
    let removedPage = false;

    const currentTags = [...tags];
    for (const tag of tags) {
      this.onRemoveTag(tag, currentTags);
      currentTags.shift();
      if (tag.type == 'active-page') removedPage = true;
    }
    return removedPage;
  }

  selectTags(event: KeyboardEvent) {
    const arrowLeftClicked = isKey(event, keyCodes.ArrowLeft);
    const arrowRightClicked = isKey(event, keyCodes.ArrowRight);
    const modifiers = getModifiers(event);
    const selectedLength: number = this.searchInputEl ? this.searchInputEl.selectionEnd - this.searchInputEl.selectionStart : 0;
    const allTextSelected = !this.inputQuery || selectedLength === this.inputQuery?.length;
    if (modifiers.length === 1 && modifiers[0] === 'shift') {
      if (arrowRightClicked) {
        if (this.tagList.isSomeMarked) event.stopPropagation();

        if (this.searchInputEl.selectionStart === 0 && isKey(event, keyCodes.ArrowRight)) {
          this.tagList.markNext('right');
        }
      }

      if (arrowLeftClicked) {
        if (this.searchInputEl.selectionStart === 0) {
          this.tagList.markNext('left');
          return;
        }
      }
    } else if (modifiers.length === 2 && modifiers.includes('shift') && modifiers.includes('control') && allTextSelected) {
      this.tagList.markAll();
    } else {
      if (this.tagList.isSomeMarked && (arrowRightClicked || arrowLeftClicked)) {
        this.tagList.unmarkAll();
        return;
      }
    }
  }

  selectAll() {
    this.focusSearchInput();
    if (this.inputQuery?.length) {
      this.searchInputEl.setSelectionRange(0, this.searchInputEl.value.length);
    }
    this.cdr.markForCheck();
  }

  onTagsMarkedChange(event: 'all' | 'partial' | 'none') {
    if (event !== 'none') this.hideCursor = true;
    else this.hideCursor = false;
    this.cdr.markForCheck();
  }

  @ViewChild('suggestionsDropdown', { read: ElementRef }) suggestionsDropdownRef: ElementRef;
  @ViewChild('suggestionsDropdown') suggestionsDropdown: SuggestionsDropdownComponent;
  @ViewChild('scopesDropdown', { read: ElementRef }) scopesDropdownRef: ElementRef;
  @ViewChild('scopesDropdown') scopesDropdown: SuggestionsDropdownComponent;

  preservedChar: string;

  async setSuggestionsDropdownVisibility(
    visible = false,
    options: { preserveChar?: string; point?: { x: number; y: number } } = {},
    interactionType?: 'esc_key' | 'external_click'
  ) {
    if (!this.suggestionsDropdown) return;
    if (this.hubService.suggestionsDropdownVisible == visible) return;

    const preservedChar = this.preservedChar;
    this.preservedChar = options.preserveChar;

    const el = this.suggestionsDropdownRef.nativeElement as HTMLElement;
    const { point } = options;
    const telemetryEvent: EventInfo = {
      name: '',
      category: 'suggestion_box',
      target: interactionType,
      jsonData: this.getTagEventJsonData(this.tagList.model),
      location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
    };

    if (!visible) {
      //Time out is used to allow x hide transition finish first
      this.hubService.suggestionsDropdownVisible = false;
      if (this.hubService.canFocus()) {
        this.searchInputEl.focus();
      }
      if (preservedChar && !this.hubService.readOnly) {
        const query =
          this.searchInputEl.value.slice(0, this.omniboxCursorPosition) +
          preservedChar +
          this.searchInputEl.value.slice(this.omniboxCursorPosition);
        this.hubService.inputQuery = query;
        this.focusSearchInput(true, true);
        setTimeout(() => {
          if (!this.searchInputEl) {
            return;
          }
          this.searchInputEl.selectionStart = this.searchInputEl.selectionEnd = this.omniboxCursorPosition + 1;
        }); // HACK - timeout is needed so the cursor placement will happen after the search input focus (https://stackoverflow.com/a/10576409)
      }

      telemetryEvent.name = 'cancel';
      telemetryEvent.label = this.suggestionsDropdown.query;
    } else {
      telemetryEvent.name = 'open';

      el.style.top = this.inputContainerRef.nativeElement.clientTop + this.inputContainerRef.nativeElement.offsetHeight + 'px';

      if (point) {
        const paddingLeft = +getComputedStyle(document.body).paddingLeft.split('px')[0];
        if (this.isEmbed && getWidthBreakpointScreen() === 'extra-small') {
          el.style.left = '80px';
        } else {
          el.style.left = point.x - this.searchInputEl.getBoundingClientRect().left + paddingLeft + 'px';
        }
        this.suggestionsDropdown.focusSearch();
      } else {
        el.style.left = this.searchInputEl.clientLeft + this.searchInputEl.style.paddingLeft + 'px';
      }

      this.hubService.suggestionsDropdownVisible = true;
      this.cdr.markForCheck();
    }

    this.eventsService.event('suggestions', telemetryEvent);
  }

  async onXClicked() {
    this.eventsService.event('search_bar.clear', {
      search: {
        query: this.inputQuery,
      },
      location: { title: this.hubService.currentLocation },
    });
    if (!this.isLauncher && this.hubService.currentLocation.includes('c/')) {
      //in result collection page we only clear the query
      this.hubService.query = '';
    } else if (this.tagsService.all[0]?.type === 'active-page') {
      this.onPageRemovedTag(this.tagsService.all[0].id);
      await this.hubService.openPage('/search');
    } else {
      this.hubService.clearQueryParams(this.NON_REMOVABLE_PARAMS);
    }
    this.inputQuery = '';
    this.tagsService.reset();
    this.focusSearchInput(true, true);
  }

  async handleColon(char: string) {
    // hack - disabled search hub suggestions
    if (!this.searchInputEl) {
      return;
    }
    const input = this.searchInputEl.value || '';

    const ss = this.searchInputEl?.selectionStart;
    const se = this.searchInputEl?.selectionEnd;

    if (ss != 0 && input[ss - 1] != ' ' && this.hubService.focusInputState) {
      return true;
    }

    if (se != input.length && input[se] != ' ') {
      return true;
    }
    return false;

    this.omniboxCursorPosition = ss;
    this.inputQuery = input.slice(0, ss) + input.slice(se);
    if (this.hubService.suggestionsEnabled) {
      this.setSuggestionsDropdownVisibility(true, { preserveChar: char });
      this.suggestionsDropdown?.focusSearch();
    }
    return true;
  }

  onSuggestionBoxInput(query: string) {
    const telemetryEvent: EventInfo = {
      name: 'lookup',
      category: 'suggestion_box',
      label: query,
      jsonData: this.getTagEventJsonData(this.tagList.model),
      location: { title: this.isLauncher ? 'launcher' : this.hubService.currentLocation },
    };

    this.eventsService.event('suggestions', telemetryEvent);
  }

  onFyiClick(fyiButtonRef: ElementRef) {
    this.fyiService.addOpenFyiQueryParam('badges', fyiButtonRef, this.menuWidth);
  }

  onHelpClick(helpButtonRef: ElementRef) {
    this.helpPopupService.open(helpButtonRef, this.menuWidth);
  }

  private async handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): Promise<void> {
    if (!this.hubService.canFocus(true)) {
      return;
    }
    const modifiers = getModifiers(keys);
    if (this.isNative && modifiers.includes('control') && keys.includes('n')) {
      self.window.open('/');
      return;
    }

    if (keys.length > 1) return;
    const key = keys[0];
    if (isEnterKey(key)) {
      this.onEnterPressed();
      return;
    }
    if (isSemicolonKey(event) && (await this.handleColon(event.key))) {
      event.stopPropagation();
    }
    switch (key) {
      case 'escape':
        return this.onEscapeKey();
      case 'ArrowUp':
        // Ugly hack to make chrome put cursor on the end after focus. Otherwise will put cursor at start and only on next click will move
        setTimeout(() => {
          if (this.searchInputEl) {
            this.searchInputEl.selectionStart = this.searchInputEl.selectionEnd = this.inputQuery?.length || 0;
          }
          this.focusSearchInput();
        }, 0);
        break;
      case 'slash':
        if (!this.hubService.focusInputState) {
          setTimeout(() => {
            this.searchInputEl.focus();
          }, 0);
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  }

  onEnterPressed() {
    if (this.redirectOnEnter) {
      if (this.isEmbed) {
        this.hubService.openStandardEmbed();
        this.hubService.clearState();
      } else {
        this.window.switchToStandard();
      }
      return;
    }
    if (this.hubService.searchMethod === 'Search-On-Enter' && this.hubService.query !== this.hubService.inputQuery) {
      this.hubService.autoCompleteEnabled = false;
      this.hubService.query = this.hubService.inputQuery;
    }
  }

  private onEscapeKey(): void {
    if (this.keyboardService.stopListening) {
      this.keyboardService.stopListening = false;
      return;
    }
    //clear search
    if (this.inputQuery || this.filtersService.hasFilters) {
      this.eventsService.event('search.erased', { label: this.inputQuery, location: { title: this.hubService.currentLocation } });
      this.hubService.query = '';
      this.filtersService.removeAllFilters();
    } else if (this.tagList?.model?.length > 0) {
      //Clear all scopes of current curator
      this.eventsService.event('search.erased', { label: this.inputQuery, location: { title: this.hubService.currentLocation } });
      this.tagsService.reset();
      this.hubService.openHomePage();
    } else {
      if (this.embedService) this.embedService?.hide();
      else this.window?.hide();
      this.logger.info('bar window close on escape');
    }
    this.searchInputEl?.focus();
    this.cdr.markForCheck();
  }

  private initIntercom(user: UserInt.Info) {
    if (this.isEmbed) return;
    const w: any = window;
    const intercom = w.Intercom;
    w.intercomSettings = {
      app_id: Config.intercom.appId,
      name: `${user.firstName} ${user.lastName || ''}`.trim(),
      hide_default_launcher: true,
      custom_launcher_selector: '.intercom-launcher',
      email: user.email,
      user_id: user.id,
    };

    if (typeof intercom === 'function') {
      intercom('reattach_activator');
      intercom('update', w.intercomSettings);
      intercom('hide');
    } else {
      const d = document;
      const i: any = () => {
        i.c(arguments);
      };
      i.q = [];
      i.c = (args) => {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = () => {
        const script = d.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${Config.intercom.appId}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(script, x);
      };

      if (document.readyState === 'complete') l();
      else if (w.attachEvent) w.attachEvent('onload', l);
      else w.addEventListener('load', l, false);
      w.Intercom('boot', w.intercomSettings);
    }

    const t = Date.now();
    const interval = setInterval(() => {
      const launcher = document.querySelector('.intercom-launcher');
      if (Date.now() - t >= 5000) {
        clearInterval(interval);
        return;
      }
      if (!launcher) return;
      if (w.Intercom.booted) {
        // Add class to show the launcher
        if (this.intercomLoaded) launcher.classList.add('intercom-booted');
        clearInterval(interval);
      }
    }, 20);
  }

  hideIntercom() {
    const w: any = window;
    const intercom = w.Intercom;
    if (!intercom) return;
    const launcher = document.querySelector('.intercom-launcher');
    if (launcher) launcher.classList.remove('intercom-booted');
  }

  async onLauncherAvatarClick(host: 'avatarIcon' | 'searchBarButton'): Promise<void> {
    const location: string = this.hubService.currentLocation;
    let menuOptionClicked = false;
    const el: ElementRef = host === 'avatarIcon' ? this.avatarElement : this.embeddedAvatarElement;
    const { x, y } = el.nativeElement.getBoundingClientRect();

    this.eventsService.event('launcher.avatar', {
      location: { title: this.isLauncher ? 'launcher' : location },
      target: 'open_menu',
    });
    const offset: Partial<ConnectedPosition> = { offsetY: 30 };

    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top', ...offset }];

    const options: OptionsPopupData = {
      title: this.user.email,
      options: [{ label: 'Admin Center', type: 'navigation', value: 'admin', icon: { type: 'font', value: 'icon-cog2' } }],
    };

    const popOpts = this.embedService ? (await firstValueFrom(this.embedService.options$)).popup : null;

    if (popOpts?.allowPin) {
      options.options.unshift({
        label: popOpts?.hideOnClickout != false ? 'Pin' : 'Unpin',
        type: 'custom',
        value: 'pin',
        icon: { type: 'font', value: 'icon-cog2' },
      });
    }

    this.optionsPopupRef = this.popupService.open<OptionsPopupComponent, OptionsPopupData>({ x, y }, OptionsPopupComponent, options, {
      position,
    });
    this.optionsPopupRef.compInstance.close.subscribe(() => {
      this.optionsPopupRef.destroy();
    });
    this.optionsPopupRef.compInstance.invoke.subscribe((item) => {
      menuOptionClicked = true;
      if (item.value == 'pin') {
        this.embedService.toggleHideOnClickout();
        this.optionsPopupRef.destroy();
      } else if (item.type === 'navigation') {
        this.eventsService.event('launcher.avatar', {
          location: { title: this.isLauncher ? 'launcher' : location },
          target: item.label,
        });
        this.optionsPopupRef.destroy();
        if (this.isEmbed) {
          this.embedService.openUrl(item.value);
        } else if (this.isLauncher) {
          this.window.switchToStandard(item.value);
        }
      }
    });
    this.optionsPopupRef.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      if (menuOptionClicked) return;
      this.eventsService.event('launcher.avatar', {
        location: { title: this.isLauncher ? 'launcher' : location },
        target: 'close_menu',
      });
    });
  }

  private sendAutocompleteSuggestionEvent(telemetryEvent: EventInfo, suggestion: Omnibox.Suggestion) {
    const isContact: boolean = ['account', 'identity'].includes(suggestion?.group);
    const label = isContact ? `filter:${suggestion.label}` : suggestion.id;
    this.eventsService.event('suggestions', { ...telemetryEvent, label });
  }

  changeFocusState(res: boolean) {
    setTimeout(() => {
      this.hubService.focusInputState = res;
    }, 30);
  }

  checkDisplayEnterIndication() {
    let res = false;
    if (this.hubService.searchMethod === 'Search-On-Enter') {
      res = (this.hubService.inputQuery || '') !== (this.hubService.query || '');
    }
    if (this.isLauncher) {
      res = Object.values(this.hubService.state || {}).some((p) => p.length);
    }
    this.displayEnterIndication = res;
    this.cdr.markForCheck();
  }
}
