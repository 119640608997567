import { Chats } from '@local/client-contracts';
import { UiIconModel } from '@local/ui-infra';
import { AvatarItemModel } from '../../models/avatar-item.model';
import { KnowledgeType } from '@local/client-contracts/src/experiences';

type CreatedByInfo = {
  avatar: AvatarItemModel;
  description: string;
};
export type AssistantStateType = 'disabled' | 'enabled' | 'deleted';
export type AssistantChatData = {
  id: string;
  emoji?: string;
  name?: string;
  description?: string;
  icon?: UiIconModel;
  canAccess?: boolean;
  isDefault?: boolean;
  createdByInfo?: CreatedByInfo;
  knowledgeType?: KnowledgeType;
  model?: string;
  isGlobalAssistant?: boolean;
  conversationStarterMessages?: string[];
  state?: AssistantStateType;
  allowFileUpload?: boolean;
  allowWebAccess?: boolean;
};

export type CurrentChatData = {
  chatSession?: Chats.Chat;
  draftQuery?: string;
  assistant?: AssistantChatData;
};
export type ChatErrorState = 'loading' | 'no-access' | 'not-found' | null;

export const NEW_CHAT_ID = 'new';
