<div class="window__handler ui-draggable-handle" [ngClass]="{ home: hubService.currentLocation === 'home' }" dragArea>
  <div class="navigator" data-cy="breadcrumbs-navigator">
    <u-button
      type="secondary"
      fontIcon="arrow-left-window"
      (click)="onNavClick('back')"
      [escape]="false"
      [pTooltip]="(canGoBack$ | async) && (back$ | async) ? (back$ | async) : null"
      tooltipPosition="right"
      tooltipStyleClass="u-tooltip"
      [versionNumber]="2"
      [sizeElement]="'small'"
      data-cy="breadcrumbs-back"
      [stateElement]="!(canGoBack$ | async) ? 'disabled' : 'regular'"
    >
    </u-button>
    <u-button
      type="secondary"
      fontIcon="arrow-right-window"
      (click)="onNavClick('forward')"
      [escape]="false"
      [pTooltip]="(canGoForward$ | async) && (forward$ | async) ? (forward$ | async) : null"
      tooltipPosition="right"
      tooltipStyleClass="u-tooltip"
      [versionNumber]="2"
      [sizeElement]="'small'"
      data-cy="breadcrumbs-forward"
      [stateElement]="!(canGoForward$ | async) ? 'disabled' : 'regular'"
    >
    </u-button>
  </div>
  @if (controls && !isMac) {
    <div class="window-controls">
      @if (hasMinimize()) {
        <u-icon
          class="window-control"
          [model]="{ type: 'font', value: 'icon-minimize-window' }"
          [styles]="{ fontSize: '10px', lineHeight: '28px', color: 'var(--color-text-primary)' }"
          (click)="onControlClick($event, 'minimize')"
        >
        </u-icon>
      }

      @if (hasMaximize()) {
        <u-icon
          class="window-control"
          [model]="{
            type: 'font',
            value: (invertMaximizeIcon$ | async) ? 'icon-windows-unmaximize' : 'icon-max-window',
          }"
          [styles]="{ fontSize: '10px', lineHeight: '28px', color: 'var(--color-text-primary)' }"
          (click)="onControlClick($event, !altDown && isMac ? 'fullscreen' : 'maximize')"
        >
        </u-icon>
      }

      @if (hasClose()) {
        <u-icon
          class="x-button window-control"
          [model]="{ type: 'font', value: 'icon-close-window' }"
          [styles]="{ fontSize: '10px', lineHeight: '28px', color: 'var(--color-text-primary)' }"
          (click)="onControlClick($event, 'close')"
        >
        </u-icon>
      }
    </div>
  }
</div>
