import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChildren,
  WritableSignal,
  signal,
  AfterViewInit,
  ElementRef,
  QueryList,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogService } from '@shared/services';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { RouterService } from '@shared/services/router.service';
import { removeFirstBackslash } from '@local/ts-infra';
import { Logger } from '@unleash-tech/js-logger';
import { combineLatest, Observable, startWith, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { getWidthBreakpointScreen } from '@shared/utils';
import { HubService } from 'src/app/bar/services/hub.service';

@UntilDestroy()
@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly MAX_CHARACTER_LARGE_SCREEN = 36;
  private readonly MAX_CHARACTER = 24;
  private readonly MAX_OPEN_ITEMS = 3;
  private popupMode: boolean;
  items: Breadcrumb[];
  showPath: WritableSignal<boolean> = signal(true);
  showGhost: WritableSignal<boolean> = signal(false);
  private readonly destroy$ = new Subject();
  ellipsisStatus = signal<boolean[]>([]);

  @Input() showArrows: boolean;
  @Input() showBreadcrumbsItems: boolean;
  @Input() styles: { [key: string]: string };
  @Input() alwaysShowPath: boolean = false;

  @Input() set popupModeItems(value: Breadcrumb[]) {
    this.popupMode = true;
    this.transformsItems(value);
  }
  @ViewChildren('breadcrumbElement') breadcrumbElements!: QueryList<ElementRef>;

  get isLargeScreen() {
    return ['large', 'extra-large'].includes(getWidthBreakpointScreen());
  }

  constructor(
    public breadcrumbsService: BreadcrumbsService,
    private routingService: RouterService,
    private logService: LogService,
    private cdr: ChangeDetectorRef,
    private hubService: HubService
  ) {
    this.breadcrumbsService.items$.pipe().subscribe((items) => {
      if (this.popupMode) {
        return;
      }
      this.transformsItems(items);
      cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.breadcrumbsService.show$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.showPath.set(val);
    });
    this.breadcrumbsService.showGhost$.pipe(untilDestroyed(this)).subscribe((val) => {
      this.showGhost.set(val);
    });
    this.routingService.active$.pipe(untilDestroyed(this)).subscribe((route) => {
      if (!route.startsWith('c/')) {
        this.showPath.set(true);
      }
    });
  }

  ngAfterViewInit(): void {
    this.breadcrumbElements.changes.subscribe(() => {
      this.updateEllipsisStatus();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  transformsItems(items: Breadcrumb[]) {
    if (items?.length > this.MAX_OPEN_ITEMS) {
      const cutOffItemsTooltip = this.getTooltip(items);
      const placeholderItem = { title: '...', path: '', notClickable: true, tooltip: cutOffItemsTooltip };
      this.items = [items[0], placeholderItem, ...items.slice(-2)];
    } else {
      this.items = items;
    }
    this.cdr.markForCheck();
  }

  private getTooltip(items: Breadcrumb[]) {
    const cutOffItems = items.slice(1, items.length - 2);
    return cutOffItems.map((item) => item.title).join(' / ');
  }

  getTitleTooltip(title: string) {
    return title?.length > (this.isLargeScreen ? this.MAX_CHARACTER_LARGE_SCREEN : this.MAX_CHARACTER) ? title : null;
  }

  navigate(path: string): void {
    const url = path.endsWith('/') ? path.slice(0, -1) : path;
    if (this.popupMode) {
      this.hubService.openUrl(url);
    } else {
      this.routingService.navigateByUrl(url);
    }
  }
  goBack(): void {
    window.history.go(-1);
  }

  private updateEllipsisStatus(): void {
    if (!this.breadcrumbElements) return;
    setTimeout(() => {
      const statuses = this.breadcrumbElements.map((element) => {
        return this.isTextTruncated(element.nativeElement);
      });
      this.ellipsisStatus.set(statuses);
    }, 0);
  }

  private isTextTruncated(element: HTMLElement): boolean {
    return element.scrollWidth > element.clientWidth;
  }
}
