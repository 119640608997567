import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { ResultSettings, SettingsFormat } from '../../../results/utils/results-settings';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HubService } from 'src/app/bar/services/hub.service';
import { Observable, Subscription } from 'rxjs';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { Filter, FilterChangeData } from '@shared/components/filters/models';
import { ResultsService } from 'src/app/bar/services/results.service';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { TypingViewMode, UButtonComponent, UInputComponent } from '@local/ui-infra';
import { SortService } from 'src/app/bar/services/sort.service';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import {
  AnswerGenerateState,
  AnswerSearchItem,
  findStateDifferences,
  getSortOptions,
  isAnswerItem,
  ScrollTrigger,
  SearchResults,
  SortOption,
} from '../../../results';
import { isAnswersSettings, isLinkResourcesSettings } from 'src/app/bar/services/search/client/source-setings.util';
import { Sort } from '@local/client-contracts/src/search';
import { resultsViewSettings } from '@local/common-web';
import { RouterService } from '@shared/services/router.service';
import { SIDE_PANEL_HOME_PAGE_PATH, SIDE_PANEL_PAGE_PATH } from 'src/app/bar/utils/constants';
import { RetrySourceModel } from 'src/app/bar/services/search/models/retry-source-modal';
import { RETRY_COUNT_INCREMENT, SIDE_PANEL_FOOTER_TITLE } from '../../helpers/side-panel-constants';
import { ChatsService } from '../../../chat-page/services/chats.service';
import { deepMergeFilters } from 'src/app/bar/utils/filters-utils';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { KeyName } from '@local/ts-infra';

@UntilDestroy()
@Component({
  selector: 'app-search-side-panel',
  templateUrl: './search-side-panel.component.html',
  styleUrls: ['./search-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSidePanelComponent implements OnInit, OnDestroy {
  private readonly SESSION_NAME = 'side-panel';
  private readonly FILTERS_SETTINGS = resultsViewSettings['extension-side-panel'];
  private readonly SEARCH_SETTINGS_PLATFORM = 'desktop-browser-extension-side-panel';
  private searchSession: SearchSession;
  private resultsFiltersSub: Subscription;
  private currentState;
  private sortOptions: SortOption[] = getSortOptions(null);
  private keyHandlerId: string;

  resultFilters = signal<Filter[]>([]);
  items = signal<SearchResults[]>([]);
  displayContext = signal<SearchResultContext>(null);
  loading = signal<boolean>(false);
  emptyResults = signal<boolean>(false);
  isLoadingAnswer = signal<boolean>(false);
  answerTypingViewMode = signal<TypingViewMode>('interactive');
  inputQuery$: Observable<string>;
  inputSearchOnFocus: boolean;

  @ViewChild('searchInput') searchInput: UInputComponent;

  constructor(
    private searchService: SearchService,
    private hubService: HubService,
    private filtersService: FiltersService,
    private resultsService: ResultsService,
    private sortService: SortService,
    private searchParamsService: SearchParamsService,
    private routerService: RouterService,
    private chatsService: ChatsService,
    private keyboardService: KeyboardService
  ) {}

  ngOnDestroy() {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  ngOnInit() {
    this.initSearchParams();
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 0);
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    if (!keys || keys.length > 1) {
      return;
    }
    const key = keys[0];
    switch (key) {
      case 'slash':
        if (this.searchInput && !this.inputSearchOnFocus) {
          this.changeFocusSearchInput('focus');
          event.preventDefault();
          break;
        }
    }
  }

  private changeFocusSearchInput(mode: 'blur' | 'focus') {
    switch (mode) {
      case 'focus': {
        if (!this.inputSearchOnFocus) {
          this.searchInput.focusInput();
        }
        break;
      }
      case 'blur': {
        if (this.inputSearchOnFocus) {
          this.searchInput.blurInput();
        }
        break;
      }
    }
  }

  private initSearchParams() {
    this.inputQuery$ = this.hubService.inputQuery$;
    this.hubService.state$.pipe(untilDestroyed(this)).subscribe((state) => {
      const emptySearchParams = !this.hubService.query && isEmpty(this.filtersService.allFilters);
      if (emptySearchParams) {
        this.routerService.navigateByUrl(SIDE_PANEL_HOME_PAGE_PATH);
        return;
      }
      const newState = cloneDeep(state);
      delete newState.fyi; // ignore fyi state change
      if (!isEqual(newState, this.currentState)) {
        const trigger = this.getSearchTrigger(newState);
        this.currentState = newState;
        this.startSearch(trigger);
      }
    });
  }

  private getSearchTrigger(state: any) {
    const currentState = cloneDeep(this.currentState);
    const newState = cloneDeep(state);
    const diff = findStateDifferences(newState, currentState);
    if (diff.includes('q')) {
      return 'user_query';
    }
    if (diff.includes('sort')) {
      return 'sort';
    }
    if (diff.includes('if') || diff.includes('of')) {
      return 'user_filter';
    }
    return 'reload';
  }

  private startSearch(trigger: string) {
    this.preSearch();
    this.searchSession = this.searchService.getOrCreateSearchSession(this.SESSION_NAME);
    const options = this.getSearchOptions(trigger);
    this.searchSession
      .search$(options)
      .pipe(untilDestroyed(this))
      .subscribe((ctx: SearchResultContext) => {
        this.handleSearchResults(ctx);
      });
  }

  private preSearch() {
    this.changeFocusSearchInput('blur');
    this.loading.set(true);
    this.isLoadingAnswer.set(false);
    this.emptyResults.set(false);
    this.items.set([]);
    this.setupFilters();
  }

  private getSearchOptions(trigger: string) {
    const preFilters = this.filtersService.getPreFilters(true);
    const postFilters = this.filtersService.postFilters;
    const query = this.hubService.query;
    const sort = this.searchParamsService.getSort();
    const format = this.getSearchFormat(sort);
    const sources = cloneDeep(ResultSettings.GetSettings(this.SEARCH_SETTINGS_PLATFORM, format));

    for (const source of sources) {
      const mergedPreFilters = deepMergeFilters(preFilters, source.filters?.preFilters);
      const mergedPostFilters = deepMergeFilters(postFilters, source.filters?.postFilters);
      source.filters = { ...(source.filters || {}), preFilters: mergedPreFilters, postFilters: mergedPostFilters };
      if (sort) {
        source.sorting = sort;
      }
      if (source.header?.clickable) {
        source.header.clickable = false;
      }
      source.footer = { title: SIDE_PANEL_FOOTER_TITLE };
      const isLinkResources = isLinkResourcesSettings(source);
      if (isLinkResources) {
        source.advancedSearch = true;
        source.contentSearch = true;
        source.disableAggregations = true;
      }
    }

    const options: SearchOptions = {
      resetSession: true,
      query,
      sources,
      telemetrySearchMethod: 'Search-On-Enter',
      trigger,
    };
    return options;
  }

  private getSearchFormat(sort: Sort): SettingsFormat {
    if (sort) {
      return 'sorting';
    }
    return 'search';
  }

  private handleSearchResults(ctx: SearchResultContext) {
    const displayContext = cloneDeep(ctx);
    const relevantPeopleSource = displayContext?.sources?.find((s) => s.source.type === 'relevant-people');
    if (relevantPeopleSource?.done) {
      // Hide relevant People items if also there is people group
      const peopleSource = displayContext?.sources?.find((s) => s.source.type === 'people');
      if (relevantPeopleSource?.items.length > 0 && peopleSource?.items?.flat().length > 0) {
        displayContext.items = displayContext.items.filter((i) => i.source !== 'relevant-people');
      }
    }
    const answerSource = displayContext.sources?.find((s) => isAnswersSettings(s?.source));
    if (answerSource) {
      const answerItems = answerSource.items as AnswerSearchItem[];
      const answerItem = answerItems?.find((s) => isAnswerItem(s));
      const currentAnswerState = answerItem?.state;
      this.isLoadingAnswer.set(currentAnswerState === 'Loading');
      const generatingState = AnswerGenerateState.includes(currentAnswerState);
      this.answerTypingViewMode.set(generatingState ? 'static' : 'interactive');
    }
    this.displayContext.set(displayContext);
    this.items.set(displayContext.items);
    if (ctx.searchCompleted) {
      this.loading.set(false);
      this.emptyResults.set(!this.items()?.length);
    }
  }

  private async setupFilters() {
    this.resultsFiltersSub?.unsubscribe();
    this.resultsFiltersSub = null;
    const moreFilters = this.FILTERS_SETTINGS.moreFilters;
    const filters = this.FILTERS_SETTINGS.filters;
    const subject$ = this.filtersService.getFloatingResultsFilters({
      sessionName: this.SESSION_NAME,
      baseFilters: filters,
      moreFilters,
    });
    this.resultsFiltersSub = subject$.pipe(untilDestroyed(this)).subscribe((r) => {
      this.resultFilters.set(r);
    });
  }

  onQueryChange(query: string) {
    this.hubService.inputQuery = query;
  }

  clearSearchParams() {
    this.hubService.clearQueryParams();
  }

  onEnterInput() {
    this.hubService.query = this.hubService.inputQuery;
  }

  onFilterChange(data: FilterChangeData) {
    this.resultsService.handleDataChanges(data);
  }

  openSort(button: UButtonComponent) {
    this.sortService.openSortPopup(button.pButtonRef?.el, { sortOptions: this.sortOptions });
  }

  prepareNextPage(trigger?: ScrollTrigger) {
    const resourceType = 'link-resources';
    const sources = this.displayContext()
      .sources.filter((s) => s.source.type === resourceType)
      .map((s) => ({ id: s.source.id, type: s.source.type }));
    this.searchSession.nextPage$(trigger, sources);
  }

  expandSearchGroup(headerItem: SearchResults) {
    const group = headerItem?.clientId || headerItem?.source;
    const retrySources: RetrySourceModel[] = [];
    const groupSourceIndex = this.displayContext().sources.findIndex((item) => (item.source.id || item.source.type) === group);
    if (groupSourceIndex === -1) return;
    const retrySource = this.displayContext().sources[groupSourceIndex];
    const retrySourceSettings = retrySource.source;
    if ('maxCount' in retrySourceSettings) {
      const countCurrentItems = retrySource.items.length;
      retrySourceSettings.maxCount = countCurrentItems + RETRY_COUNT_INCREMENT;
    }
    retrySources.push({ sourceIndex: groupSourceIndex, sourceSettings: retrySourceSettings });
    this.searchSession.retrySources(retrySources);
  }

  followUpChat(answerSearchItem: AnswerSearchItem) {
    this.chatsService.goToChatPageWithHistory(answerSearchItem, false, SIDE_PANEL_PAGE_PATH);
  }

  onSelectedIndexChanged(index: number) {
    if (!index && this.isLoadingAnswer()) {
      return;
    }
    this.changeFocusSearchInput(index ? 'blur' : 'focus');
  }
}
