import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  signal,
  TrackByFunction,
  viewChild,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { getAssistantTitle, getOS } from '@local/common-web';
import { PopupRef } from '@local/ui-infra';
import { KeysNavigationComponent } from '@shared/components/keys-navigation.component';
import { EventsService, LogService } from '@shared/services';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { generateTitleUrl, isEnterKey, isKey, isSingleKey, keyCodes, KeyName } from '@local/ts-infra';
import { isElement } from '@shared/utils/elements-util';
import { cloneDeep, isEqual } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import {
  isChild,
  isSearchLine,
  ITEMS_HEIGHT,
  SearchPopupData,
  SearchPopupItem,
  SearchPopupItemType,
  SortFunction,
  TelemetryTarget,
} from './model';
import { SearchPopupItemComponent } from './search-popup-item/search-popup-item.component';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { GoLinksService } from 'src/app/bar/services/go-links.service';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { OpenPageCommand } from '@local/client-contracts/src/commands';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatsService } from '../../chat-page/services/chats.service';
import { SearchPopupSettings } from './search-popup-settings';
import { Collections, LocalActions } from '@local/client-contracts';
import { PreviewService } from 'src/app/bar/services/preview.service';
import { COLLECTION_TYPE_TO_ICON_MAP } from '../../collections-page/helpers/collection-type-icon-map';
import {
  CollectionItem,
  ExperienceSearchItem,
  GoToItem,
  HeaderItem,
  SearchResults,
  StaticSearchItem,
} from '../../results/models/results-types';
import { isCollection, isExperienceSearchItem, isGoTo, isHeader, isLocalAction, isStaticItem } from '../../results/utils/results.util';
import { EntityIcon, EntityIconType } from '@local/client-contracts/src/style';
type UpdatedSearchResults = Array<SearchPopupItem<'parent'> | SearchPopupItem<'child'> | SearchPopupItem<'search-line'>>;

@UntilDestroy()
@Component({
  selector: 'search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopupComponent
  extends KeysNavigationComponent<SearchPopupItem<SearchPopupItemType>, SearchPopupItemComponent>
  implements SearchPopupData, OnInit, OnDestroy, AfterViewInit
{
  readonly MAX_SCROLL_HEIGHT: number = 245;
  readonly MAX_SCROLL_HEIGHT_NO_RESULTS: number = 280;
  readonly lastSearchItem: SearchPopupItem<'search-line'> = {
    type: 'search-line',
    id: '0',
    icon: { type: 'font-icon', value: 'icon-magnifier' },
    title: 'Search for',
    visibility: 'search-only',
    command: null,
  };
  private searchSession: SearchSession;
  private searchSubscription: Subscription;
  sortBy: SortFunction<SearchPopupItem<'parent'>>;
  query$ = new BehaviorSubject<string>('');
  items$: Observable<SearchPopupItem<SearchPopupItemType>[]>;
  placeholder$: Observable<string>;
  autoComplete: string;
  name: string;
  hasUserInteracted = false;
  itemsHeight;
  telemetryName: string;
  noScrollbar = false;
  searchResultsGroup = signal<UpdatedSearchResults>([]);
  scrollSize = signal(this.MAX_SCROLL_HEIGHT);
  resetScroll: WritableSignal<boolean> = signal(false);
  get query() {
    return this.query$.getValue();
  }
  set query(v: string) {
    this.query$.next(v);
  }
  get supportsTelemetry(): boolean {
    return !!this.eventsService && !!this.routerService && !!this.telemetryName;
  }
  @Output() select$ = new EventEmitter<{ item: SearchPopupItem<'child'>; via: TelemetryTarget }>();
  @Output() searchSelect$ = new EventEmitter<TelemetryTarget>();
  scrollAreaRef = viewChild(NgScrollbar);
  @ViewChild('resultsContainer') resultsContainerRef: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;
  @ViewChild('searchInputAutoComplete') searchInputAutocompleteRef: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) public scrollViewport: CdkVirtualScrollViewport;

  constructor(
    private host: ElementRef,
    private ref: PopupRef<SearchPopupItem<SearchPopupItemType>, SearchPopupData>,
    logService: LogService,
    cdr: ChangeDetectorRef,
    keyboard: KeyboardService,
    private goLinksService: GoLinksService,
    public collectionsService: CollectionsService,
    private collectionsUtilService: CollectionsUtilService,
    private experienceService: ExperiencesService,
    protected searchService: SearchService,
    protected previewService: PreviewService,
    private chatsService: ChatsService,
    @Optional() private eventsService?: EventsService,
    @Optional() private routerService?: RouterService
  ) {
    super(logService, cdr, keyboard);
    this.searchSession = this.searchService.getOrCreateSearchSession('commandK');
    const { placeholder$, telemetryName, name } = this.ref.data;
    this.placeholder$ = placeholder$;
    this.telemetryName = telemetryName;
    this.name = name;
    this.logger.scope(`${name}Component`);
  }

  ngOnInit() {
    super.ngOnInit();
    this.handleChangeQuery();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.scrollArea = this.scrollAreaRef();
    if (this.searchInputRef) {
      this.focusInput();
    }
    if (this.searchInputRef?.nativeElement) {
      fromEvent(this.searchInputRef.nativeElement, 'input')
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => (this.hasUserInteracted = true));
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySearchSession();
  }
  private handleChangeQuery() {
    this.startSearch();
    this.query$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((prev, next) => isEqual(prev, next))
      )
      .subscribe((query) => this.startSearch(query));
  }
  private scrollToTop() {
    setTimeout(() => {
      this.scrollAreaRef()?.scrollTo({ top: 0 });
      this.scrollViewport.checkViewportSize();
    }, 0);
  }
  private handleSearchResultsGroupChange(updatedResults: UpdatedSearchResults) {
    this.onPostSearchItemsUpdated(updatedResults);
    this.setScrollbarHight(updatedResults);
  }
  private onPostSearchItemsUpdated(items: SearchPopupItem<SearchPopupItemType>[]) {
    this.items = items;
    const noResults = !(items?.length - 1);
    if (noResults) {
      {
        this.selectedIndex = 0;
      }
    }
    this.select('first');
  }
  private setScrollbarHight(items) {
    const itemsHeight = items.reduce((total, { type }) => {
      return (total += ITEMS_HEIGHT[type]);
    }, 0);
    this.scrollSize.set(itemsHeight);
    this.scrollViewport.setTotalContentSize(itemsHeight);
    this.scrollAreaRef().nativeElement.style.height = itemsHeight + 'px';
    this.noScrollbar = itemsHeight <= this.MAX_SCROLL_HEIGHT;
    this.scrollViewport.checkViewportSize();
  }
  protected handleKeys(keys: KeyName[], event): void {
    if (this.eventToSelectAction(event)) {
      this.hasUserInteracted = true;
    }

    if (isSingleKey('ArrowUp', keys) && (this.selectedIndex === null || this.selectedIndex === this.firstSelectableIndex)) {
      this.focusInput();
      if (this.selectedIndex === 0) {
        event.stopPropagation();
        return;
      }
    }

    const oneLineBeforeLast = this.selectedIndex + 1 < this.items?.length && this.items[this.selectedIndex + 1]?.type === 'search-line';
    if (isSingleKey('ArrowDown', keys) && !this.query && oneLineBeforeLast) {
      event.stopPropagation();
      return;
    }

    super.handleKeys(keys, event);
    if (event.propagationStopped) {
      return;
    }

    if (isSingleKey('escape', keys) && this.ref) {
      this.ref.close('keyboard');
      event.stopPropagation();
      return;
    }

    if (this.items) {
      if (getOS() === 'MacOS') keys = keys.map((k) => (k = k === 'control' ? 'command' : k));

      const matchingIdx = this.items.findIndex(
        (item) =>
          item?.shortcut &&
          item.shortcut.length === keys.length &&
          item.shortcut.every((key) => keys.map((k) => k.toLowerCase()).includes(key.toLowerCase()))
      );

      if (matchingIdx !== -1 && !keys.includes('enter')) {
        const match = this.items[matchingIdx];
        if (!isChild(match)) {
          return;
        }
        if (this.selectedIndex === matchingIdx) {
          this.emitSelect(match, 'keyboard');
        }
        event.stopPropagation();
        return;
      }
    }
    const selected = this.selectedItem;

    if (isChild(selected)) {
      if (isEnterKey(keys[0]) && this.selectedIndex !== null) {
        if (!selected) {
          event.stopPropagation();
          return;
        }
        this.emitSelect(selected, 'keyboard');
        event.stopPropagation();
        return;
      }

      if (isSingleKey('tab', keys)) {
        this.emitSelect(selected, 'keyboard');
        event.stopPropagation();
        return;
      }
      if (isSingleKey('ArrowRight', keys) && this.autoComplete) {
        event.stopPropagation();
        return;
      }
    }

    if (isSearchLine(selected)) {
      if (isEnterKey(keys[0]) && this.selectedIndex !== null) {
        if (!selected) {
          event.stopPropagation();
          return;
        }
        this.onSearch(selected, 'keyboard');
        event.stopPropagation();
        return;
      }
    }

    if (isElement(event.target) && !(this.host?.nativeElement as HTMLElement)?.contains(event.target)) {
      event.stopPropagation();
      return;
    }
    event.stopPropagation();
    return;
  }

  emitSelectByCommand(item: SearchPopupItem<'child'>, via: TelemetryTarget) {
    switch (item?.command.type) {
      case 'open-page':
        this.openPage(item);
        break;
      case 'open-assistant':
        this.experienceService.openAssistant(item.id);
        break;
      case 'open-chat':
        this.chatsService.openChat(item.data?.assistantId, this.query);
        break;
      case 'open-collection':
        this.openCollection(item);
        break;
      case 'open-url':
        this.openUrl(item);
        break;
      default:
        this.select$.emit({ item, via });
        break;
    }
  }
  async emitSelect(item: SearchPopupItem<'child'>, via: TelemetryTarget) {
    if (this.supportsTelemetry && item.data?.context?.resource?.params?.highlights?.length <= 1) {
      this.eventsService.event('command_bar.commands', {
        location: { title: this.routerService.active },
        target: via,
        //@ts-ignore
        label: item.title.toLocaleLowerCase().split(':')[0]?.replaceAll(' ', '_'),
        resources: [{ appId: item.keywords[0], linkId: item.data.context.linkId }],
        jsonData: JSON.stringify({ commands: { location: 'command_bar' } }),
      });
    }
    switch (item.id) {
      case 'new-go-link':
        this.goLinksService.openPopup();
        break;

      case 'new-curated-collection':
        this.collectionsService.openCollectionView({ kind: 'Static' });
        break;

      case 'new-live-collection':
        this.collectionsService.openCollectionView({ kind: 'Live' });
        break;

      case 'new-wiki-collection':
        this.collectionsService.openCollectionView({ kind: 'Wiki' });
        break;

      case 'new-card':
        this.collectionsUtilService.openWikiCard();
        break;

      case 'new-general-assistant':
        this.experienceService.createDraftAssistant('general');
        break;

      case 'new-rfp-assistant':
        this.experienceService.createAssistant({ experienceType: 'rfp' });
        break;

      case 'new-salesforce-cases-assistant':
        this.experienceService.createAssistant({ experienceType: 'salesforce' });
        break;
      default:
        this.emitSelectByCommand(item, via);
        break;
    }
    this.ref.close(via);
  }
  private openUrl(item) {
    if (isCollection(item) || item?.source === 'wiki-collection-items') {
      this.openWikiCard(item);
    } else {
      this.routerService.navigate(['external-link'], { queryParams: { url: item.command.url } }, false);
    }
  }
  private openPage(item) {
    const cmd = item.command as OpenPageCommand;
    this.routerService.navigateByUrl(cmd.url);
  }
  private openCollection(item) {
    const url = generateTitleUrl(this.collectionsService.getCollectionPrefix(item as Collections.Collection), item?.title, item?.id);
    this.routerService.navigateByUrl(url);
  }
  private openWikiCard(item) {
    this.previewService.setPreviewState('popup', 0, {
      type: 'result',
      filterType: 'wiki-local',
      id: item.id,
      view: { title: { text: item.title }, icon: null },
      action: { type: 'wiki card' },
    });
  }

  onItemClick(event: MouseEvent, item: SearchPopupItem<'child'>) {
    if (this.supportsTelemetry) {
      this.eventsService.event('command_bar.click', {
        location: { title: this.routerService.active },
        name: this.telemetryName,
        //@ts-ignore
        label: item.title.toLowerCase().replaceAll(' ', '_'),
        jsonData: JSON.stringify({ action: { trigger: 'command_bar' } }),
      });
    }

    this.emitSelect(item, 'mouse_click');
  }

  trackItem: TrackByFunction<SearchPopupItem<SearchPopupItemType>> = (index: number, item: SearchPopupItem<SearchPopupItemType>): string =>
    `${item.id}_${item.type}_${index}`;

  flatItems(items: SearchPopupItem<SearchPopupItemType>[]): Exclude<SearchPopupItem<SearchPopupItemType>[], 'children'> {
    let flat: Exclude<SearchPopupItem<SearchPopupItemType>[], 'children'> = [];
    for (const item of items) {
      flat.push(item);
      if (item.children) {
        flat = flat.concat(item.children);
      }
    }
    return flat;
  }

  isSelectable(item: SearchPopupItem<SearchPopupItemType>): boolean {
    return item?.type === 'child' || item?.type === 'search-line';
  }

  onKeyDown(event: KeyboardEvent): void {
    if (isKey(event, keyCodes.ArrowRight) && this.autoComplete) {
      this.query = this.autoComplete;
    }
  }

  focusInput() {
    if (!this.searchInputRef) {
      return;
    }
    setTimeout(() => (this.searchInputRef.nativeElement as HTMLInputElement).focus(), 0);
  }
  private convertResultToHeader(result: HeaderItem): SearchPopupItem<'parent'> {
    return {
      title: result.title,
      visibility: null,
      children: [],
      type: 'parent',
      command: null,
      icon: null,
    };
  }
  private convertResultToAssistantItem(
    result: ExperienceSearchItem & { highlights?: string[]; iconOverlay?: EntityIcon<EntityIconType> }
  ): SearchPopupItem<'child'> {
    return {
      id: result.id,
      visibility: 'always',
      type: 'child',
      resultType: 'assistant',
      highlights: result?.highlights,
      source: 'answers',
      title: getAssistantTitle(result),
      command: {
        type: 'open-assistant',
      },
      icon: result.iconOverlay || (result.icon as EntityIcon<EntityIconType>),
      emoji: result?.emoji,
      settings: result?.settings,
      extraData: { experienceType: result?.experienceType },
    };
  }
  private convertResultToGotoItem(result: GoToItem & { highlights?: string[] }): SearchPopupItem<'child'> {
    return {
      id: result.id,
      visibility: 'always',
      type: 'child',
      resultType: 'goto',
      subtitle: result?.subtitle,
      subTitleByGroup: result?.subTitleByGroup,
      highlights: result?.highlights,
      source: 'answers',
      icon: result.icon,
      title: result?.title,
      command: result.command,
      parentId: result?.parentId,
    };
  }
  private convertResultToStaticSearchItem(result: StaticSearchItem): SearchPopupItem<'child'> {
    return {
      visibility: 'always',
      type: 'child',
      resultType: 'static-search-item',
      source: 'answers',
      icon: result.icon,
      title: result?.title,
      data: result.data,
      command: { type: result.invokeType },
    };
  }
  private convertResultToCollectionItem(result: CollectionItem): SearchPopupItem<'child'> {
    const iconValue = result?.emoji ?? this.setCollectionIcon(result);

    return {
      visibility: 'always',
      type: 'child',
      resultType: 'collection',
      highlights: result?.highlights,
      source: 'answers',
      id: result?.id,
      icon: {
        type: result?.emoji ? 'emoji' : 'font-icon',
        value: iconValue,
      },
      title: result?.title,
      command: { type: 'open-collection' },
      extraData: { kind: result?.kind },
    };
  }
  private convertResultToWikiCardItem(result): SearchPopupItem<'child'> {
    return {
      visibility: 'always',
      type: 'child',
      resultType: 'result',
      subtitle: result?.subtitle,
      highlights: result?.highlights,
      source: result?.source,
      id: result?.id,
      icon: {
        type: 'font-icon',
        value: result?.view?.icon?.name,
      },
      title: result?.view?.title?.text,
      data: result.data,
      command: result?.view?.title?.onClick,
      extraData: { kind: result?.kind },
      parentId: result?.parentId,
    };
  }
  private convertResultToLocalActionItem(result: LocalActions.LocalActionItem & { highlights?: string[] }): SearchPopupItem<'child'> {
    return {
      id: result.id,
      visibility: 'always',
      type: 'child',
      resultType: 'local-action',
      highlights: result?.highlights,
      source: 'answers',
      icon: result?.icon,
      title: result?.title,
      command: result?.command,
    };
  }
  private startSearch(query?: string) {
    this.unsubscribeSearchSession();
    const view = !query?.length ? 'default' : 'search';
    const sources = cloneDeep(SearchPopupSettings.settings[view]);
    const options: SearchOptions = {
      resetSession: view === 'default',
      query: query,
      sources,
      trigger: 'user_query',
      telemetrySearchMethod: 'Quick-Search',
    };
    this.searchSubscription = this.searchSession
      .search$(options)
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((prev, next) => isEqual(prev, next))
      )
      .subscribe(async (res: SearchResultContext) => {
        if (!res.searchCompleted) {
          return;
        }
        const ctx = cloneDeep(res);
        const updatedSearchResults: UpdatedSearchResults = ([] = (ctx?.items || []).map((result: SearchResults) => {
          return this.convertResult(result);
        }));
        updatedSearchResults.push(this.lastSearchItem);
        this.checkScroll(updatedSearchResults);

        this.searchResultsGroup.set(updatedSearchResults);
        this.handleSearchResultsGroupChange(updatedSearchResults);
      });
  }
  private checkScroll(updatedSearchResults) {
    const hadNoResults = this.searchResultsGroup().length === 1;
    const hasResults = updatedSearchResults.length > 1;
    if (hadNoResults && hasResults) {
      this.resetScroll.set(true);
      this.scrollToTop();
    }
  }
  private convertResult(result: SearchResults) {
    if (isHeader(result)) {
      const header = this.convertResultToHeader(result);
      return header;
    }
    if (isGoTo(result)) {
      const gotoItem = this.convertResultToGotoItem(result);
      return gotoItem;
    }
    if (isExperienceSearchItem(result)) {
      const assistantItem = this.convertResultToAssistantItem(result);
      return assistantItem;
    }
    if (isStaticItem(result)) {
      const staticSearchItem = this.convertResultToStaticSearchItem(result);
      return staticSearchItem;
    }
    if (isCollection(result)) {
      const collectionItem = this.convertResultToCollectionItem(result);
      return collectionItem;
    }
    if (result.type === 'result' && result?.source === 'wiki-collection-items') {
      const wikiCardItem = this.convertResultToWikiCardItem(result);
      return wikiCardItem;
    }
    if (isLocalAction(result)) {
      const localActionItem = this.convertResultToLocalActionItem(result);
      return localActionItem;
    }
  }
  private setCollectionIcon(collection) {
    let defaultIcon: string;
    defaultIcon = COLLECTION_TYPE_TO_ICON_MAP[collection.kind];
    return defaultIcon;
  }
  private unsubscribeSearchSession() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
  }
  private destroySearchSession() {
    if (this.searchSession) {
      this.searchSession.destroy();
    }
    this.unsubscribeSearchSession();
  }
  onSearch(item: SearchPopupItem<'search-line'>, via: TelemetryTarget = 'mouse_click') {
    this.routerService.navigateByUrl(`search?q=${this.query}`);
    this.searchSelect$.emit(via);
  }
}
