import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { isMac } from '@local/common-web';
import { Intent } from '../../views/hub/shared/model';

@Component({
  selector: 'control-button',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlButtonComponent {
  @Input('icon') iconName: string;
  @Input() active: boolean;
  @Input() unread: boolean;
  @Input() intent?: Intent;
  @Input() title?: string;
  @Input() fontSize?: string = '16px';

  isMac = isMac();
  @HostBinding('class.active')
  @HostBinding('class.alert')
  @HostBinding('class.windows-control')
  get isNonMac() {
    return !this.isMac;
  }
  @HostBinding('class.active') get isActive() {
    return this.active;
  }
  @HostBinding('class') get class() {
    return { [this.intent]: !!this.intent };
  }

  existUnreadFyi() {
    return typeof this.unread === 'number' && this.unread > 0;
  }
}
