import { Collections, Commands, Experiences, Style } from '@local/client-contracts';
import { UiIconModel } from '@local/ui-infra';
import { Breadcrumb } from '@shared/services/breadcrumbs.service';
import { KeyName } from '@local/ts-infra';
import { Observable } from 'rxjs';
import { ExperienceType } from '@local/client-contracts/src/experiences';
import { SearchSourceType } from 'src/app/bar/services/search/client/search-source-type';
import { ResultType } from '@local/client-contracts/src/search';

export interface SearchPopupItem<T extends SearchPopupItemType, E extends ExperienceType = ExperienceType> {
  type: T;
  resultType?: ResultType;
  source?: SearchSourceType;
  description?: string;
  id?: string;
  icon?: T extends 'parent' ? null : Style.EntityIcon<Style.EntityIconType>;
  emoji?: string;
  title: string;
  subtitle?: string | Breadcrumb[];
  subTitleByGroup?: string;
  shortcut?: Array<KeyName>;
  command: T extends 'parent' | 'search-line' ? null : Commands.Command;
  data?: { [key: string]: any };
  children?: SearchPopupItem<'child'>[];
  visibility: T extends 'parent' ? null : 'search-only' | 'always';
  highlights?: string[];
  parentId?: T extends 'parent' ? null : string;
  keywords?: string[];
  // -> null = no sorting
  sortBy?: T extends 'parent' ? SortFunction | 'default' : null;
  inlineIcon?: UiIconModel;
  settings?: Experiences.ExperienceSettingsMap[E];
  extraData?: {
    kind?: Collections.Kind;
    experienceType?: E;
  };
}

export interface SearchPopupData {
  placeholder$?: Observable<string>;
  telemetryName?: string;
  name: string;
}

export type SearchPopupItemType = 'child' | 'parent' | 'search-line';

export const ITEMS_HEIGHT: Record<SearchPopupItemType, number> = {
  child: 33,
  parent: 46,
  'search-line': 35,
};

export const isChild = (x: any): x is SearchPopupItem<'child'> => x?.type === 'child';
export const isSearchLine = (x: any): x is SearchPopupItem<'search-line'> => x?.type === 'search-line';

export type SortFunction<T = SearchPopupItem<SearchPopupItemType>> = (a: T, b: T) => number;

export type TelemetryTarget = 'mouse_click' | 'keyboard';

export interface SelectPayload {
  item: SearchPopupItem<'child'>;
  via?: TelemetryTarget;
}
