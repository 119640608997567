import { Filters } from '@local/client-contracts';
import { Filter, TimeDropdown } from '@shared/components/filters/models';
import { cloneDeep, isArray, mergeWith, union } from 'lodash';

export const isTimeFilter = (filter: Filter): filter is TimeDropdown => filter.picker === 'time-dropdown';

export const deepMergeFilters = (baseObject: Filters.Values, additionalObject: Filters.Values): Filters.Values => {
  const baseClone = cloneDeep(baseObject || {});
  return mergeWith(baseClone, additionalObject || {}, (objValue: any, srcValue: any) => {
    if (isArray(objValue)) {
      return union(objValue, srcValue);
    }
  });
};
