import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Workspace } from '@local/client-contracts';
import { EventInfo, EventsService } from '@shared/services';
import { UiIconModel, PopupRef } from '@local/ui-infra';

export type AvatarPopupData = {
  location: EventInfo['location'];
  email: string;
  workspace: Workspace.Workspace;
  workspaceIcon: UiIconModel;
  hideLogout: boolean;
};
@Component({
  selector: 'avatar-popup',
  templateUrl: './avatar-popup.component.html',
  styleUrls: ['./avatar-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPopupComponent implements OnInit {
  @Output() logout = new EventEmitter();
  avatarPopupData: AvatarPopupData;

  constructor(
    private ref: PopupRef<AvatarPopupComponent, AvatarPopupData>,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.avatarPopupData = this.ref.data;
  }

  async onLogout() {
    this.eventsService.event('hub_side_menu.log_out', { location: this.avatarPopupData.location }, true);
    this.logout.emit();
  }

  onClickWorkspace() {
    this.eventsService.event('hub_side_menu.profile_select_workspace', {
      label: this.avatarPopupData.workspace.accountId,
      location: this.avatarPopupData.location,
    });
  }
}
