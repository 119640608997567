import { ElementRef, Injectable } from '@angular/core';
import {
  SortingFilterData,
  SortingFilterPopupComponent,
} from '../views/collections-page/components/sorting-filter-popup/sorting-filter-popup.component';
import { PopupRef, PopupService } from '@local/ui-infra';
import { take } from 'rxjs';
import { Search } from '@local/client-contracts';
import { SearchParamsService } from './search-params.service';
import { EventsService } from '@shared/services';
import { HubService } from './hub.service';

@Injectable()
export class SortService {
  private _popupSortRef: PopupRef<SortingFilterPopupComponent, SortingFilterData>;
  get popupSortRef(): PopupRef<SortingFilterPopupComponent, SortingFilterData> {
    return this._popupSortRef;
  }

  constructor(
    private popupService: PopupService,
    private searchParamsService: SearchParamsService,
    private eventsService: EventsService,
    private hubService: HubService
  ) {}

  openSortPopup(el: ElementRef, data: SortingFilterData, currentSort?: Search.Sort, enableSort: boolean = true) {
    if (this.popupSortRef) {
      this.popupSortRef.destroy();
    }
    const { x, y } = el.nativeElement.getBoundingClientRect();

    const position = { left: x - 130, top: y + 32 };
    this._popupSortRef = this.popupService.open<SortingFilterPopupComponent, any>(position, SortingFilterPopupComponent, data);
    this.popupSortRef.compInstance.onSortEvent.subscribe((sort: Search.Sort) => {
      this.sendSortEvent(sort);
    });
    this.popupSortRef.compInstance.currentSort = currentSort;
    this.popupSortRef.destroy$.pipe(take(1)).subscribe(() => {
      this._popupSortRef = null;
    });
    if (enableSort) {
      this.popupSortRef.compInstance.onSortEvent.subscribe((searchSort: Search.Sort) => {
        this.searchParamsService.addSort(searchSort);
      });
    }
    return this.popupSortRef;
  }

  sendSortEvent(sort: Search.Sort) {
    this.eventsService.event('sort', {
      label: this.buildTelemetrySortValue(sort),
      location: { title: this.hubService.currentLocation },
    });
  }

  private buildTelemetrySortValue(sort: Search.Sort) {
    if (!sort) {
      return 'most_relevant';
    }
    const order = sort.order === 'Ascending' ? 'asc' : 'desc';
    if (sort.by === 'Timestamp') {
      return `date_${order}`;
    }
    return order;
  }

  destroyPopUpSort() {
    if (this.popupSortRef) {
      this._popupSortRef.destroy();
    }
  }
}
