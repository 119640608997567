<div style="display: grid">
  <div class="u-tab-view"  [class.primary]="isPrimary" cdkDropListOrientation="horizontal" cdkDropList (cdkDropListDropped)="drop($event)">
    <div #tabsContainer class="u-tab-view-content">
      <div #static class="u-tab-container" *ngIf="tabsStatic?.length">
        <div
          #staticTabs
          class="u-tab"
          *ngFor="let tab of tabsStatic.slice(0, staticTabsLimit)"
          (click)="openTab($event, tab)"
          (contextmenu)="openContextMenu($event, tab.id)"
          [ngClass]="{ 'active-tab': tab.id === selectedTabId || (!selectedTabId && tab.id === activeTabId) }"
          [pTooltip]="tab.tooltip"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
          <span class="tab-content">
            <u-icon [ngClass]="{ 'tab-icon': tab?.name }" *ngIf="tab.icon" [model]="{ type: 'font', value: tab.icon }"></u-icon>
            <span class="tab-name" *ngIf="tab?.name">{{ tab?.name }}</span>
          </span>
          <span class="tab-amount" *ngIf="!checkIsNil(tab) && tab?.amount">{{ tab.amount }}</span>
        </div>
      </div>
      <div #moving class="u-tab-container" *ngIf="tabsMoving?.length">
        <div
          #movingTabs
          class="u-tab"
          [ngClass]="{ 'active-tab': tab?.id === selectedTabId || (!selectedTabId && tab?.id === activeTabId) }"
          *ngFor="let tab of tabsMoving.slice(0, movingTabsLimit)"
          (click)="openTab($event, tab)"
          (contextmenu)="openContextMenu($event, tab?.id)"
          [pTooltip]="tab?.tooltip"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
          cdkDrag
        >
          <u-icon class="tab-icon" *ngIf="tab?.icon" [model]="{ type: 'font', value: tab.icon }"></u-icon>
          <span *ngIf="tab && !tab?.editMode; else input">{{ tab?.name }}</span>

          <ng-template #input>
            <input-title
              *ngIf="tab?.editMode"
              [text]="tab?.name"
              [inputPlaceholder]="tab?.name"
              [defaultInputModel]="tab?.name"
              [inputModel]="tab?.name"
              (onDisplayInput)="displayInput($event)"
              (onBlurUInput)="renameTab($event, tab.id)"
              (outsideClick)="onEditTitleClickOut(tab)"
            >
            </input-title>
          </ng-template>
        </div>
      </div>
      <div *ngIf="tabsActionStatic?.length && !showOnOptions[0]?.items?.length">
        <div
          class="u-tab action"
          *ngFor="let tab of tabsActionStatic"
          (click)="doAction($event, tab?.name)"
          [pTooltip]="tab.tooltip"
          tooltipPosition="top"
          tooltipStyleClass="u-tooltip"
        >
          <u-icon class="static-action-tab-icon" [model]="{ type: 'font', value: tab.icon }" [styles]="{ fontSize: '10px' }"></u-icon>
        </div>
      </div>
      <u-menubar class="more-menubar" *ngIf="!hideMoreOptions && showOnOptions[0]?.items?.length" [items]="showOnOptions"></u-menubar>
    </div>
    <div class="content"><ng-content></ng-content></div>
  </div>
</div>
