import { Collections, MemorySearch, Search } from '@local/client-contracts';
import { observable, ManualPromise, ValueStorage, Constants } from '@local/common';
import { filterByKey, isLiveCollection, isStaticCollection, isWikiCollection } from '@local/common-web';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { SessionService } from '@shared/services/session.service';
import { cloneDeep, keyBy } from 'lodash';
import moment from 'moment';
import { Observable, ReplaySubject, Subject, Subscription, debounceTime, filter, firstValueFrom, map } from 'rxjs';
import Semaphore from 'semaphore-async-await';
import { CollectionItem, SearchResults, isCollection } from 'src/app/bar/views';
import * as uuid from 'uuid';
import { SearchResultContext, SearchService, SearchSession } from '../..';
import { CollectionsSearchHelperService } from '../../../collections-search-helper.service';
import { CollectionsService } from '../../../collections.service';
import { SearchOptions } from '../../search-options';
import { LinkResourcesSourceSettings } from '../link-resources';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchRequestFilters } from '../search-request-filters';
import { SearchResponse } from '../search-response';
import { CollectionsSourceSettings } from './collections-source-settings';
import { WikisService } from '../../../wikis/wikis.service';
import { FlagsService } from '@shared/services/flags.service';

const filterKeys: string[] = ['collection-createdBy', 'collection-modifiedAt', 'collection-tags', 'favorite', 'collection-type'];
type SearchCollectionsCacheValue = { timestamp: number };
type SearchCollectionsCache = Record<string, SearchCollectionsCacheValue>;
export class CollectionsSearchClient extends MemorySearchClient<CollectionsSourceSettings> {
  private lastTimeSearchCollections: SearchCollectionsCache = {};
  private collectionSearchesEntry: ValueStorage<SearchCollectionsCache>;
  private instances: { [sessionName: string]: { subscriptions: Subscription[]; searchSessions: SearchSession[] } } = {};

  private readonly STORAGE_KEY: string = 'collectionsSearchesTimestamp';
  private readonly refreshMinutes: number = 15;
  private readonly filterClientId = 'collection';
  private disableWikis: boolean;
  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private collectionsService: CollectionsService,
    private wikisService: WikisService,
    private searchService: SearchService,
    private sessionService: SessionService,
    private sessionStorageService: SessionStorageService,
    private flagsService: FlagsService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time'], filterKeys);
    this.logger = logService.scope('CollectionsSearchClient');
    this.collectionSearchesEntry = this.sessionStorageService.getStore('local', 'account').entry<SearchCollectionsCache>(this.STORAGE_KEY);
    this.collectionSearchesEntry.current$.subscribe((current) => {
      this.lastTimeSearchCollections = cloneDeep(current || {});
    });
  }

  @observable
  getInput(request: SearchRequest<CollectionsSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const subject = new ReplaySubject<MemorySearch.Item[]>(1);
    this._innerSearch(request, subject, response);
    const time = request.sourceSettings.debounceTime ?? 0;
    return subject.pipe(debounceTime(time));
  }

  protected async filter(items: MemorySearch.Item[], settings: CollectionsSourceSettings): Promise<any[]> {
    const filters = { ...(settings.filters.preFilters || {}), ...(settings.filters.postFilters || {}) };
    const user = await firstValueFrom(this.sessionService.current$.pipe(filter((v) => !!v)));
    const newItems = [];
    for (const item of items) {
      const collection = item.data as Collections.Collection;

      let satisfyFilter = true;
      for (const key of filterKeys) {
        const filter = filters[key];
        satisfyFilter = filter
          ? filterByKey(key.replace(`${this.filterClientId}-`, ''), user, filter, {
              createdBy: collection.accountId,
              favoriteMarkedTime: collection.favoriteMarkedTime,
              kind: collection.kind,
              modifiedTime: collection.modifiedTime,
              tags: collection.tags,
            })
          : true;
        if (!satisfyFilter) break;
      }

      if (satisfyFilter) {
        newItems.push(item);
      }
    }
    return newItems;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: CollectionsSourceSettings): Promise<SearchResults[]> {
    const newItems: SearchResults[] = [];
    for (const item of items) {
      if (isCollection(item.data)) {
        if (sourceSettings.isFavoriteItem) {
          newItems.push(<any>{ ...item.data, favoriteMarkedTime: item.data.favoriteMarkedTime });
        } else {
          newItems.push(item.data);
        }
      }
    }
    return newItems;
  }

  private async _innerSearch(
    request: SearchRequest<CollectionsSourceSettings>,
    subject: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse
  ) {
    const sourceSettings = request.sourceSettings;
    const sorting = sourceSettings.sorting;
    const instanceKey = this.getInstanceKey(request);
    this.destroy(request.id, instanceKey);
    this.instances[instanceKey] = { subscriptions: [], searchSessions: [] };
    let current: { [id: string]: Collections.Collection } = {};
    await this.initDisableWiki();
    if (sourceSettings?.filters?.preFilters['collection-type']?.includes('Wiki') && this.disableWikis) {
      subject.next([]);
      return;
    }
    this.instances[instanceKey].subscriptions.push(
      this.getCollectionsItems(request.query, sourceSettings.collectionIds, sourceSettings.filters).subscribe((items) => {
        if (response.cancelled) return;
        const hasFilters =
          !!Object.values(sourceSettings.filters?.postFilters || {}).length ||
          !!Object.values(sourceSettings.filters?.preFilters || {}).length;
        if (!hasFilters) {
          response.extra = { totalResults: items.length };
        }
        const mapItems = [];
        for (const collection of items) {
          collection.resultsCount = collection.resultsCount || current[collection.id]?.resultsCount;
          mapItems.push({
            data: collection,
            searchText: this.buildTextForSearch(collection),
            sortValue: this.getSortValue(sorting, collection),
          });
        }
        subject.next(mapItems);
        current = keyBy(items, (i) => i.id);
        if (items.length) {
          this.updateResultsCount(response, items, subject, request.trigger, instanceKey, sourceSettings);
        }
      })
    );
  }
  private async initDisableWiki() {
    this.disableWikis = await this.flagsService.isEnabled(Constants.DISABLED_WIKIS_FLAG);
  }
  private getInstanceKey(request: SearchRequest<CollectionsSourceSettings>) {
    let name = request.sessionName;
    if (request.sourceSettings.id) {
      name += `-${request.sourceSettings.id}`;
    }
    return name;
  }

  private getCollectionsItems(query: string, collectionIds: string[], reqFilters: SearchRequestFilters): Observable<CollectionItem[]> {
    return this.collectionsService.all$.pipe(
      map((items) => {
        if (!items) {
          return [];
        }
        items = collectionIds?.length ? items.filter((c) => collectionIds.includes(c.id)) : items;
        const filters = { ...(reqFilters?.preFilters || {}), ...(reqFilters?.postFilters || {}) };
        const kinds = filters['collection-type'] || ['Live', 'Static', 'Wiki'];
        items = items.filter((c) => kinds.includes(c.kind));
        const allCollectionsMap = {};
        items.forEach((c) => (allCollectionsMap[c.id] = c));
        let mapItems: CollectionItem[] = items.map((item) => {
          const collectionItems = this.mapCollectionItems(item, allCollectionsMap);
          return { ...item, type: 'collection', items: collectionItems };
        });
        if (query) {
          mapItems = mapItems.filter((item) => this.buildTextForSearch(item).includes(query.toLowerCase()));
        }
        return mapItems;
      })
    );
  }

  private mapCollectionItems(collection: Collections.Collection, allCollectionsMap: { [key in string]: Collections.Collection }) {
    if (!isStaticCollection(collection)) {
      return;
    }
    return collection.items
      ?.map((item) => {
        if (item?.kind === 'collection' && !allCollectionsMap[item.id]) return;
        return item;
      })
      .filter((item) => !!item);
  }

  private buildTextForSearch(collection: CollectionItem): string {
    return [collection.title ?? '', collection.description ?? ''].join(' ').toLowerCase();
  }

  protected defaultSort(items: MemorySearch.Item[]): MemorySearch.Item[] {
    return items.sort((prev, current) => current?.data?.modifiedTime - prev?.data?.modifiedTime);
  }

  private async updateResultsCount(
    response: SearchResponse,
    items: CollectionItem[],
    subject: Subject<MemorySearch.Item[]>,
    trigger: string,
    instanceKey: string,
    settings: CollectionsSourceSettings
  ) {
    const sorting = settings.sorting;
    const tasks = await this.getCollectionsResultsCount(response, items, trigger, instanceKey);
    if (response.cancelled) {
      return;
    }
    for (let index = 0; index < tasks.length; index++) {
      const task = tasks[index];
      const collection = items[index];
      task.then((count) => {
        collection.resultsCount = count;
        const mapItems = items.map((collection) => ({
          data: collection,
          searchText: this.buildTextForSearch(collection),
          sortValue: this.getSortValue(sorting, collection),
        }));
        subject.next(mapItems);
      });
    }
  }

  private liveSourceSettings(
    collection: Collections.LiveCollection,
    searchParams: Collections.LiveSearchParams
  ): LinkResourcesSourceSettings[] {
    const cacheValue = this.lastTimeSearchCollections[collection.id];
    return [
      {
        type: 'link-resources',
        requestMaxCount: 0,
        caching: { strategy: this.getCacheStrategy(cacheValue) },
        useSourceFilters: true,
        sessionId: uuid.v4(),
        tag: 'get-collections-count',
        disableAggregations: true,
        preventRTF: true,
        contentSearch: true,
        advancedSearch: true,
        sorting: searchParams?.sort,
        filters: { preFilters: searchParams?.filters || {}, postFilters: {} },
      } as LinkResourcesSourceSettings,
    ];
  }

  async getCollectionsResultsCount(response: SearchResponse, collections: Collections.Collection[], trigger: string, instanceKey: string) {
    const tasks: Promise<number>[] = [];
    const locks = new Semaphore(5);
    for (const collection of collections) {
      const task = new ManualPromise<number>();
      tasks.push(task);
      try {
        await locks.acquire();
        if (response.cancelled) {
          task.resolve(0);
          return;
        }
        await this.getCollectionResultsCount(collection, task, trigger, instanceKey);
      } finally {
        locks.release();
      }
    }
    this.collectionSearchesEntry.set(this.lastTimeSearchCollections);
    return tasks;
  }

  async getCollectionResultsCount(collection: Collections.Collection, task: ManualPromise<number>, trigger: string, instanceKey: string) {
    const isLive = isLiveCollection(collection);
    if (isLive) {
      const searchParams = collection.searchParams || {};
      if (!Object.keys(searchParams).length || (!searchParams.query && !Object.keys(searchParams.filters || {}).length)) {
        task.resolve(0);
        return;
      }
    }
    this.lastTimeSearchCollections[collection.id] = {
      timestamp: moment().valueOf(),
    };

    let collectionError = false;
    if (!isLive && !isStaticCollection(collection) && !isWikiCollection(collection)) {
      this.logger.error(`getCollectionsResultsCount collection missing type, id: ${collection.id}`);
      collectionError = true;
    }

    if (isWikiCollection(collection)) {
      const countCards = await this.wikisService.getCountCardsWiki(collection.id);
      task.resolve(countCards);
      return;
    }

    const searchParams = collection.searchParams;
    const sourceSettings =
      isLive || collectionError
        ? this.liveSourceSettings(collection as Collections.LiveCollection, searchParams)
        : CollectionsSearchHelperService.getSourceSettings(collection, null, searchParams, false, 'only', true);

    const searchSession = this.searchService.getOrCreateSearchSession(`collection-${collection.id}`);
    this.instances[instanceKey].searchSessions.push(searchSession);
    const options: SearchOptions = {
      resetSession: true,
      query: isLive ? collection.searchParams?.query || '' : '',
      sources: sourceSettings,
      trigger: trigger ?? 'collections/user_query',
    };
    const subscription = searchSession.search$(options).subscribe((ctx: SearchResultContext) => {
      if (ctx.searchCompleted) {
        const totalResults: number = CollectionsSearchHelperService.getCollectionResultsCount(collection, ctx);
        searchSession.destroy();
        task.resolve(totalResults);
      }
    });
    this.instances[instanceKey].subscriptions.push(subscription);
  }

  private getSortValue(sorting: Search.Sort, collection: CollectionItem): string | number {
    let sortValue: number | string;
    switch (sorting?.by) {
      case 'Alphabetical':
        sortValue = collection?.title;
        break;
      case 'Timestamp':
        sortValue = collection.modifiedTime;
        break;
    }
    return sortValue;
  }

  destroy(id: number, sessionName: string): void {
    const instance = this.instances[sessionName];
    if (instance) {
      instance.subscriptions?.forEach((c) => c.unsubscribe());
      instance.searchSessions?.forEach((c) => c.destroy());
      delete this.instances[sessionName];
    }
  }

  private getCacheStrategy(cacheValue: SearchCollectionsCacheValue): Search.SearchCacheStrategy {
    const now = moment();
    const prevTime = cacheValue?.timestamp || 0;
    const lastSearchTime = moment(prevTime);
    if (!prevTime || Math.abs(now.diff(lastSearchTime, 'minutes')) > this.refreshMinutes) {
      return 'cache-and-source';
    }
    return 'cache-or-source';
  }
}
