import { Filters, MemorySearch, Search } from '@local/client-contracts';
import { EventInfo, LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep } from 'lodash';
import { from, map, Observable, ReplaySubject } from 'rxjs';
import { HeaderItem, SearchResults, TelemetryTrigger } from 'src/app/bar/views/results/models/results-types';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SourceSettings } from '../source-settings';
import { isMatrix } from '@shared/utils';
import { SourceSettingsHeader } from '../../models/source-settings-header';
import { MemorySearchItems } from '../../models/memory-search-items.model';
import { SourceResultItems } from '../../models/source-results-items.model';
import { DEFAULT_FOOTER_TITLE } from '../../models/search-client.constants';

export interface MemorySearchSettings extends SourceSettings {
  maxCount?: number;
  header?: SourceSettingsHeader;
  showOnDone?: boolean;
  noFooter?: boolean;
  minQueryLength?: number;
  matchStrategy?: MemorySearch.MatchStrategy;
}

export abstract class MemorySearchClient<T extends MemorySearchSettings> implements SearchClient<T> {
  protected logger: Logger;

  constructor(
    protected logService: LogService,
    protected memorySearchService: MemorySearchService,
    private sortTypes?: Search.SortType[],
    private filters?: string[]
  ) {
    this.logger = this.logService.scope('memory-search');
  }

  supportsSort(sort: Search.Sort): boolean {
    if (!sort) return true;
    return (this.sortTypes || []).includes(sort.type);
  }

  supportsFilters(filters: { preFilters?: Filters.Values; postFilters?: Filters.Values }): boolean {
    if (!filters) return true;
    const filterKeys = [...Object.keys(filters.preFilters || {}), ...Object.keys(filters.postFilters || {})];
    const filtersSet = new Set(filterKeys);
    return [...filtersSet.values()].every((x) => this.filters?.includes(x));
  }

  supportsMatch(response: SearchResponse): boolean {
    return true;
  }

  async search(request: SearchRequest<T>, response: SearchResponse): Promise<Observable<void>> {
    let first = true;
    const result$ = await this.innerSearch(request, response);

    return result$.pipe(
      map((results) => {
        if (response.cancelled) {
          return;
        }
        response.items = results;
        const sourceSettings = request.sourceSettings;
        if (first) {
          response.notifyUpdated();
        }
        first = false;
        if (!sourceSettings.showOnDone) {
          response.complete(true);
        }
      })
    );
  }

  getTelemetryEndEvent(response: SearchResponse): Partial<EventInfo>[] {
    return [];
  }

  nextPage(request: SearchRequest<T>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {
    return;
  }

  protected addHeaders(
    request: SearchRequest<T>,
    items: SearchResults[],
    resultCount: number,
    totalResults: number,
    response: SearchResponse
  ): void {
    const settings = request.sourceSettings;
    if (!settings.header) return;

    let { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, totalResults);
    const header: HeaderItem = {
      type: 'header',
      clickable: settings.header.clickable,
      origin: settings.type,
      title,
      titleEnd,
      group: settings.header?.group,
    };
    items.unshift(header);

    const addFooter = totalResults > settings.maxCount;

    if (!settings.noFooter && addFooter) {
      const footer: HeaderItem = {
        type: 'header',
        clickable: true,
        origin: `footer-${settings.type}`,
        title: settings.footer?.title || DEFAULT_FOOTER_TITLE,
        isFooter: true,
        selectable: true,
        group: settings.header.group ?? undefined,
      };
      items.push(footer);
    }
  }

  abstract getInput(request: SearchRequest<T>, response: SearchResponse): Promise<MemorySearch.Item[]> | Observable<MemorySearchItems>;

  abstract getOutput(items: MemorySearchItems, sourceSettings?: T): Promise<SourceResultItems>;

  protected defaultSort(items: MemorySearch.Item[]): MemorySearch.Item[] {
    return items;
  }

  protected async rank(queryTokens: string[], items: MemorySearch.Item[], settings: T): Promise<MemorySearch.Item[]> {
    if (!queryTokens?.length && !settings.sorting) {
      return this.defaultSort(items);
    }
    const rankedItems = await this.memorySearchService.rank(queryTokens, items, settings.sorting);
    return rankedItems;
  }

  protected tokenize(s: string): string[] {
    return this.memorySearchService.tokenize(s);
  }

  protected addHighlights(items: SourceResultItems, queryTokens: string[], response: SearchResponse): SourceResultItems {
    return items.map((r) => ({ ...r, highlights: queryTokens }));
  }

  protected async filter(items: MemorySearchItems, settings: T): Promise<any[]> {
    return items;
  }

  private async innerSearch(request: SearchRequest<T>, response: SearchResponse) {
    const skipInput = request.sourceSettings.minQueryLength > (request.query?.length || 0);
    const items$ = from(!skipInput ? this.getInput(request, response) : Promise.resolve([]));
    let task: Promise<void> = Promise.resolve(null);
    const result$ = new ReplaySubject<SourceResultItems>(1);

    items$.subscribe({
      next: (items) => {
        task = task.then(async () => {
          try {
            let results = await this.handleItems(request, response, items);

            results = isMatrix(results) && results.length === 1 ? results[0] : results;
            if (response.cancelled) {
              return;
            }
            result$.next(results || []);
          } catch (error) {
            result$.error(error);
          }
        });
      },
      error: (e) => {
        result$.error(e);
      },
      complete: () => {
        task.then(
          () => {
            result$.complete();
          },
          (e) => {
            this.logger.error('got error in memory search client', e);
            if (response.cancelled) {
              return;
            }
            response.error = e;
          }
        );
      },
    });
    return result$;
  }

  private async handleItems(request: SearchRequest<T>, response: SearchResponse, items: MemorySearchItems) {
    items = cloneDeep(items || []);
    const sourceSettings = request.sourceSettings;
    if (sourceSettings.filters) {
      items = await this.filter(items, sourceSettings);
      if (response.cancelled) {
        return;
      }
    }

    if (!items.length) {
      return [];
    }

    const res: MemorySearch.Response[] = [];
    const requestItems = isMatrix(items) ? items : [items];
    const supportMatch = this.supportsMatch(response);
    for (const items of requestItems) {
      if (!supportMatch) {
        res.push({ items, queryTokens: this.tokenize(request.query) } as MemorySearch.Response);
        continue;
      }
      const r = await this.memorySearchService.search({ items: items, query: request.query, matchStrategy: sourceSettings.matchStrategy });
      res.push(r);
    }

    if (response.cancelled || !res?.length) {
      return [];
    }

    const sorted: MemorySearch.Item[][] = [];
    const queryTokensArr: string[][] = [];
    const totalResults: number[] = [];

    for (const r of res) {
      queryTokensArr.push(r.queryTokens);
      totalResults.push(r.items.length);
      const s = await this.getSortedItems(r, sourceSettings);
      sorted.push(s);
    }

    const results = await this.getOutput(sorted.length === 1 ? sorted[0] : sorted, sourceSettings);

    if (response.cancelled || !results?.length) {
      return [];
    }

    const outputResult = isMatrix(results) ? results : [results];

    const enhancedResult = [];
    let index = 0;
    for (const r of outputResult) {
      enhancedResult.push(this.addHighlights(r, queryTokensArr[index], response));
      index++;
    }

    if (enhancedResult.length != sorted.length) {
      throw new Error('number of results did not match the number of input items');
    }

    index = 0;
    for (const r of enhancedResult) {
      this.addSearchTokens(r, sorted[index]);
      index++;
    }

    if (enhancedResult.length && sourceSettings.header) {
      index = 0;
      for (const r of enhancedResult) {
        if (!r.length) {
          index++;
          continue;
        }
        this.addHeaders(request, r, r.length, response.extra?.totalResults || totalResults[index], response);
        index++;
      }
    }

    return enhancedResult;
  }

  private async getSortedItems(res: MemorySearch.Response, sourceSettings): Promise<MemorySearch.Item[]> {
    const { items: matched, queryTokens } = res;
    let sorted = await this.rank(queryTokens, matched, sourceSettings);
    const totalResults = sorted.length;
    const max = sourceSettings.maxCount;
    if (max && max < totalResults) {
      sorted = sorted.slice(0, max);
    }
    return sorted;
  }

  private addSearchTokens(results, sorted) {
    let index = 0;
    for (const r of results) {
      r.searchTokens = sorted[index++].tokens;
    }
  }

  refresh(request: SearchRequest<T>, response: SearchResponse) {
    if (response.cancelled) {
      return;
    }
    this.innerSearch(request, response).then((result$) => {
      result$.subscribe({
        next: (results) => {
          if (response.cancelled) {
            return;
          }
          response.items = results;
          response.notifyUpdated();
        },
        error: (e) => {
          this.logger.error('got error in memory search client', e);
          if (response.cancelled) {
            return;
          }
          response.error = e;
          response.notifyUpdated();
        },
      });
    });
  }
}
