import { Injectable } from '@angular/core';
import { Blobs, Permissions, Wiki } from '@local/client-contracts';
import { getExtensionByFileName, getIconByExtension } from '@local/ts-infra';
import { stopEvent } from '@shared/utils/elements-util';
import { BlobsService } from 'src/app/bar/services/blobs/blob.service';
import { BlobsStorageService } from 'src/app/bar/services/blobs/blobs-storage.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { FrameEditorContextMenuService } from 'src/app/bar/views/frame-editor/services/frame-editor-context-menu.service';
import tinymce from 'tinymce';
import { validateFileSize } from '../constants/frame-editor-utils';
import { FrameEditorConstants } from '../constants/frame-editor.constants';
import { FrameEditorBlobService } from './frame-editor-blob.service';
import { FrameEditorRenderHtmlService } from './frame-editor-render-html.service';

export type StatusUploader = 'success' | 'failed' | 'deleted' | 'none';

@Injectable()
export class FrameEditorFileService {
  private readonly MAX_ATTACHMENT_FILE_SIZE_MB = 10;

  constructor(
    private blobsService: BlobsService,
    private showToasterService: ShowToasterService,
    private frameEditorStorageAttachmentsService: BlobsStorageService<Wiki.CardAttachment>,
    private frameEditorContextMenuService: FrameEditorContextMenuService,
    private frameEditorRenderHtmlService: FrameEditorRenderHtmlService,
    private frameEditorBlobService: FrameEditorBlobService
  ) {}

  getAttachment(event: any, stopPropagationEvent = true) {
    const attachment = event.target.closest(`.${FrameEditorConstants.CLASS_ATTACHMENT}`);
    if (!attachment) return;
    if (stopPropagationEvent) {
      stopEvent(event);
    }
    return attachment;
  }

  uploadFile(followingId: string, editorId: string) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '*');
    input.onchange = () => {
      const file: File = input.files[0];
      this.uploadAttachment(file, followingId, editorId);
    };
    input.click();
  }

  async uploadAttachment(file: File, followingId: string, editorId: string) {
    const { name, size, type } = file;
    if (!validateFileSize(editorId, size, this.MAX_ATTACHMENT_FILE_SIZE_MB, this.showToasterService)) {
      return;
    }
    const id = await this.frameEditorBlobService.createBlob(followingId, editorId, name, type);
    if (!id) {
      return;
    }
    this.blobsService.upload(file, id).catch(() => {
      this.frameEditorBlobService.failedToUpload(editorId, file.name, 'upload_attachment_failed', id);
    });
    const icon = getIconByExtension(getExtensionByFileName(name));
    const ext = getExtensionByFileName(name);
    const selectedNode = tinymce.get(editorId).selection.getNode();
    const attachment = this.frameEditorBlobService.createdAttachment(id, type, name, size, 'File');
    this.frameEditorStorageAttachmentsService.addAttachment(followingId, attachment);
    if (selectedNode.textContent || selectedNode.nodeName.toLocaleLowerCase() !== 'p') {
      if (selectedNode.querySelector('video')) {
        selectedNode.insertAdjacentHTML('afterend', '<p><br></p>');
        tinymce.get(editorId).selection.setCursorLocation(selectedNode.nextElementSibling, 0);
        tinymce.get(editorId).focus();
      } else {
        tinymce.get(editorId).selection.select(selectedNode, true);
        tinymce.get(editorId).selection.collapse(false);
        tinymce.get(editorId).insertContent('<p><br></p>');
      }
    }
    this.insertAttachment(id, editorId, icon, name, size, ext);
  }

  private insertAttachment(id: string, editorId: string, icon: string, name: string, size: number, ext: string) {
    const insertAttachment = this.getAttachmentHtml(id, icon, name, size, ext);
    tinymce.get(editorId).execCommand('mceInsertContent', false, insertAttachment);
  }

  private getAttachmentHtml(id: string, icon: string, name: string, size: number, ext: string) {
    return `<div class="${FrameEditorConstants.CLASS_ATTACHMENT}" id="${id}" contenteditable="false" >
              ${this.frameEditorRenderHtmlService.getInnerAttachmentHtml(icon, name, size, ext)} 
            </div><p class="bogus-attachment"><br data-mce-bogus="1"></p>`;
  }

  async duplicateAttachments(
    content: string,
    attachments: Wiki.CardAttachment[],
    shareOptions: Permissions.ShareOptions
  ): Promise<{ content?: string; attachments?: Wiki.CardAttachment[]; status: StatusUploader }> {
    if (!attachments) {
      return { content, attachments, status: 'none' };
    }

    try {
      const duplicateBlobRequests: Blobs.DuplicateBlobRequest[] = attachments.map((attachment) => ({
        id: attachment.blobId,
        shareOptions,
      }));

      const duplicateBlobResponses = await this.blobsService.duplicate({ entries: duplicateBlobRequests });

      duplicateBlobResponses.entries.forEach((response) => {
        content = content.replace(response.fromId, response.toId);
      });

      const duplicateBlobMap = new Map<string, string>();
      duplicateBlobResponses.entries.forEach((response) => {
        duplicateBlobMap.set(response.fromId, response.toId);
      });

      attachments.forEach((attachment) => {
        const toId = duplicateBlobMap.get(attachment.blobId);
        if (toId !== undefined) {
          attachment.blobId = toId;
        }
      });
    } catch (error) {
      console.error('Error duplicating file on frame editor', error);
      return { status: 'failed' };
    }

    return { content, attachments, status: 'success' };
  }

  destroy() {
    this.frameEditorContextMenuService.destroyHandlers();
  }
}
