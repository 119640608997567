<div class="go-links-popup-background" (mousedown)="closePopup($event, 'background')">
  <div
    class="go-links-popup-container"
    (mousedown)="onContainerClick()"
    [ngClass]="{ 'go-links-popup-c-loading': !viewModel, launcher: isLauncher }"
    #test
  >
    <div *ngIf="!viewModel; else content" class="go-links-popup-container-loading">
      <loader-overlay class="go-links-popup-loading" [displayText]="true"> </loader-overlay>
    </div>

    <ng-template #content>
      <div class="close-popup" data-cy="go-links-popup-close">
        <u-icon
          class="close-icon"
          *ngIf="!isLauncher && !isExtension"
          (mousedown)="closePopup($event)"
          [model]="closeIcon"
          [styles]="{ fontSize: '12px' }"
        ></u-icon>
        <u-icon
          class="close-launcher-icon"
          *ngIf="isLauncher"
          (mousedown)="closePopup($event)"
          [model]="closeIconLauncher"
          [styles]="{ fontSize: '14px' }"
        ></u-icon>
      </div>

      <div data-cy="go-links-popup-container" class="go-links-popup-header">
        <div class="go-links-popup-header-title">
          @if (isExtension) {
            <u-icon
              class="chevron-left"
              [model]="{ type: 'font', value: 'icon-chevron-left' }"
              [styles]="{ fontSize: '14px' }"
              (click)="closePopup($event)"
            ></u-icon>
          }

          <div data-cy="go-links-popup-title" class="title">{{ editGoLink ? 'Edit' : 'Create New' }} Go Link</div>
          @if (sharedAvatarsList.length > 0) {
            <avatar-list
              [model]="sharedAvatarsList"
              [tooltipHeader]="'Shared with'"
              [spreadAvatarsCount]="3"
              [tooltipLimit]="10"
              [countAll]="sharedAvatarsList.length"
            ></avatar-list>
          }
          @if (editGoLink && !isExtension) {
            <u-button class="no-border-button" label="Share" type="secondary" elementSize="small" (onClick)="onShareClick()"></u-button>
          }
        </div>
        <div class="divider"></div>
      </div>
      <div class="go-links-popup-action">
        <div *ngIf="smallScreenLauncher; else main">
          <ng-scrollbar visibility="hover" [style.height]="'335px'">
            <ng-container *ngTemplateOutlet="main"></ng-container>
          </ng-scrollbar>
        </div>
        <ng-template #main>
          <div class="go-links-popup-main" data-cy="go-links-popup-form-container">
            <div class="go-links-popup-content">
              <div class="title" data-cy="go-links-popup-form-url-title">
                Destination URL
                <div class="required-field">&nbsp;*</div>
              </div>
              <u-input
                data-cy="go-links-popup-form-url-input"
                [styles]="{ width: '100%', height: '32px', 'padding-right': withVariable && !errors?.errorUrl ? '86px' : '32px' }"
                [inputSize]="'medium'"
                [placeholder]="'Please enter a URL'"
                [removeText]="true"
                (onChange)="onUrlChange($event)"
                (onFocus)="onFocus('url')"
                (onBlur)="onBlurUrl($event)"
                (onClear)="onClearUrl()"
                [model]="viewModel?.url"
                [ngClass]="{
                  'errors-input': errors?.errorUrl && !errors.errorExistsUrls,
                  'filled-input': viewModel?.url,
                  'variable-input': withVariable && !errors?.errorUrl,
                }"
                #inputUrl
                id="inputUrl"
              >
                <div *ngIf="withVariable && !errors?.errorUrl" class="variable">Variable</div>
              </u-input>
              <div data-cy="go-links-popup-form-url-error" class="errors" *ngIf="errors?.errorUrl">
                <ng-container *ngIf="errors?.errorExistsUrls; else errorUrlTemplate">
                  <div class="error-exists-urls">
                    <div class="warning-icon"><div class="icon">!</div></div>
                    <div class="error-text-wrapper">
                      <div class="error-urls-text">
                        {{ errors?.errorUrl }}
                      </div>
                      <ng-container *ngFor="let url of errors.errorExistsUrls; let index = index; let first = first; let last = last">
                        <div class="p errors-link" (click)="existsUrl()">go/{{ url.name }}</div>
                        <div class="p error-plus" *ngIf="!last">+</div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <ng-template #errorUrlTemplate>{{ errors?.errorUrl }}</ng-template>
              </div>
            </div>
            <div class="go-links-popup-content">
              <div class="title" data-cy="go-links-popup-form-shortcut-title">
                Link shortcut
                <div class="required-field">&nbsp;*</div>
              </div>
              <u-input-group
                data-cy="go-links-popup-form-shortcut-input"
                [model]="viewModel?.name"
                [styles]="{ width: '100%', height: '32px', 'padding-right': '32px' }"
                [symbol]="'go/'"
                [placeholder]="'Your link name'"
                [removeText]="true"
                [focus]="focusName"
                (onChange)="onNameChange($event)"
                (onBlur)="onBlurName($event)"
                [ngClass]="{ 'errors-input': errors?.errorName, 'filled-input': viewModel?.name }"
                (onFocus)="onFocus('link_shortcut')"
              >
              </u-input-group>

              <div class="errors" *ngIf="errors?.errorName">
                <div data-cy="go-links-popup-form-shortcut-error" *ngIf="errors?.errorExistsName; else errorNameTemplate">
                  <div *ngIf="errors?.errorExistsNameCreatedByYou; else errorExistsName">
                    {{ errors?.errorName }}
                    <p class="errors-link" (click)="existsNameCreatedByYou()">here.</p>
                  </div>
                  <ng-template #errorExistsName>
                    {{ errors?.errorName }}<span class="errors-link" (click)="existsName()">exists.</span>
                    Try something else.
                  </ng-template>
                </div>
                <ng-template #errorNameTemplate>{{ errors?.errorName }}</ng-template>
              </div>
            </div>
            <div class="go-links-popup-content">
              <div data-cy="go-links-popup-form-description-title" class="title">Description</div>
              <u-textarea
                data-cy="go-links-popup-form-description-input"
                [styles]="{ width: '100%', height: isLauncher ? '48px' : '56px' }"
                [rows]="3"
                [cols]="49"
                [placeholder]="'Enter a brief description of the link'"
                [autofocus]="false"
                (onChange)="onDescriptionChange($event)"
                [model]="viewModel?.description"
                [ngClass]="{ 'filled-input': viewModel?.description }"
                (onFocus)="onFocus('description')"
              >
              </u-textarea>
            </div>
            <div class="go-links-popup-content unlisted">
              <u-checkbox
                data-cy="go-links-popup-form-unlisted-checkbox"
                [checked]="!viewModel.listed"
                [binary]="true"
                (click)="onClickUnlisted($event)"
              >
              </u-checkbox>
              <div class="text-unlisted" data-cy="go-links-popup-form-unlisted-title">Unlisted Go Link</div>
              <u-icon
                [model]="{ type: 'font', value: 'icon-info' }"
                [styles]="{ color: 'var(--color-primary)' }"
                [pTooltip]="unlistedTooltip"
                tooltipPosition="top"
                tooltipStyleClass="u-tooltip"
              ></u-icon>
            </div>
            <div class="go-links-popup-content create-new-tag">
              <div class="title" data-cy="go-links-popup-form-tags-title">Tags</div>
              <u-auto-complete
                data-cy="go-links-popup-form-tags-input"
                field="name"
                placeholder="Select or create a new tag"
                [suggestionItems]="tagList"
                [selectedItems]="viewModel.tags"
                [multiple]="true"
                [createNewOption]="true"
                [openListOnClick]="true"
                [className]="'tags'"
                (onChange)="onTagsChange($event)"
                (onBlur)="onBlurTags($event)"
                (inputQueryChange)="queryChangesAutoComplete($event)"
                [ngClass]="{ 'filled-input': viewModel?.tags?.length > 0 }"
                [appendTo]="test"
                #autoComplete
                (onFocus)="onFocus('tags')"
              >
                <ng-template let-value pTemplate="selectedItem">
                  <span>{{ value.name }}</span>
                </ng-template>
                <ng-template let-value pTemplate="item">
                  <div class="tag" *ngIf="!value.isNew">
                    <div class="tag-name">{{ value.name }}</div>
                    <div class="tag-description">{{ value.description }}</div>
                  </div>
                </ng-template>
                <ng-template pTemplate="footer">
                  <div class="new-tag sticky" *ngIf="displayCreateNew()" (click)="createNewTag()">
                    <div class="new">Create new</div>
                    <div class="tag-name">{{ inputTextAutoComplete }}</div>
                  </div>
                </ng-template>
              </u-auto-complete>
            </div>
            <div class="divider end"></div>
          </div>
        </ng-template>

        <div class="buttons">
          <div class="button-content">
            <u-button
              data-cy="go-links-popup-cancel-button"
              [label]="'Cancel'"
              [styles]="{ width: '96px' }"
              type="secondary"
              class="left-button"
              (mousedown)="closePopup($event)"
              id="cancel"
            ></u-button>
            <u-button
              [versionNumber]="2"
              [label]="editGoLink ? 'Update' : 'Create' + ' Go Link'"
              typeElement="primary"
              [sizeElement]="'large'"
              [stateElement]="loadingButton ? 'loading' : disabledButton() ? 'disabled' : 'regular'"
              (onClick)="saveGoLink()"
            ></u-button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #errorsPopupContent>
  <div class="errors-popup">
    <div class="close-popup">
      <u-icon class="close-icon" (click)="closeErrorsPopup()" [model]="closeIcon" [styles]="{ fontSize: '11px' }"></u-icon>
    </div>
    <div class="errors-popup-icon">!</div>
    <div class="errors-popup-message">
      Something went wrong <br />
      Please try again in a few minutes.
    </div>
    <u-button [label]="'Close'" [styles]="{ width: '70px' }" type="primary-danger" (click)="closeErrorsPopup()"></u-button>
  </div>
</ng-template>
