<div class="header">
  <section class="search-container">
    <input class="search-input" #searchInput type="text" (keydown)="onKeyDown($event)" [(ngModel)]="query" />
    <div class="search-placeholder" #searchInputAutoComplete [innerHTML]="!query ? 'Search for an action or a page' : ''"></div>
  </section>
</div>
<div class="results-container-wrapper">
  <smooth-height [trigger]="query">
    <section #resultsContainer class="results-container" [style.height.px]="scrollSize()">
      <ng-scrollbar
        [autoHeightDisabled]="false"
        class="virtual-scroll"
        visibility="hover"
        [ngClass]="{ 'no-scroll-bar': noScrollbar }"
        [style.maxHeight.px]="!query ? MAX_SCROLL_HEIGHT_NO_RESULTS : MAX_SCROLL_HEIGHT"
      >
        <cdk-virtual-scroll-viewport appendOnly [itemSize]="33" ngScrollbarView smoothScroll scrollViewport [class.virtual-scroll]="true">
          <ng-container *cdkVirtualFor="let item of searchResultsGroup(); index as i; trackBy: trackItem" [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="'parent'">
              <p class="results-item-title" #scrollItem>{{ item.title }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="'child'">
              <search-popup-item
                [model]="item"
                [class.selected]="selectedIndex === i"
                (click)="onItemClick($event, item)"
                #scrollItem
              ></search-popup-item>
            </ng-container>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </section>
  </smooth-height>
  @if (query) {
    <div class="sticky-search-line-wrapper">
      <div class="search-line" [class.selected]="selectedIndex === items?.length - 1" (click)="onSearch($event, 'mouse_click')" #scrollItem>
        <entity-icon [model]="lastSearchItem.icon" [size]="'14px'" class="search-line-icon"></entity-icon>
        <div class="search-line-text">
          {{ lastSearchItem.title }} “<strong>{{ query }}</strong
          >”
        </div>
      </div>
    </div>
  }
</div>
