import { Injectable } from '@angular/core';
import { Applications, OAuth1, OAuth2, Signin } from '@local/client-contracts';
import { OAuthCompletionRpcHandler as OAuthCompetionService } from '@local/common';
import { isNativeWindow } from '@local/common-web';
import { OAuth1SessionsRpcInvoker } from '@shared/components/link-settings/oauth1-sessions-rpc-invoker';
import { OAuth2SessionsRpcInvoker } from '@shared/components/link-settings/oauth2-sessions-rpc-invoker';
import { Observable } from 'rxjs';
import { ResourceAccessType } from 'src/app/bar/views/settings/components/apps/choose-resource-access-type/choose-resource-access-type.component';
import { v4 as uuid } from 'uuid';
import { NativeServicesRpcService, ServicesRpcService } from '..';
import { ApplicationsService } from '../applications.service';
import { LogService } from '../log.service';
import { FlagsService } from '../testim-flags.service';
import { OAuthCompletionRpcHandler } from './oauth-completions-rpc-handler';
import {
  MixedOAuthSettings,
  OAuth1Settings,
  OAuth2Settings,
  OAuth2SignInSettings,
  OAuth2UnattendedSettings,
  OAuth2UnattendedWindow,
  OAuthBrowserWindow,
  OAuthPopupWindow,
  OAuthSettings,
  OAuthWindow,
} from './oauth-window';
import { OAuth2SignInSessionsRpcInvoker } from '@shared/rpcs/oauth2-signIn-session-rpc-invoker';
import { SessionProvider } from '../session.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthWindowCollection {
  private oauth1: OAuth1.SessionService;
  private oauth2: OAuth2.SessionService;
  private oauth2SignIn: Signin.SignInSessionService;
  constructor(
    private applications: ApplicationsService,
    host: ServicesRpcService,
    native: NativeServicesRpcService,
    log: LogService,
    private flagsService: FlagsService
  ) {
    this.oauth2 = (native || host).invokeWith(OAuth2SessionsRpcInvoker, 'oauth2appsessions');
    this.oauth1 = (native || host).invokeWith(OAuth1SessionsRpcInvoker, 'oauth1sessions');
    this.oauth2SignIn = host.invokeWith(OAuth2SignInSessionsRpcInvoker, 'oauth2signinsessions');
    const completion = new OAuthCompetionService(log.logger, this.oauth1, this.oauth2, this.oauth2SignIn);
    new OAuthCompletionRpcHandler(completion).init();
  }

  private collection: { [key: string]: { window: OAuthWindow; type: Applications.AuthenticationType } } = {};

  create(appId: string, resourceAccessType: ResourceAccessType = 'Discovery'): { id: string; window: OAuthWindow } {
    let app = this.applications.apps[appId];

    const id = uuid();
    const refreshToken = this.flagsService.get<string | null>(`${appId}-refresh-token`);
    const linkSettings = resourceAccessType === 'Permissions' && app.resourcePermissions ? app.resourcePermissions : app.link;
    let settings: OAuthSettings;
    let result: OAuthWindow;

    if (app.authenticationType == 'oauth1') {
      settings = new OAuth1Settings(id, app, this.oauth1, linkSettings);
    } else if (app.authenticationType == 'oauth2' && refreshToken) {
      const ous = new OAuth2UnattendedSettings(id, app, this.oauth2, linkSettings, refreshToken);
      settings = ous;
      result = new OAuth2UnattendedWindow(this.oauth2, ous);
    } else if (app.authenticationType == 'oauth2') {
      settings = new OAuth2Settings(id, app, this.oauth2, linkSettings);
    } else {
      settings = new MixedOAuthSettings(id, app, this.oauth1, this.oauth2);
    }

    if (!result) {
      if (isNativeWindow()) {
        result = new OAuthBrowserWindow(settings);
      } else {
        result = new OAuthPopupWindow(settings);
      }
    }

    this.collection[id] = { window: result, type: app.authenticationType };

    return { id, window: result };
  }

  createSignIn(provider: SessionProvider) {
    const id = uuid();

    const settings: OAuthSettings = new OAuth2SignInSettings(id, this.oauth2SignIn, {
      name: provider.name,
      authorizeEndpoint: provider.endpoint,
      clientId: provider.clientId,
      redirectUri: provider.redirectUri,
      scopes: provider.scopes,
    });

    const result: OAuthWindow = new OAuthPopupWindow(settings);
    this.collection[id] = { window: result, type: 'mixed' };

    return { id, window: result };
  }

  get(id: string): OAuthWindow {
    return this.collection[id]?.window;
  }

  remove(id: string): void {
    const window = this.collection[id]?.window;
    if (window) {
      window.destroy();
      delete this.collection[id];
    }
  }

  oauthComplete$(id: string): Observable<any> {
    const collection = this.collection[id];
    if (collection.type == 'oauth2') {
      return this.oauth2.completed$(id);
    }
    if (collection.type == 'oauth1') {
      return this.oauth1.completed$(id);
    }
    if (collection.type == 'mixed') {
      return this.oauth2SignIn.completed$(id);
    }
  }
}
