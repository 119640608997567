import { ChangeDetectionStrategy, Component, Input, OnInit, inject, input, computed, HostListener } from '@angular/core';
import { Window } from '@local/client-contracts';
import { isMac, isNativeWindow } from '@local/common-web';
import { removeFirstBackslash } from '@local/ts-infra';
import { PopupService } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, LogService, WindowService } from '@shared/services';
import { BreadcrumbsService, type Breadcrumb } from '@shared/services/breadcrumbs.service';
import { RouterService } from '@shared/services/router.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { Logger } from '@unleash-tech/js-logger';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map, startWith, distinctUntilChanged } from 'rxjs/operators';
import { HubService } from 'src/app/bar/services/hub.service';
@UntilDestroy()
@Component({
  selector: 'window-controls',
  templateUrl: './window-controls.component.html',
  styleUrls: ['./window-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindowControlsComponent implements OnInit {
  controls = input<Partial<Array<'maximize' | 'minimize' | 'close' | 'pin'>>>([]);
  private routingService: RouterService = inject(RouterService);
  public breadcrumbsService: BreadcrumbsService = inject(BreadcrumbsService);
  private logService: LogService = inject(LogService);
  public titleBarService: TitleBarService = inject(TitleBarService);
  public hubService: HubService = inject(HubService);
  private window: WindowService = inject(WindowService);
  private eventsService: EventsService = inject(EventsService);
  private popupService: PopupService = inject(PopupService);
  canGoBack$: Observable<boolean>;
  canGoForward$: Observable<boolean>;
  back$: Observable<any>;
  forward$: Observable<any>;
  minimalControls$: Observable<boolean>;
  invertMaximizeIcon$: Observable<boolean>;
  private history: Record<string, Breadcrumb[]> = {};
  protected logger: Logger;
  isMac = isMac();
  isNative = isNativeWindow();
  hasClose = computed(() => this.controls()?.includes('close'));
  hasMaximize = computed(() => this.controls()?.includes('maximize'));
  hasMinimize = computed(() => this.controls()?.includes('minimize'));

  constructor() {
    this.logger = this.logService.scope('BreadcrumbsComponent');
  }

  @HostListener('dblclick', ['$event']) async onClick(event: MouseEvent) {
    if (!this.isNative) return;
    if (!(event.target as HTMLElement)?.attributes.getNamedItem('dragArea')) {
      return;
    }
    const location = { title: this.titleBarService.locationTitle };

    this.eventsService.event('header_doubleclick', { location });
    this.eventsService.event(`header.${(await firstValueFrom(this.window.maximized$)) ? 'un' : ''}maximize`, { location });
    if (this.isMac) {
      this.window.maximize();
    }
  }

  ngOnInit(): void {
    this.initObservables();
    this.minimalControls$ = this.popupService.notify$.pipe(
      map(() => !this.popupService.hasGlobalDialog),
      startWith(true)
    );
    this.invertMaximizeIcon$ = this.isMac ? this.window?.isFullScreen$ : this.window?.maximized$;
  }

  private initObservables() {
    this.canGoBack$ = combineLatest([this.routingService.canGoBack$, this.breadcrumbsService.navigationEnabled$]).pipe(
      untilDestroyed(this),
      map(([cgb, ne]) => cgb && ne),
      distinctUntilChanged()
    );
    this.canGoForward$ = combineLatest([this.routingService.canGoForward$, this.breadcrumbsService.navigationEnabled$]).pipe(
      untilDestroyed(this),
      map(([cgf, ne]) => cgf && ne),
      distinctUntilChanged()
    );
    const buildTitle = (b: Breadcrumb[]) => `<span class="no-wrap">${b?.map(({ title }) => title).join(' / ')} </span>`;
    const extractPath = (url: string): string => {
      try {
        if (!url) {
          return;
        }
        const path = url.startsWith('http') ? new URL(url).pathname : new URL(`http://duumy.com/${url}`).pathname;
        return removeFirstBackslash(path);
      } catch (error) {
        this.logger.error('Invalid URL', url);
      }
    };
    combineLatest([this.routingService.currentHistory$.pipe(startWith(null)), this.breadcrumbsService.items$.pipe(startWith(null))])
      .pipe(
        untilDestroyed(this),
        filter(([current, items]) => !!current && !!items)
      )
      .subscribe(([current, items]) => {
        if (current) {
          this.history[extractPath(this.routingService.current)] = items;
        }
      });
    this.back$ = this.routingService.back$.pipe(
      untilDestroyed(this),
      map((back) => extractPath(back)),
      map((back) => this.history[back] && buildTitle(this.history[back])),
      distinctUntilChanged()
    );
    this.forward$ = this.routingService.forward$.pipe(
      untilDestroyed(this),
      map((forward) => extractPath(forward)),
      map((forward) => this.history[forward] && buildTitle(this.history[forward])),
      distinctUntilChanged()
    );
  }
  async onNavClick(direction: 'back' | 'forward'): Promise<void> {
    if (direction === 'back') {
      await this.routingService.back();
      return;
    }
    await this.routingService.forward();
  }
  async onControlClick(event: MouseEvent, type: Window.Controls | 'fullscreen') {
    event.stopPropagation();
    const location = { title: this.titleBarService.locationTitle };
    switch (type) {
      case 'close': {
        this.eventsService.event('header.close', { location });
        this.window.close();
        return;
      }
      case 'minimize': {
        this.eventsService.event('header.minimize', { location });
        this.window.minimize();
        return;
      }
      case 'maximize': {
        this.eventsService.event(`header.${(await firstValueFrom(this.window.maximized$)) ? 'un' : ''}maximize`, { location });
        this.window.maximize();
        return;
      }
      case 'fullscreen': {
        this.eventsService.event('header.full_screen', { location });
        this.window.setFullScreen();
        return;
      }
      case 'pin': {
        const next = !this.window.pinned;
        this.eventsService.event(`header.${next ? 'pin' : 'unpin'}`, { location });
        this.window.pinned = next;
        return;
      }
    }
  }
}
