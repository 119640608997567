import { Search } from '@local/client-contracts';
import { SearchGroup } from 'src/app/bar/models/search-group';
import { SearchRequestFilters } from './search-request-filters';
import { SearchSourceType } from './search-source-type';
export interface SourceSettings {
  id?: string;
  type: SearchSourceType;
  noHeader?: boolean;
  filters?: SearchRequestFilters;
  group?: SearchGroup;
  showHeaderButton?: boolean;
  header?: {
    title?: string;
    titleEnd?: string;
    clickable?: boolean;
  };
  footer?: {
    title?: string;
  };
  showGroupHeader?: boolean;
  sorting?: Search.Sort;
  assistantId?: string;
  ignoreDone?: boolean;
}
