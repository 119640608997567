import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { UiIconModel } from '@local/ui-infra';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AvatarListService } from 'src/app/bar/services/avatar-list.service';
import { AvatarItemModel } from '../../../bar/models/avatar-item.model';

@Component({
  selector: 'avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarListComponent {
  private _model: AvatarItemModel[] = [];
  @Input() set model(value: AvatarItemModel[]) {
    this._model =
      value?.map((v) => {
        return {
          ...v,
          initialsImg: !v?.imgUrl ? this.avatarListService.getInitialImg(v?.name) : null,
        };
      }) || [];
    if (!this.countAll) {
      this.countAll = this.model.length;
    }
  }
  get model(): AvatarItemModel[] {
    return this._model;
  }
  @Input() tooltipHeader?: string;
  @Input() spreadAvatarsCount: number = this.model.length;
  @Input() tooltipLimit: number = this.model.length;
  @Input() sizeAvatar = '20px';
  @Input() sizeAvatarCount = '20px';
  @Input() sizeAvatarTooltip = '20px';
  @Input() countAll: number = this.model.length;
  @Input() fallbackIcon: UiIconModel = { type: 'font', value: 'icon-shared-link' };
  @Input() labelModel: string;
  @Input() avatarText?: string;

  @ViewChildren(OverlayPanel) overlayPanel: QueryList<OverlayPanel>;

  constructor(private avatarListService: AvatarListService) {}

  onHidePanel(i: number) {
    if (this.overlayPanel.get(i)) {
      this.overlayPanel.get(i).overlayVisible = false;
      this.overlayPanel.get(i).cd.markForCheck();
    }
  }
}
