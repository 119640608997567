<ng-container>
  <div *ngIf="workspace" class="user-badge" #avatarElement (click)="onAvatarClick($event)">
    <u-icon class="main-icon" [model]="workspaceIcon" [styles]="{ width: '20px', height: '20px' }"></u-icon>
    <p class="user-badge-name" [pTooltip]="workspace?.name" tooltipPosition="bottom" tooltipStyleClass="u-tooltip">
      {{ workspace?.name }}
    </p>
    <u-icon
      class="user-badge-toggle"
      [model]="{ type: 'font', value: 'icon-chevron-down' }"
      [ngClass]="isUserBadgeOpen ? 'user-badge-toggle-open' : 'user-badge-toggle-close'"
    ></u-icon>
  </div>

  <nav-menu id="nav-menu"></nav-menu>

  <div *ngIf="bottomMenuItems?.length" class="bottom-menu" id="bottom-menu" data-cy="bottom-menu">
    <div
      class="control-button-container"
      (click)="$event.stopPropagation(); onQuickActionsClick()"
      [pTooltip]="isLargeScreen() ? '' : isMac ? '⌘ K' : 'CTRL K'"
      tooltipPosition="left"
      tooltipStyleClass="u-tooltip"
    >
      <control-button id="QuickActions" class="control-button" [icon]="'icon-quick_action'" [title]="'Quick Actions'"> </control-button>
      @if (isLargeScreen()) {
        <div class="command-label">
          <span>{{ isMac ? '⌘ k' : 'ctrl k' }}</span>
        </div>
      }
    </div>
    <div
      class="control-button-container fyi"
      #fyiButton
      (click)="$event.stopPropagation(); onFyiClick()"
      [ngClass]="{ active: (isFyiActive$ | async) }"
    >
      <control-button
        data-cy="bottom-item-fyi"
        id="fyi"
        class="control-button"
        pTooltip="FYI"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        [icon]="isMac ? 'icon-alarm' : 'icon-windows-ringer'"
        [unread]="unreadFyi$ | async"
        [title]="'FYI'"
      >
      </control-button>
    </div>
    <ng-container *ngFor="let item of bottomMenuItems">
      <nav-item [item]="item" [minimized]="true" [tabIndex]="-1" [showTitle]="true"></nav-item>
    </ng-container>
    <div
      class="control-button-container"
      #helpButtonRef
      (click)="$event.stopPropagation(); onHelpClick($event)"
      [ngClass]="{ active: (isHelpActive$ | async) }"
    >
      <control-button
        [attr.data-cy]="'bottom-item-help'"
        id="help"
        pTooltip="Help"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
        class="control-button"
        [icon]="'icon-question'"
        [unread]="false"
        [title]="'Help'"
      >
      </control-button>
    </div>
  </div>

  <trial-banner
    *ngIf="onTrialPlan"
    [bannerText]="bannerText"
    (onClick)="trialBannerClick.emit()"
    [bannerType]="trialBannerType"
    [planDaysLeft]="planDaysLeft"
  ></trial-banner>
</ng-container>
