import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Tab } from '@local/ui-infra';
import { LoaderService } from '@shared/loader.service';
import { RouterService } from '@shared/services/router.service';
import { SIDE_PANEL_PAGE_PATH } from 'src/app/bar/utils/constants';
import { SidePanelService } from '../../services/side-panel.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent implements OnInit {
  private readonly DEFAULT_TAB = 'chat';
  private readonly HOME_TAB = 'home';
  private isTabAction = false;
  readonly sidePanelTabs: Tab[] = [
    { id: 'chat', icon: 'icon-chat-dots', tooltip: 'Chat' },
    { id: 'home', icon: 'icon-search-icon-fixed', tooltip: 'Search' },
  ];
  selectedTab: Tab;

  constructor(
    private loaderService: LoaderService,
    private routerService: RouterService,
    private sidePanelService: SidePanelService
  ) {}

  ngOnInit(): void {
    this.selectedTab = this.sidePanelTabs.find((s: Tab) => s.id == this.DEFAULT_TAB);
    this.loaderService.ready$.next(true);
    this.initializeLastVisitedUrls();
    this.subscribeToActiveRoute();
  }

  private subscribeToActiveRoute(): void {
    this.routerService.activeRoute$.pipe(untilDestroyed(this)).subscribe((currentRoute) => {
      if (!this.isTabAction) {
        this.updateSelectedTab(currentRoute);
      } else {
        this.isTabAction = false;
      }

      if (this.selectedTab) {
        this.sidePanelService.setLastVisitedUrl(this.selectedTab.id, this.routerService.url);
      }
    });
  }

  private initializeLastVisitedUrls(): void {
    this.sidePanelTabs.forEach((tab) => {
      const lastVisitedUrl = this.sidePanelService.getLastVisitedUrl(tab.id);
      if (!lastVisitedUrl) {
        this.sidePanelService.setLastVisitedUrl(tab.id, `${SIDE_PANEL_PAGE_PATH}/${tab.id}`);
      }
    });
  }

  private updateSelectedTab(route: ActivatedRoute): void {
    route.data
      .pipe(
        untilDestroyed(this),
        map((data: { id?: string }) => data?.id),
        filter((currentTabId) => !!currentTabId)
      )
      .subscribe((currentTabId) => {
        const matchedTab = this.sidePanelTabs.find((tab) => tab.id === currentTabId);

        if (matchedTab && matchedTab.id !== this.selectedTab.id) {
          this.selectedTab = matchedTab;
        }
      });
  }

  async setActiveTab(tabId: string): Promise<void> {
    if (this.selectedTab?.id === tabId) {
      return;
    }
    this.isTabAction = true;
    this.selectedTab = this.sidePanelTabs.find((s: Tab) => s.id === tabId);
    const lastVisitedUrl = this.sidePanelService.getLastVisitedUrl(tabId);
    await this.routerService.navigateByUrl(lastVisitedUrl || `${SIDE_PANEL_PAGE_PATH}/${tabId}`);
  }

  openInNewTab(): void {
    if (!this.selectedTab) {
      return;
    }

    if (this.selectedTab.id === this.HOME_TAB && this.routerService.url.includes(this.HOME_TAB)) {
      this.routerService.navigateByUrl('search', {}, true, true);
      return;
    }

    const urlParams = this.routerService.url.replace(/^\/side-panel/, '');
    this.routerService.navigateByUrl(urlParams, null, true, true);
  }
}
