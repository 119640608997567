import { Injectable, OnDestroy } from '@angular/core';
import { Commands, LocalActions, Search } from '@local/client-contracts';
import { PopupService, TelemetryTarget } from '@local/ui-infra';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';
import { isOpenUrlCommandResponse, isSelectParamCommand } from '@shared/utils';
import { Logger } from '@unleash-tech/js-logger';
import { isEqual } from 'lodash';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { CommandBarService } from 'src/app/bar/services/command-bar.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { NavTreeService } from 'src/app/bar/services/nav-tree.service';
import { EventInfoResource, EventsService } from '.';
import { ApplicationsService } from './applications.service';
import { KeyboardService } from './keyboard.service';
import { LinksService } from './links.service';
import { LocalActionsService } from './local-actions.service';
import { LogService } from './log.service';
import { RouterService } from './router.service';
import { SearchPopupItem } from 'src/app/bar/views/special-views/search-popup/model';
import { SearchPopUpService } from 'src/app/bar/views/special-views/search-popup/search-popup.service';
import { startsWihVowel } from '@local/ts-infra';
import { AssistantsIconsConst, Constants } from '@local/common';
import { FlagsService } from './flags.service';
@Injectable()
export class LocalActionsRegistrator implements OnDestroy {
  private logger: Logger;
  private commandBarSubs: Subscription[];
  private readonly destroy$ = new Subject();
  private readonly children = [
    {
      visibility: 'always',
      type: 'child',
      id: 'local-action-create-new-general',
      icon: { type: 'font-icon', value: 'font-icon icon-assistant' },
      title: 'New General Assistant',
      command: {
        type: 'open-page',
        url: 'assistant/new/general',
      },
    },
    {
      visibility: 'always',
      type: 'child',
      id: 'local-action-create-new-rfp-assistant',
      icon: AssistantsIconsConst['rfp'],
      title: 'New RFP Assistant',
      command: {
        type: 'open-page',
        url: 'assistant/new/general',
      },
    },
    {
      visibility: 'always',
      type: 'child',
      id: 'local-action-create-new-salesforce-cases-assistant',
      icon: AssistantsIconsConst['salesforce'],
      title: 'New Salesforce cases Assistant',
      command: {
        type: 'open-page',
        url: 'assistant/new/general',
      },
    },
  ];
  constructor(
    private logService: LogService,
    private localActions: LocalActionsService,
    private commandBarService: CommandBarService,
    private commandsService: CommandsService,
    private popupService: PopupService,
    private keyboardService: KeyboardService,
    private routerService: RouterService,
    private applicationsService: ApplicationsService,
    private navTreeService: NavTreeService,
    private linksService: LinksService,
    private eventsService: EventsService,
    private flagsService: FlagsService,
    private searchPopUpService: SearchPopUpService
  ) {
    this.logger = logService.scope('LocalActionsRegistrator');
  }

  init(): void {
    this.registerToCommandBar();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromCommandBar();
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  private unsubscribeFromCommandBar() {
    if (this.commandBarSubs) {
      this.commandBarSubs.forEach((s) => s.unsubscribe());
    }
  }

  private async registerToCommandBar() {
    const children = [...this.children];
    const [disabledGoLinks, disableCollections, disableWikis] = await Promise.all([
      this.flagsService.isEnabled(Constants.DISABLED_GO_LINKS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_COLLECTIONS_FLAG),
      this.flagsService.isEnabled(Constants.DISABLED_WIKIS_FLAG),
    ]);

    const update$ = combineLatest([
      this.navTreeService.currentNode$.pipe(
        distinctUntilChanged((prev, next) => isEqual(prev?.data?.filters ?? {}, next?.data?.filters ?? {}))
      ),
      this.localActions.all$,
    ]);
    update$.pipe(takeUntil(this.destroy$)).subscribe(async ([node, actions]) => {
      ['contextual-local-actions-parent', 'all-local-actions-parent'].forEach((id) => this.commandBarService.remove(id));
      const filters = node?.data?.filters ?? {};
      const isView = node?.group === 'views';
      if (isView && filters.type?.length) {
        const apps = await this.applicationsService.hasType(filters.type);
        if (apps.length) {
          filters.app = apps.map((a) => a.name);
        }
      }
      const contextualActions = this.predicateActions(actions, filters);

      const context$ = this.commandBarService.add(<SearchPopupItem<'parent'>>{
        id: 'contextual-local-actions-parent',
        command: null,
        icon: null,
        type: 'parent',
        visibility: null,
        title: `Create New in ${isView ? node?.title : this.createContextualCommandBarTitle(filters)}`,
        children: contextualActions.map((i) => this.localActionToSearchPopItem(i)),
        data: { sortId: 'CONTEXTUAL_CREATE' },
        sortBy: 'default',
      });

      if (!disabledGoLinks) {
        children.push({
          visibility: 'always',
          type: 'child',
          id: 'local-action-create-go-link',
          icon: { type: 'font-icon', value: 'font-icon icon-link2' },
          title: 'New Go Link',
          command: null,
        });
      }
      if (!disableCollections) {
        children.push(
          {
            visibility: 'always',
            type: 'child',
            id: 'local-action-create-new-collection',
            icon: { type: 'font-icon', value: 'font-icon icon-curated' },
            title: 'New Curated Collection',
            command: null,
          },
          {
            visibility: 'always',
            type: 'child',
            id: 'local-action-create-new-live-collection',
            icon: { type: 'font-icon', value: 'font-icon icon-live-collection' },
            title: 'New Live Collection',
            command: null,
          }
        );
      }
      if (!disableWikis) {
        children.push(
          {
            visibility: 'always',
            type: 'child',
            id: 'local-action-create-new-wiki-collection',
            icon: { type: 'font-icon', value: 'font-icon icon-wiki' },
            title: 'New Wiki',
            command: null,
          },
          {
            visibility: 'always',
            type: 'child',
            id: 'local-action-create-new-card',
            icon: { type: 'font-icon', value: 'font-icon icon-card' },
            title: 'New Card',
            command: null,
          }
        );
      }

      const all$ = this.commandBarService.add(<SearchPopupItem<'parent'>>{
        id: 'all-local-actions-parent',
        command: null,
        icon: null,
        type: 'parent',
        visibility: null,
        title: 'Create New',
        children: children,
        data: { sortId: 'CREATE' },
        sortBy: 'default',
      });

      this.unsubscribeFromCommandBar();
      this.commandBarSubs = [all$, context$].map((s) =>
        s
          .pipe(
            // filter(({ item: { data } }) => !!data),
            takeUntil(this.localActions.all$.pipe(skip(1)))
          )
          .subscribe(
            ({
              item: {
                command,
                title,
                id,
                data: { context, resourceTitle },
              },
              via,
            }) => {
              this.onInvoke(command, context, { title, id, resourceTitle }, via);
            }
          )
      );
    });
  }

  private predicateActions(actions: LocalActions.LocalActionItem[], filters: Search.RequestFilters): LocalActions.LocalActionItem[] {
    const res = [];
    const { account, app, type, service } = filters;
    for (const action of actions) {
      const appId = action.appId;
      const resourceTitle = action.title;
      const linkId = action.linkId;

      // App match
      if ((app ?? []).includes(this.applicationsService.apps?.[appId]?.name ?? '')) {
        res.push(action);
        continue;
      }

      // Service Match
      if (appId && (this.applicationsService.apps[appId]?.services ?? []).some((s) => service?.includes(s.name.toLowerCase()))) {
        res.push(action);
        continue;
      }

      // Type match
      if ((type ?? []).includes(resourceTitle)) {
        res.push(action);
        continue;
      }

      // Link Match
      if ((account ?? []).includes(this.linksService.links?.[linkId]?.name ?? '')) {
        res.push(action);
        continue;
      }
    }

    return res;
  }

  private createContextualCommandBarTitle(filters: Search.RequestFilters) {
    const { app } = filters;
    if (app?.length) {
      return app[0];
    }
  }

  private localActionToSearchPopItem(action: LocalActions.LocalActionItem): SearchPopupItem<'child'> {
    const id = action.id;
    const firstLinkId = Object.keys(action.urls)[0];
    const linkId = action.urls[firstLinkId][0].linkId;
    const title = action.title;
    const icon = action.icon;
    const appId = action.appId;
    const command = action.command;

    const context: Commands.Context = {
      resource: {
        id,
        kind: 'local-action',
        params: action.params,
      } as Commands.LocalActionContextResource,
      linkId,
    };
    return {
      visibility: 'always',
      type: 'child',
      id: `local-action-${id}`,
      icon,
      title,
      command,
      data: { context, resourceTitle: title ?? 'Account' },
      keywords: [this.applicationsService.apps[appId]?.name ?? '', title ?? ''],
    };
  }

  private getResourceEventData(resources: { id: string; linkId: string }[]) {
    return resources.map(({ id, linkId }, i) => {
      const paramId = id.substring(id.indexOf('_') + 1, id.length);
      return {
        id: paramId,
        list: 'action_params_list',
        type: `action-param:${paramId}`,
        position: i,
        linkId,
      };
    });
  }

  private async onInvoke(
    command: Commands.Command,
    context: Commands.Context & { [key: string]: any },
    data: { [key: string]: any } = {},
    via: TelemetryTarget
  ) {
    const res = await this.commandsService.executeCommand(command, context);

    if (!isOpenUrlCommandResponse(res) || res.data.opened) {
      return;
    }

    const resourceName = new CapitalizePipe().transform(data.resourceTitle, true);
    this.searchPopUpService.placeholder = `Select ${startsWihVowel(resourceName) ? 'an' : 'a'} ${resourceName}`;

    const { recency, highlights, type } = res.data.params;
    const children = [...highlights, ...recency].map<SearchPopupItem<'child'>>(
      ({
        view: {
          title: { text: title, onClick },
          icon,
        },
        appId,
        linkId,
        id,
      }) => {
        return {
          id,
          visibility: 'always',
          type: 'child',
          title,
          icon,
          command: { type: 'select-param', value: onClick },
          data: { linkId, appId, resourceId: type === 'resource' ? id : null, resourceName },
        };
      }
    );
    this.searchPopUpService
      .add({ id: data.id, icon: null, command: null, visibility: null, title: data.title, type: 'parent', children, sortBy: 'default' })
      .subscribe(({ item, via }) => {
        this.onParamSelect(item, via);
        this.searchPopUpService.destroy();
      });
    this.searchPopUpService.open(via, 'LocalAction');
    this.eventsService.event('command_bar.expend_group', {
      location: { title: this.routerService.location },
      //@ts-ignore
      label: data.title.toLowerCase().replaceAll(' ', '_') + '/' + resourceName.toLowerCase().replaceAll(' ', '_'),
    });
  }

  private onParamSelect(item: SearchPopupItem<'child'>, via?: TelemetryTarget) {
    if (!item || !isSelectParamCommand(item.command)) return;
    const {
      id,
      command: { value: command },
      data: { linkId, appId, resourceName },
    } = item;
    this.commandsService.executeCommand(command);
    this.reportRedirectCommandEvent(item.data?.resourceName, via, [{ ...item.data }]);
  }

  private reportRedirectCommandEvent(title: string, via: TelemetryTarget, resources: Partial<EventInfoResource>[]) {
    this.eventsService.event('command_bar.commands', {
      location: { title: this.routerService.active },
      target: via,
      //@ts-ignore
      resources,
      label: title.toLocaleLowerCase().split(':')[0]?.replaceAll(' ', '_'),
      jsonData: JSON.stringify({ commands: { location: 'command_bar' } }),
    });
  }

  private reportRedirectEvent(
    { id, linkId, resourceName, appId }: { id: string; linkId: string; resourceName: string; appId: string },
    via?: TelemetryTarget
  ) {
    if (!linkId || !id) {
      this.logger.warn('Item must have id and data.linkId properties in order to report event ');
      return;
    }
    const label = `${appId}:select_${resourceName}`.replace('/', '_').toLowerCase();
    const event = {
      target: via,
      label,
      resources: this.getResourceEventData([{ id, linkId }]),
      location: { title: this.routerService.location },
    };
    this.eventsService.event('results.redirect', event);
  }
}
