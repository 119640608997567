import { Injectable } from '@angular/core';
import { Config } from '@environments/config';
import { SlackBot } from '@local/client-contracts';
import { Logger } from '@unleash-tech/js-logger';
import { ServicesRpcService } from './rpc.service';
import { SlackBotRpcInvoker } from '@local/common';
import { ReplaySubject, Observable, firstValueFrom } from 'rxjs';
import { isEqual } from 'lodash';
import { LogService } from './log.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class SlackBotService {
  protected logger: Logger;
  private service: SlackBot.Service;
  private slackUrlDataInitiated: boolean;
  private slackUrlData$ = new ReplaySubject<SlackBot.InstallationResponse>(1);
  private nextFetch: number;
  private running: boolean;
  private readonly CACHE_TIME = 5 * 60 * 1000;

  constructor(
    services: ServicesRpcService,
    logService: LogService,
    private sessionService: SessionService
  ) {
    this.service = services.invokeWith(SlackBotRpcInvoker, 'slackbot');
    this.logger = logService.scope('SlackBotService');
  }

  installUrl$(accountId: string, token: string, force = false, redirectUrl?: string): Observable<SlackBot.InstallationResponse> {
    this.innerGetUrlData(accountId, token, force, redirectUrl);
    return this.slackUrlData$.asObservable();
  }

  private async innerGetUrlData(accountId: string, token: string, force: boolean, redirectUrl?: string): Promise<void> {
    if (!accountId || !token) {
      this.logger.error('missing token or accountId', { accountId, token });
      return;
    }
    if (this.running) {
      return;
    }
    const now = Date.now();
    if (!force && now < this.nextFetch) {
      return;
    }
    try {
      this.running = true;
      let url = Config.slackInstallUrl;
      if (redirectUrl) {
        url += `?rel-redirect=${redirectUrl}`;
      }
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-unleash-acc': accountId,
        },
      });
      const res = (await response.json()) as SlackBot.InstallationResponse;
      this.nextFetch = now + this.CACHE_TIME;
      if (!this.slackUrlDataInitiated || !isEqual(res, await firstValueFrom(this.slackUrlData$))) {
        this.slackUrlData$.next(res);
        this.slackUrlDataInitiated = true;
      }
    } catch (error) {
      this.logger.error('failed to fetch slack redirect url', error);
    } finally {
      this.running = false;
    }
  }

  async update(installationId: string, invites: SlackBot.InstallationInvites) {
    return this.service.updateInstallation(installationId, invites);
  }

  async getWorkspaceInstallation(): Promise<SlackBot.InstallationResponse> {
    return this.service.getInstallation();
  }
  async isSlackInstalled() {
    const session = await firstValueFrom(this.sessionService.current$);
    const slackConfig = session.workspace.settings.assistant?.configs.find((config) => config.type === 'Slack');
    const slackEnabled = slackConfig?.enabled;
    if (!slackEnabled) {
      return false;
    }
    const res = await firstValueFrom(this.installUrl$(session?.workspace?.accountId, session.token));
    return res?.installed;
  }
}
